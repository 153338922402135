import Checkbox from '@mui/material/Checkbox';

const GNECheckbox = ({
  checked,
  onChange,
  disabled,
  label,
  wrapperCss,
  size,
}) => {
  return (
    <div
      className={`d-flex align-items-center ${wrapperCss ? wrapperCss : ''}`}>
      <Checkbox
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        className='gne-checkbox'
        size={size}
      />
      <label
        className={disabled ? 'checkbox-label-disabled' : 'checkbox-label'}>
        {label}
      </label>
    </div>
  );
};

export default GNECheckbox;
