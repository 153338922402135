import { createSlice } from '@reduxjs/toolkit';
import { trendsInitaiState } from './trendsInitailState';

const trendsSlice = createSlice({
  name: 'trends',
  initialState: trendsInitaiState,
  reducers: {
    setLocalFilters: (state, action) => {
      state.localFilters = action.payload;
    },
    setCustomerTypes: (state, action) => {
      state.customerTypes = action.payload;
    },
    setAllProductOptions: (state, action) => {
      state.allProductOptions = action.payload;
    },
    setKpiOptions: (state, action) => {
      state.kpiOptions = action.payload;
    },
    setDimensionOptions: (state, action) => {
      state.dimensionOptions = action.payload;
    },
    setTimePeriods: (state, action) => {
      state.timePeriod = action.payload;
    },
    setMarketingFilters: (state, action) => {
      state.marketingFilters = action.payload;
    },
    setChannelFilter: (state, action) => {
      state.marketingFilters.Channel = action.payload;
    },
    setDiscussionTopicFilter: (state, action) => {
      state.marketingFilters.DiscussionTopic = action.payload;
    },
    setStakeholderTypeFilter: (state, action) => {
      state.marketingFilters.PayerStakeholderType = action.payload;
    },
    setTemplateNameFilter: (state, action) => {
      state.marketingFilters.TemplateName = action.payload;
    },
    setSurveyTypeFilter: (state, action) => {
      state.marketingFilters.SurveyType = action.payload;
    },
    setPresentationNameFilter: (state, action) => {
      state.marketingFilters.PresentationName = action.payload;
    },
    setKeyMessageFilter: (state, action) => {
      state.marketingFilters.KeyMessage = action.payload;
    },
    setIsAccountAvailable: (state, action) => {
      state.isAccountAvailable = action.payload;
    },
    setViewName: (state, action) => {
      state.viewName = action.payload;
    },
    setChartData: (state, action) => {
      state.chartData = action.payload;
    },
    setChartType: (state, action) => {
      state.chartType = action.payload;
    },
    setOldChartType: (state, action) => {
      state.oldChartType = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    // setCards: (state, action) => {
    //   state.cards = action.payload;
    // },
    setDefaultCard: (state, action) => {
      state.defaultCards = action.payload;
    },
    setTimespan: (state, action) => {
      state.timespan = action.payload;
    },
    setSelectedKPI: (state, action) => {
      state.selectedKPI = action.payload;
    },
    setSelectedDimension: (state, action) => {
      state.selectedDimension = action.payload;
    },
    setKPIclickedStatus: (state, action) => {
      state.KPIselectedStatus = action.payload;
    },
    setExpression: (state, action) => {
      state.expression = action.payload;
    },
    setNoDimensionValue: (state, action) => {
      state.noDimensionValue = action.payload;
    },
    setBenchmarkNoDimensionValue: (state, action) => {
      state.benchmarkNoDimensionValue = action.payload;
    },
    setSelectedAppId: (state, action) => {
      state.selectedAppId = action.payload;
    },
    setShowSuccess: (state, action) => {
      state.showSuccess = action.payload;
    },
    setSelectedAppLink: (state, action) => {
      state.selectedAppLink = action.payload;
    },
    setSelectedCardView: (state, action) => {
      state.selectedCardView = action.payload;
    },
    setAdvanceButtonStatus: (state, action) => {
      state.advanceButtonValue = action.payload;
    },
    setAdvanceOptions: (state, action) => {
      state.advanceOptions = action.payload;
    },
    setAdvanceDropdownOptions: (state, action) => {
      state.advanceDropdownOptions = action.payload;
    },
    setSystemAlertsData: (state, action) => {
      state.systemAlertsData = action.payload;
    },
    setNotificationssData: (state, action) => {
      state.notificationsData = action.payload;
    },
    setWhatsNewDatas: (state, action) => {
      state.whatsNewData = action.payload;
    },
    // New Actions
    setDynamicFilter: (state, action) => {
      state.KPIFilter = action.payload;
    },
    setLocalFiltersData: (state, action) => {
      state.localFiltersData = action.payload;
    },
    setLocalFilterDependencyData: (state, action) => {
      state.localFiltersDependencyData = action.payload;
    },
    setEcosystem: (state, action) => {
      state.KPIFilter = {
        ...state.KPIFilter,
        Ecosystem: { ...state.KPIFilter.Ecosystem, ...action.payload },
      };
    },
    setEcosystemOptions: (state, action) => {
      state.KPIFilter = {
        ...state.KPIFilter,
        Ecosystem: { ...state.KPIFilter.Ecosystem, options: action.payload },
      };
    },
    setSelectedEcosystem: (state, action) => {
      state.KPIFilter = {
        ...state.KPIFilter,
        Ecosystem: { ...state.KPIFilter.Ecosystem, value: action.payload },
      };
    },
    setSquad: (state, action) => {
      state.KPIFilter = {
        ...state.KPIFilter,
        Squad: { ...state.KPIFilter.Squad, ...action.payload },
      };
    },
    setSquadOptions: (state, action) => {
      state.KPIFilter = {
        ...state.KPIFilter,
        Squad: { ...state.KPIFilter.Squad, options: action.payload },
      };
    },
    setSelectedSquad: (state, action) => {
      state.KPIFilter = {
        ...state.KPIFilter,
        Squad: { ...state.KPIFilter.Squad, value: action.payload },
      };
    },
    setProduct: (state, action) => {
      state.KPIFilter = {
        ...state.KPIFilter,
        Product: { ...state.KPIFilter.Product, ...action.payload },
      };
    },
    setProductOptions: (state, action) => {
      state.KPIFilter = {
        ...state.KPIFilter,
        Product: { ...state.KPIFilter.Product, options: action.payload },
      };
    },
    setSelectedProduct: (state, action) => {
      state.KPIFilter = {
        ...state.KPIFilter,
        Product: { ...state.KPIFilter.Product, value: action.payload },
      };
    },
    setTimePeriod: (state, action) => {
      state.KPIFilter = {
        ...state.KPIFilter,
        TimePeriod: { ...state.KPIFilter.TimePeriod, ...action.payload },
      };
    },
    setTimePeriodOptions: (state, action) => {
      state.KPIFilter = {
        ...state.KPIFilter,
        TimePeriod: { ...state.KPIFilter.TimePeriod, options: action.payload },
      };
    },
    setSelectedTimePeriod: (state, action) => {
      state.KPIFilter = {
        ...state.KPIFilter,
        TimePeriod: { ...state.KPIFilter.TimePeriod, value: action.payload },
      };
    },
    setDefaultTimePeriod: (state, action) => {
      state.defaultTimePeriod = action.payload;
    },
  },
});

export const {
  setKPIclickedStatus,
  setDynamicFilter,
  setAdvanceButtonStatus,
  setAdvanceOptions,
  setBenchmarkNoDimensionValue,
  setChartData,
  setChartType,
  setNoDimensionValue,
  setSelectedCardView,
  setSelectedKPI,
  setSelectedDimension,
  setSelectedTimePeriod,
  setEcosystem,
  setEcosystemOptions,
  setSelectedEcosystem,
  setSquad,
  setSquadOptions,
  setSelectedSquad,
  setProduct,
  setProductOptions,
  setSelectedProduct,
  setKpiOptions,
  setDefaultCard,
  // setCards,
  setTableData,
  setOldChartType,
  setViewName,
  setIsAccountAvailable,

  setKeyMessageFilter,
  setPresentationNameFilter,
  setSurveyTypeFilter,
  setTemplateNameFilter,
  setStakeholderTypeFilter,
  setDiscussionTopicFilter,
  setChannelFilter,
  setMarketingFilters,
  setTimePeriods,
  setTimePeriodOptions,
  setDimensionOptions,
  setAllProductOptions,
  setCustomerTypes,
  setLocalFilters,
  setTimespan,
  setExpression,
  setSelectedAppId,
  setShowSuccess,
  setSelectedAppLink,
  setAdvanceDropdownOptions,
  setSystemAlertsData,
  setNotificationssData,
  setWhatsNewDatas,
  setLocalFiltersData,
  setDefaultTimePeriod,
  setLocalFilterDependencyData,
} = trendsSlice.actions;

export const trendsSliceActions = trendsSlice.actions;

export default trendsSlice.reducer;
