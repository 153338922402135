import RiseLoader from 'react-spinners/RiseLoader';

const Loader = ({ component, size }) => {
  const override = {
    display: 'block',
    margin: 'auto',
    position: 'absolute',
    top: component === 'Insights' ? '25%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  };

  return (
    <RiseLoader
      color='#004677'
      cssOverride={override}
      size={size || 8}
      aria-label='Loading Spinner'
    />
  );
};

export default Loader;
