import { createSlice } from '@reduxjs/toolkit';
import { engagementInitialState } from './engagement-initial-state';

const engagementViewSlice = createSlice({
  name: 'engagementView',
  initialState: engagementInitialState,
  reducers: {
    setEngagements: (state, action) => {
      state.engagements = action.payload;
    },
    setAllInformation: (state, action) => {
      state.allInformation = action.payload;
    },
  },
});

export const { setEngagements, setAllInformation } =
  engagementViewSlice.actions;
export default engagementViewSlice.reducer;
