/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { Input } from 'antd';

import { searchData } from 'utils/utils';

import classes from './ExportTables.module.scss';
import LinkButton from 'components/shared/button/LinkButton';
import GNEModal from 'components/shared/modal/Modal';
import { useDispatch } from 'react-redux';
import { SITE_UTILIZATION_TABLE } from 'constants/constants';
import { exportData } from 'store/customer/customer-view-actions';

const ExportTables = ({
  allColumnData,
  setAllColumnData,
  updatedColumnData,
  setupdatedColumnData,
  expression,
  ...props
}) => {
  const qlikToken = localStorage.getItem('qlikToken');
  const qlikCookie = localStorage.getItem('qlikCookie');
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');
  const [searchedData, setSearchedData] = useState(allColumnData);
  const { Search } = Input;

  useEffect(() => {
    handleReset();
    const tempData = [...allColumnData];
    setSearchedData(tempData);
  }, [allColumnData]);

  const handleChange = (e, item) => {
    const isObjectEqual = (obj1, obj2) => {
      return obj1.dataIndex === obj2.dataIndex;
    };
    const indexOfObject = updatedColumnData.findIndex((obj) =>
      isObjectEqual(obj, item)
    );
    if (indexOfObject !== -1) {
      setupdatedColumnData(
        [...updatedColumnData].filter((i) => !isObjectEqual(i, item))
      );
    } else {
      setupdatedColumnData([...updatedColumnData, item]);
    }
    setAllColumnData(
      allColumnData?.map((Data) => {
        if (Data?.dataIndex === item?.dataIndex) {
          const updatedData = {
            ...Data,
            isSelected: e?.target?.checked ? 'Y' : 'N',
          };
          return updatedData;
        }

        return Data;
      })
    );
  };

  const handleSearch = (value) => {
    const filteredData = searchData(allColumnData, value);
    setSearchText(value);
    setSearchedData(filteredData);
  };
  const handleReset = () => {
    setSearchedData(allColumnData);
    setSearchText('');
  };
  const isSave = updatedColumnData?.length === 0;

  const exportList = () => {
    const dimension = allColumnData
      .filter((column) => column?.isSelected === 'Y')
      ?.map((col) => col?.qFieldDefs?.[0]);

    const dimensions = JSON.stringify(dimension);
    dispatch(
      exportData(
        SITE_UTILIZATION_TABLE,
        dimensions,
        qlikCookie,
        qlikToken,
        expression
      )
    );
    props.onHide();
  };
  const header = (
    <>
      <p className='title title__bold mb-0'>
        <i className={`icon icon__affiliations_export_excel`} />
        Export Tables
      </p>
      <p className={`mb-3 mt-2 title title__small`}>
        Select columns to export to Excel
      </p>
      <Search
        placeholder='Search'
        onSearch={handleSearch}
        allowClear
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onReset={handleReset}
        className={classes['custom-row-padding']}
      />
      <div className={`${classes['custom-row-text']} mt-2 mb-2 p-0`}>
        <LinkButton onClick={props.handleClearAll}>Clear All</LinkButton>
        <LinkButton onClick={props.handleSelectAll}>Select All</LinkButton>
      </div>
    </>
  );

  const content = (
    <>
      <Table
        className={`custom-table gne-table ${classes.fixSize}`}
        hover>
        <tbody className={`${classes.selectedList} `}>
          {searchedData?.map((Data, index) => (
            <tr
              key={index}
              className={Data?.selected ? 'selected' : ''}>
              <td className={classes['column-names']}>{Data?.title}</td>
              <th
                className={classes['column-checkbox']}
                scope='row'>
                <input
                  type='checkbox'
                  checked={Data?.isSelected === 'Y'}
                  className={
                    Data?.isSelected === 'Y'
                      ? `${classes.btnBgColor} form-check-input`
                      : 'form-check-input'
                  }
                  id='rowcheck{Data.DatasName}'
                  onChange={(e) => handleChange(e, Data)}
                />
              </th>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );

  const footer = (
    <div className={`d-flex justify-content-end w-100 `}>
      <Link
        className='title__normal text-decoration-none me-4 mt-1'
        onClick={props.onHide}>
        Cancel
      </Link>

      <Button
        disabled={isSave}
        variant={`btn btn-gne btn-primary`}
        onClick={() => exportList()}>
        Export
      </Button>
    </div>
  );

  return (
    <GNEModal
      show={props.modalshow}
      onHide={props.onHide}
      content={content}
      footer={footer}
      titlecontent={header}
      css='size-export-lg'
      backdrop='static'
    />
  );
};

export default ExportTables;
