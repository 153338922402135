/* eslint-disable react-hooks/exhaustive-deps */
import SelectWithPagination from 'components/shared/dropdown-with-pagination/SelectWithPagination';
import React, { useCallback, useState } from 'react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-native-uuid';

import 'react-datepicker/dist/react-datepicker.css';
import Pill from 'components/shared/pills/Pill';
import classes from './EngagementFilters.module.scss';

import { setSquad } from 'store/customer/customerViewSlice';
import DatePickerRange from './DatePickerRange';

const EngagementFilters = () => {
  const dispatch = useDispatch();
  const [menuIsOpen, setMenuIsOpen] = useState({
    StakeholderName: false,
    StakeholderType: false,
    Engagement: false,
    Squad: false,
    Brand: false,
    Indication: false,
    GNE_User_Name: false,
    GENE_User_Role: false,
    EngagementEcosystem: false,
  });

  const engagementFilters = useSelector(
    (state) => state?.CustomerView?.engagementFilters
  );
  const selectedCustomer = useSelector(
    (state) => state?.CustomerView?.selectedCustomer
  );
  const selectedStartDate = useSelector(
    (state) => state?.EngagementFilters?.startDate
  );

  const selectedEndDate = useSelector(
    (state) => state?.EngagementFilters?.endDate
  );
  const squadSelectedValue = engagementFilters?.Squad?.selectedValue;

  const handleSquadClose = (value) => {
    const filteredData = squadSelectedValue.filter((item) => item !== value);

    dispatch(
      setSquad({ ...engagementFilters['Squad'], selectedValue: filteredData })
    );
  };
  const onMenuOpen = useCallback((filterName, value) => {
    let menuIsOpenLocal = menuIsOpen;
    if (filterName !== '' && value) {
      menuIsOpenLocal = Object.keys(menuIsOpen).reduce((acc, key) => {
        return { ...acc, [key]: filterName === key };
      }, {});
    } else if ((filterName !== '' && !value) || filterName === '') {
      menuIsOpenLocal = Object.keys(menuIsOpen).reduce((acc, key) => {
        return { ...acc, [key]: false };
      }, {});
    }
    setMenuIsOpen({ ...menuIsOpenLocal });
  }, []);

  const EcosystemTooltip = (
    <Tooltip bsclass='gne-tooltip tooltip--left'>
      Please note that applying the ecosystem filter may <br />
      exclude certain engagements, like house emails <br />
      and medical inquiries, as they currently can’t be
      <br />
      tracked at the ecosystem level.
    </Tooltip>
  );

  return (
    <>
      <Row className='mb-3 mt-3'>
        <DatePickerRange />
        <Col>
          <label className='title__x-small d-block'>Stakeholder Name</label>
          <SelectWithPagination
            menuIsOpen={menuIsOpen}
            onMenuOpen={onMenuOpen}
            filter={engagementFilters}
            fieldName='StakeholderName'
            isMulti={true}
            isTitleCase={true}
            cacheUniqs={[selectedCustomer, selectedStartDate, selectedEndDate]}
          />
        </Col>
        <Col>
          <label className='title__x-small d-block'>Stakeholder Type</label>
          <SelectWithPagination
            menuIsOpen={menuIsOpen}
            onMenuOpen={onMenuOpen}
            filter={engagementFilters}
            fieldName='StakeholderType'
            isMulti={true}
            cacheUniqs={[selectedCustomer, selectedStartDate, selectedEndDate]}
          />
        </Col>
        <Col>
          <label className='title__x-small d-block'>Engagements</label>
          <SelectWithPagination
            menuIsOpen={menuIsOpen}
            onMenuOpen={onMenuOpen}
            filter={engagementFilters}
            fieldName='Engagement'
            isMulti={true}
            isSelectAll={true}
            cacheUniqs={[selectedCustomer, selectedStartDate, selectedEndDate]}
          />
        </Col>
        <Col>
          <label className='title__x-small d-block'>Squad</label>
          <SelectWithPagination
            menuIsOpen={menuIsOpen}
            onMenuOpen={onMenuOpen}
            filter={engagementFilters}
            fieldName='Squad'
            isMulti={true}
            cacheUniqs={[selectedCustomer, selectedStartDate, selectedEndDate]}
          />
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <label className='title__x-small d-block'>Brand</label>
          <SelectWithPagination
            menuIsOpen={menuIsOpen}
            onMenuOpen={onMenuOpen}
            filter={engagementFilters}
            fieldName='Brand'
            isMulti={true}
            cacheUniqs={[selectedCustomer, selectedStartDate, selectedEndDate]}
          />
        </Col>
        <Col md={2}>
          <label className='title__x-small d-block'>Indication</label>
          <SelectWithPagination
            menuIsOpen={menuIsOpen}
            onMenuOpen={onMenuOpen}
            filter={engagementFilters}
            fieldName='Indication'
            isMulti={true}
            cacheUniqs={[selectedCustomer, selectedStartDate, selectedEndDate]}
          />
        </Col>
        <Col md={2}>
          <label className='title__x-small d-block'>User Name</label>
          <SelectWithPagination
            menuIsOpen={menuIsOpen}
            onMenuOpen={onMenuOpen}
            filter={engagementFilters}
            fieldName='GNE_User_Name'
            isMulti={true}
            cacheUniqs={[selectedCustomer, selectedStartDate, selectedEndDate]}
          />
        </Col>
        <Col md={2}>
          <label className='title__x-small d-block'>User Role</label>
          <SelectWithPagination
            menuIsOpen={menuIsOpen}
            onMenuOpen={onMenuOpen}
            filter={engagementFilters}
            fieldName='GENE_User_Role'
            isMulti={true}
            cacheUniqs={[selectedCustomer, selectedStartDate, selectedEndDate]}
          />
        </Col>
        <Col md={2}>
          <div className='d-flex align-items-center'>
            <label className='title__x-small'>Ecosystem</label>
            <OverlayTrigger
              placement='top'
              overlay={EcosystemTooltip}>
              <i className='icon icon__info__gray ms-2 mb-2' />
            </OverlayTrigger>
          </div>
          <SelectWithPagination
            menuIsOpen={menuIsOpen}
            onMenuOpen={onMenuOpen}
            filter={engagementFilters}
            fieldName='EngagementEcosystem'
            isMulti={true}
            cacheUniqs={[selectedCustomer, selectedStartDate, selectedEndDate]}
          />
        </Col>
        <Col></Col>
      </Row>
      <div className='gne-pill'>
        {squadSelectedValue?.map((item) => (
          <Pill
            value={item}
            handleClose={handleSquadClose}
            key={uuid.v4()}
          />
        ))}
      </div>

      {/* legend */}
      <Row>
        <Col className='mb-2'>
          <div className={classes.legend}>
            <span className='me-2'>
              <i className='circle-blue mt-4' />
              Internal
            </span>
            <span>
              <i className='circle-green' />
              External
            </span>
          </div>
        </Col>
      </Row>
      <div className='divider-horizontal'></div>
    </>
  );
};

export default EngagementFilters;
