export const AuthInitialState = {
  token: {
    accessToken: '',
    isLoading: true,
  },
  userClaimObject: {
    name: '',
    email: '',
    unixId: '',
    roleId: '',
    id: '',
  },
  restrictedProductSquad: [],
  userRestrictedEcosystemt: [],
  userRestriction: [],
  wrapperObj: [],
  favReportObj: [],
  qlikAuth: {
    qlikToken: null,
    qlikCookie: null,
  },
  componentAccessList: [],
};
