import React from 'react';
import { Card } from 'react-bootstrap';

import { ACCOUNT_TYPE } from 'constants/constants';

import LabelTags from '../label/LabelTags';
import iconSite from '../../../../assets/images/svg/site.jpg';
import iconCorpParent from '../../../../assets/images/svg/corp_parent.jpg';
import iconDoctor from '../../../../assets/images/svg/icon_user_doctor_solid.jpg';
import classes from './OrganizationalChart.module.scss';

const TreeCard = (props) => {
  const hasChildren = props.nodeData._totalSubordinates > 0;
  const isCorpParent =
    props.nodeData?.class?.toLowerCase() === ACCOUNT_TYPE.corp;

  const commonStyle = {
    width: props.width,
    height: props.height,
    border: '1px solid #919698',
    boxShadow: 'none',
    borderRadius: '10px',
    paddingLeft: '16px',
    paddingTop: '16px',
    background: '#fff',
    lineHeight: '120%',
  };
  const corpParent = {
    ...commonStyle,
    borderTop: '5px solid #6666CC',
  };
  const cardStyle = {
    ...commonStyle,
    borderTop: '5px solid #66CC66',
    borderColor: '#66CC66',
  };
  const parentStyle = {
    ...cardStyle,
    borderColor: '#a2c1d9',
  };
  const cardTitle = {
    color: '#004677',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    marginBottom: '8px',
    overflow: 'hidden',
    width: '250px',
  };
  const iconWrapperStyle = {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '6px',
  };
  const iconStyle = {
    display: 'inline-block',
    marginRight: '8px',
    width: '30px',
  };
  const wrapperStyle = {
    width: props.width,
    height: props.height,
    paddingLeft: '-16px',
  };

  const additionalDataStyle = {
    color: '#919698',
    fontSize: '0.625rem',
    marginBottom: 0,
  };

  const space = {
    width: '10px',
    display: 'inline-block',
  };

  const marginTop = {
    marginTop: '10px',
  };

  const labelWidth = {
    maxWidth: '50px',
  };

  const nodeIconWrapper = {
    display: 'flex',
    float: 'right',
    marginTop: '2px',
    padding: '0 10px',
  };

  const doctorIcon = {
    width: '10px',
    height: '11px',
    marginRight: '5px',
  };

  return (
    <div style={wrapperStyle}>
      <Card
        className={`gne-card`}
        style={
          isCorpParent ? corpParent : hasChildren ? parentStyle : cardStyle
        }>
        {/* title */}
        <div className='d-flex align-items-center justify-content-end'>
          <p style={cardTitle}>{props.nodeData?.name}</p>

          <div className={classes['node-icon-wrap']}>
            {/* shared site icon */}
            {props.nodeData?.shared === 'Y' && (
              <i className='icon icon__shared card-icon m-1'></i>
            )}
          </div>
        </div>

        <div style={iconWrapperStyle}>
          <img
            src={!props?.nodeData?.parentId ? iconCorpParent : iconSite}
            alt='Node Icon'
            style={iconStyle}
            id={props.nodeData.id}
          />

          <div>
            <div style={additionalDataStyle}>{props.nodeData?.address}</div>

            <div>
              {props.nodeData?.classification && (
                <div style={additionalDataStyle}>
                  <strong>Classification</strong>:{' '}
                  {props.nodeData?.classification}
                  <span style={space}></span>
                </div>
              )}
            </div>

            <div>
              {props.nodeData?.hin && (
                <span style={additionalDataStyle}>
                  <strong>HIN</strong>: {props.nodeData?.hin}
                  <span style={space}></span>
                </span>
              )}

              {props?.nodeData?.dea && (
                <span style={additionalDataStyle}>
                  <strong>DEA</strong>: {props?.nodeData?.dea}
                  <span style={space}></span>
                </span>
              )}
            </div>
          </div>
        </div>

        {/* labels */}
        {props?.labelAccess &&
          props?.nodeData?.parentId &&
          props?.nodeData?.labels?.length > 0 && (
            <div style={marginTop}>
              <LabelTags
                record={props.nodeData?.labels}
                showClose={false}
                style={labelWidth}
              />
            </div>
          )}

        <div style={nodeIconWrapper}>
          {props?.nodeData?.StakeholderCount &&
            props?.nodeData?.StakeholderCount > 0 && (
              <>
                <img
                  src={iconDoctor}
                  style={doctorIcon}
                  alt='Doctor'
                />
                {props?.nodeData?.StakeholderCount}
              </>
            )}
        </div>
      </Card>
    </div>
  );
};

export default TreeCard;
