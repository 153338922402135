/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import classes from './Filter.module.scss';

import LinkButton from 'components/shared/button/LinkButton';
import SelectWithPagination from 'components/shared/dropdown-with-pagination/SelectWithPagination';
import { useDispatch, useSelector } from 'react-redux';
import {
  setHCPCity,
  setHCPName,
  setHCPSpeciality,
  setHCPStakeholderType,
  setHCPState,
  setHCPZip,
} from 'store/customer/customerViewSlice';
import { FLAG, OS_FLAG } from 'constants/constants';

const SiteInfoFilters = (props) => {
  const dispatch = useDispatch();
  const APP_ID = process.env.REACT_APP_AFFILIATION_APP_ID;
  const siteInfoFilters = useSelector(
    (state) => state?.CustomerView?.siteInfoFilters
  );

  const selectedHcpName = useSelector(
    (state) => state?.CustomerView?.siteInfoFilters?.HCPName
  );

  const selectedStakeholderType = useSelector(
    (state) => state?.CustomerView?.siteInfoFilters?.HCPStakeholderType
  );

  const selectedSpeciality = useSelector(
    (state) => state?.CustomerView?.siteInfoFilters?.Speciality
  );

  const selectedState = useSelector(
    (state) => state?.CustomerView?.siteInfoFilters?.State
  );

  const selectedCity = useSelector(
    (state) => state?.CustomerView?.siteInfoFilters?.City
  );

  const selectedZip = useSelector(
    (state) => state?.CustomerView?.siteInfoFilters?.Zip
  );

  useEffect(() => {
    let isFilterApplied =
      (selectedHcpName?.selectedValue?.length > 0 ||
        selectedHcpName?.selectedValue?.length > 0 ||
        selectedStakeholderType?.selectedValue?.length > 0 ||
        selectedSpeciality?.selectedValue?.length > 0 ||
        selectedState?.selectedValue?.length > 0 ||
        selectedCity?.selectedValue?.length > 0 ||
        selectedZip?.selectedValue?.length) > 0
        ? true
        : false;
    if (isFilterApplied) {
      setResetFilter(false);
      setIsApply(true);
    } else {
      setResetFilter(true);
    }
  }, [
    selectedHcpName,
    selectedStakeholderType,
    selectedSpeciality,
    selectedState,
    selectedCity,
    selectedZip,
  ]);

  const [resetFilter, setResetFilter] = useState(props.isFilterReset);
  const [isApply, setIsApply] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState({
    HCPName: false,
    HCPStakeholderType: false,
    Speciality: false,
    State: false,
    City: false,
    Zip: false,
  });

  const onMenuOpen = useCallback((filterName, value) => {
    let menuIsOpenLocal = menuIsOpen;
    if (filterName !== '' && value) {
      menuIsOpenLocal = Object.keys(menuIsOpen).reduce((acc, key) => {
        return { ...acc, [key]: filterName === key };
      }, {});
    } else if ((filterName !== '' && !value) || filterName === '') {
      menuIsOpenLocal = Object.keys(menuIsOpen).reduce((acc, key) => {
        return { ...acc, [key]: false };
      }, {});
    }
    setMenuIsOpen({ ...menuIsOpenLocal });
  }, []);

  const resetOptions = () => {
    dispatch(setHCPName([]));
    dispatch(setHCPCity([]));
    dispatch(setHCPSpeciality([]));
    dispatch(setHCPStakeholderType([]));
    dispatch(setHCPState([]));
    dispatch(setHCPZip([]));
    setResetFilter(true);
  };

  const handleApply = () => {
    setIsApply(true);
    if (!props.site.value) {
      return;
    }
    onMenuOpen('', false);

    const hcpName =
      selectedHcpName?.selectedValue?.length > 0
        ? ',[HCP Name]={"' +
          selectedHcpName?.selectedValue?.map((a) => a?.label).join('","') +
          '"}'
        : '';

    const hcpStakeholderType =
      selectedStakeholderType?.selectedValue?.length > 0
        ? ',[Stakeholder_Type]={"' +
          selectedStakeholderType?.selectedValue
            ?.map((a) => a?.label)
            .join('","') +
          '"}'
        : '';

    const speciality =
      selectedSpeciality?.selectedValue?.length > 0
        ? ',[HCP Speciality]={"' +
          selectedSpeciality?.selectedValue?.map((a) => a?.label).join('","') +
          '"}'
        : '';

    const state =
      selectedState?.selectedValue?.length > 0
        ? ',[HCP State]={"' +
          selectedState?.selectedValue?.map((a) => a?.label).join('","') +
          '"}'
        : '';

    const city =
      selectedCity?.selectedValue?.length > 0
        ? ',[HCP City]={"' +
          selectedCity?.selectedValue?.map((a) => a?.label).join('","') +
          '"}'
        : '';

    const zip =
      selectedZip?.selectedValue?.length > 0
        ? ',[HCP Zip]={"' +
          selectedZip?.selectedValue?.map((a) => a?.label).join('","') +
          '"}'
        : '';

    const filterExp = `
      {${props.site.value}} ${hcpName} ${hcpStakeholderType} ${speciality} ${state} ${city} ${zip}
    `;

    props.onFilterApply(filterExp);
  };

  return (
    <div
      className={`animate__animated animate__fadeIn w-100 ${classes['filter-container-actions']} ${classes.info}`}>
      <div
        className={`d-flex align-items-center mb-2 ${classes['filter-wrap']}`}>
        <span className={classes['filters-title']}>Filters</span>

        <LinkButton
          className={`text text__small float-right ${
            resetFilter ? classes['reset-btn'] : ''
          }`}
          onClick={() => resetOptions()}>
          Reset
        </LinkButton>
      </div>

      <Row>
        <Col
          md={2}
          className='mb-3'>
          <label className='title__x-small mb-1'>Stakeholder Name</label>
          <SelectWithPagination
            menuIsOpen={menuIsOpen}
            onMenuOpen={onMenuOpen}
            filter={siteInfoFilters}
            fieldName='HCPName'
            isMulti={true}
            view={
              props.site?.flag === OS_FLAG
                ? 'account'
                : props.site?.flag === FLAG.account
                ? 'account'
                : 'site'
            }
            appid={APP_ID}
            account={props.site}
            isDefault={true}
          />
        </Col>
        <Col
          md={2}
          className='mb-3'>
          <label className='title__x-small mb-1'>Stakeholder Type</label>
          <SelectWithPagination
            menuIsOpen={menuIsOpen}
            onMenuOpen={onMenuOpen}
            filter={siteInfoFilters}
            fieldName='HCPStakeholderType'
            isMulti={true}
            view={
              props.site?.flag === OS_FLAG
                ? 'account'
                : props.site?.flag === FLAG.account
                ? 'account'
                : 'site'
            }
            appid={APP_ID}
            account={props.site}
            isDefault={true}
          />
        </Col>
        <Col
          md={2}
          className='mb-3'>
          <label className='title__x-small mb-1'>Speciality</label>
          <SelectWithPagination
            menuIsOpen={menuIsOpen}
            onMenuOpen={onMenuOpen}
            filter={siteInfoFilters}
            fieldName='Speciality'
            isMulti={true}
            view={
              props.site?.flag === OS_FLAG
                ? 'account'
                : props.site?.flag === FLAG.account
                ? 'account'
                : 'site'
            }
            appid={APP_ID}
            account={props.site}
            isDefault={true}
          />
        </Col>

        <Col
          md={2}
          className='mb-3'>
          <label className='title__x-small mb-1'>State</label>
          <SelectWithPagination
            menuIsOpen={menuIsOpen}
            onMenuOpen={onMenuOpen}
            filter={siteInfoFilters}
            fieldName='State'
            isMulti={true}
            view={
              props.site?.flag === OS_FLAG
                ? 'account'
                : props.site?.flag === FLAG.account
                ? 'account'
                : 'site'
            }
            appid={APP_ID}
            account={props.site}
            isDefault={true}
          />
        </Col>
        <Col
          md={2}
          className='mb-3'>
          <label className='title__x-small mb-1'>City</label>
          <SelectWithPagination
            menuIsOpen={menuIsOpen}
            onMenuOpen={onMenuOpen}
            filter={siteInfoFilters}
            fieldName='City'
            isMulti={true}
            view={
              props.site?.flag === OS_FLAG
                ? 'account'
                : props.site?.flag === FLAG.account
                ? 'account'
                : 'site'
            }
            appid={APP_ID}
            account={props.site}
            isDefault={true}
          />
        </Col>
        <Col
          md={2}
          className='mb-3'>
          <label className='title__x-small mb-1'>ZIP</label>
          <SelectWithPagination
            menuIsOpen={menuIsOpen}
            onMenuOpen={onMenuOpen}
            filter={siteInfoFilters}
            fieldName='Zip'
            isMulti={true}
            view={
              props.site?.flag === OS_FLAG
                ? 'account'
                : props.site?.flag === FLAG.account
                ? 'account'
                : 'site'
            }
            appid={APP_ID}
            account={props.site}
            isDefault={true}
          />
        </Col>
      </Row>

      <div className='d-flex justify-content-center'>
        <Button
          variant={`btn btn-gne btn-primary mt-2 ${classes.apply}`}
          disabled={!isApply}
          onClick={handleApply}>
          Apply
        </Button>
      </div>
    </div>
  );
};

export default SiteInfoFilters;
