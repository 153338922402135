import { Link, NavLink } from 'react-router-dom';
import classes from './LinkList.module.scss';

const LinkList = (props) => {
  return (
    <ul className={classes['link-list']}>
      {props.items?.map((item, index) =>
        item.isFavorite === 'Y' && item.isExternal === 'Y' ? (
          <Link
            target={'_blank'}
            to={item.reportUrl}>
            <li>
              {' '}
              {item.reportName}
              <i className='icon icon__external-blue float-right mt-1 ms-1'></i>
            </li>
          </Link>
        ) : item.isFavorite === 'Y' ? (
          <NavLink
            key={item.reportName + index}
            to={'/reportDashboard'}
            state={{ url: item.reportUrl }}>
            <li>{item.reportName}</li>
          </NavLink>
        ) : (
          ''
        )
      )}
    </ul>
  );
};

export default LinkList;
