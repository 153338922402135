import classes from './Ratings.module.scss';

const Rating = ({ number, onClick, current }) => {
  return (
    <div
      id={number}
      className={`mb-1 d-flex
        ${classes['ratings-circle']} 
        ${current === number ? classes.active : ''} 
        ${number === 10 ? classes.last : ''}
      `}
      onClick={onClick}
      title={number}>
      {number}
    </div>
  );
};

export default Rating;
