import Radio from '@mui/material/Radio';

const GNERadio = ({
  checked,
  onChange,
  value,
  name,
  label,
  cssClass,
  wrapperCss,
}) => {
  return (
    <div
      className={`d-flex align-items-center ${wrapperCss ? wrapperCss : ''}`}>
      <Radio
        checked={checked}
        onChange={onChange}
        value={value}
        name={name}
        className='gne-radio'
      />
      {label && (
        <label className={`radio-label ${cssClass ? cssClass : ''}`}>
          {label}
        </label>
      )}
    </div>
  );
};

export default GNERadio;
