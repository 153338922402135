/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { OrgChart } from 'd3-org-chart';
import { jsPDF } from 'jspdf';
import { toast } from 'react-toastify';
import { ORG_ACTIONS, ACCOUNT_TYPE } from 'constants/constants';
import TreeCard from './TreeCard';
import ContextPopover from 'components/shared/context-menu/ContextPopover';
import ContextMenu from '../context-actions/ContextMenu';
import RemoveSiteLayout from '../actions/site-actions/RemoveSiteLayout';
import ManageLabelsLayout from '../actions/site-actions/ManageLabelsLayout';
import MoveSiteLayout from '../actions/site-actions/MoveSiteLayout';
import AddSitesLayout from '../actions/AddSiteLayout';
import InfoSitesLayout from '../actions/site-info/InfoSiteLayout';
import ContractSegmentLayout from '../actions/site-actions/ContractSegmentLayout';

const D3Chart = (props) => {
  const d3Container = useRef(null);
  const chart = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [showRemoveSiteModal, setShowRemoveSiteModal] = useState(false);
  const [showManageLabelModal, setShowManageLabelModal] = useState(false);
  const [showMoveSiteModal, setShowMoveSiteModal] = useState(false);
  const [showAddActionModal, setShowAddActionModal] = useState(false);
  const [selectedNode, setSelectedNode] = useState({});
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showContractSegmentModal, setShowContractSegmentModal] =
    useState(false);
  const [corpParent, setCorpParent] = useState(false);
  const [isAccount, setIsAccount] = useState(false);

  useEffect(() => {
    if (props?.onZoomIn !== null && chart?.current) {
      chart?.current?.zoomIn();
    }
  }, [props?.onZoomIn]);

  useEffect(() => {
    if (props?.onZoomOut !== null && chart?.current) {
      chart?.current?.zoomOut();
    }
  }, [props?.onZoomOut]);

  useEffect(() => {
    if (props?.onExpandAll !== null && chart?.current) {
      chart?.current?.expandAll().fit();
    }
  }, [props?.onExpandAll]);

  useEffect(() => {
    if (props?.onCollapseAll !== null && chart?.current) {
      chart?.current?.collapseAll().fit();
    }
  }, [props?.onCollapseAll]);

  useEffect(() => {
    if (props?.onExportPDF !== null && chart?.current) {
      handleExportToPDF();
    }
  }, [props?.onExportPDF]);

  useEffect(() => {
    if (props?.onExportSVG !== null && chart?.current) {
      chart?.current?.exportSvg();
    }
  }, [props?.onExportSVG]);

  useEffect(() => {
    if (props?.searchText !== null && chart?.current) {
      filterChart(props?.searchText);
    }
  }, [props?.searchText]);

  useEffect(() => {
    // if we have data and a reference to the container
    // then display the chart
    try {
      if (props?.data?.length > 0 && d3Container.current) {
        if (!chart.current) {
          chart.current = new OrgChart();
        }

        chart?.current
          .container(d3Container?.current)
          .nodeId((d) => d?.id)
          .parentNodeId((d) => d?.parentId)
          .data(props?.data)
          .nodeWidth(() => 328)
          .nodeHeight(() => 160)
          .compactMarginBetween(() => 50)
          .compactMarginPair(() => 100)
          .initialExpandLevel(props?.selectOptions === 'site' ? 2 : 1)
          .nodeContent((d) => {
            return ReactDOMServer.renderToStaticMarkup(
              <TreeCard
                nodeData={d.data}
                labelAccess={props.labelAccess}
                height={d.height}
                width={d.width}
              />
            );
          })
          .onNodeClick((d) => {
            setCorpParent(
              d.data?.class?.toLowerCase() === ACCOUNT_TYPE.corp ||
                d.data?.class?.toLowerCase() === ACCOUNT_TYPE.os
            );
            setIsAccount(d.data?.class?.toLowerCase() === ACCOUNT_TYPE.corp);
            handleTriggerClick(d.data);
          })
          .render();
      }
    } catch (error) {
      toast.error(error.message);
    }
  }, [props.data]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTriggerClick = (d) => {
    const elm = document.getElementById(d.id);

    if (elm) {
      setAnchorEl(elm);
      setSelectedNode(d);
    }
  };

  const handleContextItemClicked = (event) => {
    setAnchorEl(null);
    handleActionClicked(event.target.id);
  };

  const handleActionClicked = (id) => {
    props.onNodeDataChange(selectedNode);

    switch (id) {
      case ORG_ACTIONS.add:
        setShowAddActionModal(true);
        break;
      case ORG_ACTIONS.move:
        setShowMoveSiteModal(true);
        break;
      case ORG_ACTIONS.remove:
        setShowRemoveSiteModal(true);
        break;
      case ORG_ACTIONS.assign:
        setShowManageLabelModal(true);
        break;
      case ORG_ACTIONS.comments:
        props.onComments();
        break;
      case ORG_ACTIONS.info:
        setShowInfoModal(true);
        break;
      case ORG_ACTIONS.contractsegment:
        setShowContractSegmentModal(true);
        break;
      default:
        break;
    }
  };

  const handleExportToPDF = () => {
    chart?.current?.exportImg({
      save: false,
      full: true,
      onLoad: (base64) => {
        const pdf = new jsPDF();
        const img = new Image();
        img.src = base64;
        img.onload = function () {
          pdf.addImage(
            img,
            'jpeg',
            5,
            5,
            595 / 3,
            ((img.height / img.width) * 595) / 3
          );
          pdf.save(props.account.label);
        };
      },
    });
  };

  const hideModal = () => {
    setShowAddActionModal(false);
  };

  const hideModalRemove = () => {
    setShowMoveSiteModal(false);
  };

  const filterChart = (value) => {
    chart?.current?.clearHighlighting();
    if (!value) {
      return;
    }

    const data = chart?.current.data();

    data.forEach((d) => (d._expanded = false));

    data.forEach((d) => {
      if (
        (value !== '' && d.name.toLowerCase().includes(value.toLowerCase())) ||
        d.address.toLowerCase().includes(value.toLowerCase())
      ) {
        d._highlighted = true;
        d._expanded = true;
      }
    });
    chart.current.data(data).render().fit();
  };

  return (
    <>
      <div ref={d3Container}></div>

      {/* display the node actions */}
      {props.nodeActionList.length > 0 && (
        <ContextPopover
          anchor={anchorEl}
          onHandleClose={handleClose}
          content={
            <ContextMenu
              nodeActionList={
                isAccount
                  ? props.nodeActionList?.filter(
                      (item) =>
                        item?.value === 'info' ||
                        item?.value === 'comments' ||
                        item?.value === 'add'
                    )
                  : props.nodeActionList
              }
              onClickMenu={(e) => handleContextItemClicked(e)}
            />
          }
        />
      )}

      {showAddActionModal && (
        <AddSitesLayout
          show={showAddActionModal}
          page={'actions-node'}
          view={ORG_ACTIONS.add}
          onHide={() => hideModal()}
          account={props.account}
          accountId={props.account?.value}
          accountName={props.account?.label}
          data={props.data}
          nodeData={selectedNode}
          labelsData={props.labelsData}
          onCallOrgChartApi={props.onCallOrgChartApi}
        />
      )}

      {showInfoModal && (
        <InfoSitesLayout
          show={showInfoModal}
          onHide={() => setShowInfoModal(false)}
          account={props.account}
          data={selectedNode}
          corpParent={corpParent}
        />
      )}

      {showMoveSiteModal && (
        <MoveSiteLayout
          show={showMoveSiteModal}
          setShowModal={setShowMoveSiteModal}
          onHide={() => hideModalRemove()}
          account={props.account}
          data={props.data}
          nodeData={selectedNode}
          onCallOrgChartApi={props.onCallOrgChartApi}
        />
      )}

      {showRemoveSiteModal && (
        <RemoveSiteLayout
          showModal={showRemoveSiteModal}
          setShowModal={setShowRemoveSiteModal}
          currentNode={selectedNode}
          onCallOrgChartApi={props.onCallOrgChartApi}
          account={props.account}
        />
      )}

      {showManageLabelModal && (
        <ManageLabelsLayout
          showModal={showManageLabelModal}
          setShowModal={setShowManageLabelModal}
          labelsData={props.labelsData}
          currentNode={selectedNode}
          onCallOrgChartApi={props.onCallOrgChartApi}
          accountId={props.account?.value}
        />
      )}
      {showContractSegmentModal && (
        <ContractSegmentLayout
          showModal={showContractSegmentModal}
          setShowModal={setShowContractSegmentModal}
          currentNode={selectedNode}
          onCallOrgChartApi={props.onCallOrgChartApi}
          accountId={props.account?.value}
        />
      )}
    </>
  );
};

export default D3Chart;
