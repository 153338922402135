/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'animate.css';
import './assets/styles/styles.scss';
import './App.scss';

import AuthenticateUser from 'components/authentication/AuthenticateUser';
import Home from 'components/home/Home';
import SystemAlerts from 'components/home/SystemAlerts';
import Authentication from 'components/authentication/Authentication';
import ReportDashboard from 'components/report-dashboard/ReportDashboard';
import Trends from 'components/trends-v2/Trends';
import CustomerView from 'components/customer/view/CustomerView';
import ViewInsights from 'components/customer/view/ViewInsights';
import ViewList from 'components/customer/view/ViewList';
import CustomerHierarchyView from 'components/customer/hierarchy/CustomerHierarchyView';
import { isCookieExpired } from 'utils/token-expired';
import LogoutModal from 'components/authentication/logoutmodal/LogoutModal';
import ConfigurePriorityView from 'components/customer/configure-priority/ConfigurePriorityView';
import UnderMaintenance from 'components/authentication/undermaintenance/UnderMaintenance';
import Unauthorized from 'components/authentication/unauthorized/Unauthorized';
import Notification from 'components/home/Notification';
import RoleSelection from 'components/role/RoleSelection';

const App = () => {
  const auth = useSelector((state) => state?.Auth?.token);
  const { unixId } = useSelector((state) => state?.Auth?.userClaimObject);

  const MINUTE_MS = 1000;

  useEffect(() => {
    const interval = setInterval(() => {
      isCookieExpired(unixId);
    }, MINUTE_MS);

    // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    return () => clearInterval(interval);
  }, [MINUTE_MS]);

  return (
    <>
      <ToastContainer
        pauseOnFocusLoss={false}
        theme='colored'
        autoClose={5000}
        position='top-right'
      />

      <Authentication authToken={auth}>
        <Routes>
          <Route
            path='/notification'
            element={<Notification />}
          />
          <Route
            path='/'
            element={<Home />}
          />
          <Route
            path='home'
            element={<Home />}
          />
          <Route
            path='system-alerts'
            element={<SystemAlerts />}
          />

          <Route
            path='authenticate'
            element={<AuthenticateUser />}
          />

          <Route
            path='/reportDashboard'
            element={<ReportDashboard />}
          />
          <Route
            path='/trends'
            element={<Trends />}
          />
          <Route
            path='/customerView'
            element={<CustomerView />}>
            <Route
              path='list'
              element={<ViewList />}
            />
            <Route
              path='list/:companyName'
              element={<ViewInsights />}
            />
          </Route>
          <Route
            path='/customer-hierarchy'
            element={<CustomerHierarchyView />}
          />
          <Route
            path='/configure-priority'
            element={<ConfigurePriorityView />}
          />
          <Route
            path='/logout'
            element={<LogoutModal />}
          />
          <Route
            path='/maintenance'
            element={<UnderMaintenance />}
          />
          <Route
            path='/unauthorized'
            element={<Unauthorized />}
          />
          <Route
            path='/roleSelection'
            element={<RoleSelection />}
          />
        </Routes>
      </Authentication>
    </>
  );
};

export default App;
