import React, { useState } from 'react';

import classes from './DefaultMessage.module.scss';

import ConfigureMyViewLayout from 'components/home/configure-my-view/ConfigureMyViewLayout';
import { CONFIGURE_VIEW, VIEW } from 'constants/constants';

const DefaultMessage = ({ page }) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className={classes.defaultMessage}>
      <h3 className={classes.defaultMessageHeading}>
        {`No ${
          page === CONFIGURE_VIEW.insights ? 'Insights' : "KPI's"
        } Selected`}
      </h3>
      <p className={classes.defaultMessageText}>
        Please{' '}
        <span
          onClick={() => setModalShow(true)}
          className='link'>
          click here
        </span>{' '}
        to configure your tiles
      </p>
      <ConfigureMyViewLayout
        show={modalShow}
        onHide={() => setModalShow(false)}
        page={page}
        view={VIEW.add}
      />
    </div>
  );
};

export default DefaultMessage;
