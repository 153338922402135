import React from 'react';
import { Table } from 'antd';
import classes from './SiteHierarchyTable.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import LinkButton from 'components/shared/button/LinkButton';
import GNECheckbox from 'components/shared/checkbox/Checkbox';
import GNERadio from 'components/shared/radio/GNERadio';
import { ORG_ACTIONS } from 'constants/constants';

const SiteHeirarchyTable = ({
  gridData,
  type,
  handleRowSelect,
  selectedRow,
  selectedLabels,
  page,
  total,
  isEdit340B,
}) => {
  const tooltip = (record) => {
    return (
      <Tooltip
        placement='bottom'
        bsclass='gne-tooltip tooltip--left'>
        <p className='text text__x-small mb-1'>
          <strong> HIN ID:</strong> {record?.hin ?? '-'}
        </p>
        <p className='text text__x-small mb-1'>
          <strong>Classification: </strong>
          {record?.classification ?? '-'}
        </p>
        <p className='text text__x-small mb-1'>
          <strong> Contract Segment: </strong>
          {record?.contractSegment ?? '-'}
        </p>
      </Tooltip>
    );
  };

  const siteColumns = [
    {
      title: '',
      dataIndex: 'selectedsite',
      key: 'selectedsite',
      width: 20,
      render: (_, record) => {
        if (type === 'checkbox') {
          return (
            <GNECheckbox
              checked={selectedRow.some((row) => row.key === record.key)}
              onChange={(e) => handleRowSelect(record, 'checkbox')}
            />
          );
        } else if (type === 'radio') {
          return (
            <GNERadio
              value={record.key}
              checked={selectedRow && selectedRow.key === record.key}
              onChange={(e) => handleRowSelect(record, 'radio')}
            />
          );
        }
      },
    },
    {
      title: 'Site',
      dataIndex: 'name',
      key: 'name',
      width: 280,
      render: (colName, record) => (
        <div>
          {colName}
          <OverlayTrigger
            overlay={tooltip(record)}
            placement='bottom'>
            <span className={`d-inline-block ml-1 ${classes['info-icon']}`}>
              <i className='icon icon__info__gray'></i>
            </span>
          </OverlayTrigger>
          {type === 'checkbox' &&
            selectedRow?.map(
              (item) =>
                item?.key === record?.key &&
                record?.labelCount + selectedLabels?.length > 5 && (
                  <i className='icon icon__tableError'></i>
                )
            )}
        </div>
      ),
    },
    {
      title: 'Site Address',
      dataIndex: 'address',
      key: 'address',
      width: 200,
    },
    {
      title: 'Ecosystem',
      dataIndex: 'ecosystem',
      key: 'ecosystem',
      width: 150,
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (_, record) => (
        <div style={{ display: 'flex' }}>
          {page !== ORG_ACTIONS.add && page !== ORG_ACTIONS.remove && (
            <LinkButton className='me-0'>
              <i className='icon icon__table-label '></i> {record?.labelCount}
            </LinkButton>
          )}

          {page !== ORG_ACTIONS.edit340 && (
            <LinkButton className='me-0'>
              {isEdit340B ? (
                <i
                  className={`icon ${
                    record?.program_type ? 'icon__tick' : 'icon__non-tick'
                  } `}></i>
              ) : (
                ''
              )}
            </LinkButton>
          )}

          <LinkButton className='me-0'>
            <i className='icon icon__nestedSites '></i>{' '}
            {record?.chlidSitesCount}
          </LinkButton>
        </div>
      ),
    },
  ];

  const pagination = {
    total: total,
    pageSizeOptions: [10, 20, 30],
    showSizeChanger: true,
  };

  const paginationConfigs = pagination
    ? {
        ...pagination,
        itemRender: (page, type, originalElement) => {
          if (type === 'next') {
            return (
              <button
                className={classes.nextButton}
                disabled={false}>
                Next
              </button>
            );
          } else if (type === 'prev') {
            return (
              <button
                className={classes.nextButton}
                disabled={false}>
                Previous
              </button>
            );
          }
          return originalElement;
        },
      }
    : pagination;

  return (
    <Table
      columns={siteColumns}
      dataSource={gridData}
      size='small'
      pagination={paginationConfigs}
      rowClassName={(record) => {
        if (type === 'checkbox') {
          return (
            (page !== ORG_ACTIONS.add &&
              page !== ORG_ACTIONS.remove &&
              record?.labelCount > 4 &&
              classes['disabled-row']) ||
            selectedRow?.map(
              (item) =>
                item?.key === record?.key &&
                record?.labelCount + selectedLabels?.length > 5 &&
                classes['disabled-row']
            )
          );
        }
      }}
    />
  );
};

export default SiteHeirarchyTable;
