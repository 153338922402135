const {
  COPAY_ANALYTICS_HD_COLUMNS,
  COPAY_CONSTANT,
} = require('constants/constants');
const { default: CopayAnalytics } = require('./CopayAnalytics');

const CopayHDView = () => {
  return (
    <CopayAnalytics
      copayColumns={COPAY_ANALYTICS_HD_COLUMNS}
      copayMeasure={COPAY_CONSTANT.HD_VIEW_MEASURE}
      copayView={'HDView'}></CopayAnalytics>
  );
};

export default CopayHDView;
