import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import classes from './EditInsightBoardTitle.module.scss';
const EditInsightBoardTitle = ({
  title,
  insightAccountId,
  handleSave,
  ...props
}) => {
  const [insightTitleName, handleTextChange] = useState(title);
  useEffect(() => {
    handleTextChange(title);
  }, [title]);
  const reset = () => {
    props.onHide();
    handleTextChange(title);
  };
  return (
    <Container className='gne-container'>
      <Modal
        {...props}
        className='gne-modal'>
        {/* the modal header*/}
        <Modal.Header
          closeButton
          onHide={reset}
          className={`pb-0 border-0`}>
          <Modal.Title>
            <p className='title title__bold title__x-medium'>
              <i className='icon icon__configureEditInsightTitle'></i>
              Rename Title
            </p>
          </Modal.Title>
        </Modal.Header>

        {/* the modal body */}
        <Modal.Body className='pt-0'>
          <Form>
            <Row>
              <Col className=' title title__bold title__x-small'>
                <Form.Label>Title</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col className='title__x-small'>
                <Form.Control
                  type='text'
                  value={insightTitleName}
                  onChange={(e) => {
                    handleTextChange(e.target.value);
                  }}
                  required
                />
                {!insightTitleName && (
                  <p className={`${classes['required']} title__normal`}>
                    *Please enter Insight Board Name
                  </p>
                )}
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        {/* the modal body */}
        <Modal.Footer className='mt-0'>
          <Link
            className='text-decoration-none me-4'
            onClick={reset}>
            Cancel
          </Link>

          <Button
            className={classes.btnBgColor}
            disabled={!insightTitleName || title === insightTitleName}
            onClick={() => handleSave(insightTitleName)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default EditInsightBoardTitle;
