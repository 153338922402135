/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Confirmation from '../../shared/confirmation/Confirmation';
import LinkButton from 'components/shared/button/LinkButton';
import GNEModal from 'components/shared/modal/Modal';
import ConfigurableTable from 'components/shared/table/ConfigurableTable';
import {
  ACCOUNT_TYPE,
  CONFIGURE_VIEW,
  PRIORITY_TABLE_COLUMNS,
} from 'constants/constants';
import {
  deleteAccount,
  addPriority,
} from 'services/configure-priority.service';
import {
  setIsApply,
  setPriorityCustomers,
  setSelectedCustomer,
} from 'store/customer/customerViewSlice';
import { toast } from 'react-toastify';
import { setDefaultCard, setCards } from 'store/home/homeMyViewSlice';
import CustomerDropdown from '../../shared/customer/CustomerDropdown';
import AdvanceSearch from '../../shared/customer/AdvanceSearch';
import 'animate.css';
import { getCustomerData } from 'store/customer/customer-view-actions';

const MyPriority = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCheckboxOption, setSelectedCheckboxOption] = useState(
    ACCOUNT_TYPE.account
  );
  const { unixId, roleId } = useSelector((state) => state.Auth.userClaimObject);
  const [modalConfirmShow, setConfirmModalShow] = useState(false);
  const [accountId, setAccountId] = useState('');
  const [selectedOption, setSelectedOption] = useState([]);
  const advanceSearchClicked = useSelector(
    (state) => state?.CustomerView?.advanceSearchClicked
  );
  const { isApply, defaultCustomerType } = useSelector(
    (state) => state?.CustomerView
  );

  useEffect(() => {
    getPriorityAccountData();
  }, [props, modalConfirmShow]);

  const getPriorityAccountData = async () => {
    await getData();
  };
  const handleNewCustomerClick = () => {
    setSelectedCheckboxOption(ACCOUNT_TYPE.account);
    dispatch(setSelectedCustomer([]));
    setShowModal(true);
  };

  const handleDelete = (record) => {
    setAccountId(record.value);
    setConfirmModalShow(true);
  };

  const onHide = () => {
    setConfirmModalShow(false);
  };

  const onDelete = async (customerId) => {
    try {
      const response = await deleteAccount(customerId, unixId, roleId);
      let updatedMyPriorityData = [];
      if (response) {
        updatedMyPriorityData = data.filter(
          (item) => item.customerId !== customerId
        );
        setData(updatedMyPriorityData);
        dispatch(setDefaultCard([]));
        dispatch(setCards([]));
      }
    } catch (err) {
      console.log(err.message);
    }
    setConfirmModalShow(false);
  };

  /**
   * Get all the priority accounts for the use
   * TODO: use the correct service, this is a temporary call
   */
  const getData = async () => {
    const result = await getCustomerData(
      true,
      '',
      0,
      roleId,
      unixId,
      false,
      [],
      defaultCustomerType
    );
    setData(result.result.data);
    dispatch(setPriorityCustomers(result.result.data));
  };

  const handleCancel = () => {
    setShowModal(false);
    setSelectedOption([]);
  };

  const handleSaveNew = async () => {
    const payload = {
      unixId: unixId,
      customerId: selectedOption?.value,
      primaryFlag: 'N',
      squad: null,
      roleId,
    };
    let isNewCustomer = data.some(
      (acc) => acc.customerId === selectedOption?.value
    );

    if (isNewCustomer === false) {
      if (selectedOption?.label === undefined) {
        toast.warn('Please select ' + selectedCheckboxOption + ' in  dropdown');
      } else {
        await addPriority(payload);
        toast.success(`${selectedOption?.label} is added to Priority List.`);
        getPriorityAccountData();
      }
    } else {
      toast.error(
        selectedCheckboxOption === ACCOUNT_TYPE.account
          ? 'Account already added'
          : 'Site already added'
      );
    }
    dispatch(setSelectedCustomer([]));
    setSelectedOption([]);
    dispatch(setDefaultCard([]));
    dispatch(setCards([]));
  };
  const handleSaveClose = async () => {
    dispatch(setIsApply(!isApply));
    const payload = {
      unixId: unixId,
      customerId: selectedOption?.value,
      primaryFlag: 'N',
      squad: null,
      roleId: roleId,
    };

    try {
      let isNewCustomer = data.some(
        (acc) => acc.customerId === selectedOption?.value
      );

      if (isNewCustomer === false) {
        if (selectedOption?.label !== undefined) {
          await addPriority(payload);
          toast.success(`${selectedOption?.label} is added to Priority List.`);
          getPriorityAccountData();
        }
      } else {
        toast.error(
          selectedCheckboxOption === ACCOUNT_TYPE.account
            ? 'Account already added'
            : 'Site already added'
        );
      }
    } catch (error) {
      console.log('error', error);
    }
    setShowModal(false);
    //setSelectedOption([]);
    dispatch(setDefaultCard([]));
    dispatch(setCards([]));
  };

  const onChangeHandler = async (customer) => {
    dispatch(setSelectedCustomer(customer));
    const result = await getCustomerData(
      true,
      '',
      0,
      roleId,
      unixId,
      false,
      [],
      defaultCustomerType
    );
    setData(result.result.data);
    setSelectedOption(customer);
  };
  const size = { x: 1100, y: 400 };

  // html modal title
  const title = (
    <>
      <p
        className='title__large title__bold d-flex align-items-center'
        title='Add New Customer title'>
        <i className='icon icon__add-big'></i>Add New Customer
      </p>
      <p
        className='text text__small'
        title='Sub title'>
        Search for account or site below.
      </p>
    </>
  );

  // html modal content
  const content = (
    <div className='mb-4'>
      <CustomerDropdown
        page={CONFIGURE_VIEW.configurePriority}
        onChangeHandler={onChangeHandler}
        focusDisabled={true}
        focusChecked={false}
        isDisabled={false}
      />

      {advanceSearchClicked[CONFIGURE_VIEW.configurePriority] && (
        <div className='animate__animated animate__fadeIn search-container addCustomerView'>
          <AdvanceSearch
            page={CONFIGURE_VIEW.configurePriority}
            viewType={CONFIGURE_VIEW.configurePriority}
          />
        </div>
      )}
    </div>
  );

  // html modal footer
  const footer = (
    <div className='d-flex justify-content-end w-100'>
      <LinkButton
        className='text text__small'
        onClick={handleCancel}>
        Cancel
      </LinkButton>
      <Button
        // disabled={selectedOption?.value === undefined}
        variant='btn btn-gne btn-outline-primary btn-gne__outline'
        onClick={handleSaveNew}
        className='text__small ms-3'>
        Save and New
      </Button>
      <Button
        variant='btn btn-gne btn-primary'
        // disabled={selectedOption?.value === undefined}
        onClick={handleSaveClose}
        className='text__small ms-3'>
        Save and Close
      </Button>
    </div>
  );

  return (
    <>
      <div className='d-flex justify-content-end'>
        <Button
          variant='btn btn-gne btn-outline-primary btn-gne__outline btn-gne__outline--add'
          onClick={handleNewCustomerClick}>
          Add New Customer
        </Button>
      </div>

      {/* table */}
      <ConfigurableTable
        rows={data}
        columns={PRIORITY_TABLE_COLUMNS}
        expandRowByClick={false}
        enableColumnSelection={false}
        enableExport={false}
        showActionIcon={true}
        iconProps={{ iconCss: 'delete' }}
        onActionClickHandler={handleDelete}
        tableName='myPriority'
        isGlobalSort={false}
        pagination={false}
        basicSort={true}
        dimentions={size}
      />

      {/* add modal */}
      <GNEModal
        titlecontent={title}
        show={showModal}
        onHide={handleCancel}
        content={content}
        css='size-medium'
        footer={footer}
        backdrop='static'
      />
      <Confirmation
        show={modalConfirmShow}
        onOK={() => onDelete(accountId)}
        onHide={onHide}
      />
    </>
  );
};

export default MyPriority;
