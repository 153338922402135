import { createSlice } from '@reduxjs/toolkit';
import { filterInitialState } from './filters-initial-state';

const filterSlice = createSlice({
  name: 'filters',
  initialState: filterInitialState,
  reducers: {
    setAccounts: (state, action) => {
      state.accounts = action.payload;
    },
  },
});

export const { setAccounts } = filterSlice.actions;
export const filterSliceActions = filterSlice.actions;
export default filterSlice.reducer;
