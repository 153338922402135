import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import GNEModal from 'components/shared/modal/Modal';
import LinkButton from 'components/shared/button/LinkButton';
import MultiSelectDropdown from 'components/home/configure-my-view/MultiSelectDropdown';
import classes from './ShareInsightBoard.module.scss';
import { CUSTOMER_VIEW_SHARE_IB } from 'constants/constants';
import { isEmpty } from 'lodash';
import { CHECKBOXCLASS } from 'constants/constants';
import { useDispatch } from 'react-redux';
import {
  setNotificationEmailStatus,
  setNotifyuserList,
} from 'store/customer/customerViewSlice';

const ShareInsightBoard = ({
  showModal,
  setShowModal,
  currentInsight,
  dropdownShareOptions,
  saveShareInsightHandler,
  saveNotifyInsightHandler,
}) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUsersToNotify, setselectedUsersToNotify] = useState([]);
  const [notifyEmail, setNotifyEmail] = useState(false);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setSelectedUsers([]);
    setselectedUsersToNotify([]);
    setShowModal(false);
    setNotifyEmail(false);
  };

  const handleShare = async () => {
    saveShareInsightHandler(selectedUsers);
    if (notifyEmail) {
      saveNotifyInsightHandler(selectedUsersToNotify);
    }
    setShowModal(false);
    setNotifyEmail(false);
    setSelectedUsers([]);
    setselectedUsersToNotify([]);
  };

  const onSelectUser = (value) => {
    setSelectedUsers(value);
    setselectedUsersToNotify(value);
    let usersData = value?.map((item) => {
      return { name: item?.label, email: item?.email };
    });
    dispatch(setNotifyuserList(usersData));
  };

  const handleNotifyEmailValue = (val) => {
    setNotifyEmail(val?.target?.checked);
    dispatch(setNotificationEmailStatus(val?.target?.checked));
  };

  const content = (
    <div className={classes['content-container']}>
      <div className='d-flex align-items-center'>
        <i
          className={`icon icon__share-popup ${classes['remove-icon-wrap']}`}></i>
        <span className={classes['title-wrap']}>Share Insight Board</span>
      </div>
      <div className={classes['subtitle-wrap']}>
        Please select people to share the insight board.
      </div>
      <div className={classes['dropdown-wrap']}>
        <div className={`d-flex ${classes['dropdown-title']}`}>Name</div>
        <MultiSelectDropdown
          options={dropdownShareOptions}
          selected={selectedUsers}
          setSelected={(e) => onSelectUser(e)}
          hasSelectAll={false}
          page={CUSTOMER_VIEW_SHARE_IB}
        />
      </div>
      <div className='pt-3'>
        <span className={classes['subtitle-wrap']}>
          <Form.Check
            inline
            type='checkbox'
            label={'Notify recipient via email'}
            checked={notifyEmail}
            onChange={handleNotifyEmailValue}
            className={notifyEmail && CHECKBOXCLASS}
            disabled={isEmpty(selectedUsers)}
          />
        </span>
      </div>
      <div className={`d-flex justify-content-end ${classes['content-foot']}`}>
        <LinkButton
          className='text text__small'
          onClick={handleCloseModal}>
          Cancel
        </LinkButton>

        <Button
          variant='btn btn-gne btn-primary'
          onClick={handleShare}
          className={`text__small ms-3 ${classes['save-btn']}`}
          disabled={isEmpty(selectedUsers)}>
          Share
        </Button>
      </div>
    </div>
  );

  return (
    <GNEModal
      show={showModal}
      onHide={handleCloseModal}
      content={content}
      css='size-medium-lg'
      backdrop='static'
    />
  );
};

export default ShareInsightBoard;
