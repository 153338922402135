import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

const Confirmation = ({ bodyMsg, onOK, onCancel, headerMsg, id, ...props }) => {
  return (
    <Container className='gne-container'>
      <Modal
        {...props}
        size='md'
        centered
        className='gne-modal'>
        {/* the modal header*/}
        <Modal.Header
          closeButton
          className={`pb-0 pt-0 border-0`}>
          <Modal.Title className='mt-0'>
            <p className='title title__bold title__x-medium'>
              Confirm Deletion
            </p>
          </Modal.Title>
        </Modal.Header>

        {/* the modal body */}
        <Modal.Body className='pt-0'>
          <Row>
            <Col className='title__x-medium'>Do you really want to delete?</Col>
          </Row>
        </Modal.Body>

        {/* the modal body */}
        <Modal.Footer className='mt-0 pb-0 pt-0'>
          <Link
            className='me-4'
            onClick={props.onHide}>
            No
          </Link>
          <Button
            className='btn btn-gne btn-primary'
            onClick={() => onOK(id)}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
export default Confirmation;
