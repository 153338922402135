/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button, OverlayTrigger, Tooltip, Col } from 'react-bootstrap';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import GNEModal from 'components/shared/modal/Modal';
import LinkButton from 'components/shared/button/LinkButton';
import MultiSelectDropdown from 'components/home/configure-my-view/MultiSelectDropdown';
import SiteHeirarchyTable from './SiteHeirarchyTable';
import Filters from '../filter/Filters';
import { addLogs } from 'services/org/org.service';
import { assignLabels, getSiteGridData } from 'services/org/label.service';
import LabelTags from '../label/LabelTags';
import {
  LOGTYPE,
  CHANGEACTIONTYPE,
  CHANGEVALUETYPE,
  ORG_ACTIONS,
} from 'constants/constants';
import classes from './AssignLabelsLayout.module.scss';

const AssignLabelsLayout = ({
  showModal,
  setShowModal,
  labelData,
  accountId,
  onCallOrgChartApi,
  setSelectedAction,
  labelAccess,
}) => {
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [initialSelectedLabels] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [temp, setTemp] = useState([]);
  const optionsInitialState = {
    siteName: [],
    city: [],
    class: [],
    ecosystem: [],
    state: [],
    zip: [],
  };
  const [selectedOption, setSelectedOption] = useState(optionsInitialState);
  const [gridData, setGridData] = useState([]);
  const [sitesData, setSitesData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [clearFilters, setClearFitlers] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [gridSize, setGridSize] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [initialFilterValue, setInitialFilterValue] =
    useState(optionsInitialState);

  const { unixId } = useSelector((state) => state.Auth.userClaimObject);

  const labelDropdownOptions = labelData?.map((item) => ({
    label: item?.labelName,
    value: item?.labelName,
    labelName: item?.labelName,
    labelColor: item?.labelColor,
    name: item?.labelName,
    key: item?.labelId,
  }));

  const handleRowSelect = (record, type) => {
    if (type === 'checkbox') {
      let newSelectedRows;
      const isSelected = selectedRow.some((row) => row.key === record.key);
      if (isSelected) {
        newSelectedRows = selectedRow.filter((row) => row.key !== record.key);
      } else {
        newSelectedRows = [...selectedRow, record];
      }
      setSelectedRow(newSelectedRows);
    }
  };

  const handleCancelLabelAssign = () => {
    setSelectedRow([]);
    setSelectedAction([]);
    setShowModal(false);
    setSelectedLabels([]);
    setTemp([]);
    setGridData([]);
    setClearFitlers(false);
  };

  useEffect(() => {
    setGridData(sitesData);
  }, [sitesData]);

  useEffect(() => {
    resetOptions();
    setSelectedFilters(optionsInitialState);
    setSelectedLabels(initialSelectedLabels);
    setSelectedRow([]);
    setTemp([]);
    setClearFitlers(false);
  }, [clearFilters]);

  const addActionLog = async () => {
    const labels = selectedLabels?.map((item) => {
      return item?.label;
    });
    const payload = selectedRow?.map((item) => ({
      LogType: LOGTYPE.userlog,
      ViewChangeType: CHANGEACTIONTYPE.label,
      ImpactedMdmId: accountId,
      ValueType: CHANGEVALUETYPE.label,
      OldValue: '',
      NewValue: item?.key ?? '',
      Action: 'labels',
      Flag: '',
      isActive: 'Y',
      ActionDetails: `assigned label ${labels.join(', ')} to ${item?.name} (${
        item.childMdmId
      }) site`,
      unixId: unixId,
      customerId: accountId,
    }));
    await addLogs(payload);
  };

  const onCallGetGridData = async () => {
    setIsLoading(true);
    const payload = {
      filters: {
        ...selectedFilters,
        customerId: accountId,
        unixId: unixId,
        fieldName: 'sites',
        offset: 0,
        action: 'assignlabel',
        cpMdmId: accountId,
      },
    };
    const data = await getSiteGridData(payload);
    if (data?.result) {
      setClearFitlers(true);
      setSitesData(data?.result?.values);
      setGridSize(data?.result?.currentListSize);
    }
    setIsLoading(false);
  };
  // apply api
  const onConfirmAssignLabels = async () => {
    let labelIDs = [];
    labelIDs = selectedLabels?.map((item) => {
      return item?.key;
    });
    const selectedSites = selectedRow?.filter((item) => {
      return !temp.includes(item?.key);
    });
    const payload = labelIDs?.reduce((acc, value) => {
      acc = [
        ...acc,
        ...selectedSites?.map((item) => ({
          customerId: item?.key,
          labelId: value,
          unixId: unixId,
          isActive: 'Y',
          cpMdmId: item?.cpMdmId,
        })),
      ];
      return acc;
    }, []);
    if (payload?.length > 0) {
      await assignLabels(payload, selectedSites);
      onCallGetGridData();
      onCallOrgChartApi();
      addActionLog();
    }
  };

  const handleClearAll = () => {
    setSelectedRow([]);
  };

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    selectedRow?.map((item) => {
      if (
        item?.labelCount + selectedLabels?.length > 5 &&
        !temp.includes(item?.key)
      ) {
        setTemp((temp) => [...temp, item?.key]);
      } else {
        setTemp((temp) => temp.filter((record) => record !== item?.key));
      }
    });
  }, [selectedLabels]);

  useEffect(() => {
    const filtered = selectedRow?.filter(
      (item) => item?.labelCount + selectedLabels?.length > 5
    );
    if (filtered?.length > 0) {
      setError(true);
    } else {
      setError(false);
    }
  }, [selectedLabels, selectedRow]);

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    selectedRow?.map((item) => {
      if (
        item?.labelCount + selectedLabels?.length > 5 &&
        !temp.includes(item?.key)
      ) {
        setTemp((temp) => [...temp, item?.key]);
      }
    });
  }, [selectedRow]);

  const onSelectLabels = (item) => {
    setSelectedLabels(item);
  };

  const handleLabelClose = (label) => {
    setSelectedLabels(selectedLabels.filter((item) => item.label !== label));
  };

  const tooltip = () => {
    return (
      <Tooltip bsclass='gne-tooltip tooltip--left'>
        {selectedRow?.length > 0
          ? selectedRow?.map((item) => {
              return <p className='text text__x-small mb-1'>{item?.name}</p>;
            })
          : 'No Sites Selected'}
      </Tooltip>
    );
  };

  const resetOptions = () => {
    setSelectedOption(optionsInitialState);
  };

  const handleFilterSelect = (selectedFilter) => {
    // setFilterCount((current) => [...current, selectedFilter]);
  };

  const handleFilterApply = async (filters) => {
    setIsLoading(true);
    setSelectedFilters(filters);
    const payload = {
      filters: {
        ...filters,
        customerId: accountId,
        unixId: unixId,
        fieldName: 'sites',
        offset: 0,
        action: 'assignlabel',
        cpMdmId: accountId,
      },
    };
    const data = await getSiteGridData(payload);
    setSitesData(data?.result?.values);
    setGridSize(data?.result?.currentListSize);
    setIsLoading(false);
  };

  const content = (
    <div className={classes['create-label-wrap']}>
      <div className='tag-wrap'>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className='d-flex'>
            <i
              className={`icon icon__assignLabels-black ${classes['assign-label-icon']}`}></i>
            <div className='title-wrap'>Assign Labels</div>
          </div>
          <div className='subtitle-wrap'>
            Search for site(s) below to assign labels.
          </div>
        </div>
        <div className={classes['dropdown-wrap']}>
          <div className={classes['dropdown-title']}>
            Assign Labels (5 Max.)
          </div>
          <div className={classes['dropdown-input']}>
            <MultiSelectDropdown
              options={labelDropdownOptions}
              selected={selectedLabels}
              setSelected={onSelectLabels}
              hasSelectAll={false}
              page={'org-chart'}
              disabled={selectedLabels?.length === 5}
            />
          </div>
        </div>
        <div className='d-flex'>
          <div>
            <div className={classes['site-tag']}>
              <div className='d-flex'>
                <div
                  style={{ fontWeight: 'bold', paddingRight: 10 }}
                  className={classes['tag-title']}>
                  Selected Site(s):
                </div>
                {selectedRow?.length === 0
                  ? 'No Site Selected'
                  : selectedRow?.length}
                <OverlayTrigger
                  overlay={tooltip()}
                  placement='bottom'>
                  <span className={`d-inline-block ${classes['info-icon']}`}>
                    <i className='icon icon__info__gray'></i>
                  </span>
                </OverlayTrigger>
              </div>
              <div>
                <LinkButton
                  className={
                    selectedRow?.length === 0
                      ? `text text_small disabled`
                      : 'text text_small'
                  }
                  onClick={handleClearAll}>
                  Clear
                </LinkButton>
              </div>
            </div>
            {error && (
              <div className={classes['error-message']}>
                A site has exceeded the label limit.Selected labels will not be
                applied to all selected sites.
              </div>
            )}
          </div>

          <Button
            variant='btn btn-gne btn-primary'
            onClick={onConfirmAssignLabels}
            className={`text__small ms-3 ${
              selectedRow?.length === 0 || selectedLabels?.length === 0
                ? classes['disabled-btn']
                : classes['apply-btn']
            }`}
            disabled={
              selectedRow?.length === 0 || selectedLabels?.length === 0
            }>
            Apply
          </Button>
        </div>
      </div>

      <div className='d-flex'>
        <div className={classes['filter-align']}>
          <Filters
            accountId={accountId}
            onFilterSelected={(selectedFilter) =>
              handleFilterSelect(selectedFilter)
            }
            onFilterApply={(filters) => handleFilterApply(filters)}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            resetOptions={resetOptions}
            page={'actions'}
            unixId={unixId}
            initialFilterValue={initialFilterValue}
            labelAccess={labelAccess}
            action={ORG_ACTIONS.filter}
          />
        </div>
        <div className={classes['table-align']}>
          <div className={classes['labels-wrap']}>
            <LabelTags
              record={selectedLabels}
              onClose={handleLabelClose}
              showClose={true}
            />
          </div>
          {gridData?.length !== 0 ? (
            <Spin spinning={isLoading}>
              <SiteHeirarchyTable
                gridData={gridData}
                selectedLabels={selectedLabels}
                type={'checkbox'}
                handleRowSelect={handleRowSelect}
                selectedRow={selectedRow}
                total={gridSize}
              />
            </Spin>
          ) : (
            <Col>
              <Spin spinning={isLoading}>
                <div className={`m-8 ${classes['message-align-wrap']}`}>
                  <p className={classes['default-message']}>No Site Selected</p>
                  <p>Please use the filters to view sites</p>
                </div>
              </Spin>
            </Col>
          )}
        </div>
      </div>
    </div>
  );
  const footer = (
    <div className='d-flex justify-content-end w-100'>
      <LinkButton
        className='text text__small'
        onClick={handleCancelLabelAssign}>
        Close
      </LinkButton>
    </div>
  );

  return (
    <div>
      <GNEModal
        show={showModal}
        onHide={handleCancelLabelAssign}
        content={content}
        css='size-large'
        footer={footer}
        backdrop='static'
      />
    </div>
  );
};

export default AssignLabelsLayout;
