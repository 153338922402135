import { trackPromise } from 'react-promise-tracker';

import {
  getQlikSenseMultiDimensionData,
  getQlikSenseUnformattedData,
} from 'services/qlik.service';
import { setTimeline, setMetrics } from './timeline-view-slice';
import { areas } from 'constants/constants';
import { setEngagements } from '../engagement/engagement-slice';

export const getTimelineEngagements = (
  appId,
  expression,
  dimension,
  limit,
  width
) => {
  return async (dispatch) => {
    try {
      trackPromise(
        getQlikSenseMultiDimensionData(
          appId,
          expression,
          dimension,
          limit,
          width
        ).then((data) => {
          const engagementDetails = data?.map((items) => {
            const records = items.map((item) => item.qText);
            const [
              source,
              engagement,
              engagementType,
              engagementDate,
              engagementId,
              userName,
              userRole,
              stakeholderName,
              stakeholderType,
              topic,
              indication,
              squad,
              product,
              childEngagementId,
              veevaUrl,
              EngagmentNote,
              DiscussionNote,
              Engagement_CLM_Presentation,
              BEMEmailAddress,
              AccountPayableEmail,
              EmailSubjectLine,
              EmailLink,
              status,
            ] = records;

            return {
              engagements: {
                engagement,
                engagementType,
                engagementDate,
                engagementId,
                userName,
                userRole,
                stakeholderName,
                stakeholderType,
                topic,
                indication,
                squad,
                product,
                source,
                childEngagementId,
                veevaUrl,
                EngagmentNote,
                DiscussionNote,
                Engagement_CLM_Presentation,
                BEMEmailAddress,
                AccountPayableEmail,
                EmailSubjectLine,
                EmailLink,
                status,
              },

              timeline: {
                engagement,
                engagementType,
                engagementDate,
                engagementId,
                status,
              },
            };
          });

          const engagements = engagementDetails.map(
            (items) => items.engagements
          );

          const timeline = engagementDetails.map((items) => items.timeline);

          dispatch(setEngagements(engagements));
          dispatch(setTimeline(timeline));
        }),
        areas.timeline
      );
    } catch (error) {
      console.log('ERROR', error);
    }
  };
};

export const getEngagementMetrics = (appId, expression, dimension, limit) => {
  return async (dispatch) => {
    try {
      trackPromise(
        getQlikSenseUnformattedData(expression, dimension, appId, limit).then(
          (data) => {
            const metrics = data?.map((item) => {
              return {
                engagement: item[0].qText,
                engagementType: item[1].qText,
                source: item[2].qText,
                number: item[3].qText,
              };
            });
            dispatch(setMetrics(metrics));
          }
        )
      );
    } catch (error) {
      console.log('ERROR', error);
    }
  };
};
