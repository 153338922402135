import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import GNEModal from 'components/shared/modal/Modal';
import LinkButton from 'components/shared/button/LinkButton';
import MultiSelectDropdown from 'components/home/configure-my-view/MultiSelectDropdown';
import LabelTags from '../../label/LabelTags';
import { assignLabels } from 'services/org/label.service';
import { addLogs } from 'services/org/org.service';
import {
  LOGTYPE,
  CHANGEACTIONTYPE,
  CHANGEVALUETYPE,
} from 'constants/constants';
import classes from './ManageLabelsLayout.module.scss';

const ManageLabelsLayout = ({
  showModal,
  setShowModal,
  labelsData,
  currentNode,
  onCallOrgChartApi,
  accountId,
}) => {
  const dropdownOptions = labelsData?.map((item) => ({
    label: item?.labelName,
    value: item?.labelName,
    labelName: item?.labelName,
    labelColor: item?.labelColor,
    name: item?.labelName,
    key: item?.labelId,
    isActive: item?.isActive,
  }));

  const currentSelectedLabels = currentNode?.labels?.map((item) => ({
    label: item?.labelName,
    value: item?.labelName,
    labelName: item?.labelName,
    labelColor: item?.labelColor,
    name: item?.labelName,
    key: item?.key,
    isActive: item?.isActive,
  }));

  const [selectedLabel, setSelectedLabel] = useState(
    currentSelectedLabels || []
  );
  const { unixId } = useSelector((state) => state.Auth.userClaimObject);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const addActionLog = async (updatedSelectedLabels) => {
    const payload = updatedSelectedLabels?.map((item) => ({
      LogType: LOGTYPE.userlog,
      ViewChangeType: CHANGEACTIONTYPE.label,
      ImpactedMdmId: currentNode?.id,
      ValueType: CHANGEVALUETYPE.label,
      OldValue: '',
      NewValue: item?.key,
      Action: 'labels',
      Flag: '',
      isActive: 'Y',
      ActionDetails: `${
        item?.isActive === 'Y' ? 'assigned' : 'unassigned'
      } label ${item?.label} to ${currentNode?.name}`,
      unixId: unixId,
      customerId: accountId,
    }));
    await addLogs(payload);
  };

  const areEqual = (array1, array2) => {
    return JSON.stringify(array1) === JSON.stringify(array2);
  };

  const actionLabels = (arr1, arr2, arr3) => {
    let updatedLabels = [];
    let removedLabels = [];
    if (arr3?.length > 0) {
      removedLabels = arr3?.map((item) => ({
        ...item,
        isActive: 'N',
      }));
      updatedLabels = [...removedLabels];
    }
    let uniqueRows = arr2.filter((item) => !arr1.includes(item.key));
    updatedLabels = [...updatedLabels, ...uniqueRows];
    addActionLog(updatedLabels);
  };

  const handleSave = async () => {
    setShowModal(false);
    let labelIDs = [];
    let currentSelectedLabelIds = [];
    let updatedLabels = [];
    labelIDs = selectedLabel?.map((item) => {
      return item?.key;
    });
    currentSelectedLabelIds = currentNode?.labels?.map((item) => {
      return item?.key;
    });
    const isPayloadUnchanged = areEqual(labelIDs, currentSelectedLabelIds);
    if (!isPayloadUnchanged) {
      const filteredLabels = currentSelectedLabels?.filter(
        (item) => !labelIDs.includes(item?.key)
      );
      updatedLabels = selectedLabel?.map((item) => ({
        customerId: currentNode?.id,
        labelId: item?.key,
        unixId: unixId,
        isActive: 'Y',
        cpMdmId: accountId,
      }));
      if (filteredLabels?.length) {
        const removedLabels = filteredLabels?.map((item) => ({
          customerId: currentNode?.id,
          labelId: item?.key,
          unixId: unixId,
          isActive: 'N',
          cpMdmId: currentNode?.cpMdmId,
        }));
        updatedLabels = [...updatedLabels, ...removedLabels];
      }
      if (updatedLabels?.length) {
        const message = await assignLabels(updatedLabels, [currentNode]);
        if (message) {
          onCallOrgChartApi();
          actionLabels(
            currentSelectedLabelIds ?? [],
            selectedLabel,
            filteredLabels ?? []
          );
        }
      }
    }
  };

  const onLabelSelected = (value) => {
    setSelectedLabel(value);
  };

  const handleLabelClose = (label) => {
    setSelectedLabel(selectedLabel.filter((item) => item.label !== label));
  };

  const content = (
    <div className={classes['content-container']}>
      <div className='d-flex align-items-center'>
        <i
          className={`icon icon__assignLabels-black ${classes['remove-icon-wrap']}`}></i>
        <span className={classes['title-wrap']}>Assign/Edit Labels</span>
      </div>
      <div className={classes['subtitle-wrap']}>
        Assign up to 5 labels below.
      </div>
      <div className={classes['dropdown-wrap']}>
        <MultiSelectDropdown
          options={dropdownOptions}
          selected={selectedLabel}
          setSelected={(e) => onLabelSelected(e)}
          hasSelectAll={false}
          page={'org-chart'}
          disabled={selectedLabel?.length === 5}
        />
        <div className={classes['label-wrap']}>
          <LabelTags
            record={selectedLabel}
            onClose={handleLabelClose}
            showClose={true}
          />
        </div>
      </div>
    </div>
  );

  const footer = (
    <div className='d-flex justify-content-end w-100'>
      <LinkButton
        className='text text__small'
        onClick={handleCloseModal}>
        Close
      </LinkButton>
      <Button
        variant='btn btn-gne btn-primary'
        onClick={handleSave}
        className={`text__small ms-3 ${classes['save-btn']}`}>
        Save
      </Button>
    </div>
  );
  return (
    <GNEModal
      show={showModal}
      onHide={handleCloseModal}
      content={content}
      css='size-medium'
      footer={footer}
      backdrop='static'
    />
  );
};

export default ManageLabelsLayout;
