/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import Loader from 'components/shared/loader/Loader';

import D3Chart from './D3Chart';

export const OrganizationalChart = (props) => {
  return (
    <>
      <div className='position-relative'>
        {props?.loading && <Loader size={4} />}
      </div>

      <div className={`animate__animated animate__fadeIn org-chart-container`}>
        {/* displays the org chart */}
        {!props?.loading && (
          <D3Chart
            data={props?.data}
            nodeActionList={props?.nodeActionList}
            account={props?.account}
            onCallOrgChartApi={props?.onCallOrgChartApi}
            labelsData={props?.labelsData}
            onComments={props?.comments}
            onNodeDataChange={props?.handleNodeData}
            labelAccess={props?.labelAccess}
            onZoomIn={props.onZoomIn}
            onZoomOut={props.onZoomOut}
            onExpandAll={props.onExpandAll}
            onCollapseAll={props.onCollapseAll}
            onExportPDF={props.onExportPDF}
            onExportSVG={props.onExportSVG}
            searchText={props.searchText}
            selectOptions={props.selectOptions}
          />
        )}
      </div>
    </>
  );
};
