/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'react-bootstrap';

import { useState, useEffect } from 'react';
import { Input } from 'antd';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { wrapMenuList } from 'react-select-async-paginate';
import GNERadio from 'components/shared/radio/GNERadio';
import {
  ValueContainer,
  dropdownOptions,
  formatOptionLabel,
} from 'utils/utils';

import classes from './Comments.module.scss';

import LinkButton from 'components/shared/button/LinkButton';

import { updateComment } from 'services/comments.service';

import { getFilters, addLogs } from 'services/org/org.service';
import {
  LOGTYPE,
  CHANGEACTIONTYPE,
  CHANGEVALUETYPE,
  ORG_ACTIONS,
  ACCOUNT_TYPE,
} from 'constants/constants';
import GNESingleSelect from 'components/shared/dropdown/GNESingleSelect';
import GNEAsyncPagination from 'components/shared/dropdown-with-pagination/GNEAsyncPagination';

const EditComment = ({
  closeDrawer,
  selectedComment,
  onEditComment,
  editDrawerOpen,
}) => {
  const [charCount, setCharCount] = useState(selectedComment.comment.length);
  const [comment, setComment] = useState(selectedComment.comment);
  const [selectOptions, setOptions] = useState(
    selectedComment.type.toLowerCase()
  );

  const initialSelectionState = {
    siteName: {
      label: selectedComment?.name,
      value: selectedComment?.childMdmId,
    },
  };
  const [selectedFilters, setSelectedFilters] = useState(
    initialSelectionState.siteName
  );
  useEffect(() => {
    // account drop has a dependency on this
    setSelectedFilters(initialSelectionState.siteName);
    setOptions(selectedComment.type.toLowerCase());
    setComment(selectedComment.comment);
  }, [selectedComment]);

  useEffect(() => {
    if (editDrawerOpen) {
      setOptions(selectedComment.type.toLowerCase());
      setComment(selectedComment.comment);
    }
  }, [editDrawerOpen]);

  const handleClose = () => {
    closeDrawer();
    setComment(selectedComment.comment);
    setOptions(selectedComment.type.toLowerCase());
  };

  const handleCommentChange = (e) => {
    const text = e.target.value;

    if (text.length < 1000) {
      setComment(text);
      setCharCount(text.length);
    }
  };

  const addActionLog = async () => {
    const payload = [
      {
        LogType: LOGTYPE.userlog,
        ViewChangeType: CHANGEACTIONTYPE.hierarchy,
        ImpactedMdmId:
          selectOptions === 'account'
            ? selectedCustomer?.value
            : selectedFilters?.value,
        ValueType: CHANGEVALUETYPE.parent,
        OldValue: '',
        NewValue: comment,
        Action: 'comments',
        Flag: '',
        isActive: 'Y',
        ActionDetails:
          selectOptions === ACCOUNT_TYPE.account
            ? `updated comment in ${selectedCustomer?.label} (${selectedCustomer?.value})`
            : `updated comment in ${selectedFilters?.label} (${selectedFilters?.value})`,
        unixId: unixId,
        customerId: selectedCustomer?.value,
      },
    ];
    await addLogs(payload);
  };

  const updateSelectedComment = async (record) => {
    const payload = {
      accountId:
        selectOptions === 'account'
          ? selectedCustomer?.value
          : selectedFilters?.value,
      comment: comment,
      unixId: unixId,
      id: selectedComment.id,
    };
    const result = await updateComment(payload);

    if (result) {
      onEditComment(comment);
      toast.success('Comment updated successfully');
      addActionLog();
      handleClose();
    } else {
      toast.error('Error adding comment');
    }
  };

  const loadOptions = async (filterName, searchQuery) => {
    let action = ORG_ACTIONS.filter;

    const response = await getFilters(
      filterName,
      selectedCustomer?.value,
      {},
      '',
      searchQuery,
      action,
      unixId
    );
    return {
      options: response?.values,
      hasMore: false,
    };
  };

  const handleOptionChange = (e) => {
    setOptions(e.target.value);
  };

  const handleFilterChange = (filterName, selectedOption) => {
    // Handle the selected option for the specified filterName
    // Update the selected filter value in state
    setSelectedFilters(selectedOption);
  };

  const selectedCustomer = useSelector(
    (state) => state?.CustomerView?.selectedCustomer
  );
  const { unixId } = useSelector((state) => state.Auth.userClaimObject);
  return (
    <>
      <label>Comment</label>
      <Input.TextArea
        rows={4}
        placeholder='Add Comment'
        onChange={handleCommentChange}
        maxLength={1000}
        value={comment}
      />
      <p>{charCount} / 1000</p>

      <div>
        <span> Tag</span>

        <div className='`title__small d-flex'>
          <GNERadio
            checked={selectOptions === 'account'}
            name='accountType'
            label='Account'
            value='account'
            onChange={handleOptionChange}
          />
          <GNERadio
            checked={selectOptions === 'site'}
            name='accountType'
            label='Site'
            value='site'
            onChange={handleOptionChange}
          />
        </div>
      </div>

      <div className='d-inline-block w-100'>
        {selectOptions === 'account' ? (
          <GNESingleSelect
            value={selectedCustomer}
            className={`single-select  w-100 ${classes['comments-dropdown']}`}
            placeholder='Select'
            isDisabled={true}
          />
        ) : (
          <GNEAsyncPagination
            placeholder='Select'
            closeMenuOnSelect={true}
            value={selectedFilters}
            defaultOptions
            loadOptions={(searchQuery) => loadOptions('siteName', searchQuery)}
            onChange={(selectedOption) =>
              handleFilterChange('siteName', selectedOption)
            }
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            formatOptionLabel={(data, { context }) =>
              formatOptionLabel(data, { context })
            }
            isMulti={false}
            components={{
              ValueContainer: ValueContainer,
              Option: wrapMenuList(dropdownOptions),
            }}
            isClearable
            cacheUniqs={[selectedFilters, selectOptions]}
            action={ORG_ACTIONS.filter}
          />
        )}
      </div>

      <div
        className={`d-flex justify-content-end w-100 ${classes['buttons-wrapper']}`}>
        <LinkButton
          className='text text__small'
          onClick={handleClose}>
          Cancel
        </LinkButton>
        <Button
          disabled={comment?.length === 0}
          variant='btn btn-gne btn-primary'
          onClick={updateSelectedComment}
          className='text__small ms-3'>
          Update
        </Button>
      </div>
    </>
  );
};

export default EditComment;
