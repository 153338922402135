import classes from './SystemAlerts.module.scss';
import { useSelector } from 'react-redux';

const SystemAlertsList = (props) => {
  const { viewName } = props;
  const systemAlertsData = useSelector(
    (state) => state?.HomeMyView?.systemAlertsData
  );

  return (
    <div className={`${classes.alertsContainer}`}>
      {systemAlertsData?.length ? (
        systemAlertsData?.map((record) => (
          <div
            className={
              viewName === 'login'
                ? `d-flex  ${classes.releaseContainer}`
                : `d-flex  ${classes.releaseContainerHeader}`
            }
            key={record?.ID}>
            <div className={`${classes.releaseIcon}`}>
              <i className={`icon icon__eco-align-change-warning-alerts`}></i>
            </div>
            <div className='me-3'>
              <p className='text text__x-small'>{record?.Description}</p>
            </div>
          </div>
        ))
      ) : (
        <div className='d-flex flex-column align-items-center '>
          <span className='text text__x-small mt-4'>
            You have no new system alerts.
          </span>
        </div>
      )}
    </div>
  );
};

export default SystemAlertsList;
