/* eslint-disable react-hooks/exhaustive-deps */
import { ProSidebarProvider } from 'react-pro-sidebar';

import Layout from 'components/layout/Layout';
import ConfigurePriority from './ConfigurePriority';

const ConfigurePriorityView = () => {
  return (
    <ProSidebarProvider>
      <Layout showRighSideBar={false}>
        <div className='gne-container'>
          <ConfigurePriority />
        </div>
      </Layout>
    </ProSidebarProvider>
  );
};

export default ConfigurePriorityView;
