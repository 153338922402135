import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import Chart from 'components/charts/Echart';
import moment from 'moment';
import LinkButton from 'components/shared/button/LinkButton';
import {
  getArrayLabelValues,
  getRestrictedProducts,
  getRestrictedSquads,
} from '../../../utils/utils';
import classes from './Tiles.module.scss';
import { CONFIGURE_VIEW, DATE_FORMAT_COMMA } from 'constants/constants';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NoDimension from '../no-dimension/NoDimensions';
import TableView from 'components/charts/TableView';

const ExpandMyView = ({ card, page, ...props }) => {
  const { filter, chartData, localFilters, kpiFilters } = card;
  const {
    selectedEcosystem = {},
    selectedTimePeriod = {},
    selectedAccount,
    selectedProduct,
    chartType = 'bar',
    focusAccountChecked,
    accountableProduct,
    selectedSquad = {},
    selectedKPI,
  } = filter;

  const kpiName = selectedKPI?.KpiName ?? '';
  const selectedDimension = selectedKPI?.dimensions[0] ?? '';
  const dimensionName = selectedDimension?.dimensionName ?? '';

  const noDimensionValue =
    card?.chartData?.values?.length > 0 && !card?.chartData?.category[0]
      ? card?.chartData?.values[0]?.data?.[0]
      : card?.chartData?.category?.[0];

  const benchmarkNoDimensionValue =
    card?.chartData?.values?.length > 0 &&
    card?.chartData?.values[1]?.data?.[0] !== undefined
      ? card?.chartData?.values[1]?.data?.[0]
      : '';

  const userRestrictedRole = useSelector(
    (state) => state?.Auth?.userRestriction
  );
  const userRestrictedEcosystem = useSelector(
    (state) => state?.Auth?.userRestrictedEcosystemt
  );
  const restrictedProductSquad = useSelector(
    (state) => state?.Auth?.restrictedProductSquad
  );

  const isMyView = page === CONFIGURE_VIEW.myView ? true : false;

  let ecosystemName = '';
  if (
    isMyView &&
    userRestrictedRole?.isNational === 'N' &&
    selectedEcosystem?.length === 0 &&
    !focusAccountChecked
  ) {
    ecosystemName = getArrayLabelValues(userRestrictedEcosystem);
  } else {
    // Manage filterEnable for old data and new data
    ecosystemName =
      selectedEcosystem?.length > 0
        ? getArrayLabelValues(selectedEcosystem)
        : 'All Ecosystems';
  }

  let squadNames = '';
  if (
    (isMyView || page === CONFIGURE_VIEW.myDefault) &&
    userRestrictedRole?.squadRestriction === 'Y' &&
    selectedSquad?.length === 0
  ) {
    squadNames = getRestrictedSquads(
      restrictedProductSquad,
      accountableProduct
    );
  } else {
    squadNames =
      selectedSquad?.length > 0
        ? getArrayLabelValues(selectedSquad)
        : 'All Squads';
  }

  let selectedProductNames = '';
  if (
    (isMyView || page === CONFIGURE_VIEW.myDefault) &&
    userRestrictedRole?.productRestrictions === 'Y' &&
    selectedProduct?.length === 0
  ) {
    selectedProductNames = getRestrictedProducts(
      restrictedProductSquad,
      accountableProduct
    );
  } else {
    selectedProductNames =
      selectedProduct?.length === 0 && accountableProduct
        ? 'All Accountable Products'
        : selectedProduct?.length === 0 && !accountableProduct
        ? 'All Products'
        : getArrayLabelValues(selectedProduct);
  }

  const timePeriod = selectedTimePeriod?.label;

  const selectedAccountNames =
    page === CONFIGURE_VIEW.insights
      ? selectedAccount?.label
      : focusAccountChecked === true
      ? selectedAccount?.length === 0
        ? `All Priority Customers`
        : getArrayLabelValues(selectedAccount)
        ? getArrayLabelValues(selectedAccount)
        : `All Priority Customers`
      : selectedAccount?.length === 0
      ? 'All'
      : getArrayLabelValues(selectedAccount)
      ? getArrayLabelValues(selectedAccount)
      : 'All';

  const isValue = noDimensionValue > 0;
  const noData = chartData?.values?.length === 0 && !noDimensionValue;
  const isColor =
    kpiName?.slice(-3) === '(%)' || kpiName?.toLowerCase().includes('change');
  const showChart = dimensionName !== 'No Dimension' && chartData && !noData;

  return (
    <>
      <Modal
        {...props}
        size='xl'
        className={`title gne-modal ${props.css}`}>
        <div className={classes.expandMyViewModal}>
          <Modal.Header
            closeButton
            className={`modal-header border-0`}></Modal.Header>
          <Modal.Body className='show-grid p-0 m-0'>
            <Row className='mb-4'>
              <Col md={8}>
                <p className={classes.expandMyViewSidebarTitle}>{card.title}</p>
                <p className='sub-title sub-title__x-small text__grey'>
                  {card.timespan}
                </p>
                {dimensionName === 'No Dimension' && !noData && (
                  <div className={classes['no-dimension-container']}>
                    <NoDimension
                      isColor={isColor}
                      noDimensionValue={noDimensionValue}
                      benchmarkNoDimensionValue={benchmarkNoDimensionValue}
                      kpiName={kpiName}
                      isValue={isValue}
                    />
                  </div>
                )}

                {card.cardView !== 'table' && showChart && (
                  <Chart
                    chartData={chartData}
                    kpiName={kpiName}
                    chartType={chartType}
                    hashView={card.cardView === 'hash'}
                  />
                )}

                {card.cardView === 'table' && showChart && (
                  <div className={classes['scrollable-table-container']}>
                    <TableView
                      data={chartData}
                      kpiName={kpiName}
                      dimensionName={dimensionName}
                      page={'expand'}
                    />
                  </div>
                )}

                {noData && (
                  <div className={classes.iconalign}>
                    <i className='icon icon__no-data center-align-icon'></i>
                  </div>
                )}
              </Col>

              <Col md={4}>
                <div className={classes.expandMyViewSidebar}>
                  <h1 className={classes.expandMyViewSidebarHeading}>
                    Chart Details
                  </h1>
                  <div className={classes.expandMyViewSidebarScrollableDiv}>
                    <div className={classes.expandMyViewSidebarDiv}>
                      <p className={classes.expandMyViewSidebarLabel}>
                        Customer
                      </p>
                      <p className={classes.expandMyViewSidebarValue}>
                        {selectedAccountNames}
                      </p>
                    </div>

                    <div className={classes.expandMyViewSidebarDiv}>
                      <p className={classes.expandMyViewSidebarLabel}>
                        Ecosystem
                      </p>
                      <p className={classes.expandMyViewSidebarValue}>
                        {ecosystemName}
                      </p>
                    </div>

                    <div className={classes.expandMyViewSidebarDiv}>
                      <p className={classes.expandMyViewSidebarLabel}>
                        Product
                      </p>
                      <p className={classes.expandMyViewSidebarValue}>
                        {selectedProductNames}
                      </p>
                    </div>

                    <div className={classes.expandMyViewSidebarDiv}>
                      <p className={classes.expandMyViewSidebarLabel}>Squad</p>
                      <p className={classes.expandMyViewSidebarValue}>
                        {squadNames}
                      </p>
                    </div>

                    <div className={classes.expandMyViewSidebarDiv}>
                      <p className={classes.expandMyViewSidebarLabel}>
                        Time Period
                      </p>
                      <p className={classes.expandMyViewSidebarValue}>
                        {timePeriod}
                      </p>
                    </div>

                    {localFilters?.map((item) => {
                      const field = kpiFilters[`selected${item.filterName}`];
                      return (
                        <div
                          className={classes.expandMyViewSidebarDiv}
                          key={item.filterName}>
                          <p className={classes.expandMyViewSidebarLabel}>
                            {item.filterLabel}
                          </p>
                          <p className={classes.expandMyViewSidebarValue}>
                            {field?.length > 0
                              ? getArrayLabelValues(field)
                              : `All ${item.filterLabel}s`}
                          </p>
                        </div>
                      );
                    })}

                    <div className={classes.expandMyViewSidebarDiv}>
                      <p className={classes.expandMyViewSidebarLabel}>
                        Created Date
                      </p>
                      <p className={classes.expandMyViewSidebarValue}>
                        {moment(card.createdDate).format(DATE_FORMAT_COMMA)}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <hr />
          </Modal.Body>
          <Modal.Footer className={`modal-header border-0`}>
            <Link
              to={card.selectedAppLink}
              target='_blank'
              className='title title__small'>
              <i className='icon icon__external-blue' />
              Go To Dashboard
            </Link>
            <LinkButton
              onClick={props.onHide}
              className='title title__small'>
              Close
            </LinkButton>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};
export default ExpandMyView;
