import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Input } from 'antd';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { wrapMenuList } from 'react-select-async-paginate';
import GNERadio from 'components/shared/radio/GNERadio';

import {
  ValueContainer,
  dropdownOptions,
  formatOptionLabel,
} from 'utils/utils';

import classes from './Comments.module.scss';

import LinkButton from 'components/shared/button/LinkButton';

import { addComment } from 'services/comments.service';

import {
  filterSelectionRefactoring,
  getFilters,
  addLogs,
} from 'services/org/org.service';
import {
  ACCOUNT_TYPE,
  CHANGEACTIONTYPE,
  CHANGEVALUETYPE,
  LOGTYPE,
  ORG_ACTIONS,
} from 'constants/constants';
import GNESingleSelect from 'components/shared/dropdown/GNESingleSelect';
import GNEAsyncPagination from 'components/shared/dropdown-with-pagination/GNEAsyncPagination';

const AddComments = ({
  onSaveComment,
  onClose,
  selectedNodeData,
  selectedOption,
  addDrawerOpen,
}) => {
  const [charCount, setCharCount] = useState(0);
  const [comment, setComment] = useState('');
  const [selectOptions, setOptions] = useState(selectedOption);
  const initialSelectionState = {
    siteName: null,
  };
  const [selectedFilters, setSelectedFilters] = useState(initialSelectionState);

  const handleClose = () => {
    onClose();
    resetOptions();
    setCharCount(0);
    setComment('');
  };

  const handleCommentChange = (e) => {
    const text = e.target.value;

    if (text.length <= 1000) {
      setComment(text);
      setCharCount(text.length);
    }
  };

  const handleOptionChange = (e) => {
    setOptions(e.target.value);
    resetOptions();
  };

  useEffect(() => {
    if (addDrawerOpen) {
      setComment('');
    }
    setSelectedFilters((prevSelectedFilters) => ({
      ...prevSelectedFilters,
      siteName: {
        label: selectedNodeData?.name,
        value: selectedNodeData?.id,
      },
    }));
    // account drop has a dependency on this
    setOptions(
      selectedOption === 'site'
        ? selectedNodeData?.class.toLowerCase() === 'site'
          ? 'site'
          : 'account'
        : 'account'
    );
    handleFilterChange('siteName', {
      label: selectedNodeData?.name,
      value: selectedNodeData?.id,
    });
  }, [
    selectedNodeData?.class,
    selectedNodeData?.name,
    selectedNodeData?.id,
    selectedOption,
    addDrawerOpen,
  ]);

  const selectedCustomer = useSelector(
    (state) => state?.CustomerView?.selectedCustomer
  );
  const { unixId } = useSelector((state) => state.Auth.userClaimObject);

  const addActionLog = async () => {
    const payload = [
      {
        LogType: LOGTYPE.userlog,
        ViewChangeType: CHANGEACTIONTYPE.hierarchy,
        ImpactedMdmId:
          selectOptions === 'account'
            ? selectedCustomer?.value
            : selectedFilters?.siteName?.value,
        ValueType: CHANGEVALUETYPE.parent,
        OldValue: '',
        NewValue: comment,
        Action: 'comments',
        Flag: '',
        isActive: 'Y',
        ActionDetails:
          selectOptions === ACCOUNT_TYPE.account
            ? `added comment to ${selectedCustomer?.label}`
            : `added comment to ${selectedFilters?.siteName?.label}`,
        unixId: unixId,
        customerId: selectedCustomer?.value,
      },
    ];
    await addLogs(payload);
  };

  const saveComment = async () => {
    const payload = {
      accountId:
        selectOptions === 'account'
          ? selectedCustomer?.value
          : selectedNodeData
          ? selectedNodeData?.id
          : selectedFilters?.siteName?.value,
      comment: comment,
      unixId: unixId,
    };
    const result = await addComment(payload);

    if (result) {
      toast.success('Comment added successfully');
      onSaveComment(comment);
      setCharCount(0);
      setComment('');
      onClose();
      addActionLog();
    } else {
      toast.error('Error adding comment');
    }
  };

  const loadOptions = async (filterName, searchQuery) => {
    const action = ORG_ACTIONS.filter;
    const filters = filterSelectionRefactoring(selectedFilters);
    const response = await getFilters(
      filterName,
      selectedCustomer?.value,
      filters,
      0,
      searchQuery,
      action,
      unixId
    );
    return {
      options: response?.values,
      hasMore: false,
    };
  };

  const handleFilterChange = (filterName, selectedOption) => {
    // Handle the selected option for the specified filterName
    // Update the selected filter value in state
    setSelectedFilters((prevSelectedFilters) => ({
      ...prevSelectedFilters,
      [filterName]: selectedOption,
    }));
  };

  const resetOptions = () => {
    setSelectedFilters(initialSelectionState);
  };

  return (
    <>
      <label>Comment</label>
      <Input.TextArea
        rows={4}
        placeholder='Add Comment'
        onChange={handleCommentChange}
        maxLength={1000}
        value={comment}
      />
      <p>{charCount} / 1000</p>

      <div>
        <span> Tag</span>

        <div className='title__small d-flex'>
          <GNERadio
            checked={selectOptions === 'account'}
            name='accountType'
            label='Account'
            value='account'
            onChange={handleOptionChange}
          />
          <GNERadio
            checked={selectOptions === 'site'}
            name='accountType'
            label='Site'
            value='site'
            onChange={handleOptionChange}
          />
        </div>
      </div>

      <div className='d-inline-block w-100'>
        {selectOptions === ACCOUNT_TYPE.account ? (
          <GNESingleSelect
            value={selectedCustomer}
            className={`single-select  w-100 ${classes['comments-dropdown']}`}
            placeholder='Select'
            isDisabled={true}
          />
        ) : (
          <GNEAsyncPagination
            placeholder='Select'
            closeMenuOnSelect={true}
            value={selectedFilters?.siteName}
            loadOptions={(searchQuery) => loadOptions('siteName', searchQuery)}
            onChange={(selectedOption) =>
              handleFilterChange('siteName', selectedOption)
            }
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            formatOptionLabel={(data, { context }) =>
              formatOptionLabel(data, { context })
            }
            isMulti={false}
            components={{
              ValueContainer: ValueContainer,
              Option: wrapMenuList(dropdownOptions),
            }}
            isClearable
            cacheUniqs={[selectedCustomer]}
            action={ORG_ACTIONS.filter}
          />
        )}
      </div>

      <div
        className={`d-flex justify-content-end w-100 ${classes['buttons-wrapper']}`}>
        <LinkButton
          className='text text__small'
          onClick={handleClose}>
          Cancel
        </LinkButton>
        <Button
          disabled={
            comment?.length === 0 ||
            (selectOptions === 'site' && selectedFilters?.siteName === null)
          }
          variant='btn btn-gne btn-primary'
          onClick={saveComment}
          className='text__small ms-3'>
          Save
        </Button>
      </div>
    </>
  );
};

export default AddComments;
