import { moveSelected } from 'utils/utils';
import isEmpty from 'lodash/isEmpty';
import { getFilters } from 'services/org/org.service';

export const loadOptionss = async (
  field,
  accountId,
  selected,
  filters,
  dataOffset,
  options,
  searchQuery,
  action,
  unixId,
  selectedFilters,
  filterClicked,
  currentFilterName,
  setCurrentFilterName,
  loadMore,
  classType
) => {
  let data = [];
  let hasMore = false;
  let listSize = 0;
  let selectedOptLt = [];
  data = options[field];
  const isOtherFilterSelected = Object.keys(selectedFilters)?.some(
    (element) => element !== field && selectedFilters[element]?.length > 0
  );
  /**
   * If user has selected any value for the current filter.
   */
  if (selected.length > 0 && !searchQuery && options[field].length > 0) {
    /**
     * If user is selecting a value for a current filters, while any one of the filters has already been selected. Then
     * an API call will be initiated.
     */
    if (currentFilterName === field && isOtherFilterSelected) {
      let { values, hasMoreElement, currentListSize, selectedOpt } =
        await getData(
          field,
          accountId,
          selected,
          filters,
          dataOffset,
          options,
          searchQuery,
          action,
          unixId,
          loadMore,
          classType
        );

      data = [...values];
      hasMore = hasMoreElement;
      listSize = currentListSize;
      selectedOptLt = selectedOpt;
    }

    setCurrentFilterName('');
    data = [...move(selected, data)];

    return {
      data: data,
      hasMore: hasMore,
      currentListSize: listSize,
      selectedOpt: selectedOptLt,
      flag: true,
    };
  }

  /**
   * If user comes for the first time then an API call is initiated.
   */
  if (
    filterClicked[field] ||
    !Object.values(filterClicked).reduce((a, b) => a + b, 0)
  ) {
    let { values, hasMoreElement, currentListSize, selectedOpt } =
      await getData(
        field,
        accountId,
        selected,
        filters,
        dataOffset,
        options,
        searchQuery,
        action,
        unixId,
        loadMore,
        classType
      );
    return {
      data: [...values],
      hasMore: hasMoreElement,
      currentListSize: currentListSize,
      selectedOpt: selectedOpt,
      flag: true,
    };
  }

  return {
    data: data,
    hasMore: hasMore,
    currentListSize: listSize,
    selectedOpt: selected,
    flag: false,
  };
};

export const getData = async (
  field,
  accountId,
  selected,
  filters,
  dataOffset,
  options,
  searchQuery,
  action,
  unixId,
  loadMore,
  classType
) => {
  let data = [];

  const { values, currentListSize, hasMoreElement } = await getFilters(
    field,
    accountId,
    filters,
    dataOffset,
    searchQuery,
    action,
    unixId,
    classType
  );
  const optionsMap = new Map(values.map((option) => [option.value, option]));
  selected = selected.filter((filter) => optionsMap.has(filter.value));
  if (selected?.length > 0 || (!isEmpty(selected) && !loadMore)) {
    const opts = moveSelected(selected, values);
    data = [...opts];
  } else {
    data = [...values];
  }

  return {
    values: data,
    currentListSize,
    hasMoreElement,
    selectedOpt: selected,
  };
};

export const shouldLoadMoreOptions = (
  scrollHeight,
  clientHeight,
  scrollTop
) => {
  const bottomBorder = (scrollHeight - clientHeight) / 2;
  return bottomBorder < scrollTop;
};

const move = (selected, options) => {
  if (selected?.length > 0 && options?.length > 0) {
    const opts = moveSelected(selected, options);
    return opts;
  } else if (options.length > 0) {
    return options;
  }

  return options;
};
