import axiosDBClient from 'axiosDBClient';

import { toast } from 'react-toastify';

export const getFilters = async (
  field,
  accountId,
  filters,
  offset,
  searchQuery,
  action,
  unixId,
  classType
) => {
  const result = await axiosDBClient.post('org/filters', {
    field: field,
    accountId: accountId,
    condition: filters,
    offset: offset || 0,
    searchText: searchQuery || '',
    action: action || '',
    unixId: unixId,
    classType: classType,
  });
  return result.data.result;
};

export const getSites = async (accountId, classType, filters) => {
  const result = await axiosDBClient.get('org/sites', {
    params: { accountId: accountId, classType: classType, filters: filters },
  });
  return result.data.result;
};

export const getChildSites = async (siteId) => {};

export const getLabels = async (accountId, unixId) => {
  const result = await axiosDBClient.get('org/labels', {
    params: { accountId, unixId },
  });
  return result.data.result;
};

export const getAccount = async (accountId) => {
  const result = await axiosDBClient.get('org/account', {
    params: { accountId },
  });
  return result.data.result;
};

export const filterSelectionRefactoring = (inputObject) => {
  const resultObject = {};

  for (const key in inputObject) {
    if (inputObject[key] !== null) {
      if (Array.isArray(inputObject[key])) {
        const filteredArray = inputObject[key].filter(
          (item) => item && item.value !== null && item.value !== ''
        );
        if (filteredArray.length > 0) {
          resultObject[key] = filteredArray.map((item) => item.value);
        }
      } else if (
        typeof inputObject[key] === 'string' &&
        inputObject[key] !== ''
      ) {
        if (key === 'grossSales')
          resultObject[key] = inputObject[key] * 1000000;
        else resultObject[key] = inputObject[key];
      }
    }
  }

  return resultObject;
};

export const removeSites = async (payload, selectedRow = [], name = '') => {
  try {
    const response = await axiosDBClient.put('org/sites', {
      sites: payload,
      name: name,
    });
    if (response.error) {
      throw new Error(response.error);
    }
    if (response.data.status === 'success') {
      toast.success(response.data.message);
    } else if (response.data.status === 'fail') {
      toast.error(response.data.message);
    } else {
      toast.warn(response.data.message);
    }
    return response.data.message;
  } catch (error) {
    console.log('error', error.message);
  }
};

export const addSites = async (
  payload,
  action,
  selectedRow = [],
  name = ''
) => {
  try {
    const response = await axiosDBClient.post('org/sites/add', {
      sites: payload,
      action: action,
      name: name,
    });
    if (response.error) {
      throw new Error(response.error);
    }
    if (response.data.status === 'success') {
      toast.success(response.data.message);
    } else if (response.data.status === 'fail') {
      toast.error(response.data.message);
    } else {
      toast.warn(response.data.message);
    }
    return response.data.message;
  } catch (error) {
    console.log(`error ${name}`, error.message);
  }
};

export const moveSites = async (payload) => {
  try {
    const response = await axiosDBClient.post('org/sites/move', {
      sites: payload,
    });
    if (response.error) {
      throw new Error(response.error);
    }
    toast.success('The Site is moved');
    return response.data.message;
  } catch (error) {
    console.log('error', error.message);
  }
};

export const shareSites = async (payload) => {
  try {
    const response = await axiosDBClient.put('org/sites/share', {
      sites: payload,
    });
    if (response.error) {
      throw new Error(response.error);
    }
    toast.success('Sites Shared successfully');
    return response.data.message;
  } catch (error) {
    console.log('error', error.message);
  }
};

export const edit340b = async (payload, name = '') => {
  try {
    const response = await axiosDBClient.post('org/sites/edit340b', {
      sites: payload,
      name: name,
    });
    if (response.error) {
      throw new Error(response.error);
    }
    if (response.data.status === 'success') {
      toast.success(response.data.message);
    } else if (response.data.status === 'fail') {
      toast.error(response.data.message);
    } else {
      toast.warn(response.data.message);
    }
    return response.data.message;
  } catch (error) {
    console.log(`error ${name}`, error.message);
  }
};

export const getUserLogs = async (
  logType,
  action,
  order,
  offset,
  customerId
) => {
  const response = await axiosDBClient.get(`org/viewlogs`, {
    params: { logType, action, order, offset, customerId },
  });
  if (response.error) {
    throw new Error('Could not fetch logs data!');
  }

  return response.data.result;
};

export const addLogs = async (payload) => {
  const response = await axiosDBClient.post(`org/viewlogs`, {
    logs: payload,
  });
  if (response.error) {
    throw new Error('Could not fetch logs data!');
  }

  return response.data.result;
};

export const getActionDropdownList = async (customerId, unixId) => {
  const result = await axiosDBClient.get('org/action-drop-down', {
    params: { customerId, unixId },
  });
  return result.data.result;
};

export const getUserLogsExport = async (
  logType,
  action,
  order,
  offset,
  customerId
) => {
  const response = await axiosDBClient.get(`org/viewlogs/export`, {
    params: { logType, action, order, offset, customerId },
    responseType: 'blob',
  });
  if (response) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Log.xlsx';
    a.click();
  }
  if (response.error) {
    throw new Error('Could not fetch logs data!');
  }

  return response.data.result;
};

export const saveFilterView = async (
  accountId,
  filters,
  unixId,
  accountName
) => {
  const result = await axiosDBClient.post('org/filterView', {
    unixId,
    filters,
    accountId: accountId.current,
    filterName: accountName.current + '_saved_filter',
  });
  return result?.data;
};

export const getSavedFilterView = async (accountId, unixId) => {
  const result = await axiosDBClient.get('org/filterView', {
    params: { accountId, unixId },
  });
  return result;
};
