import GNEModal from 'components/shared/modal/Modal';
import Rating from './Rating';
import classes from './Feedback.module.scss';
import LinkButton from 'components/shared/button/LinkButton';
import { Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { addFeedback } from 'services/feedback.service';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const Feedback = ({ show, onHide }) => {
  const rate = Array.from({ length: 10 }, (_, i) => i + 1);
  const maxChar = 5000;

  const [current, setCurrent] = useState(0);
  const [count, setCount] = useState(0);
  const [comment, setComment] = useState('');
  const [isLimitExceeded, setIsLimitExceeded] = useState(false);
  const { unixId, roleId } = useSelector((state) => state.Auth.userClaimObject);

  useEffect(() => {
    reset();
  }, []);

  const handleRatingClick = (number) => {
    setCurrent(number);
  };

  const handleSubmit = async () => {
    const payload = {
      unixId: unixId,
      roleId: roleId,
      rate: current,
      comment: comment,
    };

    try {
      await addFeedback(payload);
      onHide();
      reset();
    } catch (error) {
      toast.error('Error while submitting feedback.');
      console.log('error', error);
    }
  };

  const handleCommentChanged = (e) => {
    // set comment and count
    setComment(e?.target?.value);
    setCount(e?.target?.value?.length);

    // check we've reached the limit
    // show limit message and remove excess characters
    setIsLimitExceeded(e?.target?.value?.length > maxChar);
  };

  const reset = () => {
    setComment('');
    setCount(0);
    setCurrent(0);
    setIsLimitExceeded(false);
  };

  const handleCancel = () => {
    reset();
    onHide();
  };

  // title html
  const title = (
    <>
      <p
        className='title__large title__bold d-flex align-items-center'
        title='Feedback title'>
        <i className='icon icon__feedback-black'></i>Leave Feedback
      </p>
      <p
        className='text text__small'
        title='Sub title'>
        Compliment or critique, we are just happy to hear from you.
      </p>
    </>
  );

  // content html
  const content = (
    <>
      {rate.map((number, index) => (
        <div
          className='d-inline-block'
          key={index}
          title='Ratings'>
          <Rating
            number={number}
            current={current}
            onClick={() => handleRatingClick(number)}
          />

          {number === 1 && (
            <p
              className='text text__x-small ms-1'
              title='Hate It'>
              Hate It
            </p>
          )}
          {number === 10 && (
            <p
              className='text text__x-small ms-2'
              title='Love It'>
              Love It
            </p>
          )}
          {number !== 1 && number !== 10 && (
            <p className='text text__x-small'>&nbsp;</p>
          )}
        </div>
      ))}

      <p
        className='text text__x-small mt-4 mb-1'
        title='Comments'>
        Comments
      </p>
      {isLimitExceeded && (
        <p
          className='text text__small text__red animate__animated animate__headShake'
          title='Limit exceeded'>
          oops! It looks like you've reached the maximum characters allowed for
          comments.
        </p>
      )}
      <textarea
        className={`w-100 ${classes.comments}`}
        value={comment}
        onChange={(e) => handleCommentChanged(e)}
        title='Comment field'
      />

      <p
        className='text text__x-small animate__animated animate__fadeIn'
        title='Character limit'>
        {count} / {maxChar} Characters
      </p>
    </>
  );

  // footer
  const footer = (
    <div className='d-flex justify-content-end w-100'>
      <LinkButton
        className='text text__small'
        onClick={handleCancel}>
        Cancel
      </LinkButton>
      <Button
        className='btn btn-gne btn-primary ms-2'
        onClick={handleSubmit}
        disabled={isLimitExceeded || !current}>
        Submit
      </Button>
    </div>
  );

  return (
    <GNEModal
      titlecontent={title}
      show={show}
      onHide={handleCancel}
      content={content}
      css='size-medium'
      footer={footer}
      backdrop='static'
    />
  );
};

export default Feedback;
