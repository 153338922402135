import api from 'axiosDBClient';
import { toast } from 'react-toastify';

export const getPriorityAccounts = async (unixId, roleId) => {
  const response = await api.get('configure-priority', {
    params: { unixId, roleId },
  });

  if (response.error) {
    throw new Error('Could not fetch accounts data!');
  }

  return response.data.result;
};

export const getPriorityTeam = async (customerId) => {
  const response = await api.get('configure-priority/customer-team', {
    params: { customerId },
  });

  if (response.error) {
    throw new Error(
      'Could not fetch customer Team data for customer ID- ',
      customerId
    );
  }

  return response.data.result;
};

export const getTeamMembers = async (customerId, field) => {
  const response = await api.get('configure-priority/team-member', {
    params: { customerId, field },
  });

  if (response.error) {
    throw new Error(
      'Could not fetch customer Team data for customer ID- ',
      customerId
    );
  }

  return response.data.result;
};

export const addMemberToTeam = async (payload) => {
  const setAddToTeam = async () => {
    const response = await api.post('configure-priority', {
      ...payload,
    });

    if (response.data.error) {
      throw new Error('Could not add data!');
    }

    return response.data;
  };

  try {
    const data = await setAddToTeam();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const updateMemberOfTeam = async (customerId, record) => {
  const updateTeam = async () => {
    const response = await api.put(
      `configure-priority/primary-contact/${customerId}`,
      {
        id: record?.idx,
      }
    );
    return response.data;
  };

  try {
    const data = await updateTeam();
    toast.success(`${record.userName} updated as primary contact`);
    return data;
  } catch (error) {
    toast.error(`Error occured - Please try after sometime.`);
    console.log(error.message);
  }
};

export const addPriority = async (payload) => {
  try {
    const response = await api.post('/configure-priority', { ...payload });
    if (response.error) {
      throw new Error('Could  Add Priority Account!');
    }

    return response.data.message;
  } catch (error) {
    console.log('error', error);
  }
};

export const deleteAccount = async (accountId, unixId, roleId) => {
  const setDeleteAccount = async () => {
    const response = await api.delete(`/configure-priority/${accountId}`, {
      params: { unixId, roleId },
    });
    if (response.data.error) {
      throw new Error('Could not delete data!');
    }
    return response.data;
  };
  try {
    const data = await setDeleteAccount();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const delelteTeamMember = async (customerId, unixId) => {
  try {
    const response = await api.delete(`configure-priority/${customerId}`, {
      params: {
        unixId: unixId,
      },
    });

    if (response.data.error) {
      throw new Error('Could not delete data!');
    }

    return response.data;
  } catch (error) {
    console.log(error.message);
  }
};
