/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Input } from 'antd';

import { searchData } from 'utils/utils';

import classes from './FavoriteDashboards.module.scss';

const FavoriteDashboards = ({
  reportsData,
  setReportData,
  updatedReports,
  setUpdatedReports,
  ...props
}) => {
  useEffect(() => {
    handleReset();

    const tempReport = [...reportsData];
    tempReport?.sort((a, b) => a?.reportName?.localeCompare(b.reportName));

    setSearchedData(tempReport);
  }, [reportsData]);

  const handleChange = (e, item) => {
    if (updatedReports.indexOf(item.reportID) !== -1) {
      setUpdatedReports([...updatedReports].filter((i) => i !== item.reportID));
    } else {
      setUpdatedReports([...updatedReports, item.reportID]);
    }

    setReportData(
      reportsData?.map((report) => {
        if (report.reportID === item.reportID) {
          const updatedData = {
            ...report,
            isFavorite: e?.target?.checked ? 'Y' : 'N',
          };
          return updatedData;
        }

        return report;
      })
    );
  };

  const [searchText, setSearchText] = useState('');
  const [searchedData, setSearchedData] = useState(reportsData);
  const { Search } = Input;

  const handleSearch = (value) => {
    const filteredData = searchData(reportsData, value);
    setSearchText(value);
    setSearchedData(filteredData);
  };
  const handleReset = () => {
    setSearchedData(reportsData);
    setSearchText('');
  };
  const isSave = updatedReports?.length === 0;

  const saveList = () => {
    setSearchText('');
    props.onSave();
  };
  return (
    <Modal
      {...props}
      size='sm'
      className={`${classes.editModal} editFavoritesModal`}>
      {/* the modal header*/}
      <Modal.Header
        closeButton
        className='pt-0'>
        <Modal.Title>
          <p className='title title__bold mb-0 mt-4'>
            <i className='icon icon__favorite'></i>Favorite Dashboards
          </p>
        </Modal.Title>
      </Modal.Header>

      {/* the modal body */}
      <Modal.Body className={classes.modalBody}>
        <Row>
          <Search
            placeholder='Search'
            onSearch={handleSearch}
            allowClear
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onReset={handleReset}
          />
        </Row>
        <Table hover>
          <tbody className={classes.favoritesList}>
            {searchedData?.map((report, index) => (
              <tr
                key={index}
                className={report.selected ? 'selected' : ''}>
                <td>{report.reportName}</td>
                <th scope='row'>
                  <input
                    type='checkbox'
                    checked={report.isFavorite === 'Y'}
                    className={
                      report.isFavorite === 'Y'
                        ? `${classes.btnBgColor} form-check-input`
                        : 'form-check-input'
                    }
                    id='rowcheck{report.reportsName}'
                    onChange={(e) => handleChange(e, report)}
                  />
                </th>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>

      {/* the modal body */}
      <Modal.Footer>
        <Link
          className='title__normal text-decoration-none me-4'
          onClick={props.onHide}>
          Cancel
        </Link>
        <Button
          disabled={isSave}
          className={classes.btnBgColor}
          onClick={() => saveList()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FavoriteDashboards;
