/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import classes from './View.module.scss';

import CustomerDropdown from '../../shared/customer/CustomerDropdown';
import 'animate.css';

import { Fade } from 'react-awesome-reveal';
import {
  setCustomerViewButtonDisabled,
  setSelectedCustomer,
  setSeletedView,
  setInsightType,
  setFilterInit,
} from 'store/customer/customerViewSlice';
import { getFocusAccountData } from 'store/customer/customer-view-actions';
import {
  CONFIGURE_VIEW,
  CUSTOMER_TYPE,
  CUSTOMER_VIEW_DETAIL_PAGE,
  CV_VIEW,
  VIEW,
} from 'constants/constants';

import './warningMsg.css';
import HealthSystem from '../wrapper/HealthSystem';
import { setEngagementDateInit } from 'store/customer/filter/filter-slice';
import AdvanceSearch from '../../shared/customer/AdvanceSearch';

const ViewInsights = () => {
  const dispatch = useDispatch();

  const componentAccessList = useSelector(
    (state) => state?.Auth?.componentAccessList
  );
  const focusAccountDisabled = useSelector(
    (state) => state?.CustomerView?.focusAccountDisabled
  );
  const focusAccountChecked = useSelector(
    (state) => state?.CustomerView?.focusAccountChecked
  );
  const { unixId, roleId } = useSelector(
    (state) => state?.Auth?.userClaimObject
  );
  const selectedCustomer = useSelector(
    (state) => state?.CustomerView?.selectedCustomer
  );
  const selectedView = useSelector(
    (state) => state?.CustomerView?.selectedView
  );

  const advanceSearchClicked = useSelector(
    (state) => state?.CustomerView?.advanceSearchClicked
  );

  const notificationId = useRef(null);

  const [loadComponents, setLoadComponents] = useState(
    selectedCustomer !== undefined || selectedCustomer?.length > 0
  );

  const onChangeHandler = (selected) => {
    toast.dismiss(notificationId.current);
    dispatch(setCustomerViewButtonDisabled(true));
    if (selected?.label?.length > 1) {
      dispatch(setSelectedCustomer(selected));
      setLoadComponents(true);
      dispatch(setFilterInit());
      dispatch(setEngagementDateInit());
      dispatch(setSeletedView(CUSTOMER_TYPE.get(selected.flag)));
      dispatch(setInsightType('general'));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedView, selectedCustomer]);

  useEffect(() => {
    dispatch(
      getFocusAccountData(
        unixId,
        roleId,
        selectedView,
        CUSTOMER_VIEW_DETAIL_PAGE
      )
    );
  }, [selectedView]);

  return (
    <>
      {/* customer view filter */}
      <Row className={classes['title-container']}>
        <Row className='ps-0'>
          <Col
            md={2}
            xs={12}
            className='d-flex align-items-center ps-0'>
            <Fade>
              <p
                className={`title__bold title__medium  m-0 d-inline-block  ${classes['customer-insight-label']}`}>
                <i className='icon icon__customer-view--title'></i>
                Customer View
              </p>
            </Fade>
          </Col>
          <Col md={6}>
            <CustomerDropdown
              page={CONFIGURE_VIEW.customerView}
              viewName={VIEW.customer}
              onChangeHandler={onChangeHandler}
              focusDisabled={focusAccountDisabled}
              focusChecked={focusAccountChecked}
              isDisabled={false}
            />
          </Col>
        </Row>
        {advanceSearchClicked[CONFIGURE_VIEW.customerView] && (
          <Row className='animate__animated animate__fadeIn search-container addCustomerView w-100'>
            <AdvanceSearch
              page={CONFIGURE_VIEW.customerView}
              viewType={CONFIGURE_VIEW.customerView}
            />
          </Row>
        )}
      </Row>
      {/* sections */}
      {CV_VIEW.HEALTH_SYSTEM_VIEW.includes(selectedCustomer?.flag) && (
        <HealthSystem
          loadComponents={loadComponents}
          selectedView={selectedView}
          componentAccessList={componentAccessList}
        />
      )}
    </>
  );
};

export default ViewInsights;
