import { Container } from 'react-bootstrap';
import { ProSidebarProvider } from 'react-pro-sidebar';

import Layout from 'components/layout/Layout';
import TrendsContainer from './TrendsContainer';
import { CONFIGURE_VIEW } from 'constants/constants';

const Trends = () => {
  return (
    <ProSidebarProvider>
      <Layout showRighSideBar={false}>
        <Container className='gne-container overflow-hidden'>
          <TrendsContainer
            buttonText={'Add to My View'}
            view='add'
            page={CONFIGURE_VIEW.trends}>
            <>
              <i className='icon icon__trends--black'></i>
              <span className='title__bold title__medium'>Trends</span>
            </>
          </TrendsContainer>
        </Container>
      </Layout>
    </ProSidebarProvider>
  );
};

export default Trends;
