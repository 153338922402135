const NavSubMenu = ({ item, collapsed }) => {
  return (
    <>
      <i className={`icon icon__${item.categoryIcon} nav-link-icon`}></i>
      {!collapsed && item.categoryName}
    </>
  );
};

export default NavSubMenu;
