import axiosDBClient from 'axiosDBClient';
import {
  COMPARE_BENCHMARK,
  COMPARE_KPI,
  COMPARE_TIME_PERIOD,
  DIMENSION,
} from 'constants/constants';
import { setAdvanceDropdownOptions as setInsightAdvanceDropdownOptions } from 'store/customer/customerViewSlice';
import { setAdvanceDropdownOptions } from 'store/trends_v2/trendsSlice';

export const loadDropdownOptions = async (
  selectedOption,
  dispatch,
  selectedKPI,
  selectedTimePeriod,
  dimensionName,
  reducerName,
  selectedAdvanceOptions
) => {
  let dimensionId = selectedKPI?.dimensions.filter(
    (item) => item.dimensionName === dimensionName
  )?.[0]?.id;

  let isMyView = reducerName === 'HomeMyView' ? true : false;

  let options = [];
  if (dimensionId) {
    if (isMyView) dispatch(setAdvanceDropdownOptions([]));
    else dispatch(setInsightAdvanceDropdownOptions([]));

    options =
      selectedOption === DIMENSION
        ? await getAdvanceKpiDimensionsByIds(
            selectedKPI?.KpiId,
            dimensionId,
            selectedTimePeriod?.label
          )
        : selectedOption === COMPARE_BENCHMARK
        ? await getAdvanceKpiBenchmarkByIds(
            selectedKPI?.KpiId,
            dimensionId,
            selectedTimePeriod?.label
          )
        : selectedOption === COMPARE_KPI
        ? await getMultiKpiValues(
            selectedKPI?.KpiId,
            selectedTimePeriod?.label,
            dimensionId
          )
        : await getAdvanceKpiTimeperiodByIds(
            selectedKPI?.KpiId,
            dimensionId,
            dimensionName
          );
    if (selectedOption === COMPARE_TIME_PERIOD && dimensionName !== 'Month') {
      options = options?.filter((oneValue) => {
        if (oneValue?.label?.startsWith('Previous')) {
          if (oneValue?.label === `Previous ${selectedTimePeriod?.label}`) {
            return oneValue;
          }
        } else {
          if (oneValue?.label !== selectedTimePeriod?.label) {
            return oneValue;
          }
        }
      });
    } else if (
      selectedOption === COMPARE_TIME_PERIOD &&
      dimensionName === 'Month'
    ) {
      options = options?.filter(
        (oneValue) => oneValue?.label?.indexOf(selectedTimePeriod?.label) > 0
      );
    } else if (
      selectedOption === COMPARE_KPI &&
      selectedAdvanceOptions?.view === 'edit' &&
      selectedAdvanceOptions?.selectDropdownOption
    ) {
      let isLimitCrossed =
        selectedAdvanceOptions?.selectDropdownOption?.label?.length >= 4;

      let tempOptions = options?.map((item) => ({
        ...item,
        disabled: !isLimitCrossed
          ? false
          : selectedAdvanceOptions?.selectDropdownOption?.label.filter(
              (sel) => sel.label === item.label
            )?.length === 0,
      }));

      if (tempOptions?.length > 0) options = tempOptions;
    }
  }
  if (isMyView) dispatch(setAdvanceDropdownOptions(options));
  else dispatch(setInsightAdvanceDropdownOptions(options));
};

export const loadDropdownOptionsv2 = async (
  selectedOption,
  dispatch,
  selectedKPI,
  selectedTimePeriod,
  selectedDimension,
  selectedAdvanceOptions
) => {
  let options = [];
  if (selectedDimension?.dimensionId) {
    dispatch(setAdvanceDropdownOptions([]));

    options =
      selectedOption === DIMENSION
        ? await getAdvanceKpiDimensionsByIds(
            selectedKPI?.KpiId,
            selectedDimension?.dimensionId,
            selectedTimePeriod?.label
          )
        : selectedOption === COMPARE_BENCHMARK
        ? await getAdvanceKpiBenchmarkByIds(
            selectedKPI?.KpiId,
            selectedDimension?.dimensionId,
            selectedTimePeriod?.label
          )
        : selectedOption === COMPARE_KPI
        ? await getMultiKpiValues(
            selectedKPI?.KpiId,
            selectedTimePeriod?.label,
            selectedDimension?.dimensionId
          )
        : await getAdvanceKpiTimeperiodByIds(
            selectedKPI?.KpiId,
            selectedDimension?.dimensionId,
            selectedDimension?.dimensionName
          );
    if (
      selectedOption === COMPARE_TIME_PERIOD &&
      selectedDimension?.dimensionName !== 'Month'
    ) {
      options = options?.filter((oneValue) => {
        if (oneValue?.label?.startsWith('Previous')) {
          if (oneValue?.label === `Previous ${selectedTimePeriod?.label}`) {
            return oneValue;
          }
        } else {
          if (oneValue?.label !== selectedTimePeriod?.label) {
            return oneValue;
          }
        }
      });
    } else if (
      selectedOption === COMPARE_TIME_PERIOD &&
      selectedDimension?.dimensionName === 'Month'
    ) {
      options = options?.filter(
        (oneValue) => oneValue?.label?.indexOf(selectedTimePeriod?.label) > 0
      );
    } else if (
      selectedOption === COMPARE_KPI &&
      selectedAdvanceOptions?.view === 'edit' &&
      selectedAdvanceOptions?.selectDropdownOption
    ) {
      let isLimitCrossed =
        selectedAdvanceOptions?.selectDropdownOption?.label?.length >= 4;

      let tempOptions = options?.map((item) => ({
        ...item,
        disabled: !isLimitCrossed
          ? false
          : selectedAdvanceOptions?.selectDropdownOption?.label?.filter(
              (sel) => sel.label === item.label
            )?.length === 0,
      }));

      if (tempOptions?.length > 0) options = tempOptions;
    }
  }
  dispatch(setAdvanceDropdownOptions(options));
};

export const getAdvanceKpiDimensionsByIds = async (
  kpiId,
  dimensionId,
  selectedTimePeriod
) => {
  const response = await axiosDBClient.get(
    `kpi/advance/kpi-dimension/${kpiId}`,
    {
      params: { dimensionId, selectedTimePeriod },
    }
  );

  if (response.error) {
    throw new Error('Dimensions Not Found !');
  }
  return response.data.result;
};

export const getAdvanceKpiBenchmarkByIds = async (
  kpiId,
  dimensionId,
  selectedTimePeriod
) => {
  try {
    const response = await axiosDBClient.get(
      `kpi/advance/kpi-benchmark/${kpiId}`,
      {
        params: { dimensionId, selectedTimePeriod },
      }
    );

    if (response.error) {
      throw new Error('BenchMark Not Found !');
    }
    return response.data.result;
  } catch (error) {
    throw new Error('BenchMark Not Found !');
  }
};

export const getAdvanceKpiTimeperiodByIds = async (
  kpiId,
  dimensionId,
  primaryDimension
) => {
  const response = await axiosDBClient.get(
    `kpi/advance/kpi-time-period/${kpiId}`,
    {
      params: { dimensionId, primaryDimension },
    }
  );

  if (response.error) {
    throw new Error('Time period Not Found !');
  }
  return response.data.result;
};

export const getMultiKpiValues = async (kpiId, timePeriods, dimensionId) => {
  const response = await axiosDBClient.get(`kpi/advance/multi-kpi/${kpiId}`, {
    params: { kpiId, timePeriod: timePeriods, dimensionId: dimensionId },
  });

  if (response.error) {
    throw new Error('KPI Not Found !');
  }
  return response.data.result;
};
