import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LinkButton from 'components/shared/button/LinkButton';
import { CUSTOMER_VIEW_TEXT } from 'constants/constants';
import CopyInsightBoard from '../insights/copy-insight/CopyInsightBoard';
import classes from './SampleInsightBoard.module.scss';

export const SampleInsightsBoard = ({ insight, onItemClickHandler }) => {
  const [modalShow, setModalShow] = useState(false);

  const copyInsightHandler = () => {
    setModalShow(true);
  };

  return (
    <Row
      className={`${classes['list-item']} animate__animated animate__fadeIn`}>
      <Col
        md={9}
        className='text-start title__normal title__blue title__bold'>
        <Link
          to={insight.name.replace(CUSTOMER_VIEW_TEXT, '')}
          onClick={onItemClickHandler.bind(this, insight)}>
          {insight.name.replace(CUSTOMER_VIEW_TEXT, '')}
        </Link>
      </Col>
      <Col md={2}>
        <label className='ps-1 title__x-small text__black'>
          {insight?.boardType === 'site' ? 'Sample Site' : 'Sample Account'}
        </label>
      </Col>

      <Col md={1}>
        <span className='float-end d-flex'>
          <LinkButton
            className='me-0'
            onClick={copyInsightHandler}>
            <i className='icon icon__copy-insight m-0'></i>
          </LinkButton>
        </span>
        <CopyInsightBoard
          showModal={modalShow}
          setShowModal={setModalShow}
          currentInsight={insight}
        />
      </Col>
    </Row>
  );
};
