import React from 'react';
import classes from './Tiles.module.scss';
const EcosystemAlignmentChange = () => {
  return (
    <div className={classes['no-dimension-tile']}>
      <div className='d-flex bg-warning bg-opacity-25 align-items-center p-3'>
        <i className='icon icon__eco-align-change-warning  w-45'></i>
        <div className='text__small ml-1'>
          Your alignment has changed. <br /> Kindly update or delete this tile.
        </div>
      </div>
    </div>
  );
};

export default EcosystemAlignmentChange;
