import { Card, Col, Row } from 'react-bootstrap';

import classes from './SiteInformation.module.scss';

import LinkButton from 'components/shared/button/LinkButton';

import { numberToShortNumFormatter } from 'utils/utils';

import { FLAG } from 'constants/constants';
import { Spin } from 'antd';

export const SiteInformation = (props) => {
  const COLUMN_SPECS = {
    titleWidth: '4',
    infoWidth: '8',
  };

  const cardStyle = {
    boxShadow: 'none',
    border: '1px solid #e0efdc',
    height: '100%',
    width: '100%',
  };

  return (
    <>
      <Row>
        <Col
          md={8}
          className='p-0'>
          <Card
            className='gne-card'
            style={cardStyle}>
            <div className={classes.title}>
              <div
                className={`icon icon__site-big ${classes['align-middle']}`}></div>
              {props?.data.flag !== FLAG.account ? (
                <LinkButton
                  className={`ps-0 ${classes['align-middle']}`}
                  onClick={props.onSiteNameClick}>
                  {props?.data?.name}

                  <i className='icon icon__expand_view ms-2'></i>
                </LinkButton>
              ) : (
                <>{props?.data?.name}</>
              )}
            </div>

            {/* address */}
            <Row className={` mt-4 ${classes.detail}`}>
              <Col
                md={COLUMN_SPECS.titleWidth}
                lg={COLUMN_SPECS.titleWidth}>
                <label className='text__grey'>Address</label>
              </Col>
              <Col
                md={COLUMN_SPECS.infoWidth}
                lg={COLUMN_SPECS.infoWidth}>
                <label>{props?.data?.address}</label>
              </Col>
            </Row>

            {/* name */}
            <Row>
              <Col
                md={COLUMN_SPECS.titleWidth}
                lg={COLUMN_SPECS.titleWidth}>
                <label className='sub-title sub-title__small  text__grey'>
                  Account Name
                </label>
              </Col>
              <Col
                md={COLUMN_SPECS.infoWidth}
                lg={COLUMN_SPECS.infoWidth}>
                <LinkButton
                  className={`ps-0 ${classes['align-middle']}`}
                  onClick={props.onAccountClick}>
                  {props?.account?.label}

                  <i className='icon icon__expand_view ms-2'></i>
                </LinkButton>
              </Col>
            </Row>

            {/* classification */}
            <Row>
              <Col
                md={COLUMN_SPECS.titleWidth}
                lg={COLUMN_SPECS.titleWidth}>
                <label className='sub-title sub-title__small  text__grey'>
                  Classification
                </label>
              </Col>
              <Col
                md={COLUMN_SPECS.infoWidth}
                lg={COLUMN_SPECS.infoWidth}>
                <label className='sub-title sub-title__small  text__grey__bold'>
                  {props?.data?.classification}
                </label>
              </Col>
            </Row>

            {/* ecosystem */}
            <Row>
              <Col
                md={COLUMN_SPECS.titleWidth}
                lg={COLUMN_SPECS.titleWidth}>
                <label className='sub-title sub-title__small  text__grey'>
                  Ecosystem
                </label>
              </Col>
              <Col
                md={COLUMN_SPECS.infoWidth}
                lg={COLUMN_SPECS.infoWidth}>
                <label className='sub-title sub-title__small  text__grey__bold'>
                  {props?.data?.ecosystemName}
                </label>
              </Col>
            </Row>

            {/* primary contact */}
            <Row>
              <Col
                md={COLUMN_SPECS.titleWidth}
                lg={COLUMN_SPECS.titleWidth}>
                <label className='sub-title sub-title__small  text__grey'>
                  Primary Point of Contact
                </label>
              </Col>
              <Col
                md={COLUMN_SPECS.infoWidth}
                lg={COLUMN_SPECS.infoWidth}>
                <label className='sub-title sub-title__small  text__grey__bold'>
                  {props?.data?.primaryContact?.length > 0
                    ? props?.data?.primaryContact
                    : 'NA'}
                </label>
              </Col>
            </Row>

            {/* id */}
            <Row>
              <Col
                md={COLUMN_SPECS.titleWidth}
                lg={COLUMN_SPECS.titleWidth}>
                <label className='sub-title sub-title__small  text__grey'>
                  ID
                </label>
              </Col>
              <Col
                md={COLUMN_SPECS.infoWidth}
                lg={COLUMN_SPECS.infoWidth}>
                <label className='sub-title sub-title__small  text__grey__bold'>
                  {' '}
                  {props?.data?.id}
                </label>
              </Col>
            </Row>
          </Card>
        </Col>

        {/* insights */}
        <Col
          md={4}
          className='pe-0 d-flex justify-content-around flex-column'>
          <Row className={`justify-content-between ${classes['insight-row']}`}>
            {/* contacts */}
            <Col className='ps-0 pe-0 pb-2'>
              <Card
                className='gne-card text-center'
                style={cardStyle}>
                <label className='text text__normal text__grey'>
                  # of Affilliated Contacts
                </label>
                <Spin spinning={props?.affiliationContactLoader}>
                  <label className='ps-1 text__bold text__text-medium'>
                    {props.affiliationContact?.length > 0
                      ? props.affiliationContact
                      : 'NA'}
                  </label>
                </Spin>
              </Card>
            </Col>

            {/* hcps */}
            <Col className='pe-0 pb-2'>
              <Card
                className='gne-card text-center'
                style={cardStyle}>
                <label className='text text__normal text__grey'>
                  # of Affilliated HCP's
                </label>
                <Spin spinning={props?.hcpCountLoader}>
                  <label className='ps-1 text__bold text__text-medium'>
                    {props.hcpCount?.length > 0 ? props.hcpCount : 'NA'}
                  </label>
                </Spin>
              </Card>
            </Col>
          </Row>

          <Row className={`justify-content-between ${classes['insight-row']}`}>
            {/* engagements */}
            <Col className='ps-0 pe-0'>
              <Card
                className='gne-card text-center'
                style={cardStyle}>
                <label className='text text__normal text__grey'>
                  # of Engagements (in last 90 days)
                </label>
                <Spin spinning={props?.engagementsCountLoader}>
                  <label className='ps-1 text__bold text__text-medium'>
                    {props.engagementsCount?.length > 0
                      ? props.engagementsCount
                      : 'NA'}
                  </label>
                </Spin>
              </Card>
            </Col>

            {/* gross sales */}
            <Col className='pe-0'>
              <Card
                className='gne-card text-center'
                style={cardStyle}>
                <label className='text text__normal text__grey'>
                  Gross Sales <br />
                  (in last 12 months)
                </label>
                <Spin spinning={props?.grossSalesR12Loader}>
                  <label className='ps-1 text__bold text__text-medium'>
                    {props.grossSalesR12?.length > 0
                      ? `$${numberToShortNumFormatter(props.grossSalesR12)}`
                      : 'NA'}
                  </label>
                </Spin>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
