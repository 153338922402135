import Select from 'react-select';
import ReactSelectSearch from 'utils/hoc/react-select-search';
import { CustomDropdownIndicator } from 'utils/utils';

export const ReactSelect = (props) => {
  return (
    <Select
      {...props}
      value={props.value}
      onChange={props.onChange}
      options={props.options}
      className={`${props.className}`}
      classNamePrefix='gne'
      placeholder={props.placeholder}
      formatOptionLabel={props.formatOptionLabel}
      menuPosition={props.menuPosition}
      isClearable={props.isClearable}
      components={{
        DropdownIndicator: CustomDropdownIndicator,
        ...props?.components,
      }}
      isDisabled={props.isDisabled}
    />
  );
};

const GNESingleSelect = ReactSelectSearch(ReactSelect);
export default GNESingleSelect;
