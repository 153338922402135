import {
  Button,
  Col,
  Container,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import classes from './Edit340BLayout.module.scss';
import LinkButton from 'components/shared/button/LinkButton';
import Filters from '../filter/Filters';
import SiteHeirarchyTable from './SiteHeirarchyTable';
import { addLogs, edit340b } from 'services/org/org.service';
import { useSelector } from 'react-redux';
import { getSiteGridData } from 'services/org/label.service';
import { Spin } from 'antd';
import {
  NO_SITE_SELECTED,
  NO_SITE_SELECTED_SUBTEXT,
  optionsInitialState,
  LOGTYPE,
  CHANGEACTIONTYPE,
  CHANGEVALUETYPE,
  ACTION_TYPE,
  ORG_ACTIONS,
  EDIT_340B_SUBTEXT,
} from 'constants/constants';
import TextArea from 'antd/es/input/TextArea';
import GNERadio from 'components/shared/radio/GNERadio';


const Edit340BLayout = (props) => {
  const { accountId, labelsData, onCallOrgChartApi, accountName, labelAccess } =
    props;
  const [key, setKey] = useState('addSites');
  const [selectedRow, setSelectedRow] = useState([]);
  const [text, setText] = useState('');
  const [selectedOption, setSelectedOption] = useState(optionsInitialState);
  const [sitesData, setSitesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [gridSize, setGridSize] = useState(0);
  const maxLength = 1000;
  const { unixId } = useSelector((state) => state.Auth.userClaimObject);
  // eslint-disable-next-line
  const [initialFilterValue, setInitialFilterValue] =
    useState(optionsInitialState);
  const [attributeType, setAttributeType] = useState('340B');
  const [selectedFilters, setSelectedFilters] = useState(optionsInitialState);

  const handleRowSelect = (record, type) => {
    if (type === 'checkbox') {
      const isSelected = selectedRow.some((row) => row.key === record.key);
      let newSelectedRows;

      if (isSelected) {
        newSelectedRows = selectedRow.filter((row) => row.key !== record.key);
      } else {
        newSelectedRows = [...selectedRow, record];
      }

      setSelectedRow(newSelectedRows);
    }
  };

  const onhandleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxLength) {
      setText(inputValue);
    }
  };

  const handleClearAll = () => {
    setSelectedRow([]);
  };

  const addActionLog = async () => {
    const payload = selectedRow?.map((item) => ({
      LogType: LOGTYPE.userlog,
      ViewChangeType: CHANGEACTIONTYPE.hierarchy,
      ImpactedMdmId: accountId,
      ValueType: CHANGEVALUETYPE.parent,
      OldValue: item?.key,
      NewValue: '',
      Action: 'remove',
      Flag: '',
      isActive: 'Y',
      ActionDetails: `removed ${item?.name} (${item?.childMdmId}) from the ${accountName}(${accountId})`,
      unixId: unixId,
      customerId: accountId,
    }));
    await addLogs(payload);
  };

  const createPayloadGrid = (filters) => {
    return {
      filters: {
        ...filters,
        customerId: accountId,
        unixId: unixId,
        fieldName: 'sites',
        offset: 0,
        action: 'remove',
        cpMdmId: accountId,
      },
    };
  };

  const handleFilterApply = async (filters) => {
    setIsLoading(true);
    setSelectedFilters({ ...selectedFilters, ...filters});
    const payload = createPayloadGrid(filters);
    const data = await getSiteGridData(payload);
    setSitesData(data?.result?.values);
    setGridSize(data?.result?.currentListSize);
    setIsLoading(false);
  };

  const createPayLoad = (selectedRow, text, unixId, isActive) => {
    const outputArray = [];
    selectedRow.forEach((selectedRowItem) => {
      const { childMdmId, sourceAttributeValue,  editIsActive } = selectedRowItem;
      const output = {
        mdmId: childMdmId,
        cpMdmId: accountId,
        sourceAttributeValue: sourceAttributeValue ?? '',
        userAttributeValue: attributeType === '340B' ? 'Y' : 'N',
        attributeType: 'flag_340b',
        reason: text,
        isActive: editIsActive,
        unixId: unixId,
      };
      // Push the output to the outputArray
      outputArray.push(output);
    });

    return outputArray;
  };

  const handleApply = async (selectedRow, text) => {
    const payload = createPayLoad(selectedRow, text, unixId, 'Y');
    if (payload?.length > 0) {
      setIsLoading(true);
      const name = accountName;
      await edit340b(payload, name);
      resetSelections();

      const payloadGrid = createPayloadGrid(selectedFilters);
      const data = await getSiteGridData(payloadGrid);
      setSitesData(data?.result?.values);
      setGridSize(data?.result?.currentListSize);
      onCallOrgChartApi();
      addActionLog();
      setIsLoading(false);
    }
  };

  const resetSelections = () => {
    setKey(ACTION_TYPE.addSite);
    setText('');
    // setSitesData([]);
    // setSelectedRow([]);
  };

  const resetOptions = () => {
    setSelectedOption(optionsInitialState);
  };

  const handleFilterSelect = (selectedFilter) => {};

  const tooltip = () => {
    return (
      <Tooltip bsclass='gne-tooltip tooltip--left'>
        {selectedRow?.length > 0
          ? selectedRow?.map((item) => {
              return <p className='text text__x-small mb-1'>{item?.name}</p>;
            })
          : 'No Sites Selected'}
      </Tooltip>
    );
  };

  const onCallNext = () => {
    setKey(ACTION_TYPE.reason);
  };

  const onCallPrevious = () => {
    setKey(ACTION_TYPE.addSites);
  };

  const handleOptionChange = (event) => {
    setAttributeType(event.target.value);
  };

  return (
    <Modal
      {...props}
      size='xl'
      className='title gne-modal'>
      <Modal.Header closeButton>
        <Modal.Title
          id='contained-modal-title'
          className={`title__bold ${classes['title-wrap']}`}>
          <div className={classes['tag-wrap']}>
            <div className='d-flex flex-column'>
              <div className='d-flex align-items-center'>
                <i
                  className={`icon icon__edit-icon ${classes['delete-icon']}`}></i>
                <span className={classes['title-wrap']}>Edit 340B</span>
              </div>
              <div className={classes['subtitle-wrap']}>
                {EDIT_340B_SUBTEXT}
              </div>
            </div>
            <div className={`d-flex ${classes['radio-btn']}`}>
              <div className='d-flex align-items-center'><strong>Mark as</strong></div>
              <GNERadio
                checked={attributeType === '340B'}
                name='attributeType'
                label='340B'
                value='340B'
                onChange={handleOptionChange}
              />
              <GNERadio
                checked={attributeType === 'Non 340B'}
                name='attributeType'
                label='Non 340B'
                value='Non 340B'
                onChange={handleOptionChange}
              />
            </div>
            <div className='d-flex'>
              <div>
                <div
                  className={`d-flex align-items-center ${
                    selectedRow.length > 0
                      ? classes['site-tag']
                      : classes['no-selection-site-tag']
                  } title__small ml-4`}>
                  <div className='d-flex'>
                    <div>Selected Site(s):</div>
                    {selectedRow?.length === 0
                      ? 'No Site Selected'
                      : selectedRow?.length}
                    <OverlayTrigger
                      overlay={tooltip()}
                      placement='bottom'>
                      <span
                        className={`d-inline-block ${classes['info-icon']}`}>
                        <i className='icon icon__info__gray'></i>
                      </span>
                    </OverlayTrigger>
                  </div>
                  <div>
                    <LinkButton
                      className={
                        selectedRow?.length === 0
                          ? 'disabled'
                          : 'text text_small'
                      }
                      onClick={handleClearAll}>
                      Clear All
                    </LinkButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className='show-grid p-0 m-0'>
        <Container className='p-0 gne-container'>
          <div className={classes['tabs-navigation']}>
            <Button
              variant={`btn btn-gne btn-primary me-2 ml-2`}
              disabled={key === ACTION_TYPE.addSites}
              onClick={onCallPrevious}>
              Previous
            </Button>

            {key === ACTION_TYPE.reason ? (
              <Button
                variant={`btn btn-gne btn-primary me-2 ml-2`}
                disabled={text.length < 1}
                onClick={() => handleApply(selectedRow, text)}>
                Apply
              </Button>
            ) : (
              <Button
                variant={`btn btn-gne btn-primary me-2 ml-2`}
                disabled={selectedRow.length === 0}
                onClick={onCallNext}>
                Next
              </Button>
            )}
          </div>
          <Tabs
            id='customeHierarchyActions'
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className='mb-3'>
            <Tab
              eventKey='addSites'
              title={<span className='d-flex'>1. Find Site(s)</span>}>
              <Row>
                {/* Gross sale left panel */}
                <Col
                  md={4}
                  xs={5}
                  lg={4}
                  className={classes['library-container']}>
                  <Filters
                    accountId={accountId}
                    onFilterSelected={(selectedFilter) =>
                      handleFilterSelect(selectedFilter)
                    }
                    onFilterApply={(filters) => handleFilterApply(filters)}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    resetOptions={resetOptions}
                    page={'actions'}
                    labelsData={labelsData}
                    unixId={unixId}
                    initialFilterValue={initialFilterValue}
                    labelAccess={labelAccess}
                    action={ORG_ACTIONS.remove}
                  />
                </Col>
                {sitesData?.length !== 0 ? (
                  <Col>
                    <Spin spinning={isLoading}>
                      <SiteHeirarchyTable
                        gridData={sitesData}
                        type={'checkbox'}
                        handleRowSelect={handleRowSelect}
                        selectedRow={selectedRow}
                        page='remove'
                        total={gridSize}
                        isEdit340B={true}
                      />
                    </Spin>
                  </Col>
                ) : (
                  <Col>
                    <Spin spinning={isLoading}>
                      <div className={`m-8 ${classes['message-align-wrap']}`}>
                        <div className={classes['default-message']}>
                          {NO_SITE_SELECTED}
                        </div>
                        <p>{NO_SITE_SELECTED_SUBTEXT}</p>
                      </div>
                    </Spin>
                  </Col>
                )}
              </Row>
            </Tab>

            <Tab
              eventKey='reason'
              disabled={selectedRow.length === 0}
              title={<span className='d-flex'>2. Reason</span>}>
              <div className={`${classes['reasonPanelHeight']} col-md-12`}>
                <p>*Reason</p>
                <TextArea
                  rows={3}
                  maxLength={1000}
                  value={text}
                  className={classes['label-editinput']}
                  showCount
                  onChange={onhandleChange}
                />
              </div>
            </Tab>
          </Tabs>
        </Container>
      </Modal.Body>

      <Modal.Footer className={classes['modal-footer']}>
        <LinkButton
          onClick={props.onHide}
          className='title title__small'>
          Close
        </LinkButton>
      </Modal.Footer>
    </Modal>
  );
};

export default Edit340BLayout;
