/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';
import SelectWithPagination from 'components/shared/dropdown-with-pagination/SelectWithPagination';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';

import ConfigurableTable from 'components/shared/table/ConfigurableTable';
import classes from './PayerMix.module.scss';
import {
  setIsAccountableProduct,
  setPayerExpression,
  setAccountPayerCustomColumn,
  setSiteLoader,
  setAccountpayerMixFilters,
} from 'store/customer/customerViewSlice';
import {
  ACCOUNT_PAYER_APP_ID,
  ACCOUNT_PAYER_MIX_COLUMNS,
  ACCOUNT_PAYER_MIX_FILTER_NAME,
  TABLE_NAME_PAYER_MIX,
} from 'constants/constants';
import {
  getPayerPerformanceData,
  exportData,
} from 'store/customer/customer-view-actions';
import { getCustomerExpr, getRestrictedColumn, isArray } from 'utils/utils';

import { customerViewInitialState } from 'store/customer/customerViewInitialState';
import { Checkbox, Popover } from 'antd';

const AccountPayerMix = () => {
  const [menuIsOpen, setMenuIsOpen] = useState({
    AccountPayerParentPayer: false,
    AccountPayerName: false,
    AccountPayerBookOfBusiness: false,
    AccountPayerProduct: false,
    AccountPayerEcosystem: false,
  });
  const [popoverVisible, setPopoverVisible] = useState(false);
  const selectedPayer = useSelector(
    (state) =>
      state?.CustomerView?.AccountPayerMixFilters?.AccountPayerName
        ?.selectedValue
  );
  const selectedParentPayer = useSelector(
    (state) =>
      state?.CustomerView?.AccountPayerMixFilters?.AccountPayerParentPayer
        ?.selectedValue
  );
  const selectedBookOfBusiness = useSelector(
    (state) =>
      state?.CustomerView?.AccountPayerMixFilters?.AccountPayerBookOfBusiness
        ?.selectedValue
  );
  const selectedEcosystem = useSelector(
    (state) =>
      state?.CustomerView?.AccountPayerMixFilters?.AccountPayerEcosystem
        ?.selectedValue
  );
  const selectedProduct = useSelector(
    (state) =>
      state?.CustomerView?.AccountPayerMixFilters?.AccountPayerProduct
        ?.selectedValue
  );

  const tableTimeSlotData = useSelector(
    (state) => state?.CustomerView?.payertimeSpan
  );
  const payerMiXData = useSelector(
    (state) => state?.CustomerView?.accountMiXData
  );
  const isLoading = useSelector(
    (state) => state?.CustomerView?.accountPayerLoader
  );
  const isExporting = useSelector(
    (state) => state?.CustomerView?.exportAccountpayermix
  );
  const columnRestriction = useSelector(
    (state) => state?.CustomerView?.columnsRestiction
  );
  useEffect(() => {
    setSearchedData(payerMiXData?.values);
  }, [payerMiXData]);

  const [searchedData, setSearchedData] = useState(payerMiXData?.values);

  const dispatch = useDispatch();
  const columnsselected = useSelector(
    (state) => state?.CustomerView?.accountPayerCustomColumn
  );
  const selectedView = useSelector(
    (state) => state?.CustomerView?.selectedView
  );

  const qlikToken = localStorage.getItem('qlikToken');
  const qlikCookie = localStorage.getItem('qlikCookie');
  const [payerColumns, setPayerColumn] = useState([
    'Parent_Payer',
    'Payer_Name',
    'Book_of_Business',
    'Benefit_Type',
  ]);
  const selectedCustomer = useSelector(
    (state) => state?.CustomerView?.selectedCustomer
  );

  const [selectedColumns, setSelectedColumns] = useState(
    ACCOUNT_PAYER_MIX_COLUMNS.map((column) => {
      return column.isMandatory === 'Y' && column.dataIndex;
    })
  );

  const filteredColumns = ACCOUNT_PAYER_MIX_COLUMNS.filter((column) =>
    selectedColumns.includes(column.dataIndex)
  );

  const AccountPayerMixFilters = useSelector(
    (state) => state?.CustomerView?.AccountPayerMixFilters
  );

  const parentPayerOptions = useSelector(
    (state) => state?.CustomerView?.AccountPayerMixFilters?.ParentPayer
  );

  const payerOptions = useSelector(
    (state) => state?.CustomerView?.AccountPayerMixFilters?.Payer
  );

  const bookOfBusinessOptions = useSelector(
    (state) => state?.CustomerView?.AccountPayerMixFilters?.BookOfBusiness
  );

  const EcosystemOptions = useSelector(
    (state) => state?.CustomerView?.AccountPayerMixFilters?.Ecosystem
  );

  const ProductOptions = useSelector(
    (state) => state?.CustomerView?.AccountPayerMixFilters?.Product
  );

  const payerExpression = useSelector(
    (state) => state?.CustomerView?.payerExpression
  );
  const dimensions = useSelector(
    (state) => state?.CustomerView?.accountPayerCustomColumn
  );

  const [selectOptions, setOptions] = useState('ALL');
  const handleOptionChange = (e) => {
    dispatch(
      setAccountpayerMixFilters(customerViewInitialState.AccountPayerMixFilters)
    );
    setOptions(e.target.value);
  };

  const getbenefitExp = (selected) => {
    return selected === 'ALL' ? undefined : `Benefit_Type= {"${selected}"}`;
  };

  const benefitExp = getbenefitExp(selectOptions);
  useEffect(() => {
    if (!Array.isArray(selectedCustomer)) {
      setOptions('ALL');

      dispatch(setIsAccountableProduct(true));
    }
  }, [selectedCustomer]);
  let payerFilterExpression;
  useEffect(() => {
    if (!Array.isArray(selectedCustomer)) buildExpression();
  }, [
    selectedCustomer,
    selectedPayer,
    selectedParentPayer,
    selectedBookOfBusiness,
    selectOptions,
    selectedEcosystem,
    selectedProduct,
  ]);

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const totalRecords =
    searchedData?.length >= 0 && searchedData?.length < 100
      ? searchedData?.length
      : 100;

  const [total, setTotal] = useState(totalRecords);
  useEffect(() => {
    setTotal(totalRecords);
  }, [totalRecords]);

  const pagination = {
    total: total,
    onChange: (page, size) => {
      setPageIndex(page);
      setPageSize(size);
    },
    pageSizeOptions: [10, 20, 30],
    showSizeChanger: true,
  };

  useEffect(() => {
    dispatch(
      setAccountpayerMixFilters(customerViewInitialState.AccountPayerMixFilters)
    );
    dispatch(setIsAccountableProduct(true));
    dispatch(setSiteLoader(false));
    setSearchedData([]);
  }, [dispatch, selectedCustomer]);

  useEffect(() => {
    setPageIndex(pageIndex);
    const isLastPage = pageIndex === Math.ceil(total / pageSize);
    buildExpression();
    const selectedPageIndex =
      pageIndex > 0 && Math.ceil((pageIndex * pageSize) / 100);
    if (isLastPage && total < payerMiXData.totalSize) {
      dispatch(
        getPayerPerformanceData(
          payerFilterExpression,
          payerColumns,
          selectedPageIndex,
          qlikCookie,
          qlikToken,
          '',
          '',
          selectedView
        )
      );
      setTotal(total + 100);
    }
  }, [pageIndex]);

  const buildExpression = () => {
    const accountExp = getCustomerExpr(
      selectedCustomer,
      isArray(selectedCustomer)
    );

    const isAllParentPayer =
      isArray(parentPayerOptions) &&
      parentPayerOptions?.length === selectedParentPayer?.length;

    const isAllPayer =
      isArray(payerOptions) && payerOptions?.length === selectedPayer?.length;

    const isAllBookOfBusiness =
      isArray(bookOfBusinessOptions) &&
      bookOfBusinessOptions?.length === selectedBookOfBusiness?.length;

    const isAllEcosystem =
      isArray(EcosystemOptions) &&
      EcosystemOptions?.length === selectedEcosystem?.length;

    const isAllProduct =
      isArray(ProductOptions) &&
      ProductOptions?.length === selectedProduct?.length;

    const parentPayer = isAllParentPayer ? 'ParentPayer=' : selectedParentPayer;

    const payer = isAllPayer ? 'Payer=' : selectedPayer;

    const bookOfBusiness = isAllBookOfBusiness
      ? 'BookOfBusiness='
      : selectedBookOfBusiness;

    const ecosystem = isAllEcosystem
      ? 'AccountPayerEcosystem='
      : selectedEcosystem;

    const product = isAllProduct ? 'AccountPayerProduct=' : selectedProduct;

    // build parent payer expression

    const parentPayerExp = isArray(parentPayer)
      ? 'Parent_Payer={"' + parentPayer.map((a) => a.label).join('","') + '"}'
      : undefined;

    // build parent payer expression
    const payerExp = isArray(payer)
      ? 'Payer_Name={"' + payer.map((a) => a.label).join('","') + '"}'
      : undefined;

    // build book of business site expression
    const bookOfBusinessExp = isArray(bookOfBusiness)
      ? 'Book_of_Business={"' +
        bookOfBusiness.map((a) => a.label).join('","') +
        '"}'
      : undefined;
    // build Ecosystem expression
    const ecosystemExp = isArray(ecosystem)
      ? 'Ecosystem={"' + ecosystem.map((a) => a.label).join('","') + '"}'
      : undefined;
    // build Product expression
    const productExp = isArray(product)
      ? 'Product={"' + product.map((a) => a.label).join('","') + '"}'
      : undefined;
    if (benefitExp !== undefined) {
      payerFilterExpression = `${benefitExp}`;
      payerFilterExpression = payerFilterExpression.concat(`,${accountExp}`);
    } else {
      payerFilterExpression = `${accountExp}`;
    }

    if (parentPayerExp !== undefined) {
      payerFilterExpression = payerFilterExpression?.concat(
        `, ${parentPayerExp}`
      );
    }

    if (payerExp !== undefined) {
      payerFilterExpression = payerFilterExpression?.concat(`, ${payerExp}`);
    }
    if (ecosystemExp !== undefined) {
      payerFilterExpression = payerFilterExpression?.concat(
        `, ${ecosystemExp}`
      );
    }
    if (productExp !== undefined) {
      payerFilterExpression = payerFilterExpression?.concat(`, ${productExp}`);
    }
    if (bookOfBusinessExp !== undefined) {
      payerFilterExpression = payerFilterExpression?.concat(
        `, ${bookOfBusinessExp}`
      );
    }

    dispatch(setPayerExpression(payerFilterExpression));
  };
  useEffect(() => {
    if (!selectedCustomer?.label) {
      dispatch(setSiteLoader(false));
      setSearchedData([]);
      setAccountPayerCustomColumn([]);
      return;
    }
    buildExpression();
    dispatch(
      getPayerPerformanceData(
        payerFilterExpression,
        payerColumns,
        0,
        qlikCookie,
        qlikToken,
        'Estimated_Payer_Sale',
        'descend',
        selectedView
      )
    );
  }, [
    selectedCustomer,
    selectedParentPayer,
    selectedPayer,
    selectedBookOfBusiness,
    selectOptions,
    columnsselected,
    selectedEcosystem,
    selectedProduct,
  ]);
  const handleSorting = (column, order) => {
    buildExpression();
    dispatch(
      getPayerPerformanceData(
        payerFilterExpression,
        payerColumns,
        0,
        qlikCookie,
        qlikToken,
        column,
        order,
        selectedView
      )
    );
  };

  const onMenuOpen = useCallback((filterName, value) => {
    let menuIsOpenLocal = menuIsOpen;
    if (filterName !== '' && value) {
      menuIsOpenLocal = Object.keys(menuIsOpen).reduce((acc, key) => {
        return { ...acc, [key]: filterName === key };
      }, {});
    } else if ((filterName !== '' && !value) || filterName === '') {
      menuIsOpenLocal = Object.keys(menuIsOpen).reduce((acc, key) => {
        return { ...acc, [key]: false };
      }, {});
    }
    setMenuIsOpen({ ...menuIsOpenLocal });
  }, []);

  const downloadData = () => {
    dispatch(
      exportData(
        'AccountPayerMix',
        selectedCustomer?.value,
        qlikCookie,
        qlikToken,
        payerExpression,
        undefined,
        dimensions
      )
    );
  };

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    if (
      value === 'Payer_Name' ||
      value === 'Parent_Payer' ||
      value === 'Book_of_Business' ||
      value === 'Benefit_Type'
    ) {
      const ucol = payerColumns.includes(value)
        ? payerColumns.filter((item) => item !== value)
        : [...payerColumns, value];
      setPayerColumn(ucol);
      dispatch(setAccountPayerCustomColumn(ucol));
    }
  };

  const handleColumnSelection = (checkedValues) => {
    setSelectedColumns(checkedValues);
  };

  const handlePopoverVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };
  const columnSelectionPanel = (
    <Checkbox.Group
      defaultValue={selectedColumns}
      onChange={handleColumnSelection}
      className={classes.checkboxGroup}
      key={Math.random()}>
      {ACCOUNT_PAYER_MIX_COLUMNS.map((column) => (
        <div
          className={classes.checkboxWrapper}
          key={column.id}>
          <Checkbox
            className={classes.checkbox}
            key={column.key}
            value={column.key}
            disabled={
              column.key === 'Estimated_Payer_Sale' ||
              column.key === 'Estimated_Contribution_to_Account'
            }
            onChange={handleCheckboxChange}>
            {column.title.length > 15
              ? `${column.title.substring(0, 20)}...`
              : column.title}
          </Checkbox>
        </div>
      ))}
    </Checkbox.Group>
  );
  const exportTooltip = (
    <Tooltip bsclass='gne-tooltip tooltip--left'>Send to Excel</Tooltip>
  );
  const size = { x: 1100, y: 400 };

  const popoverContent = (
    <div className={classes.acc_custom_popup}>{columnSelectionPanel}</div>
  );

  return (
    <div className='full-width-in-container light-grey'>
      <div
        id='AccountPayerMix'
        className='pt-3'>
        <Row>
          <Col
            className='pt-3 d-flex align-item-center'
            md={6}>
            <p className='title title__bold title__large mb-1'>Payer Mix</p>
          </Col>
          <Col
            className='d-flex justify-content-end'
            md={6}>
            <Popover
              content={popoverContent}
              trigger='click'
              open={popoverVisible}
              onOpenChange={handlePopoverVisibleChange}>
              <i
                className={`icon icon__affiliations_standardTable_settings mt-3 me-3`}
              />
            </Popover>
            <OverlayTrigger
              placement='top'
              overlay={exportTooltip}>
              <i
                onClick={downloadData}
                className={`icon icon__affiliations_export_excel1 mt-3`}
              />
            </OverlayTrigger>
          </Col>
        </Row>
        <Row>
          <Col md={9}>
            <div className='title title__x-small pt-0 title__grey d-flex '>
              {tableTimeSlotData} |
              <p
                className={`title title__x-small title__italic title__grey ${classes['acc-verbiag']}`}>
                Based on % contribution of estimated payer sales using GNE 867 &
                IQVIA sales
              </p>
            </div>
          </Col>
          <Col
            md={3}
            className='d-flex mt-6 mb-3 justify-content-end'>
            <div className={`title__small ${classes['radio-group']} mt-2 `}>
              <label className='radio-label text text-small d-flex'>
                <input
                  type='radio'
                  name='banneroption'
                  value='ALL'
                  className={classes['acc_radio_label']}
                  checked={selectOptions === 'ALL'}
                  onChange={handleOptionChange}
                />
                All
              </label>

              <label className='radio-label d-flex'>
                <input
                  type='radio'
                  name='banneroption'
                  value='MEDICAL'
                  className={classes['acc_radio_label']}
                  checked={selectOptions === 'MEDICAL'}
                  onChange={handleOptionChange}
                />
                Medical
              </label>

              <label className='radio-label d-flex'>
                <input
                  type='radio'
                  name='banneroption'
                  className={classes['acc_radio_label']}
                  value='PHARMACY'
                  checked={selectOptions === 'PHARMACY'}
                  onChange={handleOptionChange}
                />
                Pharmacy
              </label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className='title__x-small'>Ecosystem</label>
            <SelectWithPagination
              menuIsOpen={menuIsOpen}
              onMenuOpen={onMenuOpen}
              filter={AccountPayerMixFilters}
              appid={ACCOUNT_PAYER_APP_ID}
              fieldName='AccountPayerEcosystem'
              isMulti={true}
              filterName={ACCOUNT_PAYER_MIX_FILTER_NAME}
              cacheUniqs={[
                selectedCustomer,
                selectedPayer,
                selectedParentPayer,
                selectedBookOfBusiness,
                selectOptions,
                selectedProduct,
              ]}
            />
          </Col>
          <Col>
            <label className='title__x-small'>Parent Payer</label>
            <SelectWithPagination
              menuIsOpen={menuIsOpen}
              onMenuOpen={onMenuOpen}
              filter={AccountPayerMixFilters}
              appid={ACCOUNT_PAYER_APP_ID}
              fieldName='AccountPayerParentPayer'
              isMulti={true}
              filterName={ACCOUNT_PAYER_MIX_FILTER_NAME}
              cacheUniqs={[
                selectedCustomer,
                selectedPayer,
                selectedBookOfBusiness,
                selectOptions,
                selectedEcosystem,
                selectedProduct,
              ]}
            />
          </Col>

          <Col>
            <label className='title__x-small'>Payer</label>
            <SelectWithPagination
              menuIsOpen={menuIsOpen}
              onMenuOpen={onMenuOpen}
              filter={AccountPayerMixFilters}
              appid={ACCOUNT_PAYER_APP_ID}
              fieldName='AccountPayerName'
              isMulti={true}
              filterName={ACCOUNT_PAYER_MIX_FILTER_NAME}
              cacheUniqs={[
                selectedCustomer,
                selectedParentPayer,
                selectedBookOfBusiness,
                selectOptions,
                selectedEcosystem,
                selectedProduct,
              ]}
            />
          </Col>

          <Col>
            <label className='title__x-small'>Book of Business</label>
            <SelectWithPagination
              menuIsOpen={menuIsOpen}
              onMenuOpen={onMenuOpen}
              filter={AccountPayerMixFilters}
              appid={ACCOUNT_PAYER_APP_ID}
              fieldName='AccountPayerBookOfBusiness'
              isMulti={true}
              filterName={ACCOUNT_PAYER_MIX_FILTER_NAME}
              cacheUniqs={[
                selectedCustomer,
                selectedParentPayer,
                selectedPayer,
                selectOptions,
                selectedEcosystem,
                selectedProduct,
              ]}
            />
          </Col>
          <Col>
            <label className='title__x-small'>Product</label>
            <SelectWithPagination
              menuIsOpen={menuIsOpen}
              onMenuOpen={onMenuOpen}
              filter={AccountPayerMixFilters}
              appid={ACCOUNT_PAYER_APP_ID}
              fieldName='AccountPayerProduct'
              isMulti={true}
              filterName={ACCOUNT_PAYER_MIX_FILTER_NAME}
              cacheUniqs={[
                selectedCustomer,
                selectedParentPayer,
                selectedPayer,
                selectOptions,
                selectedEcosystem,
                selectedBookOfBusiness,
              ]}
            />
          </Col>
        </Row>
        &nbsp;
      </div>

      <Row
        id='PayerMix'
        className={classes.sitePerformanceContainer}>
        <Col xs={12}>
          <Spin spinning={isLoading || isExporting}>
            <ConfigurableTable
              rows={searchedData}
              columns={getRestrictedColumn(
                TABLE_NAME_PAYER_MIX,
                filteredColumns,
                columnRestriction
              )}
              expandRowByClick={true}
              enableColumnSelection={false}
              enableExport={false}
              exportFileName='PayerMixDetails'
              tableName={TABLE_NAME_PAYER_MIX}
              expression={payerFilterExpression}
              isGlobalExport={true}
              pagination={pagination}
              isGlobalSort={true}
              onSort={handleSorting}
              dimentions={size}
            />
          </Spin>
        </Col>
      </Row>
    </div>
  );
};

export default AccountPayerMix;
