/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, memo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ListManager } from 'react-beautiful-dnd-grid';

import { getCardsData } from 'store/customer/customer-view-actions';
import Tile from 'components/shared/tiles/Tile';
import DefaultMessage from 'components/shared/message/DefaultMessage';
import {
  setCards,
  setCustomerViewButtonDisabled,
} from 'store/customer/customerViewSlice';
import classes from './TileContainer.module.scss';
import { CARD_POISTIONS_UPDATED_MESSAGE, areas } from 'constants/constants';
import { Spinner } from 'components/shared/loader/Spinner';
import { toast } from 'react-toastify';

const TileContainer = memo(({ page }) => {
  const dispatch = useDispatch();
  const cards = useSelector((state) => state?.CustomerView?.cards);
  const shouldLoad = useRef(true);
  const hasCards = useRef(true);
  const customerInsightBrdId = useSelector(
    (state) => state?.CustomerView?.custInsightsBrdId
  );
  const insightType = useSelector((state) => state?.CustomerView?.insightType);

  useEffect(() => {
    if (shouldLoad.current) {
      dispatch(getCardsData(customerInsightBrdId, insightType));
      shouldLoad.current = false;

      setTimeout(() => {
        hasCards.current = false;
      }, 100);
    }
  }, [customerInsightBrdId]);

  useEffect(() => {
    if (cards?.length === 0 && hasCards.current) {
      hasCards.current = false;
    }
  }, [cards]);

  const sortList = async (list, payload) => {
    const updatedCards = list
      .slice()
      .sort((first, second) => first.positionIndex - second.positionIndex);
    dispatch(setCards(updatedCards));
    toast.success(CARD_POISTIONS_UPDATED_MESSAGE);
    dispatch(setCustomerViewButtonDisabled(false));
  };

  const reorderList = (sourceIndex, destinationIndex) => {
    if (destinationIndex === sourceIndex) {
      return;
    }
    let cardData = [...cards];
    let list = [];
    const payload = {
      cards: [
        {
          cardId: cards[sourceIndex].cardId,
          positionId: cards[destinationIndex].positionIndex,
        },
        {
          cardId: cards[destinationIndex].cardId,
          positionId: cards[sourceIndex].positionIndex,
        },
      ],
    };
    for (const card of cardData) {
      if (cardData.indexOf(card) === sourceIndex) {
        list.push({
          ...card,
          positionIndex: cards[destinationIndex].positionIndex,
        });
      } else if (cardData.indexOf(card) === destinationIndex) {
        list.push({ ...card, positionIndex: cards[sourceIndex].positionIndex });
      } else {
        list.push({ ...card });
      }
    }
    sortList(list, payload);
  };

  const ListElement = ({ item: card }) => {
    return (
      <Col md={12}>
        <Tile
          card={card}
          key={card.cardId}
          page={page}
          area={areas.customerView}
        />
      </Col>
    );
  };
  const filteredCards = cards.filter((item) => item?.isDeleted !== true);
  return (
    <div className='tile-container'>
      {filteredCards?.length > 0 && (
        <>
          <div
            md={12}
            className={classes['tile-container-drag-drop-container']}>
            <Row className='list-manager-insights'>
              <ListManager
                items={filteredCards}
                direction='horizontal'
                maxItems={3}
                render={(item) => <ListElement item={item} />}
                onDragEnd={reorderList}
                className='list-manager'
              />
            </Row>
          </div>
        </>
      )}

      {hasCards.current && <Spinner area={areas.customerView} />}

      {!hasCards.current && filteredCards?.length === 0 && (
        <DefaultMessage page={page} />
      )}
    </div>
  );
});

export default TileContainer;
