import axiosQlikClient from 'api';
import axiosDBClient from 'axiosDBClient';
import { trackPromise } from 'react-promise-tracker';
import isArray from 'lodash/isArray';
import moment from 'moment';
import {
  setAllAccountOptions,
  setEcosystemOptions,
  setProductOptions,
  setTimePeriodOptions,
  setTimePeriods,
  setChartData,
  setNoDimensionValue,
  setSelectedProduct,
  setCards,
  setSelectedTimePeriod,
  setAccountDetails,
  setInsightBoards,
  setContacts,
  setBeamChart,
  setCRMScores,
  setUses,
  setAffiliationsData,
  setSurveyTotal,
  setAffiliated,
  setSitePerformanceData,
  setTimespan,
  setDarwinReportsData,
  setSiteLoader,
  setAffLoader,
  setExportLoader,
  setFocusAccountChecked,
  setProductMixData,
  setPayerMixData,
  setPayerChart,
  setSquadOptions,
  setAffiliationsSiteData,
  setBeamChartSite,
  setColumnRestricted,
  setAccountMixData,
  setAccountPayerLoader,
  setSitePayerLoader,
  setCopayLoader,
  setCopayData,
  setCrmQuarter,
  setCrmPercent,
} from './customerViewSlice';
import { setQlikToken } from 'store/auth/Authslice';
import {
  CUSTOMER_VIEW_DETAIL_PAGE,
  SITE_UTILIZATION_TABLE,
  TABLE_NAME_COPAY_ANALYTICS,
  areas,
} from 'constants/constants';
import {
  setQlikAuth,
  getQlikSenseData,
  getQlikSenseUnformattedData,
} from 'services/qlik.service';
import { buildCardDetails, getFieldName } from 'utils/utils';
import { FILTER_DATE_FORMAT } from 'constants/constants';
import { DEFAULT_DATE_FORMAT } from 'constants/constants';

/**
 * Get user specific account data from the database
 * that will be displayed in the account dropdown
 * store the accounts in the store
 * @returns
 */
export const getFocusAccountData = (
  unixId,
  roleId,
  field = 'account',
  page
) => {
  return async (dispatch) => {
    const getAccounts = async () => {
      const response = await axiosDBClient.get('filter/corp-parent-priority', {
        params: { unixId, roleId, field },
      });

      if (response.data.error) {
        throw new Error('Could not fetch focus accounts for user ' + unixId);
      }

      return response.data.result;
    };

    try {
      const accounts = await getAccounts();
      dispatch(setAllAccountOptions(accounts));
      if (page !== CUSTOMER_VIEW_DETAIL_PAGE || accounts?.length === 0)
        dispatch(setFocusAccountChecked(accounts?.length > 0));
    } catch (error) {
      console.log(error);
    }
  };
};

/**
 * Get product data from the database
 * that will be displayed in the product dropdown
 * store the product in the store
 * @returns
 */
export const getEcosystemData = (unixId) => {
  return async (dispatch) => {
    const getData = async () => {
      const response = await axiosDBClient.get('filter/ecosystem', {
        params: { unixId },
      });

      const ecosystems = response.data.result;
      let ecosystemOptions = [];

      if (ecosystems?.length > 0) {
        ecosystemOptions = ecosystems.map((ecosystem) => {
          return {
            label: ecosystem.ecosystemName,
            value: ecosystem.ecosystemId,
            accountOptions: ecosystem.accounts.map((acc) => {
              return {
                label: acc.accountName,
                value: acc.accountId,
              };
            }),
          };
        });
      }

      if (ecosystemOptions?.length > 0) {
        dispatch(setEcosystemOptions(ecosystemOptions));
      }
    };

    try {
      await getData();
    } catch (error) {}
  };
};

export const getSquadData = () => {
  return async (dispatch) => {
    const getSquads = async () => {
      const url = `filter/squads`;
      const response = await axiosDBClient.get(url);
      if (response.data.error) {
        throw new Error('Could not fetch squads!');
      }

      return response.data.result;
    };

    try {
      const squads = await getSquads();

      dispatch(setSquadOptions(squads));
    } catch (error) {
      console.log(error);
    }
  };
};

/**
 * Get product data from the database
 * that will be displayed in the product dropdown
 * store the product in the store
 * @returns
 */
export const getProductData = (selectedProduct) => {
  return async (dispatch) => {
    const getProducts = async () => {
      const response = await axiosDBClient.get('filter/products');

      if (response.data.error) {
        throw new Error('Could not fetch products!');
      }

      return response.data.result;
    };

    try {
      let products = await getProducts();

      dispatch(setProductOptions(products));

      if (isArray(selectedProduct)) {
        dispatch(setSelectedProduct(selectedProduct));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

/**
 * Gets time period data and formats it so
 * the Time Period dropdown can use it
 * also stores time period
 * store the kpi time period in the store
 * @param {*} kpiId the kpi id
 * @returns
 */
export const getTimePeriodData = (kpiId) => {
  return async (dispatch) => {
    const getTimePeriods = async () => {
      const response = await axiosDBClient.get('filter/time-period', {
        params: { kpiId },
      });

      if (response.data.error) {
        throw new Error('Could not fetch products!');
      }
      return response.data.result;
    };

    try {
      const timePeriods = await getTimePeriods();

      const timePeriodOptions = timePeriods.map((timePeriod) => {
        return {
          label: timePeriod.timePeriodName,
          value: timePeriod.timePeriodId,
        };
      });

      dispatch(setTimePeriodOptions(timePeriodOptions));
      dispatch(setTimePeriods(timePeriods));
      let exp = timePeriods.filter((tp) => tp.uXDefaultValue === 'Y')[0];
      dispatch(
        setSelectedTimePeriod({
          label: exp.timePeriodName,
          value: exp.timePeriodId,
        })
      );
    } catch (error) {
      console.log(error.message);
    }
  };
};

/**  MultidimensionAPICall

 */

export const getMultiDimensionChartData = async (
  measureObj,
  dimensionObj,
  appId,
  chartType,
  limit
) => {
  const payload = {
    appId: appId,
    limit: limit,
    measure: measureObj,

    dimension: dimensionObj,
    dataType: chartType,
  };
  const headers = {
    qlikCookie: localStorage.getItem('qlikCookie'),
    Authorization: `Bearer ${localStorage.getItem('qlikToken')}`,
  };
  let response = await axiosDBClient.post(
    '/analytics',
    JSON.stringify(payload),
    {
      headers: headers,
    }
  );
  if (response.data.error) {
    throw new Error('Could not fetch focus ');
  }

  return response.data;
};

/**
 * Make an API call to get qlik data
 * @param {*} token
 * @param {*} expression the expression used qMeasure
 * @param {*} dimension the dimension used for qDimension
 * @returns
 */
export const getQlikFilteredData = (
  token,
  cookie,
  expression,
  dimension,
  appId,
  limit
) => {
  return async (dispatch) => {
    const getQlikData = async () => {
      limit = limit | 30;
      const response = await axiosQlikClient.get('qlik', {
        params: { measure: expression, dimension, appId, limit },
        headers: { qlikCookie: cookie, Authorization: `Bearer ${token}` },
      });

      if (response.error) {
        throw new Error('Could not fetch qlik data!');
      }

      return response;
    };

    try {
      trackPromise(
        getQlikData().then((response) => {
          setQlikAuth(
            response?.headers['authorization']?.split(' ')[1],
            response?.headers['qlikcookie']
          );
          dispatch(
            setQlikToken({
              qlikToken: response?.headers['authorization']?.split(' ')[1],
              qlikCookie: response?.headers['qlikcookie'],
            })
          );
          if (!response.data?.data?.values[0]) {
            dispatch(setNoDimensionValue(response.data.data.category[0]));
          }
          dispatch(setChartData(response.data.data));
        })
      );
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const getAllKpiAndDimension = async (roleId, view) => {
  try {
    const response = await axiosDBClient.get(
      `kpi/customer/kpi-dimension?roleId=${roleId}&field=${view}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const addToInsightsBoard = async (payload, isUpdate) => {
  const setAddToInsightsBoard = async () => {
    const response = isUpdate
      ? await axiosDBClient.put('v2/customer/add-update-insight-board', {
          ...payload,
        })
      : await axiosDBClient.post('v2/customer/add-update-insight-board', {
          ...payload,
        });

    if (response.data.error) {
      throw new Error('Could not add data!');
    }

    return response.data;
  };

  try {
    const data = await setAddToInsightsBoard();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getTimespan = (expression, appId) => {
  return async (dispatch) => {
    try {
      trackPromise(
        getQlikSenseData(expression, null, appId, null).then((data) => {
          dispatch(
            setTimespan(
              data.data.data.category[0].replace(new RegExp("'", 'g'), ' ')
            )
          );

          setQlikAuth(
            data?.headers['authorization']?.split(' ')[1],
            data?.headers['qlikcookie']
          );

          dispatch(
            setQlikToken({
              qlikToken: data?.headers['authorization']?.split(' ')[1],
              qlikCookie: data?.headers['qlikcookie'],
            })
          );
        })
      );
    } catch (error) {
      console.log(error.message);
    }
  };
};

const updateMyViewx = async (payload) => {
  const setUpdateMyView = async () => {
    const response = await axiosDBClient.put(
      `v2/customer/insight-card/${payload.cardId}`,
      {
        ...payload,
      }
    );

    if (response.data.error) {
      throw new Error('Could not update data!');
    }

    return response.data;
  };

  try {
    const data = await setUpdateMyView();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const updateInsightCardTitle = async (cardId, title, unixId) => {
  const setUpdateMyView = async () => {
    const response = await axiosDBClient.patch(
      `v2/customer/insight-card/title/${cardId}`,
      {
        title: title,
        unixId: unixId,
      }
    );

    if (response.data.error) {
      throw new Error('Could not update Title!');
    }

    return response.data;
  };

  try {
    const data = await setUpdateMyView();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const deleteMyView = async (cardId, positionIndex, unixId) => {
  const setDeleteMyView = async () => {
    const response = await axiosDBClient.delete(
      `v2/customer/insight-card/${cardId}`,
      {
        data: {
          custInsightsBrdId: 1,
          positionIndex: positionIndex,
          unixId: unixId,
        },
      }
    );

    if (response.data.error) {
      throw new Error('Could not delete data!');
    }

    return response.data;
  };

  try {
    const data = await setDeleteMyView();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getQlikDataForCard = async (
  expression,
  dimension,
  appId,
  limit
) => {
  try {
    return await getQlikSenseData(expression, dimension, appId, limit);
  } catch (error) {
    console.log(error.message);
  }
};

export const getCardsData = (customerInsightBrdId, insightType) => {
  return async (dispatch) => {
    const getCards = async () => {
      const response = await axiosDBClient.get(`v2/customer/insight-card`, {
        params: {
          custInsightsBrdId: customerInsightBrdId,
          insightType: insightType,
          offset: 0,
          limit: 12,
        },
      });

      if (response.data.error) {
        throw new Error('Could not fetch my view data!');
      }
      return response.data.data;
    };

    try {
      const cards = await getCards();
      for (let card of cards) {
        try {
          card = await buildCardDetails(card, true);
        } catch (error) {
          console.log(error);
        }
      }

      if (cards?.length === 0) {
        dispatch(setCards([]));
        return;
      }

      let chartDataArr = [...cards];
      cards.map(async (val, i) => {
        trackPromise(
          getMultiDimensionChartData(
            val?.advance?.measure,
            val?.advance?.dimension,
            val?.appId,
            val?.advance?.chartType,
            val?.limit
          ).then((res) => {
            let savedTimespan;
            getQlikSenseData(
              val.filter.timespanExpression,
              null,
              val.appId,
              null
            ).then((response) => {
              savedTimespan = response.data.data.category[0]?.replace(
                new RegExp("'", 'g'),
                ' '
              );

              chartDataArr[cards.indexOf(val)] = {
                ...val,
                chartData: res?.data[0],
                timespan: savedTimespan,
                chartType: val?.advance?.chartType,
              };
              dispatch(setCards([...chartDataArr]));
            });
          }),
          areas.customerView
        );
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const getAccountDetails = (companyName, view) => {
  return async (dispatch) => {
    const getCustomerViewAccountDetails = async (id) => {
      const response = await axiosDBClient.get(`customer/account`, {
        params: { id: companyName },
      });

      if (response.data.error) {
        throw new Error('Could not fetch account details for ' + companyName);
      }

      return response.data.result;
    };

    try {
      const data = await getCustomerViewAccountDetails(view);
      dispatch(setAccountDetails(data[0]));
    } catch (error) {
      console.log(error);
    }
  };
};

export const getBeamData = (expression, dimension, dataType, appId, limit) => {
  return async (dispatch) => {
    try {
      trackPromise(
        getQlikSenseUnformattedData(
          expression,
          dimension,
          appId,
          limit,
          dataType
        ).then((response) => {
          const data = { contractSegment: response[0], quarter: response[1] };
          dispatch(setBeamChart(data));
        }),
        areas.bannerSegment
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const getBeamDataSite = (
  expression,
  dimension,
  dataType,
  appId,
  limit
) => {
  return async (dispatch) => {
    try {
      trackPromise(
        getQlikSenseUnformattedData(
          expression,
          dimension,
          appId,
          limit,
          dataType
        ).then((response) => {
          const data = {
            contractSegment: response[0],
            rebate: response[1],
            discount: response[2],
            membership: response[3]?.length > 0 ? response[3][0][1]?.qText : [],
            quarter: response[4]?.length > 0 ? response[4] : [],
          };
          dispatch(setBeamChartSite(data));
        }),
        areas.bannerSegment
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const getPayerPieData = (
  expression,
  dimension,
  dataType,
  appId,
  limit,
  othersAfterLimit
) => {
  return async (dispatch) => {
    try {
      trackPromise(
        getQlikSenseUnformattedData(
          expression,
          dimension,
          appId,
          limit,
          dataType,
          othersAfterLimit
        ).then((response) => {
          const data = { payerMix: response };
          dispatch(setPayerChart(data));
        }),
        areas.bannerSegment
      );
    } catch (error) {
      console.log(error);
    }
  };
};
export const getCRMResultApi = (expressions, dataType, appId) => {
  return async (dispatch) => {
    try {
      trackPromise(
        getQlikSenseUnformattedData(
          expressions,
          null,
          appId,
          null,
          null,
          null,
          true
        ).then((data) => {
          dispatch(
            setCRMScores({
              nps: data?.[0][0]?.qText,
              sat: data?.[0][1]?.qText,
              partner: data?.[0][2]?.qText,
            })
          );
          dispatch(
            setCrmPercent({
              nps: data?.[0][3]?.qText,
              sat: data?.[0][4]?.qText,
              partner: data?.[0][5]?.qText,
            })
          );
          dispatch(setCrmQuarter(data[0][6].qText.replaceAll("'", ' ')));
          dispatch(
            setSurveyTotal({
              nps: data[0][7].qNum === 0 ? '' : data[0][7].qText,
              sat: data[0][8].qNum === 0 ? '' : data[0][8].qText,
              partner: data[0][9].qNum === 0 ? '' : data[0][9].qText,
            })
          );
        }),
        areas.bannerNPS
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const getCRMLineChart = async (
  expressions,
  dimension,
  appId,
  limit,
  isSortDate
) => {
  try {
    return trackPromise(
      getQlikSenseData(expressions, dimension, appId, limit, isSortDate).then(
        (data) => {
          return data.data;
        }
      ),
      areas.bannerLineChart
    );
  } catch (error) {
    console.log(error);
  }
};

export const getUses = (expressions, dimension, appId, limit) => {
  return async (dispatch) => {
    try {
      trackPromise(
        getQlikSenseUnformattedData(
          expressions,
          dimension,
          appId,
          limit,
          null
        ).then((data) => {
          dispatch(setUses(data[0][1].qText));
        }),
        areas.bannerNPS
      );
    } catch (error) {
      console.log('USERS FAIL', error.message);
    }
  };
};

export const getAffilliatedWith = (expressions, dimension, appId, limit) => {
  return async (dispatch) => {
    try {
      trackPromise(
        getQlikSenseUnformattedData(
          expressions,
          dimension,
          appId,
          limit,
          null
        ).then((data) => {
          dispatch(setAffiliated(data[0][0].qText));
        })
      );
    } catch (error) {
      console.log('Error getting survey totals', error.message);
    }
  };
};

export const getAllInsightBoards = (unixId, roleId) => {
  return async (dispatch) => {
    const getInsightBoards = async () => {
      const response = await axiosDBClient.get(`customer/insight-board`, {
        params: { unixId, roleId },
      });

      if (response.data.error) {
        throw new Error('Could not fetch insight boards details');
      }

      return response.data.result;
    };

    try {
      trackPromise(
        getInsightBoards().then((data) => {
          dispatch(setInsightBoards(data));
        }),
        areas.customerViewList
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const getCustomerContacts = (accountId) => {
  return async (dispatch) => {
    const getContacts = async () => {
      if (!accountId) {
        return [];
      }

      const response = await axiosDBClient.get(`customer/contacts`, {
        params: { accountId },
      });

      if (response.data.error) {
        throw new Error('Could not fetch contacts');
      }

      return response.data.result;
    };

    try {
      const data = await getContacts();

      dispatch(setContacts(data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const setQlikCardData = (cards) => {
  return async (dispatch) => {
    let chartDataArr = [...cards];

    for (const card of cards) {
      const { filter, expression, appId } = card;
      const { dimensionName = '', timespanExpression } = filter;
      const limit = 30;
      trackPromise(
        getQlikDataForCard(
          expression,
          dimensionName,
          appId,
          limit
          // eslint-disable-next-line no-loop-func
        ).then((data) => {
          if (data) {
            try {
              // get the timespan based on the timespan expression
              getQlikSenseData(timespanExpression, null, appId, null).then(
                (response) => {
                  const savedTimespan = response.data.data.category[0].replace(
                    new RegExp("'", 'g'),
                    ' '
                  );
                  chartDataArr[cards.indexOf(card)] = {
                    ...card,
                    chartData: data?.data?.data,
                    timespan: savedTimespan,
                  };

                  dispatch(setCards([...chartDataArr]));
                }
              );
            } catch (error) {
              console.log('ERROR', error);
            }

            setQlikAuth(
              data?.headers['authorization']?.split(' ')[1],
              data?.headers['qlikcookie']
            );
            dispatch(
              setQlikToken({
                qlikToken: data?.headers['authorization']?.split(' ')[1],
                qlikCookie: data?.headers['qlikcookie'],
              })
            );
          }
        }),
        areas.customerView
      );
    }
  };
};

export const updateCardPositions = async (payload) => {
  const updatePositions = async () => {
    const response = await axiosDBClient.put(`customer/insight-card/`, {
      ...payload,
    });

    if (response.data.error) {
      throw new Error('Could not update positions!');
    }

    return response.data;
  };

  try {
    const data = await updatePositions();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getQlikDataforDropdown = async ({
  qlikToken,
  qlikCookie,
  appId,
  fieldName,
  offset,
  searchText,
  searchOffset,
  account,
  startDate,
  endDate,
  isAccountableProduct,
  selectedProducts,
  selectedSquad,
  field,
  expression,
  payer,
  parentPayer,
  bookOfBusiness,
  isDefault,
}) => {
  const getQlikData = async () => {
    const url =
      searchText === '' ? 'qlik/dropdown-list' : 'qlik/dropdown-search';
    const offsetValue = searchText?.length > 3 ? searchOffset : offset;
    const response = await axiosQlikClient.get(url, {
      params: {
        appId,
        fieldName,
        offset: offsetValue,
        searchText,
        account: isArray(account)
          ? account.map((a) => a.value).join(',')
          : account.value,
        startDate: moment(startDate, DEFAULT_DATE_FORMAT).format(
          FILTER_DATE_FORMAT
        ),
        endDate: moment(endDate, DEFAULT_DATE_FORMAT).format(
          FILTER_DATE_FORMAT
        ),
        isAccountableProduct: isAccountableProduct,
        products: selectedProducts?.map((a) => a.label).join('","'),
        squads: selectedSquad?.map((a) => a.label).join('","'),
        field,
        expression,
        payer: payer?.map((a) => a.label).join('","'),
        parentPayer: parentPayer?.map((a) => a.label).join('","'),
        bookOfBusiness: bookOfBusiness?.map((a) => a.label).join('","'),
        isDefault: isDefault,
      },
      headers: { qlikCookie, Authorization: `Bearer ${qlikToken}` },
    });

    if (response.error) {
      throw new Error('Could not fetch qlik data!');
    }

    return response;
  };

  try {
    const response = await getQlikData();
    setQlikAuth(
      response?.headers['authorization']?.split(' ')[1],
      response?.headers['qlikcookie']
    );

    return response.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const updateInsightBoardTitle = async (title, insightId) => {
  const updateInsightBoard = async () => {
    const response = await axiosDBClient.put(
      `customer/insight-board/${insightId}`,
      {
        title,
      }
    );
    if (response.data.error) {
      throw new Error('Could not update Insight Board Title!');
    }

    return response.data;
  };

  try {
    const data = await updateInsightBoard();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const shareInsightBoard = async (insightsBoard, shareWith) => {
  const shareInsightBoard = async () => {
    const response = await axiosDBClient.post(`customer/share-insight-board`, {
      insightsBoard,
      shareWith,
    });
    if (response.data.error) {
      throw new Error('Could not share Insight Board!');
    }

    return response.data.result;
  };

  try {
    const data = await shareInsightBoard();
    return data;
  } catch (error) {}
};

export const notifyRecipientByMail = async (insightsBoard, shareWith) => {
  const notifyRecipientByMail = async () => {
    const response = await axiosDBClient.post(`/notify-user`, {
      insightsBoard,
      shareWith,
    });
    if (response.data.error) {
      throw new Error('Could not notify recipient!');
    }

    return response?.data;
  };

  try {
    const data = await notifyRecipientByMail();
    return data;
  } catch (error) {}
};

export const copyInsightBoard = async (insightBoardFrom, insightBoardTo) => {
  const copyInsightBoard = async () => {
    const response = await axiosDBClient.post(`customer/copy-insight-board`, {
      insightBoardFrom,
      insightBoardTo,
    });
    if (response.data.error) {
      throw new Error('Could not copy Insight Board!');
    }

    return response.data.result;
  };

  try {
    const data = await copyInsightBoard();
    return data;
  } catch (error) {}
};

export const loadOptions = async (params, isMulti) => {
  try {
    const payload = { ...params, fieldName: getFieldName(params.fieldName) };
    const { values, currentListSize, hasMoreElement } =
      (await getQlikDataforDropdown(payload)) || {};
    const dataOptions = values?.map((item) => ({
      label: item,
      value: item,
    }));
    const options = [...dataOptions];
    return {
      values: options,
      currentListSize,
      hasMoreElement,
    };
  } catch (err) {
    console.log(err); // eslint-disable
  }
};

export const deleteInsightBoardTitle = async (Id) => {
  const setDeleteInsightBoard = async () => {
    const response = await axiosDBClient.delete(`customer/insight-board/${Id}`);
    if (response.data.error) {
      throw new Error('Could not delete data!');
    }
    return response.data;
  };
  try {
    const data = await setDeleteInsightBoard();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getAffiliationsData = (
  account,
  offset,
  hcpFlag,
  qlikCookie,
  qlikToken,
  column,
  order,
  filterExp
) => {
  return async (dispatch) => {
    const getQlikData = async () => {
      dispatch(setAffLoader(true));
      const response =
        account &&
        (await axiosQlikClient.get(`qlik/affiliation`, {
          params: {
            account,
            offset,
            hcpFlag: hcpFlag || false,
            sortBy: column,
            order:
              order !== undefined
                ? order === 'descend'
                  ? 'DESC'
                  : 'ASC'
                : null,
            filterExpression: encodeURIComponent(filterExp),
          },
          headers: { qlikCookie, Authorization: `Bearer ${qlikToken}` },
        }));

      if (response.error) {
        dispatch(setAffLoader(false));
        throw new Error('Could not fetch qlik data!');
      }
      return response;
    };

    try {
      trackPromise(
        getQlikData().then((response) => {
          setQlikAuth(
            response?.headers['authorization']?.split(' ')[1],
            response?.headers['qlikcookie']
          );
          dispatch(
            setQlikToken({
              qlikToken: response?.headers['authorization']?.split(' ')[1],
              qlikCookie: response?.headers['qlikcookie'],
            })
          );

          if (offset === 0) {
            dispatch(setAffiliationsData({ ...response.data, append: false }));
          } else {
            dispatch(setAffiliationsData({ ...response.data, append: true }));
          }
          dispatch(setAffLoader(false));
        })
      );
    } catch (error) {
      dispatch(setAffLoader(false));
      console.log(error.message);
    }
  };
};

export const getAffiliationsDataSite = (
  account,
  offset,
  hcpFlag,
  qlikCookie,
  qlikToken,
  column,
  order,
  filterExp,
  corpParent
) => {
  return async (dispatch) => {
    const getQlikData = async () => {
      dispatch(setAffLoader(true));
      const response =
        account &&
        (await axiosQlikClient.get(`qlik/affiliation-site`, {
          params: {
            account,
            offset,
            hcpFlag: hcpFlag || false,
            sortBy: column,
            order:
              order !== undefined
                ? order === 'descend'
                  ? 'DESC'
                  : 'ASC'
                : null,
            filterExpression: encodeURIComponent(filterExp),
            corpParent,
          },
          headers: { qlikCookie, Authorization: `Bearer ${qlikToken}` },
        }));

      if (response.error) {
        dispatch(setAffLoader(false));
        throw new Error('Could not fetch qlik data!');
      }
      return response;
    };

    try {
      trackPromise(
        getQlikData().then((response) => {
          setQlikAuth(
            response?.headers['authorization']?.split(' ')[1],
            response?.headers['qlikcookie']
          );
          dispatch(
            setQlikToken({
              qlikToken: response?.headers['authorization']?.split(' ')[1],
              qlikCookie: response?.headers['qlikcookie'],
            })
          );

          if (offset === 0) {
            dispatch(
              setAffiliationsSiteData({ ...response.data, append: false })
            );
          } else {
            dispatch(
              setAffiliationsSiteData({ ...response.data, append: true })
            );
          }
          dispatch(setAffLoader(false));
        })
      );
    } catch (error) {
      dispatch(setAffLoader(false));
      console.log(error.message);
    }
  };
};

export const exportData = (
  table,
  account,
  qlikCookie,
  qlikToken,
  expression,
  timePeriod,
  dimension,
  filterExp,
  columnsToExclude,
  copayDimenssion = '',
  measure = '',
  fileName = ''
) => {
  return async (dispatch) => {
    let payload = { export: true, data: table?.toLowerCase() };
    dispatch(setExportLoader(payload));
    const FileDownload = require('js-file-download');
    const getQlikData = async () => {
      let parameters;
      if (table === 'Affiliation') {
        parameters = {
          table,
          account,
          hcpFlag: expression,
          expression: timePeriod,
        };
      } else if (table === 'SiteAffiliation') {
        parameters = {
          table,
          account,
          filterExpression: encodeURIComponent(filterExp),
        };
      } else if (table === SITE_UTILIZATION_TABLE) {
        parameters = {
          table,
          dimension: encodeURIComponent(account),
          expression: expression,
        };
      } else if (table === TABLE_NAME_COPAY_ANALYTICS) {
        parameters = {
          table,
          dimension: copayDimenssion,
          expression: expression,
          account,
          measureCol: measure,
        };
      } else {
        parameters = {
          table,
          account,
          expression,
          timePeriod: timePeriod?.value,
          dimension,
          columnsToExclude: columnsToExclude,
        };
      }

      const response = await axiosQlikClient.get(`qlik/export`, {
        params: parameters,
        headers: { qlikCookie, Authorization: `Bearer ${qlikToken}` },
        responseType: 'blob',
      });

      if (response.error) {
        throw new Error('Could not fetch qlik data!');
      }

      payload.export = false;
      dispatch(setExportLoader(payload));
      FileDownload(
        response.data,
        table === TABLE_NAME_COPAY_ANALYTICS
          ? `${fileName}.xlsx`
          : `${table}.xlsx`
      );
      return response;
    };

    try {
      trackPromise(
        getQlikData()
          .then((response) => {
            setQlikAuth(
              response?.headers['authorization']?.split(' ')[1],
              response?.headers['qlikcookie']
            );
            dispatch(
              setQlikToken({
                qlikToken: response?.headers['authorization']?.split(' ')[1],
                qlikCookie: response?.headers['qlikcookie'],
              })
            );
          })
          .catch((e) => {
            payload.export = false;
            dispatch(setExportLoader(payload));
          })
      );
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const getSitePerformanceData = (
  expression,
  offset,
  qlikCookie,
  qlikToken,
  selectedTimePeriod,
  column,
  order
) => {
  return async (dispatch) => {
    const getQlikData = async () => {
      dispatch(setSiteLoader(true));
      const response = await axiosQlikClient.get(`qlik/site`, {
        params: {
          offset,
          expression,
          timePeriod: selectedTimePeriod?.value,
          sortBy: column,
          order:
            order !== undefined ? (order === 'descend' ? 'DESC' : 'ASC') : null,
        },
        headers: { qlikCookie, Authorization: `Bearer ${qlikToken}` },
      });

      if (response.error) {
        throw new Error('Could not fetch qlik data!');
      }
      return response;
    };

    try {
      trackPromise(
        getQlikData().then((response) => {
          setQlikAuth(
            response?.headers['authorization']?.split(' ')[1],
            response?.headers['qlikcookie']
          );
          dispatch(
            setQlikToken({
              qlikToken: response?.headers['authorization']?.split(' ')[1],
              qlikCookie: response?.headers['qlikcookie'],
            })
          );
          dispatch(setSiteLoader(false));

          if (offset === 0) {
            dispatch(
              setSitePerformanceData({ ...response.data, append: false })
            );
          } else {
            dispatch(
              setSitePerformanceData({ ...response.data, append: true })
            );
          }
          dispatch(setSitePerformanceData(response.data));
        })
      );
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const getPayerPerformanceData = (
  expression,
  dimension,
  offset,
  qlikCookie,
  qlikToken,
  column,
  order,
  view = ''
) => {
  return async (dispatch) => {
    const getQlikData = async () => {
      view === 'account'
        ? dispatch(setAccountPayerLoader(true))
        : dispatch(setSitePayerLoader(true));
      const response = await axiosQlikClient.get(`qlik/payer`, {
        params: {
          offset,
          expression,
          dimension,
          sortBy: column,
          order:
            order !== undefined ? (order === 'descend' ? 'DESC' : 'ASC') : null,
          view,
        },
        headers: { qlikCookie, Authorization: `Bearer ${qlikToken}` },
      });

      if (response.error) {
        throw new Error('Could not fetch qlik data!');
      }
      return response;
    };

    try {
      trackPromise(
        getQlikData()
          .then((response) => {
            setQlikAuth(
              response?.headers['authorization']?.split(' ')[1],
              response?.headers['qlikcookie']
            );
            dispatch(
              setQlikToken({
                qlikToken: response?.headers['authorization']?.split(' ')[1],
                qlikCookie: response?.headers['qlikcookie'],
              })
            );
            view === 'account'
              ? dispatch(setAccountPayerLoader(false))
              : dispatch(setSitePayerLoader(false));

            if (offset === 0) {
              view === 'account'
                ? dispatch(
                    setAccountMixData({ ...response.data, append: false })
                  )
                : dispatch(
                    setPayerMixData({ ...response.data, append: false })
                  );
            } else {
              view === 'account'
                ? dispatch(
                    setAccountMixData({ ...response.data, append: false })
                  )
                : dispatch(setPayerMixData({ ...response.data, append: true }));
            }
            view === 'account'
              ? dispatch(setAccountMixData({ ...response.data, append: false }))
              : dispatch(setPayerMixData(response.data));
          })
          .catch((error) => {
            dispatch(setAccountPayerLoader(false));
            dispatch(setSitePayerLoader(false));
          })
      );
    } catch (error) {
      console.log(error.message);
      dispatch(setAccountPayerLoader(false));
      dispatch(setSitePayerLoader(false));
    }
  };
};

export const getProductMixData = (
  expression,
  offset,
  qlikCookie,
  qlikToken,
  selectedTimePeriod,
  column,
  order
) => {
  return async (dispatch) => {
    const getQlikData = async () => {
      dispatch(setSiteLoader(true));
      const response = await axiosQlikClient.get(`qlik/product-mix`, {
        params: {
          offset,
          expression,
          timePeriod: selectedTimePeriod?.value,
          sortBy: column,
          order:
            order !== undefined ? (order === 'descend' ? 'DESC' : 'ASC') : null,
        },
        headers: { qlikCookie, Authorization: `Bearer ${qlikToken}` },
      });

      if (response.error) {
        throw new Error('Could not fetch qlik data!');
      }
      return response;
    };

    try {
      trackPromise(
        getQlikData().then((response) => {
          setQlikAuth(
            response?.headers['authorization']?.split(' ')[1],
            response?.headers['qlikcookie']
          );
          dispatch(
            setQlikToken({
              qlikToken: response?.headers['authorization']?.split(' ')[1],
              qlikCookie: response?.headers['qlikcookie'],
            })
          );
          dispatch(setSiteLoader(false));

          if (offset === 0) {
            dispatch(setProductMixData({ ...response.data, append: false }));
          } else {
            dispatch(setProductMixData({ ...response.data, append: true }));
          }
          dispatch(setProductMixData(response.data));
        })
      );
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const getAffiliationToData = (
  expression,
  dimension,
  appId,
  qlikCookie,
  qlikToken
) => {
  return async (dispatch) => {
    const getQlikData = async () => {
      const response = await axiosQlikClient.get(`qlik/aff`, {
        params: {
          expression,
          dimension,
          appId,
        },
        headers: { qlikCookie, Authorization: `Bearer ${qlikToken}` },
      });

      if (response.error) {
        throw new Error('Could not fetch qlik data!');
      }
      return response;
    };

    try {
      trackPromise(
        getQlikData().then((response) => {
          setQlikAuth(
            response?.headers['authorization']?.split(' ')[1],
            response?.headers['qlikcookie']
          );
          dispatch(
            setQlikToken({
              qlikToken: response?.headers['authorization']?.split(' ')[1],
              qlikCookie: response?.headers['qlikcookie'],
            })
          );
          dispatch(setSiteLoader(false));

          dispatch(setAffiliated(response.data.values[0]));
        })
      );
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const loadAccountData = async ({
  offset,
  searchToken,
  filed,
  ecosystems,
  unixId,
  isMyPriority,
}) => {
  try {
    let response = isMyPriority
      ? await axiosDBClient.get('configure-priority/customer', {
          params: { unixId, offset, searchToken, filed, ecosystems },
        })
      : await axiosDBClient.get('/filter/corp-parent', {
          params: { offset, searchToken, filed, ecosystems },
        });
    return response.data;
  } catch (error) {}
};

export const getDarwinReports = (accountId, qlikCookie, qlikToken) => {
  return async (dispatch) => {
    const getQlikData = async () => {
      const response = await axiosQlikClient.get(`qlik/darwin`, {
        params: { accountId },
        headers: { qlikCookie, Authorization: `Bearer ${qlikToken}` },
      });

      if (response.error) {
        throw new Error('Could not fetch qlik data!');
      }
      return response;
    };

    try {
      getQlikData().then((response) => {
        setQlikAuth(
          response?.headers['authorization']?.split(' ')[1],
          response?.headers['qlikcookie']
        );
        dispatch(
          setQlikToken({
            qlikToken: response?.headers['authorization']?.split(' ')[1],
            qlikCookie: response?.headers['qlikcookie'],
          })
        );
        dispatch(setDarwinReportsData(response.data.values));
      });
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const CopayAnalyticsData = (
  measure,
  dimension,
  expression,
  sort,
  orderBy,
  offset
) => {
  return async (dispatch) => {
    const getQlikData = async () => {
      dispatch(setCopayLoader(true));
      const headers = {
        qlikCookie: localStorage.getItem('qlikCookie'),
        Authorization: `Bearer ${localStorage.getItem('qlikToken')}`,
      };

      const response = await axiosDBClient.post(
        `qlik/copay`,
        JSON.stringify({
          measure: measure,
          dimension: dimension,
          expression: expression,
          sortBy: sort,
          order:
            orderBy !== undefined
              ? orderBy === 'descend'
                ? 'DESC'
                : 'ASC'
              : null,
          offset,
        }),
        {
          headers: headers,
        }
      );
      if (response.error) {
        dispatch(setCopayLoader(false));
        throw new Error('Could not fetch qlik data!');
      }
      dispatch(setCopayLoader(false));
      return response;
    };
    try {
      trackPromise(
        getQlikData()
          .then((response) => {
            setQlikAuth(
              response?.headers['authorization']?.split(' ')[1],
              response?.headers['qlikcookie']
            );
            dispatch(
              setQlikToken({
                qlikToken: response?.headers['authorization']?.split(' ')[1],
                qlikCookie: response?.headers['qlikcookie'],
              })
            );
            if (offset === 0) {
              dispatch(setCopayData({ ...response.data, append: false }));
            } else {
              dispatch(setCopayData({ ...response.data, append: false }));
            }
            return;
          })
          .catch((error) => {
            dispatch(setCopayLoader(false));
          })
      );
    } catch (error) {
      dispatch(setCopayLoader(false));
    }
  };
};

export const getSiteUtilizationData = async (
  expression,
  siteUtiliazationType,
  selectedPageIndex,
  qlikCookie,
  qlikToken,
  column,
  order
) => {
  try {
    const response = await axiosQlikClient.get('qlik/site-utilization', {
      params: {
        filterExpression: expression,
        siteUtilizationType: siteUtiliazationType,
        sortBy: column,
        order: order,
        offset: selectedPageIndex || 0,
      },
      headers: { qlikCookie: qlikCookie, Authorization: `Bearer ${qlikToken}` },
    });
    return response?.data;
  } catch (error) {
    return;
  }
};
export const getTableRestrictions = (roleId) => {
  return async (dispatch) => {
    const getRestrictions = async () => {
      const response = await axiosDBClient.get(
        `role-restriction/restricted-column`,
        {
          params: { roleId },
        }
      );
      if (response.error) {
        return;
      }
      return response?.data?.result;
    };
    try {
      const response = await getRestrictions();

      dispatch(setColumnRestricted(response));
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const getPlanData = async (customerId) => {
  try {
    const response = await axiosDBClient.get(`customer/account-plan`, {
      params: { customerId },
    });
    if (response.error) {
      return;
    }
    return response?.data?.result;
  } catch (error) {
    console.log(error.message);
  }
};

export const deletePlanData = async (docId) => {
  try {
    const response = await axiosDBClient.delete(
      `customer/account-plan/${docId}`
    );
    if (response.error) {
      return;
    }
    return response?.status;
  } catch (error) {
    console.log(error.message);
  }
};

export const updatePlanData = async (docId, userName, docName) => {
  try {
    const response = await axiosDBClient.put(
      `customer/account-plan/`,
      JSON.stringify({
        docId: docId,
        userName: userName,
        docName: docName,
      })
    );
    if (response.error) {
      return;
    }
    return response?.status;
  } catch (error) {
    console.log(error.message);
  }
};

export const addPlanData = async (customerId, docUrl, docName, userName) => {
  try {
    const response = await axiosDBClient.post(
      `customer/account-plan/`,
      JSON.stringify({
        customerId: customerId,
        docUrl: docUrl,
        docName: docName,
        userName: userName,
      })
    );
    if (response.error) {
      return;
    }
    return response?.status;
  } catch (error) {
    console.log(error.message);
  }
};

export const getAdvanceSearchFieldsData = async (params, options) => {
  try {
    const response = await axiosDBClient.get(`v2/filter/advance`, {
      params: params,
    });
    const result = response.data?.result;
    return {
      data: result?.data ?? [],
      hasMore: result?.hasMoreElement,
      currentListSize: result?.currentListSize,
    };
  } catch (error) {
    console.log(error);
  }
};

export const getCustomerData = async (
  isPriority,
  searchText,
  offset,
  roleId,
  unixId,
  isExactSearch,
  ecosystems,
  flag,
  state,
  zip,
  city,
  userEcosystem
) => {
  try {
    const response = await axiosDBClient.get('v2/filter/customer', {
      params: {
        isPriority,
        searchText,
        offset,
        roleId,
        unixId,
        isExactSearch,
        ecosystems,
        flag,
        state,
        zip,
        city,
        userEcosystem,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
