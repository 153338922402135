import { PING_FEDERATE_URL } from 'constants/constants';
import classes from './LogoutModal.module.scss';
import { Button } from 'react-bootstrap';

const LogoutModal = () => {
  const navigateToHome = () => {
    window.location.href = PING_FEDERATE_URL;
  };
  return (
    <div className={`d-flex flex-column align-items-center bg-white`}>
      <div className={`border-bottom border-lightgrey border-2`}>
        <i className='icon icon__logo animate__animated animate__rubberBand'></i>
      </div>
      <div className={`${classes.messageContainer} mt-4`}>
        <p
          className={`${classes.subMessage} text__grey title__bold title__medium`}>
          You have been logged out.
        </p>
        <p className={`${classes.sessionMessage} mt-4 mb-4`}>
          Your session has expired. Click "Log in" to redirect to the login
          page.
        </p>
        <Button
          variant={`outline-primary w-25 btn-gne btn-gne__outline float-right`}
          onClick={navigateToHome}>
          <span>Log in</span>
        </Button>
      </div>
    </div>
  );
};

export default LogoutModal;
