import {
  Sidebar,
  Menu,
  MenuItem,
  useProSidebar,
  SubMenu,
  menuClasses,
} from 'react-pro-sidebar';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import classes from './NavBar.module.scss';
import NavSubMenu from './NavSubMenu';

const NavBar = ({ items }) => {
  const componentAccessList = useSelector(
    (state) => state?.Auth?.componentAccessList
  );

  const isCustomerHierarchyAuthorised = (item) => {
    return (
      componentAccessList?.includes('Wrapper-Org-Hierarchy') ||
      item.categoryName !== 'Customer Hierarchy'
    );
  };
  const location = useLocation();
  const { collapseSidebar, collapsed } = useProSidebar();

  const categoryData = [
    ...items,
    ...useSelector((state) => state?.Auth?.wrapperObj),
  ];

  const currentRoute = location.pathname;

  return (
    <div className='left-side-nav'>
      <Sidebar
        defaultCollapsed
        collapsedWidth='56px'>
        <Menu
          renderExpandIcon={({ open }) => {
            return (
              !collapsed && (
                <>
                  {open ? (
                    <i
                      className={`${classes['nav-link-icon']} icon icon__menu-collapse`}></i>
                  ) : (
                    <i
                      className={`${classes['nav-link-icon']} icon icon__menu-expand`}></i>
                  )}
                </>
              )
            );
          }}>
          <MenuItem
            rootStyles={{
              backgroundColor: 'var(--color-secondary-blue)',
              ['.' + menuClasses.button]: {
                backgroundColor: 'var(--color-secondary-blue)',
                paddingRight: '10px !important',
                paddingLeft: '10px !important',
                margin: '10px 5px',
                '&:hover': {
                  backgroundColor: 'var(--color-secondary-blue) !important',
                },
              },
              ['.' + menuClasses.label]: {
                display: 'flex',
                justifyContent: 'space-between',
              },
              ['.' + menuClasses.menuItemRoot]: {
                padding: '10px 5px',
              },
            }}
            key='menuitem1'>
            <button
              className={classes['menu-btn']}
              onClick={() => collapseSidebar()}>
              {!collapsed ? (
                <div className='navbar-collapse'></div>
              ) : (
                <div className='navbar-expand'></div>
              )}
            </button>
          </MenuItem>
          {categoryData.map((item, index) => {
            return !item.reportList ? (
              isCustomerHierarchyAuthorised(item) && (
                <MenuItem
                  component={<Link to={item.link} />}
                  key={index}
                  className={
                    currentRoute.indexOf(item.link) !== -1 ? 'active' : ''
                  }
                  title={item.categoryName}>
                  <i
                    title={item.categoryName}
                    className={`icon icon__${item.categoryIcon} nav-link-icon`}></i>
                  {!collapsed && item.categoryName}
                </MenuItem>
              )
            ) : (
              <SubMenu
                key={index}
                title={item.categoryName}
                label={
                  <NavSubMenu
                    item={item}
                    collapsed={collapsed}
                  />
                }
                rootStyles={{
                  ['& > .' + menuClasses.button]: {
                    paddingRight: '10px !important',
                    '&:hover': {
                      backgroundColor: 'var(--color-tertiary-blue) !important',
                    },
                  },
                  ['.' + menuClasses.label]: {
                    textOverflow: 'initial',
                  },
                  ['& > .' + menuClasses.icon]: {
                    backgroundImage:
                      "url('./icon_menu_engagement.png') !important",
                    width: '26px',
                    height: ' 26px',
                  },
                }}
                className={
                  currentRoute.indexOf(item.link) !== -1 ? 'active' : ''
                }>
                {item.reportList &&
                  item?.reportList?.map((report, i) => {
                    return (
                      <MenuItem
                        title={report.reportName}
                        component={
                          report.isExternal === 'Y' ? (
                            <Link
                              target={'_blank'}
                              to={report.reportURL}
                            />
                          ) : (
                            <NavLink
                              to={'/reportDashboard'}
                              state={{ url: report.reportURL }}
                            />
                          )
                        }
                        rootStyles={{
                          ['.' + menuClasses.button]: {
                            backgroundColor: 'var(--color-dark-blue)',
                            '&:hover': {
                              backgroundColor:
                                'var(--color-tertiary-blue) !important',
                            },
                          },
                        }}
                        key={i}>
                        {report.reportName}
                        {report.isExternal === 'Y' ? (
                          <i className='icon icon__external float-right mt-1'></i>
                        ) : (
                          ''
                        )}
                      </MenuItem>
                    );
                  })}
              </SubMenu>
            );
          })}
        </Menu>
      </Sidebar>
    </div>
  );
};

export default NavBar;
