/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { Input } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import { searchData } from 'utils/utils';
import 'animate.css';

import classes from './View.module.scss';

import { InsightsBoard } from './InsightsBoard';

import {
  CONFIGURE_VIEW,
  CUSTOMER_TYPE,
  CUSTOMER_VIEW_UPDATE_TO_INSIGHTS,
  VIEW,
} from 'constants/constants';

import {
  setCustomerViewButtonText,
  setSelectedAccountId,
  setSelectedCustomer,
  setCustomerInsightBoardId,
  setCards,
  setFocusAccountChecked,
  setCustomerViewButtonDisabled,
  setSeletedView,
  setPayerMixCustomColumns,
  setInsightType,
  setAccountPayerCustomColumn,
  setFilterInit,
} from 'store/customer/customerViewSlice';
import { getAllInsightBoards } from 'store/customer/customer-view-actions';
import { areas } from 'constants/constants';
import { Spinner } from 'components/shared/loader/Spinner';
import { SampleInsightsBoard } from './SampleInsightBoard';
import CustomerDropdown from '../../shared/customer/CustomerDropdown';
import { setEngagementDateInit } from 'store/customer/filter/filter-slice';
import AdvanceSearch from '../../shared/customer/AdvanceSearch';

const ViewList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const insightsBoards = useSelector(
    (state) => state?.CustomerView?.insightBoards
  );

  const componentAccessList = useSelector(
    (state) => state?.Auth?.componentAccessList
  );
  const advanceSearchClicked = useSelector(
    (state) => state?.CustomerView?.advanceSearchClicked
  );
  const [searchText, setSearchText] = useState('');
  const [searchedData, setSearchedData] = useState(insightsBoards);
  const { Search } = Input;

  const { unixId, roleId } = useSelector(
    (state) => state?.Auth?.userClaimObject
  );
  const handleSearch = (value) => {
    const filteredData = searchData(insightsBoards, value);
    setSearchText(value);
    setSearchedData(filteredData);
  };

  const handleReset = () => {
    setSearchedData(insightsBoards);
    setSearchText('');
  };

  const itemClickHandler = (insight) => {
    dispatch(setSeletedView(CUSTOMER_TYPE.get(insight?.flag)));
    dispatch(setCards([]));
    dispatch(
      setPayerMixCustomColumns(['ParentPayer', 'Payer', 'BookOfBusiness'])
    );
    dispatch(
      setAccountPayerCustomColumn([
        'Parent_Payer',
        'Payer_Name',
        'Book_of_Business',
        'Benefit_Type',
      ])
    );
    dispatch(setCustomerViewButtonText(CUSTOMER_VIEW_UPDATE_TO_INSIGHTS));
    dispatch(
      setSelectedCustomer({
        value: insight.accountId,
        label: insight.accountName,
        zip: insight.zip,
        veevaId: insight?.veevaId,
        flag: insight?.flag,
      })
    );
    let isPriorityAccount = insight?.isPriorityAccount === 'Y' ? true : false;
    dispatch(setSelectedAccountId(insight.accountId));
    dispatch(setCustomerInsightBoardId(insight.id));
    dispatch(setInsightType(insight.insightType));
    dispatch(setCustomerViewButtonDisabled(true));
    dispatch(setFocusAccountChecked(isPriorityAccount));
  };

  const onChangeHandler = (selected) => {
    dispatch(setSelectedCustomer(selected));
    dispatch(setSeletedView(CUSTOMER_TYPE.get(selected?.flag)));
    navigate(`${selected?.label}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setFilterInit());
    dispatch(setEngagementDateInit());
    dispatch(getAllInsightBoards(unixId, roleId));
    dispatch(setSelectedCustomer([]));
    dispatch(setInsightType('general'));
  }, []);

  useEffect(() => {
    setSearchedData(insightsBoards);
  }, [insightsBoards]);

  const sampleInsights = searchedData.filter(
    (ib) => ib.insightType === 'sample'
  );
  const generalInsights = searchedData.filter(
    (ib) => ib.insightType === 'general'
  );

  return (
    <>
      <Row className={classes['title-container']}>
        <Col
        className='p-0'
          md={12}
          xs={9}>
          <Fade>
            <p className='title__bold title__medium me-3 animate__animated animate__fadeIn'>
              <i className='icon icon__customer-view--title'></i>
              Customer View
            </p>
          </Fade>
          {/* account type */}
          <div className={`${classes['account-dropdown']}`}>
            <Row>
              <Col
                md={9}
                xs={12}
                className='mt-1 mb-2'>
                <div className={classes['dropdown-container']}>
                  <CustomerDropdown
                    page={CONFIGURE_VIEW.viewList}
                    viewName={VIEW.customer}
                    onChangeHandler={onChangeHandler}
                    focusDisabled={false}
                    focusChecked={true}
                    isDisabled={false}
                  />
                </div>
              </Col>
              <Col
                md={3}
                xs={12}
                className='mt-5'>
                {/* configure priority link */}
                {componentAccessList?.includes(
                  'Customer-View-Configure-Priority'
                ) && (
                  <div className={classes['configure-container']}>
                    <Link
                      to='/configure-priority'
                      className='d-flex align-items-center ms-3'>
                      <i className='icon icon__configure'></i>{' '}
                      <span
                        className={`text__bold text__small ${classes['configure-label']}`}>
                        Configure Priority
                      </span>
                    </Link>
                  </div>
                )}
              </Col>
            </Row>
            {advanceSearchClicked[CONFIGURE_VIEW.viewList] && (
              <Row className='animate__animated animate__fadeIn search-container customerView w-100'>
                <AdvanceSearch page={CONFIGURE_VIEW.viewList} />
              </Row>
            )}
          </div>
        </Col>
      </Row>

      <Fade>
        <Row className={`${classes['insights-header-container']}`}>
          <Col className='animate__animated animate__fadeIn p-0'>
            {/* insights board Title */}
            <p className='title__bold title__medium mb-4'>
              <i className='icon icon__insight-board-title'></i>
              Insight Boards
            </p>
          </Col>
          <Col className={`${classes['searchbar-container']} p-0`}>
            {/* Search Insights */}
            <Search
              placeholder='Search'
              onSearch={handleSearch}
              allowClear
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onReset={handleReset}
              className={classes['search-bar']}
            />
          </Col>
        </Row>
      </Fade>

      {sampleInsights?.map((item, index) => (
        <Fade
          key={index}
          cascade
          style={{ transitionDelay: `${index * 100}ms` }}>
          <span>
            <SampleInsightsBoard
              insight={item}
              key={index}
              onItemClickHandler={itemClickHandler}
            />
          </span>
        </Fade>
      ))}

      {generalInsights?.map((item, index) => (
        <Fade
          key={index}
          cascade
          style={{ transitionDelay: `${index * 100}ms` }}>
          <span>
            {item.insightType === 'general' && (
              <InsightsBoard
                insight={item}
                key={index}
                onItemClickHandler={itemClickHandler}
              />
            )}
            <Spinner area={areas.customerViewList} />
          </span>
        </Fade>
      ))}
    </>
  );
};

export default ViewList;
