import { useLocation } from 'react-router-dom';

import { PING_FEDERATE_URL } from 'constants/constants';
import { isTokenExpired } from '../../utils/token-expired';

const Authentication = ({ authToken, children }) => {
  const location = useLocation();
  const removeLocalStorage = () => {
    localStorage.clear();
  };
  /**
   * Commenting this block for double authentication- Will keep on testing and see if this fix impact the authntication
   * or not.
   */
  // useEffect(() => {
  //   if (isTokenExpired(localStorage.getItem('token'))) {
  //     if (!authToken.isLoading && !location.state) {
  //       removeLocalStorage();
  //       window.location.href = PING_FEDERATE_URL;
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const pages = ['authenticate', 'logout', 'maintenance', 'unauthorized'];
  const isReturnChild = pages.some((loc) => location.pathname.includes(loc));

  if (
    (!authToken.isLoading && !isTokenExpired(localStorage.getItem('token'))) ||
    isReturnChild
  ) {
    return children;
  } else if (isTokenExpired(localStorage.getItem('token'))) {
    removeLocalStorage();
    window.location.href = PING_FEDERATE_URL;
    return <></>;
  } else {
    return children;
  }
};

export default Authentication;
