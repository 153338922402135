/* eslint-disable react-hooks/exhaustive-deps */
import Loader from 'components/shared/loader/Loader';
import ConfigurableTable from 'components/shared/table/ConfigurableTable';
import { ACCOUNT_TYPE } from 'constants/constants';
import { useEffect, useState } from 'react';
import ManageLabelsLayout from '../actions/site-actions/ManageLabelsLayout';
import InfoSitesLayout from '../actions/site-info/InfoSiteLayout';
import LabelTagColumnRender from './LabelTagColumnRender';

export const ORG_HIERARCHY_LIST_VIEW_COLUMNS = [
  {
    id: 1,
    dataIndex: 'name',
    key: 'name',
    title: 'Site Name',
    isMandatory: 'Y',
    fixed: 'left',
    width: 250,
    sorter: (a, b) => a?.name?.localeCompare(b?.name),
    render: (value) => {
      return <span className='title__blue'>{value}</span>;
    },
  },

  {
    id: 2,
    dataIndex: 'immediateParentName',
    key: 'immediateParentName',
    title: 'Immediate Parent Name',
    isMandatory: 'Y',
    width: 250,
    sorter: (a, b) =>
      a?.immediateParentName?.localeCompare(b?.immediateParentName),
  },
  {
    id: 3,
    dataIndex: 'corporateParentName',
    key: 'corporateParentName',
    title: 'Corporate Parent Name',
    isMandatory: 'Y',
    width: 250,
    sorter: (a, b) =>
      a?.corporateParentName?.localeCompare(b?.corporateParentName),
  },
  {
    id: 4,
    dataIndex: 'address',
    key: 'address',
    title: 'Address',
    isMandatory: 'Y',
    width: 250,
    sorter: (a, b) => a?.address?.localeCompare(b?.address),
  },
  {
    id: 9,
    dataIndex: 'ecosystemName',
    key: 'ecosystemName',
    title: 'Ecosystem',
    isMandatory: 'Y',
    width: 250,
    sorter: (a, b) => a?.ecosystemName?.localeCompare(b?.ecosystemName),
  },
  {
    id: 10,
    dataIndex: 'class',
    key: 'class',
    title: 'Class',
    isMandatory: 'Y',
    width: 150,
    sorter: (a, b) => a?.class?.localeCompare(b?.class),
  },
  {
    id: 11,
    dataIndex: 'classificationCategory',
    key: 'classificationCategory',
    title: 'Classification Category',
    isMandatory: 'Y',
    width: 200,
    sorter: (a, b) =>
      a?.classificationCategory?.localeCompare(b?.classificationCategory),
  },
  {
    id: 12,
    dataIndex: 'classification',
    key: 'classification',
    title: 'Classification Type',
    isMandatory: 'Y',
    width: 200,
    sorter: (a, b) => a?.classification?.localeCompare(b?.classification),
  },
  {
    id: 13,
    dataIndex: 'siteId',
    key: 'siteId',
    title: 'Site MDM ID',
    isMandatory: 'Y',
    width: 170,
    sorter: (a, b) => a?.siteId?.localeCompare(b?.siteId),
  },
  {
    id: 14,
    dataIndex: 'iqviaId',
    key: 'iqviaId',
    title: 'Site IQVIA (INS) ID',
    isMandatory: 'Y',
    width: 180,
    sorter: (a, b) => a?.iqviaId?.localeCompare(b?.iqviaId),
  },
  {
    id: 15,
    dataIndex: 'hin',
    key: 'hin',
    title: 'HIN',
    isMandatory: 'Y',
    width: 150,
    sorter: (a, b) => a?.hin?.localeCompare(b?.hin),
  },
  {
    id: 16,
    dataIndex: 'dea',
    key: 'dea',
    title: 'DEA',
    isMandatory: 'Y',
    width: 150,
    sorter: (a, b) => a?.dea?.localeCompare(b?.dea),
  },
  {
    id: 17,
    dataIndex: 'grossSalesR12',
    key: 'grossSalesR12',
    title: 'Gross Sales R12M',
    isMandatory: 'Y',
    width: 180,
    sorter: {
      compare: (a, b) => a.grossSalesR12 - b.grossSalesR12,
      multiple: 2,
    },
    render: (value) => {
      if (!value) return <></>;
      const formattedNumber =
        '$' +
        Number(value)?.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      return <span>{formattedNumber}</span>;
    },
  },
  {
    id: 18,
    dataIndex: 'engagementsR6M',
    key: 'engagementsR6M',
    title: 'Engagements R3M',
    isMandatory: 'Y',
    width: 180,
    sorter: (a, b) => a?.engagementsR6M - b?.engagementsR6M,
  },
  {
    id: 19,
    dataIndex: 'StakeholderCount',
    key: 'StakeholderCount',
    title: 'HCPs',
    isMandatory: 'Y',
    width: 100,
    sorter: {
      compare: (a, b) => a.StakeholderCount - b.StakeholderCount,
      multiple: 2,
    },
  },
  {
    id: 20,
    dataIndex: 'Contacts',
    key: 'Contacts',
    title: 'Contacts',
    isMandatory: 'Y',
    width: 100,
    sorter: (a, b) => a?.Contacts - b?.Contacts,
  },
  {
    id: 21,
    dataIndex: 'shared',
    key: 'shared',
    title: 'Shared?',
    isMandatory: 'Y',
    width: 100,
    sorter: (a, b) => a?.shared?.localeCompare(b?.shared),
  },
  {
    id: 22,
    dataIndex: 'labels',
    key: 'labels',
    title: 'My Labels',
    isMandatory: 'Y',
    width: 300,
  },
];

const OrgHierarchyListView = ({
  data,
  account,
  loading,
  labelsData,
  onCallOrgChartApi,
  labelAccess,
  nodeActionList,
}) => {
  const [sites, setSites] = useState(data ?? []);
  const [selectedNode, setSelectedNode] = useState({});
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showManageLabelModal, setShowManageLabelModal] = useState(false);

  useEffect(() => {
    setSites(data);
  }, [data]);

  const sortData = () => {};
  const size = { x: 800, y: 400 };

  const handleOnSiteClick = (record) => {
    const node =
      sites?.length && sites?.find((item) => item.siteId === record.siteId);
    setSelectedNode(node);
    setShowInfoModal(true);
  };

  const handleOnLabelClick = (record) => {
    const node =
      sites?.length && sites?.find((item) => item.siteId === record.siteId);
    setSelectedNode(node);
    setShowManageLabelModal(true);
  };

  const filterSite =
    sites?.length &&
    sites?.filter((item) => {
      return item?.flag !== 1;
    });

  return (
    <>
      <div className='position-relative'>{loading && <Loader size={4} />}</div>
      <div className='mt-2'>
        {sites?.length && (
          <ConfigurableTable
            rows={filterSite ?? []}
            columns={ORG_HIERARCHY_LIST_VIEW_COLUMNS.map((col) => {
              if (col.dataIndex === 'name') {
                return {
                  ...col,
                  render: (value, record) => (
                    <a
                      href='#!'
                      target='_self'
                      onClick={() => handleOnSiteClick(record)}>
                      {value}
                    </a>
                  ),
                };
              }
              if (col.dataIndex === 'labels') {
                return {
                  ...col,
                  render: (value, record) => {
                    return (
                      <LabelTagColumnRender
                        value={value}
                        record={record}
                        labelAccess={labelAccess}
                        handleOnLabelClick={handleOnLabelClick}
                        nodeActionList={nodeActionList}
                      />
                    );
                  },
                };
              }
              return col;
            })}
            expandRowByClick={false}
            enableColumnSelection={false}
            tableName='listView'
            showActionIcon={true}
            pagination={true}
            onSort={sortData}
            isGlobalSort={true}
            dimentions={size}
          />
        )}
      </div>

      {showInfoModal && (
        <InfoSitesLayout
          show={showInfoModal}
          onHide={() => setShowInfoModal(false)}
          account={account}
          data={selectedNode}
          corpParent={
            selectedNode?.class?.toLowerCase() === ACCOUNT_TYPE.corp ||
            selectedNode?.class?.toLowerCase() === ACCOUNT_TYPE.os
          }
        />
      )}

      {showManageLabelModal && (
        <ManageLabelsLayout
          showModal={showManageLabelModal}
          setShowModal={setShowManageLabelModal}
          labelsData={labelsData}
          currentNode={selectedNode}
          onCallOrgChartApi={onCallOrgChartApi}
          accountId={account?.value}
        />
      )}
    </>
  );
};

export default OrgHierarchyListView;
