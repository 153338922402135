import axiosDBClient from 'axiosDBClient';
import { toast } from 'react-toastify';

export const getComments = async (unixId, accountId, type) => {
  const response = await axiosDBClient.get('org/comments', {
    params: { unixId: unixId, accountId: accountId, type: type },
  });

  if (response.error) {
    throw new Error('Could not fetch comments data!');
  }

  return response.data.result;
};

export const addComment = async (payload) => {
  try {
    const response = await axiosDBClient.post('org/comment', { ...payload });
    if (response.error) {
      throw new Error('Could not add comment!');
    }

    return response.data.message;
  } catch (error) {
    console.log('error', error);
  }
};

export const deleteComment = async (commentId, unixId) => {
  const setDeleteComment = async () => {
    const response = await axiosDBClient.delete(`org/comment/${commentId}`, {
      params: {
        unixId: unixId,
      },
    });
    if (response.data.error) {
      throw new Error('Could not delete comment!');
    }
    return response.data;
  };
  try {
    const data = await setDeleteComment();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const updateComment = async (payload) => {
  const updateComment = async () => {
    const response = await axiosDBClient.put(`org/comment`, { ...payload });
    return response.data;
  };

  try {
    const data = await updateComment();
    return data;
  } catch (error) {
    toast.error(`Error occured - Please try after sometime.`);
    console.log(error.message);
  }
};

export const getCommentUserList = async (accountId) => {
  try {
    const response = await axiosDBClient.get('org/comments/unixids', {
      params: { accountId },
    });
    if (response.error) {
      throw new Error(response.error);
    }
    return response;
  } catch (error) {
    console.log(error);
    return [];
  }
};
