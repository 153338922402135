import GNEModal from 'components/shared/modal/Modal';
import React from 'react';
import LinkButton from 'components/shared/button/LinkButton';
import { useState } from 'react';
import classes from './WhatsNew.module.scss';
import { useSelector } from 'react-redux';

function WhatsNew(props) {
  const [expandedDescriptions, setExpandedDescriptions] = useState([]);
  const whatsNewData = useSelector((state) => state?.HomeMyView?.whatsNewData);
  const toggleDescription = (index) => {
    setExpandedDescriptions((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };
  const title = (
    <div className='mb-4 d-flex'>
      {' '}
      <i className='icon icon__whats-new-title pe-2'></i>
      <p className='mb-1 text text__bold text__base'> What's New</p>
    </div>
  );
  const content = (
    <div>
      {whatsNewData?.data?.map((release, index) => (
        <div
          key={index}
          className='mb-4'>
          <div className='text text__secondary-blue text__small mx-1'>
            {new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
              new Date(release?.createdDate)
            )}
          </div>
          {release?.releaseNotes?.map((feature, featureIndex) => (
            <div
              className={`d-flex  ${classes.releaseContainer}`}
              key={feature?.id}>
              <div className={`me-4 ${classes.releaseIcon}`}>
                <i className={`icon icon${feature?.categoryIcon}`}></i>
              </div>
              <div className='me-3'>
                <div className='text text__bold text__small'>
                  {feature?.title}
                </div>
                <p className='text text__x-small'>
                  {expandedDescriptions[feature?.id] ||
                  feature?.decription?.length <= 150
                    ? feature?.decription
                    : `${feature?.decription?.slice(0, 131)}...`}
                  {feature?.decription?.length > 150 && (
                    <LinkButton
                      className='inline text text__dark-blue'
                      onClick={() => toggleDescription(feature?.id)}>
                      {expandedDescriptions[feature?.id]
                        ? 'View less'
                        : 'View more'}
                    </LinkButton>
                  )}
                </p>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );

  return (
    <GNEModal
      show={props.show}
      onHide={props.onHide}
      content={content}
      titlecontent={title}
      css={`size-new-lg animate__animated animate__${
        props.hide
          ? 'fadeOutTopRight'
          : props.show
          ? 'fadeInTopRight'
          : 'fadeOutTopRight'
      }`}
      backdrop='static'
    />
  );
}

export default WhatsNew;
