import api from 'api';
import moment from 'moment';
import { setQlikAuth } from 'services/qlik.service';

export const isTokenExpired = (token) => {
  const decodedJWT = parseJWT(token);
  if (decodedJWT !== null && decodedJWT.exp * 1000 > Date.now()) {
    return false;
  } else {
    return true;
  }
};

export const parseJWT = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const isCookieExpired = async (unixId) => {
  const __cDate = localStorage.getItem('__cDate');
  const cookieCreateDate = moment(__cDate);
  //var newDateObj = moment(cookieCreateDate).add(60, 'm').format();
  var now = moment(new Date());
  var duration = moment.duration(now.diff(cookieCreateDate));
  //console.log(now, 'Cookie Life in Minute', duration.asMinutes());
  var track = true;
  if (
    duration.asMinutes() > localStorage.getItem('__tracker') &&
    track &&
    localStorage.getItem('qlikCookie') !== undefined
  ) {
    track = false;
    try {
      const response = await api.get(`qlik/validate-cookie`, {
        headers: {
          qlikCookie: localStorage.getItem('qlikCookie'),
          Authorization: `Bearer ${localStorage.getItem('qlikToken')}`,
        },
      });
      setQlikAuth(response?.data.token, response?.data.cookie);
      localStorage.setItem(
        '__tracker',
        parseInt(localStorage.getItem('__tracker')) + 5
      );
      // console.log(now, 'Validating Cookie', response?.data.cookie);
      track = true;
      return true;
    } catch (error) {
      localStorage.setItem(
        '__tracker',
        parseInt(localStorage.getItem('__tracker')) + 5
      );
      //will perform logout here
      return false;
    }
  } else {
    return true;
  }
};
