import { useLocation } from 'react-router-dom';
import { ProSidebarProvider } from 'react-pro-sidebar';
import IFrameLayout from 'components/layout/IFrameLayout';

const ReportDashboard = () => {
  const location = useLocation();
  const url = location?.state?.url;
  return (
    <ProSidebarProvider>
      <IFrameLayout>
        <iframe
          src={url}
          title='dashboard'></iframe>
      </IFrameLayout>
    </ProSidebarProvider>
  );
};

export default ReportDashboard;
