import React, { useState } from 'react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import EditInsightBoardTitle from '../insights/edit-insight/EditInsightBoardTitle';
import LinkButton from 'components/shared/button/LinkButton';
import {
  DATE_FORMAT,
  CUSTOMER_VIEW_TEXT,
  ACCOUNT_TYPE,
} from 'constants/constants';
import ContextPopover from 'components/shared/context-menu/ContextPopover';
import classes from './View.module.scss';
import {
  updateInsightBoardTitle,
  deleteInsightBoardTitle,
  shareInsightBoard,
  notifyRecipientByMail,
} from 'store/customer/customer-view-actions';
import {
  setAppUsers,
  setInsightBoards,
  setSelectedCustomer,
  setNotificationEmailStatus,
} from 'store/customer/customerViewSlice';
import { useSelector, useDispatch } from 'react-redux';
import Confirmation from '../../shared/confirmation/Confirmation';
import ShareInsightBoard from '../insights/share-insight/ShareInsightBoard';
import CopyInsightBoard from '../insights/copy-insight/CopyInsightBoard';
import { getTeamMembers } from 'services/configure-priority.service';
import { toast } from 'react-toastify';

export const InsightsBoard = ({ insight, onItemClickHandler }) => {
  const insightsBoards = useSelector(
    (state) => state?.CustomerView?.insightBoards
  );
  const { unixId } = useSelector((state) => state.Auth.userClaimObject);

  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [modalConfirmShow, setConfirmModalShow] = useState(false);
  const [shareModalShow, setShareModalShow] = useState(false);
  const [copyModalShow, setCopyModalShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const appActiveUsers = useSelector((state) => state?.CustomerView?.appUsers);
  const getEmailNotificationStatus = useSelector(
    (state) => state?.CustomerView?.notificationEmailStatus
  );
  const notifyUsersByEmailList = useSelector(
    (state) => state?.CustomerView?.notifyUsersByEmailList
  );
  const [selectedUsersToNotify, setselectedUsersToNotify] = useState([]);

  const editInsightHandler = () => {
    setAnchorEl(null);
    setModalShow(true);
  };

  const getOptionsTosharewith = async () => {
    if (appActiveUsers?.length === 0) {
      dispatch(
        setAppUsers(
          await getTeamMembers(insight.accountId, ACCOUNT_TYPE.shared)
        )
      );
    }
  };

  const shareInsightHandler = () => {
    setAnchorEl(null);
    getOptionsTosharewith();
    setShareModalShow(true);
  };

  const copyInsightHandler = () => {
    setAnchorEl(null);
    setCopyModalShow(true);
    dispatch(setSelectedCustomer([]));
  };

  const handleSave = async (title) => {
    const insightId = insight?.id;
    try {
      await updateInsightBoardTitle(title, insightId);
      const insightData = insightsBoards?.map((item) => {
        let updatedTitle = item.name;
        if (item.id === insight.id) {
          updatedTitle = title;
        }
        return { ...item, name: updatedTitle };
      });

      dispatch(setInsightBoards(insightData));
    } catch (err) {
      console.log(err.message);
    }
    setModalShow(false);
  };

  const saveShareInsightHandler = async (shareWith) => {
    const sharedByName = appActiveUsers?.filter(
      (uid) => uid?.unixId.toLowerCase() === unixId.toLowerCase()
    );
    let insightsBoard = {
      insightBorardIdToShare: insight.id,
      accountId: insight.accountId,
      insightsBoardName: insight.name || insight.accountName,
      isPriorityAccount: insight.isPriorityAccount
        ? insight.isPriorityAccount
        : 'N',
      boardType: insight.boardType,
      sharedBy: sharedByName?.[0]?.label,
    };
    const response = await shareInsightBoard(insightsBoard, shareWith);
    let successMsg = insight.accountName + ' Shared with : ';
    if (!response?.some((item) => item.message !== 'success')) {
      response.map((rs, index) => {
        successMsg += (index ? ', ' : '') + rs.sharedWith;
        return 'success';
      });
      toast.success(successMsg);
      if (getEmailNotificationStatus) {
        saveNotifyInsightHandler(selectedUsersToNotify);
        dispatch(setNotificationEmailStatus(false));
      }
    } else {
      toast.error('Operation Failed');
      return 'success';
    }
  };

  const saveNotifyInsightHandler = async (notifyEmailWith) => {
    const sharedByName = appActiveUsers?.filter(
      (uid) => uid?.unixId.toLowerCase() === unixId.toLowerCase()
    );
    let insightsBoard = {
      insightBorardIdToShare: insight.id,
      accountId: insight.accountId,
      insightsBoardName: insight.name || insight.accountName,
      isPriorityAccount: insight.isPriorityAccount,
      boardType: insight.boardType,
      sharedBy: sharedByName?.[0]?.label,
    };

    await notifyRecipientByMail(insightsBoard, notifyUsersByEmailList);
  };

  const onHide = () => {
    setModalShow(false);
    setConfirmModalShow(false);
  };
  const deleteInsightHandler = () => {
    //call delete api
    setAnchorEl(null);
    setConfirmModalShow(true);
  };

  const onDelete = async (id) => {
    //API integration
    try {
      const response = await deleteInsightBoardTitle(id);
      if (response) {
        const insightDataExceptDeleted = insightsBoards.filter(
          (item) => item.id !== id
        );
        dispatch(setInsightBoards(insightDataExceptDeleted));
      }
    } catch (err) {
      console.log(err.message);
    }
    setConfirmModalShow(false);
  };

  const contextContent = (
    <>
      <div
        className='menu-item'
        onClick={shareInsightHandler}>
        <i className={`icon icon__share me-1 ${classes['edit-icon']}`}></i>
        <LinkButton className={classes['context-menu-button']}>
          Share
        </LinkButton>
      </div>
      <div
        className='menu-item'
        onClick={copyInsightHandler}>
        <i className='icon icon__copy-insight me-2'></i>
        <LinkButton className={classes['context-menu-button']}>Copy</LinkButton>
      </div>
      <div
        className='menu-item'
        onClick={editInsightHandler}>
        <i
          className={`icon icon__configureEditInsightTile ${classes['edit-icon']}`}></i>
        <LinkButton className={classes['context-menu-button']}>
          Rename Title
        </LinkButton>
      </div>
      <div
        className='menu-item ms-1'
        onClick={deleteInsightHandler}>
        <i className='icon icon__delete-insight me-2'></i>
        <LinkButton className={classes['context-menu-button']}>
          Delete
        </LinkButton>
      </div>
    </>
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleContextClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const tooltip = (sharedBy) => {
    return (
      <Tooltip bsclass='gne-tooltip tooltip__white'>
        <p className='text text__x-small mb-1'>
          <b>Shared by: </b>
          {sharedBy}
        </p>
      </Tooltip>
    );
  };

  return (
    <Row
      className={`list-card ${classes['list-item-insight']} animate__animated animate__fadeIn`}>
      <Col
        md={5}
        className='text-start title__normal title__blue title__bold'>
        <Row>
          <Col
            md={12}
            className='me-0'>
            <span>
              <Link
                to={insight.name.replace(CUSTOMER_VIEW_TEXT, '')}
                onClick={onItemClickHandler.bind(this, insight)}>
                {insight.name.replace(CUSTOMER_VIEW_TEXT, '')}{' '}
              </Link>
            </span>

            {insight.sharedBy ? (
              <span className='ms-2'>
                <OverlayTrigger
                  overlay={tooltip(insight.sharedBy)}
                  placement='bottom'>
                  <i className='icon icon__sharedby'></i>
                </OverlayTrigger>
              </span>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Col>
      <Col md={2}>
        <label className='sub-title sub-title__x-small  text__grey'>
          Created:
        </label>
        <label className='ps-1 title__x-small text__black'>
          {moment(insight.createdDate).format(DATE_FORMAT)}
        </label>
      </Col>
      <Col md={2}>
        <label className='sub-title sub-title__x-small  text__grey'>
          Last viewed:
        </label>
        <label className='ps-1 title__x-small text__black'>
          {moment(insight.lastViewedDate).format(DATE_FORMAT)}
        </label>
      </Col>
      <Col md={1}>
        <label className='ps-1 title__x-small text__black'>
          {insight?.boardType === 'site' ? 'Site' : insight.label}
        </label>
      </Col>

      <Col md={2}>
        <span className='float-end d-flex'>
          <div
            className={`ms-auto ${classes['icon-wrap']}`}
            onClick={(e) => handleContextClick(e)}>
            <i className='icon icon__card-toggle text-end'></i>
          </div>
          <ContextPopover
            anchor={anchorEl}
            onHandleClose={handleClose}
            content={contextContent}
          />
        </span>
        <ShareInsightBoard
          showModal={shareModalShow}
          setShowModal={setShareModalShow}
          currentInsight={insight}
          dropdownShareOptions={appActiveUsers}
          saveShareInsightHandler={(shareWith) => {
            saveShareInsightHandler(shareWith);
          }}
          saveNotifyInsightHandler={(notifyWithshare) => {
            if (notifyWithshare?.length) {
              setselectedUsersToNotify(notifyWithshare);
            }
          }}
        />
        <CopyInsightBoard
          showModal={copyModalShow}
          setShowModal={setCopyModalShow}
          currentInsight={insight}
        />
        <EditInsightBoardTitle
          show={modalShow}
          handleSave={handleSave}
          onHide={onHide}
          title={insight?.name}
          insightAccountId={insight.id}
        />
        <Confirmation
          show={modalConfirmShow}
          onOK={onDelete}
          onHide={onHide}
          id={insight.id}
        />
      </Col>
    </Row>
  );
};
