import Popover from '@mui/material/Popover';

const ContextPopover = (props) => {
  const open = Boolean(props.anchor);

  return (
    <Popover
      open={open}
      anchorEl={props.anchor}
      onClose={props.onHandleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      className='gne-context'>
      {props.content}
    </Popover>
  );
};

export default ContextPopover;
