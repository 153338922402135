import 'animate.css';
import LinkButton from 'components/shared/button/LinkButton';
import GNEAsyncPagination from 'components/shared/dropdown-with-pagination/GNEAsyncPagination';
import { ALL, CONFIGURE_VIEW, CUSTOMER_TYPE } from 'constants/constants';
import { useRef, useState } from 'react';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { wrapMenuList } from 'react-select-async-paginate';
import { getAdvanceSearchFieldsData } from 'store/customer/customer-view-actions';
import {
  setAdvanceSearchClicked,
  setAdvanceSearchSelectedData,
  setIsApply,
} from 'store/customer/customerViewSlice';
import { ValueContainer, dropdownOptions } from 'utils/utils';
import classes from './AdvanceSearchLayout.module.scss';
import { uniqBy } from 'lodash';
import { searchResult } from 'components/customer/view/utils';
const AdvanceSearch = (props) => {
  const { page } = props;
  const dispatch = useDispatch();

  const firstLoading = useRef(true);
  const shouldLoadMore = useRef(false);

  const {
    isApply,
    advanceSearchSelectedData,
    advanceSearchClicked,
    defaultCustomerType,
  } = useSelector((state) => state?.CustomerView);
  const customerTypes = useSelector((state) => state.trends.customerTypes);

  const initialState = {
    customerType: [],
    city: [],
    state: [],
    zip: [],
  };

  const [selectedState, setSelectedState] = useState(advanceSearchSelectedData);
  const [offset, setOffset] = useState(initialState);
  const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState(true);

  const whiteViews = [CONFIGURE_VIEW.viewList];
  const grayViews = [
    CONFIGURE_VIEW.configurePriority,
    CONFIGURE_VIEW.customerView,
    CONFIGURE_VIEW.orgHierarchy,
    CONFIGURE_VIEW.copyInsight,
  ];
  const leftGrayViews = [CONFIGURE_VIEW.teamSetup];

  const getArrowClass = (page) => {
    if (whiteViews.includes(page)) {
      return 'centerWhite';
    }
    if (grayViews.includes(page)) {
      return 'centerGray';
    }
    if (leftGrayViews.includes(page)) {
      return 'leftgray';
    }
    return '';
  };

  const handleApply = () => {
    dispatch(setAdvanceSearchSelectedData(selectedState));
    dispatch(
      setAdvanceSearchClicked({ id: page, value: !advanceSearchClicked[page] })
    );
    dispatch(setIsApply(!isApply));
  };

  const handleReset = () => {
    setSelectedState({ ...initialState });
    dispatch(setAdvanceSearchSelectedData(initialState));
  };

  const handleOnChange = (value, field) => {
    setSelectedState({ ...selectedState, [field]: value });
    setIsApplyButtonDisabled(false);
  };

  const [options, setOptions] = useState([]);
  const onLoadCustomerType = async (searchText) => {
    if (!searchText) {
      const currentCustomerType =
        page === CONFIGURE_VIEW.trends ? customerTypes : defaultCustomerType;
      const data = [];
      currentCustomerType.forEach((i) => {
        data.push({
          label: CUSTOMER_TYPE.get(i),
          value: i,
        });
      });
      const customerTypeUnqOpts = uniqBy(data, 'label');
      setOptions(customerTypeUnqOpts);

      return {
        options: customerTypeUnqOpts,
        hasMore: false,
      };
    } else {
      const data = searchResult(options, searchText);
      return {
        options: data,
        hasMore: false,
      };
    }
  };

  const onLoadCity = async (searchText, loadedOptions, firstLoad) => {
    if (searchText && searchText.length <= 2) {
      return { options: [], hasMore: false };
    }
    try {
      const dataOffset =
        firstLoad === true
          ? 0
          : firstLoading.current
          ? 0
          : !shouldLoadMore.current
          ? 0
          : offset.city;

      const params = {
        offset: dataOffset,
        searchText,
        field: 'city',
        selectedZIP:
          selectedState.zip?.length > 0
            ? selectedState.zip?.map((item) => item.value)
            : [],
        selectedState:
          selectedState.state?.length > 0
            ? selectedState.state?.map((item) => item.value)
            : [],
      };
      const { data, hasMore, currentListSize } =
        await getAdvanceSearchFieldsData(params);

      if (hasMore) {
        setOffset({ ...offset, city: dataOffset + currentListSize });
      }

      return {
        options: data,
        hasMore: hasMore,
      };
    } catch (error) {
      console.log(error);
    }
  };

  const onLoadState = async (searchText, loadedOptions, firstLoad) => {
    try {
      const dataOffset =
        firstLoad === true
          ? 0
          : firstLoading.current
          ? 0
          : !shouldLoadMore.current
          ? 0
          : offset.state;

      const params = {
        offset: dataOffset,
        searchText,
        field: 'STATE_CODE',
        selectedCity:
          selectedState.city?.length > 0
            ? selectedState.city?.map((item) => item.value)
            : [],
        selectedZIP:
          selectedState.zip?.length > 0
            ? selectedState.zip?.map((item) => item.value)
            : [],
      };
      const { data, hasMore, currentListSize } =
        await getAdvanceSearchFieldsData(params);

      if (hasMore) {
        setOffset({ ...offset, state: dataOffset + currentListSize });
      }

      return {
        options: data,
        hasMore: hasMore,
      };
    } catch (error) {
      console.log(error);
    }
  };

  const onLoadZip = async (searchText, loadedOptions, firstLoad) => {
    try {
      const dataOffset =
        firstLoad === true
          ? 0
          : firstLoading.current
          ? 0
          : !shouldLoadMore.current
          ? 0
          : offset.zip;

      const params = {
        offset: dataOffset,
        searchText,
        field: 'zip',
        selectedCity:
          selectedState.city?.length > 0
            ? selectedState.city?.map((item) => item.value)
            : [],
        selectedState:
          selectedState.state?.length > 0
            ? selectedState.state?.map((item) => item.value)
            : [],
      };
      const { data, hasMore, currentListSize } =
        await getAdvanceSearchFieldsData(params);

      if (hasMore) {
        setOffset({ ...offset, zip: dataOffset + currentListSize });
      }

      return {
        options: data,
        hasMore: hasMore,
      };
    } catch (error) {
      console.log(error);
    }
  };

  const shouldLoadMoreOptions = (scrollHeight, clientHeight, scrollTop) => {
    if (firstLoading.current) {
      firstLoading.current = false;
      return true;
    }
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    const loadMore = bottomBorder < scrollTop;
    shouldLoadMore.current = loadMore;

    return loadMore;
  };

  const colLength =
    props?.viewType !== CONFIGURE_VIEW.configurePriority &&
    props?.viewType !== CONFIGURE_VIEW.copyInsight
      ? 3
      : 6;

  return (
    <div className={`${classes['advanceSearchBox']}`}>
      <div className={`arrow ${getArrowClass(props.page)}`}></div>

      <div className={`w-100 d-flex flex-column align-items-end px-2`}>
        <LinkButton
          className={`text text__x-small float-right mt-1 ms-1 ${
            isApplyButtonDisabled ? 'disabled' : ''
          }`}
          onClick={handleReset}
          disabled={isApplyButtonDisabled}>
          {isApplyButtonDisabled ? (
            <i className='icon icon__reset-disabled mb-1'></i>
          ) : (
            <i className='icon icon__reset mb-1 '></i>
          )}
          Reset
        </LinkButton>
      </div>

      <Row>
        <Col md={colLength}>
          <label
            className={`title__x-small me-1${
              page === CONFIGURE_VIEW.copyInsight ? ' title__grey' : ''
            }`}>
            Customer Type
          </label>

          <OverlayTrigger
            className={`w-100`}
            placement='right'
            overlay={
              <Tooltip bsclass='gne-tooltip tooltip--left'>
                Account or Site both refer to business stakeholders. The term
                'Account' represents the corporate parent or headquarters, while
                'Sites' are entities owned by and associated with an Account.
                You can find Oncology Supergroups here too.
                <br />
                <br />
                Payer and PBM: Coming soon!
                <br />
                <br />
                GPO:Coming soon!
              </Tooltip>
            }>
            <i className={`icon icon__info__gray ${classes['infoIcon']}`}></i>
          </OverlayTrigger>

          <GNEAsyncPagination
            value={selectedState.customerType}
            loadOptions={onLoadCustomerType}
            shouldLoadMore={shouldLoadMoreOptions}
            onChange={(value) => handleOnChange(value, 'customerType')}
            isMulti={true}
            components={{
              ValueContainer: ValueContainer,
              Option: wrapMenuList(dropdownOptions),
            }}
            placeholder={ALL}
            closeMenuOnSelect={false}
            isClearable
            isDisabled={page === CONFIGURE_VIEW.copyInsight}
          />
        </Col>
        <Col md={colLength}>
          <label className='title__x-small'>City</label>

          <GNEAsyncPagination
            value={selectedState.city}
            loadOptions={onLoadCity}
            shouldLoadMore={shouldLoadMoreOptions}
            onChange={(value) => handleOnChange(value, 'city')}
            isMulti={true}
            components={{
              ValueContainer: ValueContainer,
              Option: wrapMenuList(dropdownOptions),
            }}
            placeholder={ALL}
            closeMenuOnSelect={false}
            isClearable
            cacheUniqs={[selectedState.state, selectedState.zip]}
          />
        </Col>
        <Col md={colLength}>
          <label className='title__x-small'>State</label>

          <GNEAsyncPagination
            value={selectedState.state}
            loadOptions={onLoadState}
            shouldLoadMore={shouldLoadMoreOptions}
            onChange={(value) => handleOnChange(value, 'state')}
            isMulti={true}
            components={{
              ValueContainer: ValueContainer,
              Option: wrapMenuList(dropdownOptions),
            }}
            placeholder={ALL}
            closeMenuOnSelect={false}
            isClearable
            cacheUniqs={[selectedState.city, selectedState.zip]}
          />
        </Col>
        <Col md={colLength}>
          <label className='title__x-small'>ZIP</label>

          <GNEAsyncPagination
            value={selectedState.zip}
            loadOptions={onLoadZip}
            shouldLoadMore={shouldLoadMoreOptions}
            onChange={(value) => handleOnChange(value, 'zip')}
            isMulti={true}
            components={{
              ValueContainer: ValueContainer,
              Option: wrapMenuList(dropdownOptions),
            }}
            placeholder={ALL}
            closeMenuOnSelect={false}
            isClearable
            cacheUniqs={[selectedState.city, selectedState.state]}
          />
        </Col>
      </Row>

      <div className='w-100 d-flex flex-column align-items-center pt-3'>
        <Button
          variant={`btn btn-gne btn-primary  mt-2 `}
          disabled={isApplyButtonDisabled}
          onClick={handleApply}>
          Apply
        </Button>
      </div>
    </div>
  );
};

export default AdvanceSearch;
