import { AsyncPaginate } from 'react-select-async-paginate';
import ReactSelectSearch from 'utils/hoc/react-select-search';

export const AsyncPagination = (props) => {
  return (
    <AsyncPaginate
      {...props}
      debounceTimeout={500}
      classNamePrefix='gne'
      className={`${props.className} single-select`}
      blurInputOnSelect={false}
      isTitleCase={props.isTitleCase ?? false}
      isSearchable={false}
      hideSelectedOptions={false}
      value={props.value}
      loadOptions={props.loadOptions}
      options={props.options ? props.options : undefined}
      onChange={props.onChange}
      isMulti={props.isMulti}
      components={props.components}
      placeholder={props.placeholder}
      searchPlaceholder={props.searchPlaceholder}
      closeMenuOnSelect={props.closeMenuOnSelect}
      isClearable={props.isClearable}
      cacheUniqs={props.cacheUniqs}
      isDisabled={props.isDisabled}
      onMenuOpen={props.onMenuOpen}
      menuPosition={props.menuPosition}
      maxMenuHeight={props.maxMenuHeight}
    />
  );
};

const GNEAsyncPagination = ReactSelectSearch(AsyncPagination);
export default GNEAsyncPagination;
