import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Input, Table } from 'antd';
import GNEModal from 'components/shared/modal/Modal';
import LinkButton from 'components/shared/button/LinkButton';
import Confirmation from 'components/shared/confirmation/Confirmation';
import {
  getLabels,
  deleteLabels,
  createLabel,
  updateLabel,
} from 'services/org/label.service';
import { addLogs } from 'services/org/org.service';
import {
  CHANGEACTIONTYPE,
  CHANGEVALUETYPE,
  LOGTYPE,
} from 'constants/constants';
import classes from './CreateLabelsLayout.module.scss';
import GNESingleSelect from 'components/shared/dropdown/GNESingleSelect';

const CreateLabelsLayout = ({
  labelData,
  dropdownColorOptions,
  showModal,
  setShowModal,
  accountId,
  onCallOrgChartApi,
  setSelectedAction,
}) => {
  const { TextArea } = Input;
  const { unixId } = useSelector((state) => state.Auth.userClaimObject);

  const colordropdownOptions = dropdownColorOptions?.map((item) => ({
    label: (
      <div
        className={classes['dropdown-option']}
        style={{ backgroundColor: item }}></div>
    ),
    value: item,
  }));

  const [labelName, setLabelName] = useState('');
  const [missingField, setMissingField] = useState(false);
  const [editMode, setEditMode] = useState(0);
  const [selectedColor, setSelectedColor] = useState('');
  const [updatedLabelText, setUpdatedLabelText] = useState('');
  const [udpatedLabelColor, setUpdatedLabelColor] = useState('');
  const [labelsData, setLabelsData] = useState(labelData);
  const [modalConfirmShow, setConfirmModalShow] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState('');

  const labelDataLimit = labelsData?.length === 10;

  const onEditLabelName = (value) => {
    setUpdatedLabelText(value);
  };
  const onEditLabelColor = (value) => {
    setUpdatedLabelColor(value);
  };

  useEffect(() => {
    setLabelsData(labelData);
  }, [labelData]);

  const columns = [
    {
      title: '',
      dataIndex: 'labelName',
      key: 'labelName',
      width: 316,
      render: (col, record) =>
        editMode === record?.key ? (
          <TextArea
            rows={1}
            maxLength={20}
            value={updatedLabelText}
            className={classes['label-editinput']}
            onChange={(e) => onEditLabelName(e.target.value)}
          />
        ) : (
          record?.labelName
        ),
    },
    {
      title: '',
      dataIndex: 'labelColor',
      key: 'labelColor',
      width: 100,
      render: (col, record) =>
        editMode === record?.key ? (
          <GNESingleSelect
            dropdownStyle={{ zIndex: '100000' }}
            className={classes['color-editinput']}
            onChange={(e) => onEditLabelColor(e)}
            options={colordropdownOptions}
            value={udpatedLabelColor || record?.labelColor}
          />
        ) : (
          <div
            className={classes['dropdown-option']}
            style={{
              backgroundColor: record?.labelColor,
              marginTop: 5,
              marginLeft: 8,
            }}></div>
        ),
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <span className={classes['actions-wrap']}>
          {editMode === record?.key ? (
            <>
              <LinkButton
                className='me-0'
                onClick={() => onSaveEditValue(record)}>
                Save
              </LinkButton>
              <LinkButton
                className='me-0'
                onClick={() => onCancelEditValue(record?.key)}>
                Cancel
              </LinkButton>
            </>
          ) : (
            <>
              <LinkButton
                className='me-0'
                onClick={() =>
                  editInsightHandler(
                    record?.key,
                    record?.labelName,
                    record?.labelColor
                  )
                }>
                <i className='icon icon__edit-metric m-0'></i>
              </LinkButton>
              <LinkButton
                className='ms-1 me-0'
                onClick={() => {
                  setConfirmModalShow(true);
                  setDeleteRowId(record?.key);
                }}>
                <i className='icon icon__delete m-0'></i>
              </LinkButton>
            </>
          )}
        </span>
      ),
    },
  ];

  const handleCancelLabels = () => {
    setSelectedAction([]);
    setShowModal(false);
    setLabelName('');
    setSelectedColor('');
    setUpdatedLabelText('');
    setUpdatedLabelColor('');
    setMissingField(false);
  };

  const editInsightHandler = (id, label, color) => {
    setEditMode(id);
    setUpdatedLabelText(label);
    setUpdatedLabelColor(color);
  };

  const onSaveEditValue = async (record) => {
    const payload = {
      unixId: unixId,
      labelName: updatedLabelText ? updatedLabelText : record?.labelName,
      labelColor: udpatedLabelColor ? udpatedLabelColor : record?.labelColor,
      isActive: 'Y',
      labelId: record?.key,
    };
    if (
      updatedLabelText !== record?.labelName ||
      udpatedLabelColor !== record?.labelColor
    ) {
      const response = await updateLabel(payload);
      if (response) {
        let updatedData = [...labelsData];

        // eslint-disable-next-line array-callback-return
        updatedData?.map((item, index) => {
          if (item?.key === record?.key) {
            updatedData[index] = {
              key: updatedData[index]?.key,
              labelId: updatedData[index]?.labelId,
              labelName: updatedLabelText || updatedData[index]?.labelName,
              labelColor: udpatedLabelColor || updatedData[index]?.labelColor,
            };
          }
        });

        setLabelsData(updatedData);
        setUpdatedLabelText('');
        setUpdatedLabelColor('');
        onCallOrgChartApi('create');
        addActionLog(
          'labels',
          `updated ${labelName} label `,
          record?.labelName,
          updatedLabelText
        );
      }
    }
    setEditMode(0);
  };

  const onCancelEditValue = () => {
    setEditMode(0);
    setUpdatedLabelText('');
    setUpdatedLabelColor('');
  };

  const addActionLog = async (action, actiondetail, oldvalue, newvalue) => {
    const payload = [
      {
        LogType: LOGTYPE.userlog,
        ViewChangeType: CHANGEACTIONTYPE.label,
        ImpactedMdmId: accountId,
        ValueType: CHANGEVALUETYPE.label,
        OldValue: oldvalue,
        NewValue: newvalue,
        Action: action,
        Flag: '',
        isActive: 'Y',
        ActionDetails: actiondetail,
        unixId: unixId,
        customerId: accountId,
      },
    ];
    await addLogs(payload);
  };

  const getUpdatedData = async () => {
    const data = await getLabels(accountId);
    setLabelsData(data);
    setLabelName('');
    setSelectedColor('');
    setMissingField(false);
    if (data) {
      onCallOrgChartApi('create');
      addActionLog('labels', `created ${labelName} label`, '', labelName);
    }
  };

  const createLabelAction = async () => {
    if (labelName && selectedColor) {
      const payload = {
        customerId: accountId,
        unixId: unixId,
        labelName: labelName,
        labelColor: selectedColor,
        isActive: 'Y',
      };
      const message = await createLabel(payload);
      if (message) {
        getUpdatedData();
      }
    } else {
      setMissingField(true);
    }
  };

  const deleteLabelActions = async (id) => {
    setConfirmModalShow(false);
    try {
      const response = await deleteLabels(accountId, id);
      let updatedLabelData = [];
      if (response) {
        updatedLabelData = labelsData?.filter((item) => item?.key !== id);
        setLabelsData(updatedLabelData);
        onCallOrgChartApi('create');
        addActionLog('labels', `removed ${labelName} label`, labelName, '');
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const onHideConfiramtionModal = () => {
    setConfirmModalShow(false);
  };

  const content = (
    <div className={classes['create-label-wrap']}>
      <div className='d-flex'>
        <i className='icon icon__createLabels'></i>
        <div className='title-wrap'> Create Labels</div>
      </div>
      <div className={classes['modal-subtitle']}>
        Create up to 10 labels below.
      </div>
      <div className={classes['input-container']}>
        <div className={classes['label-input']}>
          New Label
          <TextArea
            rows={1}
            placeholder='Label Name'
            allowClear
            value={labelName}
            className={classes['label-editinput']}
            onChange={(e) =>
              setLabelName(
                e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
              )
            }
            disabled={labelDataLimit}
            showCount
            maxLength={20}
          />
        </div>
        <div className={classes['label-input']}>
          Color
          <GNESingleSelect
            dropdownStyle={{ zIndex: '100000' }}
            className={classes['color-editinput']}
            onChange={(value) => setSelectedColor(value)}
            options={colordropdownOptions}
            value={selectedColor}
            disabled={labelDataLimit}
          />
        </div>
        <div>
          <Button
            variant='btn btn-gne btn-outline-primary btn-gne__outline'
            onClick={createLabelAction}
            className={classes['create-btn']}
            disabled={labelDataLimit}>
            Create
          </Button>
        </div>
      </div>
      {missingField && (
        <div className={classes['error-message']}>
          Please select all the fields
        </div>
      )}
      {labelsData?.length > 0 && (
        <div className={classes['table-container']}>
          <Table
            columns={columns}
            dataSource={labelsData}
            size='small'
            pagination={false}
            showHeader={false}
          />
        </div>
      )}
    </div>
  );

  const footer = (
    <div className='d-flex justify-content-end w-100'>
      <LinkButton
        className='text text__small'
        onClick={handleCancelLabels}>
        Cancel
      </LinkButton>
    </div>
  );

  return (
    <div>
      <GNEModal
        show={showModal}
        onHide={handleCancelLabels}
        content={content}
        css='size-medium'
        footer={footer}
        backdrop='static'
      />
      <Confirmation
        show={modalConfirmShow}
        onOK={() => deleteLabelActions(deleteRowId)}
        onHide={onHideConfiramtionModal}
      />
    </div>
  );
};

export default CreateLabelsLayout;
