import { createSlice } from '@reduxjs/toolkit';
import { AuthInitialState } from './AuthInitialState';

export const Authslice = createSlice({
  name: 'Auth',
  initialState: {
    ...AuthInitialState,
  },
  reducers: {
    resetAll: (state) => {
      return {
        ...AuthInitialState,
      };
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setRestrictedEcosystem: (state, action) => {
      state.userRestrictedEcosystemt = action.payload;
    },
    setUserRestriction: (state, action) => {
      state.userRestriction = action.payload;
    },
    setRestrictedProductSquad: (state, action) => {
      state.restrictedProductSquad = action.payload;
    },
    setUserClaimObject: (state, action) => {
      state.userClaimObject = action.payload;
    },
    setWrapperObj: (state, action) => {
      state.wrapperObj = action.payload;
    },
    setFavReportObj: (state, action) => {
      state.favReportObj = action.payload;
    },
    setQlikToken: (state, actions) => {
      state.qlikAuth = actions.payload;
    },
    setComponentAccessList: (state, actions) => {
      state.componentAccessList = actions.payload;
    },
    setRoles: (state, actions) => {
      state.roles = actions.payload;
    },
  },
});

export const {
  resetAll,
  setToken,
  setUserClaimObject,
  setWrapperObj,
  setFavReportObj,
  setQlikToken,
  setComponentAccessList,
  setRestrictedEcosystem,
  setUserRestriction,
  setRestrictedProductSquad,
  setRoles,
} = Authslice.actions;

export default Authslice.reducer;
