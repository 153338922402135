import Header from './header/Header';
import RightSideBar from './side-bar/RightSideBar';

import classes from './Layout.module.scss';
import NavBar from 'components/shared/nav-bar-latest/NavBar';
import { navData } from '../../lib/navData';

const Layout = (props) => {
  return (
    <div className='d-flex'>
      {/* header */}
      <Header />

      {/* wrapper */}
      <NavBar items={navData} />

      {/* child components listed inside of the Layout tag */}
      <main className={`flex-grow-1 ${classes.content}`}>
        <div
          className='alert alert-warning text text__text-small text__bold text-center rotate-screen'
          role='alert'>
          For the best experience, please rotate your device to landscape mode.
        </div>
        {props.children}
      </main>

      {/* right side bar */}
      {props.showRighSideBar && <RightSideBar />}
    </div>
  );
};

export default Layout;
