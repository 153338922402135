/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';
import {
  setSelectedStartDate,
  setSelectedEndDate,
} from 'store/customer/filter/filter-slice';

const DatePickerRange = () => {
  const dispatch = useDispatch();
  const defaultStartDate = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 15);
  const defaultEndDate = new Date();
  defaultEndDate.setDate(defaultEndDate.getDate() - 1);
  const minStartDate = new Date();
  minStartDate.setMonth(minStartDate.getMonth() - 6);
  const selectedCustomer = useSelector(
    (state) => state?.CustomerView?.selectedCustomer
  );

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const startDatepickerRef = useRef(null);
  const endDatepickerRef = useRef(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    dispatch(setSelectedStartDate(date));
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    dispatch(setSelectedEndDate(date));
  };

  function handleClickStartDatepickerIcon() {
    const datepickerElement = startDatepickerRef.current;
    datepickerElement.setFocus(true);
  }
  function handleClickEndDatepickerIcon() {
    const datepickerElement = endDatepickerRef.current;
    datepickerElement.setFocus(true);
  }

  useEffect(() => {
    dispatch(setSelectedStartDate(defaultStartDate));
    dispatch(setSelectedEndDate(defaultEndDate));
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
  }, [selectedCustomer]);

  return (
    <>
      <Col className='date-picker-wrapper'>
        <label className='title__x-small d-block'>Start Date</label>

        <DatePicker
          selected={startDate}
          onChange={handleStartDateChange}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={endDate}
          minDate={minStartDate}
          dateFormat='MMM dd, yyyy'
          className='gne-datepicker w-100'
          ref={startDatepickerRef}
        />
        <i
          className='icon icon__calendar'
          onClick={() => handleClickStartDatepickerIcon()}></i>
      </Col>
      <Col className='date-picker-wrapper'>
        <label className='title__x-small d-block'>End Date</label>
        <DatePicker
          selected={endDate}
          onChange={handleEndDateChange}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          maxDate={new Date()}
          dateFormat='MMM dd, yyyy'
          className='gne-datepicker'
          ref={endDatepickerRef}
        />
        <i
          className='icon icon__calendar'
          onClick={() => handleClickEndDatepickerIcon()}></i>
      </Col>
    </>
  );
};

export default DatePickerRange;
