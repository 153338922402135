import {
  Button,
  Col,
  Container,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import classes from './RemoveSiteLayout.module.scss';
import LinkButton from 'components/shared/button/LinkButton';
import Filters from '../filter/Filters';
import SiteHeirarchyTable from './SiteHeirarchyTable';
import { removeSites, addLogs } from 'services/org/org.service';
import { useSelector } from 'react-redux';
import { getSiteGridData } from 'services/org/label.service';
import { Spin } from 'antd';
import {
  NO_SITE_SELECTED,
  NO_SITE_SELECTED_SUBTEXT,
  REMOVE_SITE_SUBTEXT,
  optionsInitialState,
  LOGTYPE,
  CHANGEACTIONTYPE,
  CHANGEVALUETYPE,
  ACTION_TYPE,
  ORG_ACTIONS,
} from 'constants/constants';
import TextArea from 'antd/es/input/TextArea';

const RemoveSitesLayout = (props) => {
  const { accountId, labelsData, onCallOrgChartApi, accountName, labelAccess } =
    props;
  const [key, setKey] = useState('addSites');
  const [selectedRow, setSelectedRow] = useState([]);
  const [text, setText] = useState('');
  const [selectedOption, setSelectedOption] = useState(optionsInitialState);
  const [sitesData, setSitesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [gridSize, setGridSize] = useState(0);
  const maxLength = 1000;
  const { unixId } = useSelector((state) => state.Auth.userClaimObject);
  // eslint-disable-next-line
  const [initialFilterValue, setInitialFilterValue] =
    useState(optionsInitialState);

  const handleRowSelect = (record, type) => {
    if (type === 'checkbox') {
      const isSelected = selectedRow.some((row) => row.key === record.key);
      let newSelectedRows;

      if (isSelected) {
        newSelectedRows = selectedRow.filter((row) => row.key !== record.key);
      } else {
        newSelectedRows = [...selectedRow, record];
      }

      setSelectedRow(newSelectedRows);
    }
  };

  const onhandleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxLength) {
      setText(inputValue);
    }
  };

  const handleClearAll = () => {
    setSelectedRow([]);
  };

  const addActionLog = async () => {
    const payload = selectedRow?.map((item) => ({
      LogType: LOGTYPE.userlog,
      ViewChangeType: CHANGEACTIONTYPE.hierarchy,
      ImpactedMdmId: accountId,
      ValueType: CHANGEVALUETYPE.parent,
      OldValue: item?.key,
      NewValue: '',
      Action: 'remove',
      Flag: '',
      isActive: 'Y',
      ActionDetails: `removed ${item?.name} (${item?.childMdmId}) from the ${accountName}(${accountId})`,
      unixId: unixId,
      customerId: accountId,
    }));
    await addLogs(payload);
  };
  const createPayLoad = (selectedRow, text, unixId, isActive) => {
    const outputArray = [];
    selectedRow.forEach((selectedRow) => {
      const { childMdmId, userChildLevel, userParentLevel } = selectedRow;
      const output = {
        childMdmId,
        hcosParentMdmId: selectedRow?.hcosParentMdmId || '',
        hcosChildLevel: selectedRow?.hcosChildLevel || '',
        hcosParentLevel: selectedRow?.hcosParentLevel || '',
        userParentMdmId: selectedRow?.userParentMdmId || '',
        userChildLevel: userChildLevel + 1,
        userParentLevel: userParentLevel + 1,
        sharedSite: selectedRow?.sharedSite || '',
        isActive: isActive,
        unixId: unixId,
        reason: text,
        cpMdmId: accountId,
      };

      // Push the output to the outputArray
      outputArray.push(output);
    });

    return outputArray;
  };

  const handleApply = async (selectedRow, text) => {
    const payload = createPayLoad(selectedRow, text, unixId, 'N');
    if (payload?.length > 0) {
      const name = accountName;
      await removeSites(payload, selectedRow, name);
      resetSelections();
      onCallOrgChartApi();
      addActionLog();
    }
  };

  const resetSelections = () => {
    setKey(ACTION_TYPE.addSite);
    setText('');
    setSitesData([]);
    setSelectedRow([]);
  };

  const resetOptions = () => {
    setSelectedOption(optionsInitialState);
  };

  const handleFilterApply = async (filters) => {
    setIsLoading(true);
    const payload = {
      filters: {
        ...filters,
        customerId: accountId,
        unixId: unixId,
        fieldName: 'sites',
        offset: 0,
        action: 'remove',
        cpMdmId: accountId,
      },
    };
    const data = await getSiteGridData(payload);
    setSitesData(data?.result?.values);
    setGridSize(data?.result?.currentListSize);
    setIsLoading(false);
  };

  const handleFilterSelect = (selectedFilter) => {
    console.log(selectedFilter, '..aca');
  };

  const tooltip = () => {
    return (
      <Tooltip bsclass='gne-tooltip tooltip--left'>
        {selectedRow?.length > 0
          ? selectedRow?.map((item) => {
              return <p className='text text__x-small mb-1'>{item?.name}</p>;
            })
          : 'No Sites Selected'}
      </Tooltip>
    );
  };

  const onCallNext = () => {
    setKey(ACTION_TYPE.reason);
  };

  const onCallPrevious = () => {
    setKey(ACTION_TYPE.addSite);
  };

  return (
    <Modal
      {...props}
      size='xl'
      className='title gne-modal'>
      <Modal.Header
        closeButton
        className='ps-0'>
        <Modal.Title
          id='contained-modal-title'
          className='w-100'>
          <Row className='justify-content-between align-items-center mb-2 mt-3'>
            <Col>
              <i
                className={`icon icon__remove-black ${classes['delete-icon']}`}></i>
              <span className='title-wrap'>Remove Sites</span>
              <div className='text text__small'>{REMOVE_SITE_SUBTEXT}</div>
            </Col>
            <Col>
              <div
                className={`site-tag ${
                  selectedRow.length > 0 ? 'selected' : 'no-selection-site-tag'
                }`}>
                <div className='d-flex'>
                  <div>Selected Site(s): &nbsp;</div>
                  {selectedRow?.length === 0
                    ? 'No Site Selected'
                    : selectedRow?.length}
                  <OverlayTrigger
                    overlay={tooltip()}
                    placement='bottom'>
                    <span className='d-inline-block info-icon'>
                      <i className='icon icon__info__gray'></i>
                    </span>
                  </OverlayTrigger>
                </div>
                <div>
                  <LinkButton
                    className={
                      selectedRow?.length === 0 ? 'disabled' : 'text text_small'
                    }
                    onClick={handleClearAll}>
                    Clear All
                  </LinkButton>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className='show-grid p-0 m-0'>
        <Container className='p-0 gne-container'>
          <div className='button-wrapper'>
            <Button
              variant={`btn btn-gne btn-primary me-2 ml-2`}
              disabled={key === ACTION_TYPE.addSite}
              onClick={onCallPrevious}>
              Previous
            </Button>

            {key === ACTION_TYPE.reason ? (
              <Button
                variant={`btn btn-gne btn-primary me-2 ml-2`}
                disabled={text.length < 1}
                onClick={() => handleApply(selectedRow, text)}>
                Remove
              </Button>
            ) : (
              <Button
                variant={`btn btn-gne btn-primary me-2 ml-2`}
                disabled={selectedRow.length === 0}
                onClick={onCallNext}>
                Next
              </Button>
            )}
          </div>
          <Tabs
            id='customeHierarchyActions'
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className='mb-3'>
            <Tab
              eventKey='addSites'
              title={<span className='d-flex'>1. Find Site(s)</span>}>
              <Row>
                {/* Gross sale left panel */}
                <Col
                  md={4}
                  xs={5}
                  lg={4}
                  className={classes['library-container']}>
                  <Filters
                    accountId={accountId}
                    onFilterSelected={(selectedFilter) =>
                      handleFilterSelect(selectedFilter)
                    }
                    onFilterApply={(filters) => handleFilterApply(filters)}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    resetOptions={resetOptions}
                    page={'actions'}
                    labelsData={labelsData}
                    unixId={unixId}
                    initialFilterValue={initialFilterValue}
                    labelAccess={labelAccess}
                    action={ORG_ACTIONS.remove}
                  />
                </Col>
                {sitesData?.length !== 0 ? (
                  <Col>
                    <Spin spinning={isLoading}>
                      <SiteHeirarchyTable
                        gridData={sitesData}
                        type={'checkbox'}
                        handleRowSelect={handleRowSelect}
                        selectedRow={selectedRow}
                        page='remove'
                        total={gridSize}
                      />
                    </Spin>
                  </Col>
                ) : (
                  <Col>
                    <Spin spinning={isLoading}>
                      <div className='m-8 message-align-wrap'>
                        <div className='default-message'>
                          {NO_SITE_SELECTED}
                        </div>
                        <p>{NO_SITE_SELECTED_SUBTEXT}</p>
                      </div>
                    </Spin>
                  </Col>
                )}
              </Row>
            </Tab>

            <Tab
              eventKey='reason'
              disabled={selectedRow.length === 0}
              title={<span className='d-flex'>2. Reason</span>}>
              <div className={`${classes['reasonPanelHeight']} col-md-12`}>
                <p>*Reason</p>
                <TextArea
                  rows={3}
                  maxLength={1000}
                  value={text}
                  className={classes['label-editinput']}
                  showCount
                  onChange={onhandleChange}
                />
              </div>
            </Tab>
          </Tabs>
        </Container>
      </Modal.Body>

      <Modal.Footer className={classes['modal-footer']}>
        <LinkButton
          onClick={props.onHide}
          className='title title__small'>
          Close
        </LinkButton>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveSitesLayout;
