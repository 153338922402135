import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Input } from 'antd';
import { useSelector } from 'react-redux';
import GNEModal from 'components/shared/modal/Modal';
import GNERadio from 'components/shared/radio/GNERadio';
import LinkButton from 'components/shared/button/LinkButton';
import { addLogs, edit340b } from 'services/org/org.service';
import {
  LOGTYPE,
  CHANGEACTIONTYPE,
  CHANGEVALUETYPE,
} from 'constants/constants';
import classes from './ContractSegementLayout.module.scss';

const ContractSegmentLayout = ({
  showModal,
  setShowModal,
  currentNode,
  onCallOrgChartApi,
  account,
}) => {
  const { TextArea } = Input;
  const [editReason, setEditReason] = useState('');
  const [missingReason, setMissingReason] = useState(false);
  const [segmentType, setSegmentType] = useState('340B');

  const { unixId } = useSelector((state) => state.Auth.userClaimObject);

  const handleCloseModal = () => {
    setShowModal(false);
    setMissingReason(false);
  };

  const onChangeReason = (value) => {
    setEditReason(value);
  };

  const handleSegementType = (e) => {
    setSegmentType(e.target.value);
  };

  const addActionLog = async () => {
    const payload = [
      {
        LogType: LOGTYPE.userlog,
        ViewChangeType: CHANGEACTIONTYPE.hierarchy,
        ImpactedMdmId: currentNode?.parentId,
        ValueType: CHANGEVALUETYPE.parent,
        OldValue: currentNode?.id,
        NewValue: '',
        Action: 'remove',
        Flag: '',
        isActive: 'Y',
        ActionDetails: `removed ${currentNode?.name} ${currentNode?.childMdmId} from ${account?.label} ${account?.value})`,
        unixId: unixId,
        customerId: account?.value,
      },
    ];
    await addLogs(payload);
  };

  const handleSave = async () => {
    if (editReason === '') {
      setMissingReason(true);
    } else {
      console.log(currentNode);
      const payload = [
        {
          mdmId: currentNode?.childMdmId,
          sourceAttributeValue: currentNode?.sourceAttributeValue ?? '',
          userAttributeValue: segmentType === '340B' ? 'Y' : 'N',
          attributeType: 'flag_340b',
          reason: editReason,
          isActive: currentNode?.isActive,
          unixId: unixId,
        },
      ];
      await edit340b(payload, account?.label);
      onCallOrgChartApi();
      setShowModal(false);
      addActionLog();
    }
  };

  const content = (
    <div className={classes['content-container']}>
      <div className='d-flex align-items-center'>
        <i className={`icon icon__edit-icon ${classes['icon-wrap']}`}></i>
        <span className={classes['title-wrap']}>Edit 340B</span>
      </div>
      <div className={classes['subtitle-wrap']}>
        Update 340B contract information.
      </div>
      <div className={classes['radio-wrap']}>
        <div className={classes['radio-wrap-title']}>Mark as</div>
        <GNERadio
          checked={segmentType === '340B'}
          name='accountType'
          label='340B'
          value='340B'
          onChange={handleSegementType}
        />
        <GNERadio
          checked={segmentType === 'Non 340B'}
          name='accountType'
          label='Non 340B'
          value='Non 340B'
          onChange={handleSegementType}
        />
      </div>
      <div className={classes['textbox-wrap']}>
        <div className={`d-flex ${classes['textbox-title']}`}>
          <div className={classes['mandatory-icon-style']}>*</div>Reason
        </div>
        <TextArea
          maxLength={1000}
          rows={4}
          showCount
          value={editReason}
          onChange={(e) => onChangeReason(e.target.value)}
          className={missingReason && classes['error-message']}
        />
      </div>
    </div>
  );

  const footer = (
    <div className='d-flex justify-content-end w-100'>
      <LinkButton
        className='text text__small'
        onClick={handleCloseModal}>
        Close
      </LinkButton>
      <Button
        variant='btn btn-gne btn-primary'
        onClick={handleSave}
        className='text__small ms-3'
        disabled={editReason === ''}>
        Save
      </Button>
    </div>
  );
  return (
    <GNEModal
      show={showModal}
      onHide={handleCloseModal}
      content={content}
      css='size-medium'
      footer={footer}
      backdrop='static'
    />
  );
};

export default ContractSegmentLayout;
