import classes from './LabelTag.module.scss';

const LabelTags = ({ record, showClose, onClose, style }) => {
  const labelStyles = {
    borderRadius: '20px',
    marginRight: '5px',
    padding: '3px 8px 2px',
    textTransform: 'uppercase',
    color: '#fff',
    display: 'inline-block',
    fontFamily: '"Gene-Sans-Bold", sans-serif',
    fontSize: '0.625rem',
    lineHeight: '12px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: 'fit-content',
    marginBottom: '0',
  };
  return (
    <>
      {record?.length > 0 &&
        record.map((label, index) => (
          <label
            className={classes['customer-tag']}
            style={{
              ...labelStyles,
              ...{ background: label?.labelColor },
              ...style,
            }}
            key={index}
            title={label?.labelName}>
            {label?.labelName}
            {showClose && (
              <span
                className={`close-button text__x-small text__bold text__white ${classes['close-label-align']}`}
                onClick={() => onClose(label?.labelName)}>
                x
              </span>
            )}
          </label>
        ))}
    </>
  );
};

export default LabelTags;
