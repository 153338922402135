/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {} from 'store/home/home-my-view-actions';
import ConfigurableTable from 'components/shared/table/ConfigurableTable';

import {
  SITE_AFFILIATIONS_COLUMNS,
  TABLE_NAME_SITE_AFFILIATION,
} from 'constants/constants';

import {
  exportData,
  getAffiliationsDataSite,
} from 'store/customer/customer-view-actions';
import {
  setAffLoader,
  setAffiliationsSiteData,
} from 'store/customer/customerViewSlice';
import AffiliationsFilters from './AffiliationsFilter';
import { getRestrictedColumn } from 'utils/utils';

const SiteAffiliations = ({
  columns,
  filterExp,
  enableTitle,
  corpParent,
  tableType,
}) => {
  const dispatch = useDispatch();
  const qlikToken = localStorage.getItem('qlikToken');
  const qlikCookie = localStorage.getItem('qlikCookie');
  const [filterExpression, setFilterExpression] = useState(filterExp);
  const flatAffiliationsData = useSelector(
    (state) => state?.CustomerView?.affiliationsSiteData
  );
  const isLoading = useSelector((state) => state?.CustomerView?.affLoader);
  const isExporting = useSelector(
    (state) => state?.CustomerView?.exportAffiliation
  );

  const hcpFlag = useSelector((state) => state?.CustomerView.hcpFlag);
  const group = {};
  const [searchedData, setSearchedData] = useState(
    flatAffiliationsData?.values
  );

  const selectedCustomer = useSelector(
    (state) => state?.CustomerView?.selectedCustomer
  );
  const affiliationExpression = useSelector(
    (state) => state?.CustomerView?.affiliationsExpression
  );
  const columnRestriction = useSelector(
    (state) => state?.CustomerView?.columnsRestiction
  );
  const account = {
    id: selectedCustomer?.value,
    name: selectedCustomer?.label,
  };
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const totalRecords =
    searchedData?.length >= 0 && searchedData?.length < 100
      ? searchedData?.length
      : 100;

  const [total, setTotal] = useState(totalRecords);

  let pivotAffiliationsData1 = flatAffiliationsData?.values && [
    ...flatAffiliationsData?.values,
  ];

  pivotAffiliationsData1?.forEach(
    ({
      siteId,
      siteName,
      siteAddress,
      siteCity,
      siteState,
      siteZip,
      ...rest
    }) => {
      group[siteId] = group[siteId] || {
        siteId,
        siteName,
        siteAddress,
        siteCity,
        siteState,
        siteZip,
        children: [],
      };
      group[siteId].children.push(rest);
    }
  );

  useEffect(() => {
    setSearchedData(flatAffiliationsData?.values);
  }, [flatAffiliationsData]);

  const pagination = {
    total: total,
    onChange: (page, size) => {
      setPageIndex(page);
      setPageSize(size);
    },
    pageSizeOptions: [10, 20, 30],
    showSizeChanger: true,
  };

  useEffect(() => {
    const isLastPage = pageIndex === Math.ceil(total / pageSize);
    const selectedPageIndex =
      pageIndex > 0 && Math.ceil((pageIndex * pageSize) / 100);

    if (isLastPage && total < flatAffiliationsData.totalSize) {
      dispatch(
        getAffiliationsDataSite(
          selectedCustomer?.value,
          selectedPageIndex,
          hcpFlag,
          qlikCookie,
          qlikToken,
          'hcpName',
          'ASC',
          filterExpression,
          corpParent
        )
      );
      setTotal(total + 100);
    }
  }, [pageIndex]);

  useEffect(() => {
    if (!selectedCustomer?.value) {
      dispatch(setAffLoader(false));
      setSearchedData([]);
      return;
    }

    if (selectedCustomer?.value && filterExpression) {
      dispatch(setAffiliationsSiteData({}));
      dispatch(
        getAffiliationsDataSite(
          selectedCustomer?.value,
          0,
          hcpFlag,
          qlikCookie,
          qlikToken,
          'hcpName',
          'ASC',
          filterExpression,
          corpParent
        )
      );
    }
  }, [selectedCustomer?.value, hcpFlag, filterExpression]);

  const handleSorting = (column, order) => {
    dispatch(
      getAffiliationsDataSite(
        selectedCustomer?.value,
        0,
        hcpFlag,
        qlikCookie,
        qlikToken,
        column,
        order,
        filterExpression,
        corpParent
      )
    );
  };

  const handleFilterApply = (filterExp) => {
    setFilterExpression(filterExp);
  };
  const downloadData = () => {
    dispatch(
      exportData(
        'SiteAffiliation',
        selectedCustomer?.value,
        qlikCookie,
        qlikToken,
        null,
        null,
        null,
        affiliationExpression
      )
    );
  };
  const size = { x: 1300, y: 400 };
  const exportTooltip = (
    <Tooltip bsclass='gne-tooltip tooltip--left'>Send to Excel</Tooltip>
  );

  return (
    <>
      <div id='SiteAffiliations'>
        {enableTitle && (
          <Fade>
            <Row className='mt-5'>
              <Col md={6}>
                <p className='title title__bold title__large'> Affiliations </p>
              </Col>
              <Col
                className='d-flex justify-content-end'
                md={6}>
                <OverlayTrigger
                  placement='top'
                  overlay={exportTooltip}>
                  <i
                    onClick={downloadData}
                    className={`icon icon__affiliations_export_excel1 mt-3`}
                  />
                </OverlayTrigger>
              </Col>
            </Row>
          </Fade>
        )}
        {!window?.location?.href.includes('customer-hierarchy') ? (
          <Row>
            <AffiliationsFilters
              site={account}
              applyFilter={(filterExp) => handleFilterApply(filterExp)}
            />
          </Row>
        ) : (
          ''
        )}
        <Row>
          <Spin spinning={isLoading || isExporting}>
            <ConfigurableTable
              rows={flatAffiliationsData?.values}
              columns={getRestrictedColumn(
                TABLE_NAME_SITE_AFFILIATION,
                columns || SITE_AFFILIATIONS_COLUMNS,
                columnRestriction
              )}
              expandRowByClick={false}
              enableColumnSelection={false}
              enableExport={false}
              exportFileName='affiliations'
              isGlobalExport={true}
              tableName={TABLE_NAME_SITE_AFFILIATION}
              pagination={pagination}
              isGlobalSort={true}
              onSort={handleSorting}
              tableType={tableType}
              dimentions={size}
            />
          </Spin>
        </Row>
      </div>
    </>
  );
};

export default SiteAffiliations;
