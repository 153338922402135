import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import LabelTags from '../label/LabelTags';

const LabelTagColumnRender = ({
  value,
  record,
  handleOnLabelClick,
  labelAccess,
  nodeActionList,
}) => {
  if (value?.length > 0) {
    const canEditLabel = nodeActionList?.find(
      (item) => item.value === 'assign'
    );
    if (
      // record?.class?.toLowerCase() !== ACCOUNT_TYPE.corp &&
      // record?.class?.toLowerCase() !== ACCOUNT_TYPE.os &&
      labelAccess &&
      canEditLabel &&
      record?.parentId
    ) {
      // view and assign
      return (
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip bsclass='gne-tooltip tooltip--left'>
              Click here to edit labels
            </Tooltip>
          }>
          <span onClick={() => handleOnLabelClick(record)}>
            <LabelTags
              record={value}
              showClose={false}
            />
          </span>
        </OverlayTrigger>
      );
    }
    if (labelAccess && record?.parentId) {
      // only for view
      return (
        <LabelTags
          record={value}
          showClose={false}
        />
      );
    }
  }
  return <></>;
};

export default LabelTagColumnRender;
