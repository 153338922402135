import React from 'react';

import classes from './Pill.module.scss';

const Pill = ({ value, handleClose }) => {
  return (
    <div className={`${classes['pill']}`}>
      <span>{value.label}</span>
      <span
        className={classes['close-button']}
        onClick={() => handleClose(value)}>
        x
      </span>
    </div>
  );
};
export default Pill;
