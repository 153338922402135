/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Col,
  Container,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import LinkButton from 'components/shared/button/LinkButton';

import Filters from '../filter/Filters';
import { addSites, getAccount, addLogs } from 'services/org/org.service';
import SiteHeirarchyTable from './SiteHeirarchyTable';
import { getSiteGridData } from 'services/org/label.service';

import {
  ACCOUNT_TYPE,
  ACTION_TYPE,
  ADD_SITE_SUBTEXT,
  NO_SITE_SELECTED,
  NO_SITE_SELECTED_SUBTEXT,
  optionsInitialState,
  LOGTYPE,
  CHANGEACTIONTYPE,
  CHANGEVALUETYPE,
  ORG_ACTIONS,
} from 'constants/constants';

const AddSitesLayout = (props) => {
  const {
    accountId,
    accountName,
    labelsData,
    page,
    onCallOrgChartApi,
    labelAccess,
  } = props;
  const [key, setKey] = useState('addSites');
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedRowAdd, setSelectedRowAdd] = useState({});
  const [selectedAccount, setSeletedAccount] = useState({});
  const [selectedOption, setSelectedOption] = useState(optionsInitialState);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [addTo, setaddTo] = useState(ACCOUNT_TYPE.site);
  const [addType, setaddType] = useState('add');
  const [text, setText] = useState('');
  const [sitesData, setSitesData] = useState([]);
  const [sitesDataTo, setSitesDataTo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [gridSize, setGridSize] = useState(0);
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(false);
  const maxLength = 1000;
  const { unixId } = useSelector((state) => state.Auth.userClaimObject);
  // eslint-disable-next-line no-unused-vars
  const [initialFilterValue, setInitialFilterValue] =
    useState(optionsInitialState);

  const handleRowSelectSite = (selectedRow) => {
    setSelectedRowAdd(selectedRow);
  };

  useEffect(() => {
    if (page === ACTION_TYPE.node) {
      setSelectedRowAdd(props?.nodeData);
    } else if (page === ACTION_TYPE.filter) {
      getAccountDetails();
    }
  }, []);

  useEffect(() => {
    isNextDisabled();
  }, [key, selectedRow, selectedRow, selectedRowAdd]);

  const getAccountDetails = async () => {
    const result = await getAccount(accountId);
    setSeletedAccount(result[0]);
  };

  const handleRowSelect = (record, type) => {
    if (type === 'checkbox') {
      const isSelected = selectedRow.some((row) => row.key === record.key);
      let newSelectedRows;
      if (isSelected) {
        newSelectedRows = selectedRow.filter((row) => row.key !== record.key);
      } else {
        newSelectedRows = [...selectedRow, record];
      }

      setSelectedRow(newSelectedRows);
    }
  };

  const handleClearAll = () => {
    setSelectedRow([]);
  };

  const onhandleAddTo = (value) => {
    setaddTo(value);
  };

  const onhandleAddType = (value) => {
    setaddType(value);
    setSitesData([]);
  };

  const onhandleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxLength) {
      setText(inputValue);
    }
  };

  const resetOptions = () => {
    setSelectedOption(optionsInitialState);
  };

  const handleFilterApply = async (filters) => {
    setIsLoading(true);
    setSelectedFilter(filters);
    const payload = {
      filters: {
        ...filters,
        customerId: accountId,
        unixId: unixId,
        fieldName: 'sites',
        offset: 0,
        action: key === ACTION_TYPE.addSite ? addType : ACTION_TYPE.remove,
        cpMdmId: accountId,
      },
    };
    const data = await getSiteGridData(payload);
    if (key === 'addSites') {
      setSelectedRow([]);
      setSitesData(data?.result?.values);
    } else if (key === 'moveSite') {
      const response = data?.result?.values;
      // Extract childMdmId values from selectedrow i
      const childMdmIdsInArray = new Set(
        selectedRow?.map((item) => item?.childMdmId)
      );

      // Filter array2 to remove items with childMdmId values present in selected row
      const filteredArray = response?.filter(
        (item) => !childMdmIdsInArray.has(item?.childMdmId)
      );

      setSitesDataTo(filteredArray);
    }
    setGridSize(data?.result?.currentListSize);
    setIsLoading(false);
  };

  const createPayloadGrid = () => {
    return {
      filters: {
        ...selectedFilter,
        customerId: accountId,
        unixId: unixId,
        fieldName: 'sites',
        offset: 0,
        action: addType,
        cpMdmId: accountId,
      },
    };
  };

  const handleFilterSelect = (selectedFilter) => {
    // setFilterCount((current) => [...current, selectedFilter]);
  };

  const addActionLog = async (addTo) => {
    const payload = selectedRow?.map((item) => ({
      LogType: LOGTYPE.userlog,
      ViewChangeType: CHANGEACTIONTYPE.hierarchy,
      ImpactedMdmId: accountId,
      ValueType: CHANGEVALUETYPE.parent,
      OldValue: '',
      NewValue: item?.key,
      Action: 'add',
      Flag: '',
      isActive: 'Y',
      ActionDetails: `added ${item?.name}(${item?.childMdmId}) to ${
        addTo === ACCOUNT_TYPE.account
          ? `${accountName}  (${accountId})`
          : `${selectedRowAdd.name} (${selectedRowAdd.childMdmId})}`
      }`,
      unixId: unixId,
      customerId: accountId,
    }));
    await addLogs(payload);
  };

  function createPayLoad(
    addTo,
    addType,
    selectedRow,
    selectedRowAdd,
    text,
    unixId
  ) {
    const outputArray = [];
    selectedRow.forEach((selectedRow) => {
      const { childMdmId } = selectedRow;
      const output = {
        childMdmId,
        hcosParentMdmId: selectedRow?.hcosParentMdmId || '',
        hcosChildLevel: selectedRow?.hcosChildLevel || '',
        hcosParentLevel: selectedRow?.hcosParentLevel || '',
        userParentMdmId:
          addTo === ACCOUNT_TYPE.account && page === ACTION_TYPE.filter
            ? accountId
            : selectedRowAdd?.childMdmId || '',
        userChildLevel:
          addTo === ACCOUNT_TYPE.account && page === ACTION_TYPE.filter
            ? selectedAccount.UserChildLevel + 1
            : selectedRowAdd.userChildLevel + 1,
        userParentLevel:
          addTo === ACCOUNT_TYPE.account && page === ACTION_TYPE.filter
            ? selectedAccount.UserChildLevel
            : selectedRowAdd.userChildLevel,
        sharedSite: addType === ACCOUNT_TYPE.shared ? 'Y' : 'N',
        isActive: 'Y',
        unixId: unixId,
        reason: text,
        cpMdmId: props?.nodeData?.cpMdmId,
      };
      outputArray.push(output);
    });

    return outputArray;
  }

  const handleApply = async (selectedRow, selectedRowAdd, text) => {
    const payload = createPayLoad(
      addTo,
      addType,
      selectedRow,
      selectedRowAdd,
      text,
      unixId,
      'N'
    );
    const name = Object.keys(selectedRowAdd).length
      ? selectedRowAdd?.name
      : accountName;
    if (payload?.length > 0) {
      await addSites(payload, addType, selectedRow, name);
      resetSelections();
      setIsLoading(true);
      const payloadGrid = createPayloadGrid();
      const data = await getSiteGridData(payloadGrid);
      setSitesData(data?.result?.values);
      setGridSize(data?.result?.currentListSize);
      setIsLoading(false);
      onCallOrgChartApi();
      addActionLog(addTo);
    }
  };
  const resetSelections = () => {
    setKey('addSites');
    setText('');
    setSitesData([]);
    setSitesDataTo([]);
    setSelectedRow([]);
    setSelectedRowAdd({});
  };

  const tooltip = () => {
    return (
      <Tooltip bsclass='gne-tooltip tooltip--left'>
        {selectedRow?.length > 0
          ? selectedRow?.map((item) => {
              return <p className='text text__x-small mb-1'>{item?.name}</p>;
            })
          : 'No Sites Selected'}
      </Tooltip>
    );
  };

  const onCallNext = (key) => {
    let updatedNext = '';
    if (page !== ACTION_TYPE.node) {
      updatedNext =
        key === ACTION_TYPE.addSite ? ACTION_TYPE.moveSite : ACTION_TYPE.reason;
    } else {
      updatedNext = ACTION_TYPE.reason;
    }
    setKey(updatedNext);
  };

  const onCallPrevious = (key) => {
    let updatedPrevious = '';
    if (page !== ACTION_TYPE.node) {
      updatedPrevious =
        key === ACTION_TYPE.reason ? ACTION_TYPE.moveSite : ACTION_TYPE.addSite;
    } else {
      updatedPrevious = ACTION_TYPE.addSite;
    }
    setKey(updatedPrevious);
  };

  const isNextDisabled = () => {
    if (page !== ACTION_TYPE.node) {
      setIsNextBtnDisabled(
        (key === ACTION_TYPE.addSite &&
          Object.keys(selectedRow).length === 0) ||
          (key === ACTION_TYPE.moveSite &&
            Object.keys(selectedRowAdd).length === 0)
      );
    } else {
      setIsNextBtnDisabled(selectedRow.length === 0);
    }
  };

  return (
    <Modal
      {...props}
      size='xl'
      className='title gne-modal'>
      <Modal.Header
        closeButton
        className={`modal-header border-0 ps-0`}>
        <Modal.Title
          id='contained-modal-title-vcenter'
          className='w-100'>
          <Row className='justify-content-between align-items-center mb-2'>
            <Col className='pe-0'>
              <div className='d-flex align-items-center'>
                <i className='icon icon__add-big'></i>
                <span className='title__medium'>Add Sites</span>
              </div>
              <p className='title__small'>{ADD_SITE_SUBTEXT}</p>
            </Col>

            <Col className='ps-0'>
              {/* target sites */}
              <div
                className={`site-tag selectionLayout ${
                  selectedRow.length > 0 ? 'selected' : 'no-selection-site-tag'
                }`}>
                <div>
                  Selected Site(s) :
                  <span className={'selected-site-wrap'}>
                    {selectedRow.length === 0
                      ? ' No Site Selected '
                      : `${selectedRow.length} sites selected`}
                  </span>
                </div>
                {selectedRow.length > 0 && (
                  <OverlayTrigger
                    overlay={tooltip()}
                    placement='bottom'>
                    <span className={`d-inline-block ${'info-icon'}`}>
                      <i className='icon icon__info__gray'></i>
                    </span>
                  </OverlayTrigger>
                )}
                <span
                  onClick={handleClearAll}
                  disabled={selectedRow.length === 0}
                  className={`title__${
                    selectedRow.length === 0 ? 'grey' : 'blue'
                  }`}>
                  Clear
                </span>
              </div>
            </Col>

            <Col>
              {/* destination site */}
              <div
                className={`site-tag ${
                  selectedRowAdd?.name?.length > 0
                    ? 'selected'
                    : 'no-selection-site-tag'
                }`}>
                <span className='selectionLayout'>
                  Add To :{' '}
                  {page === ACTION_TYPE.node ? (
                    selectedRowAdd?.name
                  ) : (
                    <span className='selected-site-wrap'>
                      {(key === ACTION_TYPE.moveSite ||
                        key === ACTION_TYPE.reason) &&
                      addTo === ACCOUNT_TYPE.account
                        ? accountName
                        : selectedRowAdd?.childMdmId?.length > 0
                        ? selectedRowAdd?.name
                        : 'No Site Selected'}
                    </span>
                  )}
                </span>
              </div>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className='show-grid p-0 m-0'>
        <Container className='p-0 gne-container'>
          <div className='button-wrapper'>
            {/* previous button */}
            <Button
              variant={`btn btn-gne btn-primary me-2 ml-2`}
              disabled={key === ACTION_TYPE.addSite}
              onClick={() => onCallPrevious(key)}
              className='previous-button-styles'>
              Previous
            </Button>

            {key === ACTION_TYPE.reason ? (
              // apply button
              <Button
                variant={`btn btn-gne btn-primary me-2 ml-2 `}
                disabled={text.length < 1}
                onClick={() => handleApply(selectedRow, selectedRowAdd, text)}>
                Apply
              </Button>
            ) : (
              // next button
              <Button
                variant={`btn btn-gne btn-primary me-2 ml-2`}
                disabled={isNextBtnDisabled}
                onClick={() => onCallNext(key)}>
                Next
              </Button>
            )}
          </div>

          <Tabs
            id='customeHierarchyActions'
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className='mb-3'>
            <Tab
              eventKey={ACTION_TYPE.addSite}
              title={<span className='d-flex'>1. Find Site(s)</span>}>
              <Row>
                {/* Gross sale left panel */}
                <Col
                  md={4}
                  xs={5}
                  lg={4}
                  className='library-container'>
                  {key === ACTION_TYPE.addSite && (
                    <Filters
                      accountId={accountId}
                      onFilterSelected={(selectedFilter) =>
                        handleFilterSelect(selectedFilter)
                      }
                      onFilterApply={(filters, addTo, addType) =>
                        handleFilterApply(filters, addTo, addType)
                      }
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                      resetOptions={resetOptions}
                      page={ACTION_TYPE.filter}
                      pageType={'add'}
                      selectedType={addType}
                      onhandleAddType={onhandleAddType}
                      labelsData={labelsData}
                      action={addType}
                      unixId={unixId}
                      initialFilterValue={initialFilterValue}
                      labelAccess={labelAccess}
                      classType={undefined}
                    />
                  )}
                </Col>

                {/* Col for base filter and chart */}
                {sitesData.length > 0 ? (
                  <Col>
                    <Spin spinning={isLoading}>
                      <Col>
                        <SiteHeirarchyTable
                          gridData={sitesData}
                          type={'checkbox'}
                          handleRowSelect={handleRowSelect}
                          selectedRow={selectedRow}
                          page='add'
                          total={gridSize}>
                          {' '}
                        </SiteHeirarchyTable>
                      </Col>
                    </Spin>
                  </Col>
                ) : (
                  <Col>
                    <Spin spinning={isLoading}>
                      <div className='m-8 message-align-wrap'>
                        <p className='default-message'>No Site Selected</p>
                        <p>Please use the filters to view sites</p>
                      </div>
                    </Spin>
                  </Col>
                )}
              </Row>
            </Tab>
            {page !== ACTION_TYPE.node && (
              <Tab
                eventKey={ACTION_TYPE.moveSite}
                disabled={selectedRow.length === 0}
                title={
                  <span className='d-flex'>2. Add Selected Site(s) To</span>
                }>
                <Row>
                  {/* Gross sale left panel */}
                  <Col
                    md={4}
                    xs={5}
                    lg={4}
                    className='library-container'>
                    {key === ACTION_TYPE.moveSite && (
                      <Filters
                        accountId={accountId}
                        onFilterSelected={(selectedFilter) =>
                          handleFilterSelect(selectedFilter)
                        }
                        onFilterApply={(filters) => handleFilterApply(filters)}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        resetOptions={resetOptions}
                        page={ACTION_TYPE.filter}
                        pageType={'addTo'}
                        addToType={'addTo'}
                        selectedType={addType}
                        onhandleAddTo={onhandleAddTo}
                        labelsData={labelsData}
                        action={ORG_ACTIONS.filter}
                        unixId={unixId}
                        initialFilterValue={initialFilterValue}
                        labelAccess={labelAccess}
                        classType={undefined}
                      />
                    )}
                  </Col>

                  {/* Col for base filter and chart */}
                  <Col>
                    {addTo === ACCOUNT_TYPE.account ? (
                      <div className='m-8 message-align-wrap-account'>
                        {`Sites can only move to ${accountName} when
                        account is selected.`}
                      </div>
                    ) : (
                      <div>
                        {sitesDataTo.length > 0 ? (
                          <Col>
                            <Spin spinning={isLoading}>
                              <Col>
                                <SiteHeirarchyTable
                                  gridData={sitesDataTo}
                                  type={'radio'}
                                  handleRowSelect={handleRowSelectSite}
                                  selectedRow={selectedRowAdd}
                                  page='add'
                                  total={gridSize}>
                                  {' '}
                                </SiteHeirarchyTable>
                              </Col>
                            </Spin>
                          </Col>
                        ) : (
                          <Col>
                            <Spin spinning={isLoading}>
                              <div className='m-8 message-align-wrap'>
                                <p className='default-message'>
                                  {NO_SITE_SELECTED}
                                </p>
                                <p>{NO_SITE_SELECTED_SUBTEXT}</p>
                              </div>
                            </Spin>
                          </Col>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              </Tab>
            )}

            <Tab
              eventKey='reason'
              disabled={
                page === ACTION_TYPE.node
                  ? selectedRow.length === 0
                  : selectedRow.length === 0 ||
                    (addTo === ACCOUNT_TYPE.account && key === 'addSites') ||
                    (addTo === ACCOUNT_TYPE.site &&
                      Object.keys(selectedRowAdd).length === 0)
              }
              title={
                page !== ACTION_TYPE.node ? (
                  <span className='d-flex'>3. Reason</span>
                ) : (
                  <span className='d-flex'>2. Reason</span>
                )
              }>
              <div className='reasonPanelHeight col-md-12'>
                <p>*Reason</p>
                <TextArea
                  rows={3}
                  maxLength={1000}
                  value={text}
                  className='label-editinput'
                  showCount
                  onChange={onhandleChange}
                />
              </div>
            </Tab>
          </Tabs>
        </Container>
      </Modal.Body>

      <Modal.Footer className='modal-footer'>
        <LinkButton
          onClick={props?.onHide}
          className='title title__small'>
          Close
        </LinkButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSitesLayout;
