import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import classes from './customerDropdown.module.scss';
import { CONFIGURE_VIEW } from 'constants/constants';
import { setAdvanceSearchSelectedData } from 'store/customer/customerViewSlice';
import { useSelector, useDispatch } from 'react-redux';

const Dropdownpill = ({ category, items, page }) => {
  const dispatch = useDispatch();
  const advanceSearchClicked = useSelector(
    (state) => state?.CustomerView?.advanceSearchClicked
  );
  const advanceSearchSelectedData = useSelector(
    (state) => state?.CustomerView?.advanceSearchSelectedData
  );
  const labels = items.map((item) => item.label).join(', ');
  const displayLabels =
    items.length > 2
      ? items
          .slice(0, 2)
          .map((item) => item.label)
          .join(', ') + '...'
      : labels;
  const showTooltip = displayLabels.endsWith('...');
  const renderTooltip = (props) => (
    <Tooltip
      id='button-tooltip'
      {...props}>
      {labels}
    </Tooltip>
  );
  const handleClose = (category) => {
    if (page === CONFIGURE_VIEW.copyInsight && category === 'customerType')
      return;
    const { [category]: _, ...newPillData } = advanceSearchSelectedData;
    dispatch(setAdvanceSearchSelectedData(newPillData));
  };
  return (
    <>
      {!advanceSearchClicked[page] && advanceSearchSelectedData ? (
        <div
          className={`${classes['pill']} ${
            page !== CONFIGURE_VIEW.trends && page !== CONFIGURE_VIEW.viewList
              ? classes['pill--newBg']
              : ''
          }`}>
          {showTooltip ? (
            <OverlayTrigger
              placement='bottom'
              overlay={renderTooltip}>
              <div
                className={`${classes['pill-label']} text text__capitalize ${
                  page === CONFIGURE_VIEW.copyInsight &&
                  category === 'customerType'
                    ? 'text__grey'
                    : ''
                }`}>
                {displayLabels}
                <span
                  className={classes['close-button']}
                  onClick={() => handleClose(category)}>
                  x
                </span>
              </div>
            </OverlayTrigger>
          ) : (
            <div
              className={`${classes['pill-label']} 'text text__capitalize ${
                page === CONFIGURE_VIEW.copyInsight &&
                category === 'customerType'
                  ? 'text__grey'
                  : ''
              }`}>
              {displayLabels}
              <span
                className={classes['close-button']}
                onClick={() => handleClose(category)}>
                x
              </span>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};
export default Dropdownpill;
