const {
  COPAY_ANALYTICS_FRM_COLUMNS,
  COPAY_CONSTANT,
} = require('constants/constants');
const { default: CopayAnalytics } = require('./CopayAnalytics');

const CopayFRMView = () => {
  return (
    <CopayAnalytics
      copayColumns={COPAY_ANALYTICS_FRM_COLUMNS}
      copayMeasure={COPAY_CONSTANT.FRM_VIEW_MEASURE}
      copayView={'FRMView'}></CopayAnalytics>
  );
};

export default CopayFRMView;
