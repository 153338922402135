import axiosDBClient from 'axiosDBClient';
import { toast } from 'react-toastify';

export const getLabels = async (customerId) => {
  const response = await axiosDBClient.get(`org/labels`, {
    params: { customerId },
  });
  if (response.error) {
    throw new Error('Could not fetch labels data!');
  }

  return response.data.result;
};

export const deleteLabels = async (customerId, labelId) => {
  const setDeleteLabel = async () => {
    const response = await axiosDBClient.delete(`org/labels`, {
      params: { customerId, labelId },
    });
    if (response.error) {
      throw new Error('Could not delete data!');
    }
    toast.success(response.data.message);
    return response.data;
  };
  try {
    const data = await setDeleteLabel();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const createLabel = async (payload) => {
  try {
    const response = await axiosDBClient.post('org/labels', {
      ...payload,
    });
    if (response.error) {
      throw new Error(response.error);
    }
    toast.success(response.data.message);
    return response.data.message;
  } catch (error) {
    console.log('error', error.message);
  }
};

export const updateLabel = async (payload) => {
  const updateLabel = async () => {
    const response = await axiosDBClient.put(`org/labels`, {
      ...payload,
    });
    toast.success(response.data.message);
    return response.data;
  };

  try {
    const data = await updateLabel();
    return data;
  } catch (error) {
    toast.error(error.message);
    console.log(error.message);
  }
};

export const getSiteGridData = async (payload) => {
  const response = await axiosDBClient.post(`org/sites/filter`, { ...payload });
  if (response.error) {
    throw new Error('Could not fetch labels data!');
  }

  return response.data;
};

export const assignLabels = async (payload, selectedSites = []) => {
  try {
    const response = await axiosDBClient.post('org/labels/assign', {
      payload,
    });
    if (response.error) {
      throw new Error(response.error);
    }
    toast.success(
      `Selected Labels are Assigned to ${selectedSites?.length ?? ''} Site(s) `
    );
    return response.data.message;
  } catch (error) {
    console.log('error', error.message);
  }
};
