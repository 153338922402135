import axiosDBClient from 'axiosDBClient';

export const getDocuments = async (unixId, roleId) => {
  try {
    const response = await axiosDBClient.get('document', {
      params: { unixId, roleId },
    });
    if (response.error) {
      throw new Error('Could not get documents!');
    }

    return response.data.result;
  } catch (error) {
    console.log('error', error);
  }
};

export const getDocumentCategories = async () => {
  try {
    const response = await axiosDBClient.get('document/category');
    if (response.error) {
      throw new Error('Could not get categories!');
    }
    return response.data.result;
  } catch (error) {
    console.log('error', error);
  }
};
