import { BENCHMARK_LEGEND_SECOND } from 'constants/constants';
import { isArray } from 'lodash';
import {
  findFirstMatchingValue,
  formatLabel,
  formatLabelWithoutKPI,
  numberToShortNumFormatter,
} from 'utils/utils';

export const chartOptions = (
  chartType,
  chartData,
  kpiName,
  radius,
  legend,
  hashView,
  view,
  contractDiscount,
  contractRebate,
  screenType
) => {
  const stackSeries = [];
  const totalBars = chartData?.values?.length;
  const horizantalStackChartTotalBars = chartData?.category?.length;
  const timeChartTotalBars =
    chartData?.values[0]?.data?.length + chartData?.values[1]?.data?.length;
  const visibleBars = 4;
  const end = (visibleBars / totalBars) * 100;
  const endGroupChartBars = (visibleBars / timeChartTotalBars) * 100;
  const endHorizantalStackChart =
    (visibleBars / horizantalStackChartTotalBars) * 100;
  const fontSize = 10;
  const barChartOptions = {
    title: {
      text: '',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      textStyle: {
        fontSize: 8,
      },
      // transitionDuration: 3,
      valueFormatter: (num) => formatLabel(num, kpiName),
    },
    legend: {},
    grid: {
      left: '1%',
      right: '20%',
      bottom: '1%',
      top: '5%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      show: false,
    },
    yAxis: {
      type: 'category',
      inverse: true,
      data: chartData?.category,
      axisLabel: {
        fontSize: fontSize,
        overflow: 'breakAll',
        width: 100,
      },
      axisTick: {
        show: false,
      },
    },
    series: [
      {
        type: 'bar',
        data: chartData?.values,
        label: {
          show: !hashView,
          position: 'right',
          fontSize: fontSize,
          formatter: (num) => formatLabel(num.data, kpiName),
        },
        itemStyle: {
          color: '#0B5A90',
          height: '48',
        },
      },
    ],
    dataZoom:
      totalBars > 5
        ? [
            {
              type: 'inside',
              yAxisIndex: 0,
              start: 0,
              end: end,
              handleSize: 5,
              zoomOnMouseWheel: false,
              moveOnMouseMove: true,
              moveOnMouseWheel: true,
              width: 5,
            },

            {
              type: 'slider',
              yAxisIndex: 0,
              start: 0,
              end: 5,
              width: 5,
              fillerColor: 'rgba(128,128,128,0.8)',
              handleStyle: {
                color: '#FFF',
                opacity: 0,
              },
              showDetail: false,
              moveHandleStyle: {
                color: '#FFF',
                opacity: 0,
              },
            },
          ]
        : [],
  };

  const lineChartOptions = {
    toolbox: {
      show: false,
      feature: {
        saveAsImage: {},
      },
      transitionDuration: 3,
    },
    title: {
      text: '',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      textStyle: {
        fontSize: 8,
      },
      valueFormatter: (num) => formatLabel(num, kpiName),
    },
    legend: {},
    grid: {
      left: '1%',
      right: '20%',
      bottom: '1%',
      top: '5%',
      containLabel: true,
    },
    yAxis: {
      type: 'value',
      data: chartData?.values,
      axisLabel: {
        formatter: (num) => formatLabel(num, kpiName),
        // color: '#120e06',
        fontSize: fontSize,
      },
      splitLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    xAxis: {
      type: 'category',
      data: chartData?.category,
      axisTick: {
        show: false,
      },
      axisLabel: {
        fontSize: fontSize,
      },
    },
    series: [
      {
        type: 'line',
        data: chartData?.values,
        symbol: 'circle',
        symbolSize: 8,
        label: {
          show: false,
          position: 'right',
          fontSize: fontSize,
          formatter: (num) => formatLabel(num.data, kpiName),
        },
        itemStyle: {
          color: '#0B5A90',
          height: '48',
        },
      },
    ],
  };

  const rebateOrDiscount = (params, list) => {
    if (list?.length === 0) return '-';

    let discount = findFirstMatchingValue(params.name, list);
    if (discount === null) return '-';
    const isNegative = discount < 0;
    const tempNumber = isNegative ? Math.abs(discount) : discount;
    let formattedNumber = numberToShortNumFormatter(tempNumber, 2);
    formattedNumber = isNegative ? `-${formattedNumber}` : formattedNumber;

    return `${'$' + formattedNumber}`;
  };

  const pieChartOptions = {
    title: {
      text: '',
    },
    tooltip: {
      trigger: 'item',
      confine: 'true',
      extraCssText: 'width:200px; white-space:pre-line; text-wrap:wrap ',
      textStyle: {
        fontSize: 8,
      },

      formatter:
        screenType !== 'site'
          ? null
          : function (params) {
              return `<span style="color: ${
                params.color
              };text-align: left; font-size: 35px; vertical-align: bottom; ">&#8226;</span>${
                params.name
              }
                                ${formatLabelWithoutKPI(
                                  params.value,
                                  '$',
                                  chartType,
                                  screenType
                                )}
                                Rebate: ${rebateOrDiscount(
                                  params,
                                  contractRebate
                                )}
                                Discount: ${rebateOrDiscount(
                                  params,
                                  contractDiscount
                                )}`;
            },
      valueFormatter:
        screenType === 'site'
          ? null
          : (num) => formatLabelWithoutKPI(num, '$', chartType, screenType),
    },
    legend: legend,
    series: [
      {
        type: 'pie',
        radius: radius,
        label: { show: false },
        data: chartData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };

  const horizantalStackChartOptions = {
    tooltip: {
      trigger: 'item',
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
      },
      transitionDuration: 3,
      // trigger: 'item',
      confine: 'true',
      // extraCssText: 'width:200px; white-space:pre-line; text-wrap:wrap ',
      textStyle: {
        fontSize: 8,
      },
      valueFormatter: (num) => formatLabel(num, kpiName),
      //valueFormatter: (num) => numberToShortNumFormatter(num, 2),
    },
    legend: {
      x: 'center',
      y: 'bottom',
      type: 'scroll',
      fontSize: fontSize,
      textStyle: {
        fontSize: 10,
      },
      itemWidth: 10,
      itemHeight: 10,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '12%',
      top: 0,
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01],
      axisLabel: {
        formatter: (num) => formatLabel(num, kpiName),
        color: '#120e06',
        fontSize: fontSize,
      },
      // axisLabel: {
      //   formatter: function (value) {
      //     return numberToShortNumFormatter(value, 2);
      //   },
      // },
    },
    yAxis: {
      type: 'category',
      inverse: true,
      data: chartData?.category,
      axisLabel: {
        fontSize: fontSize,
        overflow: 'breakAll',
        width: 100,
      },
      axisTick: {
        show: false,
      },
    },
    series: stackSeries,
    dataZoom:
      horizantalStackChartTotalBars > 5
        ? [
            {
              type: 'inside',
              yAxisIndex: 0,
              start: 0,
              end: endHorizantalStackChart,
              handleSize: 5,
              zoomOnMouseWheel: false,
              moveOnMouseMove: true,
              moveOnMouseWheel: true,
              width: 5,
            },

            {
              type: 'slider',
              yAxisIndex: 0,
              start: 0,
              end: 5,
              width: 5,
              fillerColor: 'rgba(128,128,128,0.8)',
              handleStyle: {
                color: '#FFF',
                opacity: 0,
              },
              showDetail: false,
              moveHandleStyle: {
                color: '#FFF',
                opacity: 0,
              },
            },
          ]
        : [],
  };

  const multiTimePeriodBarChartOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      textStyle: {
        fontSize: 8,
      },
      transitionDuration: 3,
      valueFormatter: (num) => formatLabel(num, kpiName),
    },
    legend: {
      x: 'center',
      y: 'bottom',
      type: 'scroll',
      fontSize: fontSize,
      padding: 0,
      borderRadius: 0,
      borderWidth: 0,
      textStyle: {
        fontSize: 10,
      },
      itemWidth: 10,
      itemHeight: 10,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '9%',
      top: 0,
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01],
      // axisLabel: {
      //   formatter: function (value) {
      //     return numberToShortNumFormatter(value, 2);
      //   },
      // },
      axisLabel: {
        formatter: (num) => formatLabel(num, kpiName),
        color: '#120e06',
        fontSize: fontSize,
      },
    },
    yAxis: {
      type: 'category',
      data: chartData?.category?.[0],
      inverse: true,
      axisLabel: {
        fontSize: fontSize,
        overflow: 'breakAll',
        width: 100,
      },
      axisTick: {
        show: false,
      },
    },
    //,
    series: [
      {
        name: chartData?.values[0]?.name,
        type: 'bar',
        data: chartData?.values[0]?.data,
      },
      {
        name: chartData?.values[1]?.name,
        type: 'bar',
        data: chartData?.values[1]?.data,
      },
    ],
    dataZoom:
      timeChartTotalBars > 5
        ? [
            {
              type: 'inside',
              yAxisIndex: 0,
              start: 0,
              end: endGroupChartBars,
              handleSize: 5,
              zoomOnMouseWheel: false,
              moveOnMouseMove: true,
              moveOnMouseWheel: true,
              width: 5,
            },

            {
              type: 'slider',
              yAxisIndex: 0,
              start: 0,
              end: 5,
              width: 5,
              fillerColor: 'rgba(128,128,128,0.8)',
              handleStyle: {
                color: '#FFF',
                opacity: 0,
              },
              showDetail: false,
              moveHandleStyle: {
                color: '#FFF',
                opacity: 0,
              },
            },
          ]
        : [],
  };
  const multiLineOptions = {
    tooltip: {
      trigger: 'item',
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow',
      },
      // trigger: 'item',
      confine: 'true',
      // extraCssText: 'width:200px; white-space:pre-line; text-wrap:wrap ',
      textStyle: {
        fontSize: 8,
      },
      transitionDuration: 3,
      valueFormatter: (num) => numberToShortNumFormatter(num, 2),
    },
    legend: {
      x: 'center',
      y: 'bottom',
      type: 'scroll',
      fontSize: fontSize,
      textStyle: {
        fontSize: 10,
      },
      itemWidth: 10,
      itemHeight: 10,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '12%',
      top: '4%',
      containLabel: true,
    },

    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: chartData?.category,
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: function (value) {
          return numberToShortNumFormatter(value, 2);
        },
      },
    },
    series: stackSeries,
    dataZoom:
      totalBars > 400
        ? [
            {
              type: 'inside',
              yAxisIndex: 0,
              start: 0,
              end: end,
              handleSize: 5,
              zoomOnMouseWheel: false,
              moveOnMouseMove: true,
              moveOnMouseWheel: true,
              width: 5,
            },

            {
              type: 'slider',
              yAxisIndex: 0,
              start: 0,
              end: 5,
              width: 5,
              fillerColor: 'rgba(128,128,128,0.8)',
              handleStyle: {
                color: '#FFF',
                opacity: 0,
              },
              showDetail: false,
              moveHandleStyle: {
                color: '#FFF',
                opacity: 0,
              },
            },
          ]
        : [],
  };
  switch (chartType) {
    case 'bar':
      return barChartOptions;
    case 'line':
      return lineChartOptions;
    case 'pie':
      return pieChartOptions;
    case 'payerPie':
      return pieChartOptions;
    case 'contractPie':
      return pieChartOptions;
    case 'stackedBar':
      isArray(chartData?.values) &&
        chartData?.values?.forEach((el) => {
          stackSeries.push({
            name: el?.name,
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series',
            },
            data: el?.data,
          });
        });
      return horizantalStackChartOptions;
    case 'multiTimePeriodBar':
      return multiTimePeriodBarChartOptions;
    case 'multiLine':
      chartData?.values?.forEach((el) => {
        stackSeries.push({
          name: el.name,
          type: 'line',
          label: {
            show: false,
          },
          emphasis: {
            focus: 'line',
          },

          lineStyle: {
            type: el.name === BENCHMARK_LEGEND_SECOND ? 'dashed' : undefined,
          },
          data: el.data,
        });
      });
      return multiLineOptions;

    default:
      return barChartOptions;
  }
};
