/* eslint-disable react-hooks/exhaustive-deps */
import { searchData } from 'utils/utils';
import ConfigurableTable from 'components/shared/table/ConfigurableTable';

import { useEffect, useState } from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Input } from 'antd';
import { useSelector } from 'react-redux';

import LinkButton from 'components/shared/button/LinkButton';
import { getDocuments } from 'services/document.service';
import { DOCUMENT_COLUMNS } from 'constants/constants';

const Documents = () => {
  const [documents, setDocuments] = useState([]);
  const [origalDocuments, setOriginalDocuments] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { unixId, roleId } = useSelector((state) => state.Auth.userClaimObject);
  const { Search } = Input;

  useEffect(() => {
    (async () => {
      // get the documents
      const docs = await getDocuments(unixId, roleId);
      setDocuments(docs);
      setOriginalDocuments(docs);
    })();
  }, []);

  const handleSearch = (value) => {
    const filteredData = searchData(origalDocuments, value);
    setSearchText(value);
    setDocuments(filteredData);
  };

  const handleReset = () => {
    setDocuments(origalDocuments);
    setSearchText('');
  };
  const emailClickHandler = async (record) => {
    window.open(
      'https://mail.google.com/mail/?view=cm&fs=1&to=' + record.PocEmail,
      '_blank',
      'noopener,noreferrer'
    );
  };

  const sortData = () => {};
  const size = { x: 800, y: 400 };
  const tooltip = () => {
    return (
      <Tooltip bsclass='gne-tooltip tooltip--email'>
        <span className='text text__x-small'>
          {`Request new reports under Help->Raise a Request. For outdated reports or specific questions about reports, contact the listed point of contact for clarification.`}
        </span>
      </Tooltip>
    );
  };

  return (
    <>
      <Row className='align-items-center m-4'>
        <Col sm={4}>
          <Search
            placeholder='Search'
            onSearch={handleSearch}
            allowClear
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onReset={handleReset}
            className='gne-search'
          />
        </Col>
        <Col sm={4}>
          <LinkButton
            className='title title__small'
            onClick={handleReset}>
            Clear Results
          </LinkButton>
        </Col>
        <Col
          sm={4}
          className='text-end'>
          <OverlayTrigger
            placement='bottom'
            overlay={tooltip()}>
            <i className={`icon icon__info_icon-yellow`} />
          </OverlayTrigger>
          <span className='text text__bold text__small mb-4 '>
            Missing Reports?
          </span>
        </Col>
      </Row>

      <div className='mt-4'>
        <ConfigurableTable
          rows={documents}
          columns={DOCUMENT_COLUMNS}
          expandRowByClick={false}
          enableColumnSelection={false}
          tableName='document'
          showActionIcon={true}
          onActionClickHandler={emailClickHandler}
          iconProps={{ iconCss: 'e-mail' }}
          pagination={false}
          isGlobalSort={true}
          onSort={sortData}
          dimentions={size}
        />
      </div>
    </>
  );
};

export default Documents;
