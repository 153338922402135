import { Carousel, Row, Col, Modal } from 'react-bootstrap';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Fade } from 'react-awesome-reveal';
import { OverlayTrigger } from 'react-bootstrap';
import classes from './Banner.module.scss';
import Chart from 'components/charts/Echart';
import LinkButton from 'components/shared/button/LinkButton';
import { Switch } from 'antd';
import { toast } from 'react-toastify';
import Contacts from './Contacts';
import {
  ACCOUNT_PAYER_APP_ID,
  Affiliated_Expression,
  BEAM_EXPRESSIONS_SITE,
  NULL_STRING,
  OS_FLAG,
  PATHWAY_URL,
  PAYER_EXPRESSIONS,
  areas,
  FLAG,
} from 'constants/constants';
import {
  roundToOne,
  isEmptyObject,
  getCustomerExpr,
  isArray,
} from 'utils/utils';
import { Spinner } from 'components/shared/loader/Spinner';

import {
  getAccountDetails,
  getBeamData,
  getCustomerContacts,
  getUses,
  getPayerPieData,
  getAffiliationToData,
  getBeamDataSite,
  getCRMResultApi,
} from 'store/customer/customer-view-actions';

import {
  setCRMScores,
  setBeamChart,
  setAccountDetails,
  setUses,
  setSelectedCustomer,
  setSeletedView,
  setBeamChartSite,
  setChartType,
  setAllAccountOptions,
  setFilterInit,
  setPayerTimeSpan,
  setFocusAccountDisabled,
  setPriorityCustomers,
} from 'store/customer/customerViewSlice';

import {
  CRM_EXPRESSIONS,
  BEAM_EXPRESSIONS,
  USES,
  REPLACE_STRING,
  QLIK_DATATYPE,
} from 'constants/constants';

import { Link, useNavigate } from 'react-router-dom';
import { getQlikSenseData } from 'services/qlik.service';
import Popover from 'react-bootstrap/Popover';
import { setFocusAccountChecked } from 'store/customer/customerViewSlice';
import {
  addMemberToTeam,
  deleteAccount,
} from 'services/configure-priority.service';
import { setEngagementDateInit } from 'store/customer/filter/filter-slice';

const Banner = (props) => {
  const view = props.data;
  const dispatch = useDispatch();
  const allAccountOptions = useSelector(
    (state) => state?.CustomerView?.allAccountOptions
  );

  const accountDetails = useSelector(
    (state) => state?.CustomerView?.accountDetails
  );
  const priorityCustomers = useSelector(
    (state) => state?.CustomerView?.priorityCustomers
  );
  const componentAccessList = useSelector(
    (state) => state?.Auth?.componentAccessList
  );
  const beamChart = useSelector((state) => state?.CustomerView?.beamChart);
  const beamChartSite = useSelector(
    (state) => state?.CustomerView?.beamChartSite
  );
  const payerChart = useSelector((state) => state?.CustomerView?.payerChart);
  const uses = useSelector((state) => state?.CustomerView?.uses);
  const crmQuarter = useSelector((state) => state?.CustomerView?.crmQuarter);
  const crmScores = useSelector((state) => state?.CustomerView?.crmScores);
  const crmPercent = useSelector((state) => state?.CustomerView?.crmPercent);
  const surveys = useSelector((state) => state?.CustomerView?.surveyTotal);
  const affiliated = useSelector((state) => state?.CustomerView?.affiliated);

  const selectedCustomer = useSelector(
    (state) => state?.CustomerView?.selectedCustomer
  );
  const contacts = useSelector((state) => state?.CustomerView?.contacts);
  const [show, setShow] = useState(false);
  const showSiteChart = beamChartSite?.contractSegment?.length > 0;
  const showPayerChart = payerChart?.payerMix?.length > 0;
  // eslint-disable-next-line
  const [lineChart, setLineChart] = useState([]);
  // eslint-disable-next-line
  const [dataModal, setDataModal] = useState(false);
  const [selectOptions, setOptions] = useState('MEDICAL BENEFIT');
  const [selectChartView, setSelectedChartView] = useState('contract');
  const [veevaUrl, setVeevaUrl] = useState('');
  const firstloadRef = useRef(true);
  const showChart =
    selectChartView === 'contract'
      ? beamChart?.contractSegment?.length > 0
      : showPayerChart;
  const [loading, setLoading] = useState(false);
  const handleOptionChange = (e) => {
    setOptions(e?.target?.value);
  };
  const focussedAccount = useSelector(
    (state) => state?.CustomerView?.focusAccountChecked
  );
  const payertimeSpan = useSelector(
    (state) => state?.CustomerView?.payertimeSpan
  );

  const [checkedSwitch, setCheckedSwitch] = useState(focussedAccount);
  const { unixId, roleId } = useSelector((state) => state.Auth.userClaimObject);
  const selectedView = useSelector(
    (state) => state?.CustomerView?.selectedView
  );
  const navigation = useNavigate();

  const onToggleChange = async (checked) => {
    setLoading(true);
    try {
      if (checked) {
        const payload = {
          unixId: unixId,
          customerId: selectedCustomer?.value,
          primaryFlag: 'N',
          squad: null,
          roleId,
        };

        const response = await addMemberToTeam(payload);
        if (response.error) {
          throw new Error(response.error);
        }
        dispatch(
          setAllAccountOptions([...allAccountOptions, selectedCustomer])
        );
        dispatch(
          setPriorityCustomers([...priorityCustomers, selectedCustomer])
        );
        dispatch(getCustomerContacts(selectedCustomer?.value));
        setCheckedSwitch(true);
        dispatch(setFocusAccountChecked(true));
        dispatch(setFocusAccountDisabled(false));
        toast.success(
          `${selectedCustomer?.label}  has been successfully added as your priority ${selectedView}.`
        );
      } else {
        const response = await deleteAccount(
          selectedCustomer?.value,
          unixId,
          roleId
        );
        if (response.error) {
          throw new Error(response.error);
        }
        dispatch(getCustomerContacts(selectedCustomer?.value));
        setCheckedSwitch(false);
        dispatch(setFocusAccountChecked(false));
        const filterAllAccounts = allAccountOptions.filter(
          (focusAccount) => focusAccount.value !== selectedCustomer?.value
        );
        dispatch(setAllAccountOptions(filterAllAccounts));
        dispatch(setPriorityCustomers(filterAllAccounts));
        dispatch(setFocusAccountDisabled(!(filterAllAccounts.length > 0)));
        toast.success(
          `${selectedCustomer?.label}  has been successfully removed as your priority  ${selectedView}.`
        );
      }
    } catch (error) {
      toast.error('Operation failed.');
      setCheckedSwitch(!checked);
    } finally {
      setLoading(false);
    }
  };

  const getUserView = () => {
    if (componentAccessList?.length === 0) {
      return 'noaccess';
    }
    if (view === 'site') {
      if (
        componentAccessList?.includes('Banner-Contract-Pie') &&
        componentAccessList?.includes('Banner-Payer-Pie')
      ) {
        return 'allaccess';
      } else if (
        componentAccessList?.includes('Banner-Payer-Pie') &&
        !componentAccessList?.includes('Banner-Contract-Pie')
      ) {
        return 'payeraccess';
      } else if (
        componentAccessList?.includes('Banner-Contract-Pie') &&
        !componentAccessList?.includes('Banner-Payer-Pie')
      ) {
        return 'contractaccess';
      }
    } else if (view === 'account') {
      if (
        componentAccessList?.includes('Banner-Contract-Pie') &&
        componentAccessList?.includes('Banner-Payer-Account-Pie')
      ) {
        return 'allaccess';
      } else if (
        componentAccessList?.includes('Banner-Payer-Account-Pie') &&
        !componentAccessList?.includes('Banner-Contract-Pie')
      ) {
        return 'payeraccess';
      } else if (
        componentAccessList?.includes('Banner-Contract-Pie') &&
        !componentAccessList?.includes('Banner-Payer-Account-Pie')
      ) {
        return 'contractaccess';
      }
    }
  };
  // eslint-disable-next-line
  const [accessView, setAccessView] = useState(getUserView());

  const getTimePeriodData = async () => {
    let dateSlotRes = await getQlikSenseData(
      PAYER_EXPRESSIONS.AccountPayer.tableTimeSoltExpression,
      [],
      ACCOUNT_PAYER_APP_ID
    );
    dispatch(setPayerTimeSpan(dateSlotRes?.data?.data?.category[0]));
  };

  useEffect(() => {
    dispatch(setChartType());
    if (view === 'account') {
      getTimePeriodData();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      !Array.isArray(selectedCustomer) &&
      accountDetails?.zip &&
      (accessView === 'payeraccess' || selectChartView === 'payer')
    ) {
      //If Account View then load Data for Account Payer Mix and Site for Site Payer Mix
      const exp =
        view === 'account'
          ? getCustomerExpr(selectedCustomer, isArray(selectedCustomer))
          : `Zip = {${accountDetails?.zip}},BenefitType = {"${selectOptions}"}`;
      getPayer(exp);
    }
    // eslint-disable-next-line
  }, [selectOptions, accountDetails]);

  const onsegmentChange = (e) => {
    setSelectedChartView(e);
    if (e === 'payer' && firstloadRef.current) {
      const exp =
        view === 'account'
          ? getCustomerExpr(selectedCustomer, isArray(selectedCustomer))
          : `Zip = {${accountDetails?.zip}},BenefitType = {"${selectOptions}"}`;
      getPayer(exp);
      firstloadRef.current = false;
    } else if (e === 'contract') {
      const REPLACE_WITH = getCustomerExpr(
        selectedCustomer,
        isArray(selectedCustomer)
      );
      getBeamSite(REPLACE_WITH);
    }
  };

  const legend = {
    orient: 'vertical',
    align: 'left',
    bottom: -6,
    icon: 'circle',
    itemWidth: 9,
    left: 10,
    height: '120px',
    textStyle: {
      color: '#ffffff',
      fontSize: '9px',
    },
    formatter: function (name) {
      return `${name}`;
    },
  };

  const hasContacts = contacts?.length > 0;

  // when the user selects an account in the dropdown
  // we check of the there is a value
  // then we get all the data we need to display on the page
  useEffect(() => {
    if (!Array.isArray(selectedCustomer)) {
      setVeevaUrl(
        `${process.env.REACT_APP_VEEVA_APP_URL}${selectedCustomer?.veevaId}/view`
      );
      fetchData();
    }
    // eslint-disable-next-line
  }, [selectedCustomer?.value]);

  useEffect(() => {
    const checkPriority =
      contacts?.length > 0
        ? contacts?.some(
            (item) => item?.unixId === unixId && item.roleCode === roleId
          )
        : false;

    setCheckedSwitch(checkPriority);
    dispatch(setFocusAccountChecked(checkPriority));
    // eslint-disable-next-line
  }, [contacts]);

  useEffect(() => {
    firstloadRef.current = true;
  }, [selectedView]);

  /**
   * reset stores and get all the data for the banner
   * @param {*} account the selected account
   */

  const getexp = (data) => {
    return `Customer_Id={"${data?.value}"}`;
  };
  const fetchData = async () => {
    resetStoreValues();
    const REPLACE_WITH = getCustomerExpr(
      selectedCustomer,
      isArray(selectedCustomer)
    );
    const AFFILIATION_TO_REPLACE = getexp(selectedCustomer);

    dispatch(getAccountDetails(selectedCustomer?.value, REPLACE_WITH));
    dispatch(getCustomerContacts(selectedCustomer?.value));

    getCRMResult(REPLACE_WITH);
    getPathwayUses(REPLACE_WITH);

    if (view === 'site') {
      getBeamSite(REPLACE_WITH);
    } else getBeam(REPLACE_WITH);
    getAffilliated(AFFILIATION_TO_REPLACE);
  };

  const getCRMResult = async (REPLACE_WITH) => {
    const scoresExps =
      view === 'account'
        ? [
            CRM_EXPRESSIONS.nps.score.replace(REPLACE_STRING, REPLACE_WITH),
            CRM_EXPRESSIONS.sat.score.replace(REPLACE_STRING, REPLACE_WITH),
            CRM_EXPRESSIONS.partner.score.replace(REPLACE_STRING, REPLACE_WITH),
          ]
        : [
            CRM_EXPRESSIONS.nps_site.score.replace(
              REPLACE_STRING,
              REPLACE_WITH
            ),
            CRM_EXPRESSIONS.sat_site.score.replace(
              REPLACE_STRING,
              REPLACE_WITH
            ),
            CRM_EXPRESSIONS.partner_site.score.replace(
              REPLACE_STRING,
              REPLACE_WITH
            ),
          ];

    const crm_exp =
      view === 'account'
        ? CRM_EXPRESSIONS.nps.percent.replaceAll(REPLACE_STRING, REPLACE_WITH)
        : CRM_EXPRESSIONS.nps_site.percent.replaceAll(
            REPLACE_STRING,
            REPLACE_WITH
          );
    const sat_exp =
      view === 'account'
        ? CRM_EXPRESSIONS.sat.percent.replaceAll(REPLACE_STRING, REPLACE_WITH)
        : CRM_EXPRESSIONS.sat_site.percent.replaceAll(
            REPLACE_STRING,
            REPLACE_WITH
          );
    const partner_exp =
      view === 'account'
        ? CRM_EXPRESSIONS.partner.percent.replaceAll(
            REPLACE_STRING,
            REPLACE_WITH
          )
        : CRM_EXPRESSIONS.partner_site.percent.replaceAll(
            REPLACE_STRING,
            REPLACE_WITH
          );
    const npsTotal = CRM_EXPRESSIONS.surveys?.nps.replace(
      REPLACE_STRING,
      REPLACE_WITH
    );
    const satTotal = CRM_EXPRESSIONS.surveys?.sat.replace(
      REPLACE_STRING,
      REPLACE_WITH
    );
    const partnerTotal = CRM_EXPRESSIONS.surveys?.partner.replace(
      REPLACE_STRING,
      REPLACE_WITH
    );
    scoresExps.push(
      crm_exp,
      sat_exp,
      partner_exp,
      CRM_EXPRESSIONS.month,
      npsTotal,
      satTotal,
      partnerTotal
    );
    dispatch(
      getCRMResultApi(scoresExps, QLIK_DATATYPE.single, CRM_EXPRESSIONS.appId)
    );
  };

  const getBeam = (REPLACE_WITH) => {
    const beamExps = [
      BEAM_EXPRESSIONS.contractSegment.expression.replace(
        REPLACE_STRING,
        REPLACE_WITH
      ),
      BEAM_EXPRESSIONS.quarter.replace(REPLACE_STRING, REPLACE_WITH),
    ];

    dispatch(
      getBeamData(
        beamExps,
        [BEAM_EXPRESSIONS.contractSegment.dimension, ''],
        [QLIK_DATATYPE.chartPie, QLIK_DATATYPE.single],
        BEAM_EXPRESSIONS.appId,
        [10, '']
      )
    );
  };

  const getBeamSite = (REPLACE_WITH) => {
    const beamExps = [
      BEAM_EXPRESSIONS_SITE.contractSegment?.expression?.replaceAll(
        REPLACE_STRING,
        REPLACE_WITH
      ),
      BEAM_EXPRESSIONS_SITE.contractSegmentRebate?.expression?.replaceAll(
        REPLACE_STRING,
        REPLACE_WITH
      ),
      BEAM_EXPRESSIONS_SITE.contractSegmentDiscount?.expression?.replaceAll(
        REPLACE_STRING,
        REPLACE_WITH
      ),
      BEAM_EXPRESSIONS_SITE.contractSegmentMembership?.expression?.replaceAll(
        REPLACE_STRING,
        REPLACE_WITH
      ),
      BEAM_EXPRESSIONS.quarter.replace(REPLACE_STRING, REPLACE_WITH),
    ];

    dispatch(
      getBeamDataSite(
        beamExps,
        [
          BEAM_EXPRESSIONS.contractSegment.dimension,
          BEAM_EXPRESSIONS.contractSegment.dimension,
          BEAM_EXPRESSIONS.contractSegment.dimension,
          BEAM_EXPRESSIONS.contractSegment.dimension,
          '',
        ],
        [
          QLIK_DATATYPE.chartPie,
          QLIK_DATATYPE.chartPie,
          QLIK_DATATYPE.chartPie,
          QLIK_DATATYPE.single,
          QLIK_DATATYPE.single,
        ],
        BEAM_EXPRESSIONS.appId,
        [10, '']
      )
    );
  };

  const getPayer = (REPLACE_WITH) => {
    const payerExp =
      view === 'account'
        ? PAYER_EXPRESSIONS.AccountPayer.expression.replaceAll(
            REPLACE_STRING,
            REPLACE_WITH
          )
        : PAYER_EXPRESSIONS.payerMix.expression.replaceAll(
            REPLACE_STRING,
            REPLACE_WITH
          );
    dispatch(
      getPayerPieData(
        payerExp,
        view === 'account'
          ? PAYER_EXPRESSIONS.AccountPayer.dimension
          : PAYER_EXPRESSIONS.payerMix.dimension,
        PAYER_EXPRESSIONS.dataType,
        view === 'account' ? ACCOUNT_PAYER_APP_ID : PAYER_EXPRESSIONS.appId,
        PAYER_EXPRESSIONS.limit,
        PAYER_EXPRESSIONS.other
      )
    );
  };

  const getPathwayUses = (REPLACE_WITH) => {
    dispatch(
      getUses(
        USES.expression.replace(REPLACE_STRING, REPLACE_WITH),
        USES.dimension,
        USES.appId,
        3
      )
    );
  };
  const qlikToken = localStorage.getItem('qlikToken');
  const qlikCookie = localStorage.getItem('qlikCookie');
  const getAffilliated = (AFFILIATION_TO_REPLACE) => {
    dispatch(
      getAffiliationToData(
        AFFILIATION_TO_REPLACE,
        selectedCustomer?.flag === OS_FLAG
          ? Affiliated_Expression?.OSAffilliated.dimension
          : Affiliated_Expression?.affilliated?.dimension,
        Affiliated_Expression?.appId,
        qlikCookie,
        qlikToken
      )
    );
  };

  const handleclick = (accountDetails) => {
    if (accountDetails) {
      const payload = {
        value: accountDetails?.accId,
        label: accountDetails?.accName,
        flag: FLAG.account,
      };
      dispatch(setFilterInit());
      dispatch(setEngagementDateInit());
      dispatch(setSeletedView('account'));
      dispatch(setSelectedCustomer(payload));
      navigation(`/customerView/list/${accountDetails?.accName}`);
    }
  };
  // reset the store values
  const resetStoreValues = () => {
    dispatch(setCRMScores({}));
    dispatch(setBeamChart({}));
    dispatch(setBeamChartSite({}));
    dispatch(setAccountDetails({}));
    dispatch(setUses(''));
    // dispatch(setQuarter(''));
  };
  // eslint-disable-next-line
  let isNonContractSegment = beamChart?.contractSegment?.some(
    (o) => o.name === 'NON-CONTRACTED*'
  );

  return (
    <Carousel>
      <Carousel.Item>
        {/* banner */}
        <section
          className={`${classes['banner-wrapper']} animate__animated animate__fadeIn animate__slower`}>
          <Row>
            <Col
              xs={12}
              md={8}
              xl={9}
              className='ps-0'>
              {/* title */}
              <Row>
                <Col className='ps-0'>
                  <Fade>
                    <p className='title__bold title__x-large title__capitalize mb-0'>
                      {accountDetails?.name?.length > 50 ? (
                        <OverlayTrigger
                          overlay={
                            <Popover className='p-2'>
                              {accountDetails?.name}
                            </Popover>
                          }
                          placement='top'>
                          <p className='title__bold title__x-large title__capitalize mb-0'>
                            {accountDetails?.name?.length > 50
                              ? `${accountDetails?.name
                                  ?.toLowerCase()
                                  .substring(0, 50)}...`
                              : accountDetails?.name?.toLowerCase()}
                            <i className='icon icon__veeva float-end'></i>
                          </p>
                        </OverlayTrigger>
                      ) : (
                        <>
                          {accountDetails?.name?.toLowerCase()}
                          {accountDetails?.name && (
                            <a
                              // className='title__blue'
                              href={veevaUrl}
                              target='_blank'
                              rel='noreferrer'>
                              <i className='icon icon__veeva'></i>
                            </a>
                          )}
                        </>
                      )}
                    </p>

                    {!accountDetails?.name && (
                      <span className='title__x-large'>{NULL_STRING}</span>
                    )}

                    <p className='text text__capitalize'>
                      {accountDetails?.classification?.toLowerCase()}
                    </p>

                    {!accountDetails?.classification && (
                      <span>{NULL_STRING}</span>
                    )}
                  </Fade>
                </Col>
              </Row>

              {/* address and relation */}
              <Row>
                {/* account info */}
                <Col className='d-flex ps-0'>
                  {/* address */}
                  <div className={classes['info-section']}>
                    <Fade cascade>
                      <p className='title__bold'>Address</p>
                      {!isEmptyObject(accountDetails) && (
                        <p className='w-75'>
                          {accountDetails?.address1} {accountDetails?.city},{' '}
                          {accountDetails?.state} {accountDetails?.zip}
                        </p>
                      )}

                      {isEmptyObject(accountDetails) && (
                        <span>{NULL_STRING}</span>
                      )}
                    </Fade>
                  </div>

                  {/* uses */}
                  <div className={classes['info-section']}>
                    {view === 'account' &&
                    selectedCustomer?.flag !== OS_FLAG ? (
                      <Fade>
                        <p className='title__bold'>Pathway</p>
                        {uses && (
                          <p className='mb-0'>
                            {/* Value Pathways <br /> powered by{' '} */}
                            <Link
                              to={PATHWAY_URL}
                              target='_blank'
                              className='text-white'>
                              {uses}{' '}
                              <i className='icon icon__external d-inline-block ms-1'></i>
                            </Link>
                          </p>
                        )}

                        {!uses && <span>{NULL_STRING}</span>}
                      </Fade>
                    ) : (
                      <Fade>
                        <p className='title__bold'>Affiliated To</p>
                        {affiliated?.accName && (
                          <span
                            className={`${classes['affaliated-to']} span-link affiliated-link`}
                            onClick={() => handleclick(affiliated)}>
                            {affiliated?.accName}
                          </span>
                        )}

                        {!affiliated?.accName && <span>{NULL_STRING}</span>}
                      </Fade>
                    )}
                  </div>
                </Col>

                {/* relationship */}
                <Col className={classes['info-container']}>
                  {/* nps score */}
                  <div className={classes['info-section']}>
                    <Fade cascade>
                      <p className={`${classes['figures']} title__bold`}>
                        <OverlayTrigger
                          overlay={
                            crmPercent?.nps > 0 ? (
                              <Popover className='p-2'>
                                The current rolling 6 months VoC score is higher
                                than that of the previous rolling 6 months.
                              </Popover>
                            ) : crmPercent?.nps < 0 ? (
                              <Popover className='p-2'>
                                The current rolling 6 months VoC score is lower
                                than that of the previous rolling 6 months.
                              </Popover>
                            ) : (
                              ''
                            )
                          }
                          placement='top'>
                          <i
                            className={`icon icon__arrow-${
                              crmPercent?.nps > 0
                                ? 'up'
                                : crmPercent?.nps < 0
                                ? 'down'
                                : ''
                            } me-1`}></i>
                        </OverlayTrigger>
                        <span className='title__bold title__medium'>
                          {roundToOne(crmScores?.nps)}
                        </span>
                        {/* <LinkButton
                          className='ms-2'
                          onClick={() => relationshipNpsClickHandler('nps')}>
                          <i className='icon icon__external'></i>
                        </LinkButton> */}
                        {/* <DataModal
                          component='RelationshipChart'
                          show={dataModal}
                          onHide={closeRelationshipNps}
                          data={lineChart}
                        /> */}
                      </p>
                      <OverlayTrigger
                        overlay={
                          <Popover className='p-2'>
                            Difference between % Promoters (rating 9-10) and %
                            Detractors (rating 0-6). An NPS of 60 is considered
                            excellent, while a score of 80 or higher is
                            considered world-class. Only applies to relationship
                            surveys.
                          </Popover>
                        }
                        placement='top'>
                        <p className='title title__bold'>
                          Relationship <br />
                          NPS
                        </p>
                      </OverlayTrigger>
                      <p>
                        {crmQuarter}{' '}
                        {surveys?.nps ? (
                          <OverlayTrigger
                            overlay={
                              <Popover className='p-2'>
                                Number of surveys within the rolling 6 months.
                              </Popover>
                            }
                            placement='top'>
                            <span>{'(#' + surveys?.nps + ')'}</span>
                          </OverlayTrigger>
                        ) : (
                          ''
                        )}
                      </p>
                    </Fade>

                    {!crmQuarter && !surveys?.nps && (
                      <Fade>
                        <span>{NULL_STRING}</span>
                        <Spinner
                          area={areas.bannerNPS}
                          css='inline'
                        />
                      </Fade>
                    )}
                  </div>

                  {/* sat score */}
                  <div className={classes['info-section']}>
                    <Fade cascade>
                      <p className={`${classes['figures']} title__bold `}>
                        <OverlayTrigger
                          overlay={
                            crmPercent?.sat > 0 ? (
                              <Popover className='p-2'>
                                The current rolling 6 months VoC score is higher
                                than that of the previous rolling 6 months.
                              </Popover>
                            ) : crmPercent?.sat < 0 ? (
                              <Popover className='p-2'>
                                The current rolling 6 months VoC score is lower
                                than that of the previous rolling 6 months.
                              </Popover>
                            ) : (
                              ''
                            )
                          }
                          placement='top'>
                          <i
                            className={`icon icon__arrow-${
                              crmPercent?.sat > 0
                                ? 'up'
                                : crmPercent?.sat < 0
                                ? 'down'
                                : ''
                            } me-1`}></i>
                        </OverlayTrigger>
                        <span className='title__bold title__medium'>
                          {roundToOne(crmScores?.sat)}
                        </span>
                        {/* <LinkButton
                          className='ms-2'
                          onClick={() => relationshipNpsClickHandler('sat')}>
                          <i className='icon icon__external'></i>
                        </LinkButton>
                        <DataModal
                          component='RelationshipChart'
                          show={dataModal}
                          onHide={closeRelationshipNps}
                          data={lineChart}
                        /> */}
                      </p>
                      <OverlayTrigger
                        overlay={
                          <Popover className='p-2'>
                            % of users whose responses are in Top 2 boxes
                            (ratings 9-10) . A score of 80 or higher is
                            considered excellent. Only applies to post
                            interaction surveys.
                          </Popover>
                        }
                        placement='top'>
                        <p className='title title__bold animate__animated animate__fadeIn'>
                          Post Interaction <br />
                          Overall Sat
                        </p>
                      </OverlayTrigger>
                      <p className='title title__small animate__animated animate__fadeIn'>
                        {crmQuarter}{' '}
                        {surveys?.sat ? '(#' + surveys?.sat + ')' : ''}
                      </p>
                    </Fade>

                    {!crmQuarter && !surveys?.sat && (
                      <Fade>
                        <span>{NULL_STRING}</span>
                        <Spinner
                          area={areas.bannerNPS}
                          css='inline'
                        />
                      </Fade>
                    )}
                  </div>

                  {/* trusted partner score */}
                  <div className={classes['info-section']}>
                    <Fade cascade>
                      <p
                        className={`${classes['figures']} title__bold title__medium d-flex animate__animated animate__fadeIn`}>
                        <OverlayTrigger
                          overlay={
                            crmPercent?.partner > 0 ? (
                              <Popover className='p-2'>
                                The current rolling 6 months VoC score is higher
                                than that of the previous rolling 6 months.
                              </Popover>
                            ) : crmPercent?.partner < 0 ? (
                              <Popover className='p-2'>
                                The current rolling 6 months VoC score is lower
                                than that of the previous rolling 6 months.
                              </Popover>
                            ) : (
                              ''
                            )
                          }
                          placement='top'>
                          <i
                            className={`icon icon__arrow-${
                              crmPercent?.partner > 0
                                ? 'up'
                                : crmPercent?.partner < 0
                                ? 'down'
                                : ''
                            } me-1`}></i>
                        </OverlayTrigger>
                        <span className='title__bold title__medium'>
                          {roundToOne(crmScores?.partner)}
                        </span>
                        {/* <LinkButton
                          className='ms-2'
                          onClick={() =>
                            relationshipNpsClickHandler('partner')
                          }>
                          <i className='icon icon__external'></i>
                        </LinkButton>
                        <DataModal
                          component='RelationshipChart'
                          show={dataModal}
                          onHide={closeRelationshipNps}
                          data={lineChart}
                        /> */}
                      </p>
                      <OverlayTrigger
                        overlay={
                          <Popover className='p-2'>
                            % of users whose responses are in Top 2 boxes
                            (ratings 9-10) . A score of 80 or higher is
                            considered excellent. Only applies to relationship
                            surveys.
                          </Popover>
                        }
                        placement='top'>
                        <p className='title title__bold animate__animated animate__fadeIn'>
                          Relationship <br />
                          Trusted Partner
                        </p>
                      </OverlayTrigger>
                      <p className='title title__small animate__animated animate__fadeIn'>
                        {crmQuarter}{' '}
                        {surveys?.partner ? (
                          <OverlayTrigger
                            overlay={
                              <Popover className='p-2'>
                                Number of surveys within the rolling 6 months.
                              </Popover>
                            }
                            placement='top'>
                            <span>{'(#' + surveys?.partner + ')'}</span>
                          </OverlayTrigger>
                        ) : (
                          ''
                        )}
                      </p>
                    </Fade>

                    {!crmQuarter && !surveys?.partner && (
                      <Fade>
                        <span>{NULL_STRING}</span>
                        <Spinner
                          area={areas.bannerNPS}
                          css='inline'
                        />
                      </Fade>
                    )}
                  </div>
                </Col>
              </Row>

              {/* contacts */}
              <Row className='mt-2'>
                <Fade className='ps-0'>
                  <div className='d-flex align-items-center mb-3'>
                    <p className='title__bold me-4 mb-0 animate__animated animate__fadeIn'>
                      Genentech Point of Contact
                    </p>

                    {hasContacts && (
                      <LinkButton
                        className={classes['link-cta']}
                        onClick={() => setShow(true)}>
                        <i className='icon icon__external'></i>
                        View All
                      </LinkButton>
                    )}

                    <Switch
                      key={checkedSwitch}
                      className='ms-3'
                      checkedChildren={
                        <i
                          className={`m-0 icon icon__white_primarycontact`}></i>
                      }
                      checked={checkedSwitch}
                      defaultChecked={focussedAccount}
                      onChange={onToggleChange}
                      size='medium'
                      loading={loading}
                    />
                    <span className='ms-2 text text__x-small'>
                      Set as Priority
                    </span>
                  </div>
                </Fade>
                <Fade className='ps-0'>
                  <Contacts
                    contacts={contacts}
                    splitIndex={3}
                    switch={checkedSwitch}
                  />
                </Fade>
              </Row>
            </Col>

            {/* contract segment */}
            <Col
              xs={12}
              md={4}
              xl={3}
              className='position-relative animate__animated animate__fadeIn pe-0'>
              <div>
                {accessView === 'payeraccess' && (
                  <div>
                    <Fade>
                      <p className='title__bold title__small text-center'>
                        Payer Mix{' '}
                        {view === 'site' ? accountDetails?.zip : payertimeSpan}
                      </p>
                    </Fade>
                    {view === 'site' && (
                      <div
                        className={`title__small ${classes['radio_selection']}`}>
                        <label className={classes['radio_button']}>
                          <input
                            type='radio'
                            name='bannerpayeroptions'
                            value='MEDICAL BENEFIT'
                            className={classes['radio_label']}
                            checked={selectOptions === 'MEDICAL BENEFIT'}
                            onChange={handleOptionChange}
                          />
                          Medical
                        </label>
                        <label className={classes['radio_button']}>
                          <input
                            type='radio'
                            name='bannerpayeroptions'
                            className={classes['radio_label']}
                            value='PHARMACY BENEFIT'
                            checked={selectOptions === 'PHARMACY BENEFIT'}
                            onChange={handleOptionChange}
                          />
                          Pharmacy
                        </label>
                      </div>
                    )}
                    {showPayerChart && (
                      <div>
                        {' '}
                        <div className={classes['chart-container']}>
                          <Chart
                            chartData={payerChart?.payerMix}
                            chartType={QLIK_DATATYPE.payerPie}
                            radius='30%'
                            legend={legend}
                            isMultiChart={true}
                            style={{
                              height: '300px',
                              width: '250px',
                              position: 'absolute',
                              top: '-100px',
                              overflow: 'hidden',
                            }}
                            view='payer'
                          />
                        </div>
                        {selectChartView !== 'contract' && (
                          <span className='d-flex text text__x-small float-end '>
                            {view === 'account' ? (
                              'Estimated Payer Contribution to Account'
                            ) : (
                              <span className='me-5'> % of lives covered</span>
                            )}
                          </span>
                        )}
                      </div>
                    )}
                    <Fade>
                      {!showPayerChart && (
                        <p className='text-center'>{NULL_STRING}</p>
                      )}
                      <Spinner area={areas.bannerSegment} />
                    </Fade>
                  </div>
                )}

                {accessView === 'contractaccess' && (
                  <div>
                    <Fade>
                      <p className='title__bold title__small text-center'>
                        Contract Segment(
                        {view === 'site'
                          ? beamChartSite?.quarter
                          : beamChart?.quarter}
                        )
                      </p>
                    </Fade>
                    {showSiteChart || showChart ? (
                      <div>
                        <div className={classes['chart-container']}>
                          <Chart
                            chartData={
                              view === 'site'
                                ? beamChartSite?.contractSegment
                                : beamChart?.contractSegment
                            }
                            chartType={QLIK_DATATYPE.contractPie}
                            radius='35%'
                            legend={legend}
                            isMultiChart={true}
                            style={{
                              height: '330px',
                              width: '100%',
                              position: 'absolute',
                              top: '-125px',
                              overflow: 'hidden',
                            }}
                            chartAllData={beamChartSite}
                            view='contract'
                            screenType={view}
                          />
                        </div>
                        {beamChartSite?.membership?.length > 0 && (
                          <div
                            className='title title__x-small'
                            style={{ position: 'relative', top: '230px' }}>
                            *<i>340B ({beamChartSite?.membership})</i>
                          </div>
                        )}
                        {isNonContractSegment && (
                          <div
                            className='title title__x-small'
                            style={{
                              position: 'relative',
                              top: '230px',
                            }}>
                            *<i>Non-contracted Sales are estimated</i>
                          </div>
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                    <Fade>
                      {!showChart && (
                        <p className='text-center'>{NULL_STRING}</p>
                      )}
                      <Spinner area={areas.bannerSegment} />
                    </Fade>
                  </div>
                )}

                {accessView === 'allaccess' && (
                  <div>
                    {' '}
                    <div
                      className={`${classes['header_allign']} d-flex flex-row`}>
                      <div
                        className={`title__bold title__x-small text-center ${classes['header_style']}`}
                        onClick={() => onsegmentChange('contract')}
                        style={
                          selectChartView === 'contract'
                            ? { borderBottom: '4px solid white' }
                            : {
                                borderBottom: '2px solid #a2c1d9',
                                color: '#a2c1d9',
                              }
                        }>
                        <div>Contract Segment</div>
                        <div>
                          (
                          {view === 'site'
                            ? beamChartSite?.quarter
                            : beamChart?.quarter}
                          )
                        </div>
                      </div>
                      <div
                        className={`title__bold title__x-small text-center ${classes['header_style']}`}
                        onClick={() => onsegmentChange('payer')}
                        style={
                          selectChartView === 'payer'
                            ? { borderBottom: '4px solid white' }
                            : {
                                borderBottom: '2px solid #a2c1d9',
                                color: '#a2c1d9',
                              }
                        }>
                        <div>Payer Mix</div>
                        <div>
                          {view === 'site'
                            ? 'Zip: ' + accountDetails?.zip
                            : payertimeSpan}
                        </div>
                      </div>
                    </div>
                    <div>
                      {selectChartView === 'contract' ? (
                        <div>
                          {showSiteChart || showChart ? (
                            <div className={classes['chart-container']}>
                              <Chart
                                chartData={
                                  view === 'site'
                                    ? beamChartSite?.contractSegment
                                    : beamChart?.contractSegment
                                }
                                chartType={QLIK_DATATYPE.contractPie}
                                radius='35%'
                                legend={legend}
                                isMultiChart={true}
                                style={{
                                  height: '330px',
                                  width: '100%',
                                  position: 'absolute',
                                  top: '-125px',
                                  overflow: 'hidden',
                                }}
                                chartAllData={beamChartSite}
                                view='contract'
                                screenType={view}
                              />
                              {beamChartSite?.membership?.length > 0 && (
                                <div
                                  className='title title__x-small'
                                  style={{
                                    position: 'relative',
                                    top: '230px',
                                  }}>
                                  *<i>340B ({beamChartSite?.membership})</i>
                                </div>
                              )}
                              {isNonContractSegment && (
                                <div
                                  className='title title__x-small'
                                  style={{
                                    position: 'relative',
                                    top: '230px',
                                  }}>
                                  *<i>Non-contracted Sales are estimated</i>
                                </div>
                              )}
                            </div>
                          ) : (
                            ''
                          )}
                          <Fade>
                            {!showSiteChart && !showChart ? (
                              <p className='text-center'>{NULL_STRING}</p>
                            ) : (
                              ''
                            )}
                            <Spinner area={areas.bannerSegment} />
                          </Fade>
                        </div>
                      ) : (
                        <div>
                          {showPayerChart && (
                            <div>
                              {view === 'site' && (
                                <div
                                  className={`title__small ${classes['radio_selection']}`}>
                                  <label className={classes['radio_button']}>
                                    <input
                                      type='radio'
                                      name='bannerpayeroptions'
                                      value='MEDICAL BENEFIT'
                                      className={classes['radio_label']}
                                      checked={
                                        selectOptions === 'MEDICAL BENEFIT'
                                      }
                                      onChange={handleOptionChange}
                                    />
                                    Medical
                                  </label>
                                  <label className={classes['radio_button']}>
                                    <input
                                      type='radio'
                                      name='bannerpayeroptions'
                                      className={classes['radio_label']}
                                      value='PHARMACY BENEFIT'
                                      checked={
                                        selectOptions === 'PHARMACY BENEFIT'
                                      }
                                      onChange={handleOptionChange}
                                    />
                                    Pharmacy
                                  </label>
                                </div>
                              )}
                              <div>
                                {' '}
                                <div className={classes['chart-container']}>
                                  <Chart
                                    chartData={payerChart?.payerMix}
                                    chartType={QLIK_DATATYPE.payerPie}
                                    radius='30%'
                                    legend={legend}
                                    isMultiChart={true}
                                    style={{
                                      height: '300px',
                                      width: '250px',
                                      position: 'absolute',
                                      top: '-100px',
                                      overflow: 'hidden',
                                    }}
                                    view='payer'
                                  />
                                </div>
                              </div>
                              {selectChartView === 'payer' && (
                                <div
                                  className='title title__x-small'
                                  style={{
                                    position: 'relative',
                                    top: '230px',
                                  }}>
                                  <i>
                                    {view === 'account'
                                      ? 'Estimated Payer Contribution to Account'
                                      : '% of lives covered'}
                                  </i>
                                </div>
                              )}
                            </div>
                          )}
                          <Fade>
                            {!showPayerChart && (
                              <p className='text-center'>{NULL_STRING}</p>
                            )}
                            <Spinner area={areas.bannerSegment} />
                          </Fade>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {accessView === 'noaccess' && (
                  <Fade>{<p className='text-center'>{}</p>}</Fade>
                )}
              </div>
            </Col>
          </Row>
        </section>

        {/* contact modal */}
        <Modal
          size='lg'
          className='title gne-modal contact'
          show={show}
          onHide={() => setShow(false)}>
          <Modal.Header
            className={`me-3`}
            closeButton>
            <Modal.Title>
              <p className='title__bold title__base animate__animated animate__fadeIn'>
                <i className='icon icon__user'></i>
                Genentech Point of Contact
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='overflow-y-auto'>
            <Contacts contacts={contacts} />
          </Modal.Body>
        </Modal>
      </Carousel.Item>
    </Carousel>
  );
};

export default Banner;
