import React from 'react';

import classes from 'components/shared/pills/Pill.module.scss';
import { ENGAGEMENT_TYPE } from 'constants/constants';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const EngagementPill = ({ item, handleClose }) => {
  const metricClass =
    item?.engagementType === ENGAGEMENT_TYPE.internal
      ? 'number__internal'
      : 'number__external';

  const tooltip = (source) => {
    return (
      <Tooltip bsclass='gne-tooltip'>
        <span className='text text__x-small'>Source: {source}</span>
      </Tooltip>
    );
  };
  return (
    <OverlayTrigger overlay={tooltip(item?.source)}>
      <div
        className={`${classes.pill} ${classes['pill-metric']} text-center animate__animated animate__fadeIn`}>
        <span className={`${classes.number} ${classes[metricClass]}`}>
          {item?.number}
        </span>
        <span>{item?.engagement}</span>
        <span
          className={classes['close-button']}
          onClick={() => handleClose(item?.engagement)}>
          x
        </span>
      </div>
    </OverlayTrigger>
  );
};
export default EngagementPill;
