/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadOptions } from 'store/customer/customer-view-actions';
import {
  getCustomerExpr,
  setEngagementData,
  formatOptionLabel,
} from 'utils/utils';
import {
  ACCOUNT_PAYER_MIX_FILTER_NAME,
  ALL,
  CONFIGURE_VIEW,
  CRM_APP_ID,
  HOME_MARKETING_FILTERS,
  COPAY_ANALYTICS_FILTER_NAME,
  SITE_UTILIZATION_FILTER_NAME,
  INSIGHT_MARKETING_FILTERS,
  AFFILATION_FILTER_NAME,
} from 'constants/constants';
import { wrapMenuList } from 'react-select-async-paginate';
import { ValueContainer, dropdownOptions } from 'utils/utils';
import { isArray } from 'lodash';
import { setCopayAnalyticsEcosystem } from 'store/customer/customerViewSlice';
import GNEAsyncPagination from './GNEAsyncPagination';

const SelectWithPagination = ({
  fieldName,
  isMulti,
  filter,
  isSelectAll,
  isTitleCase,
  filterName,
  cacheUniqs,
  appid,
  view,
  isDefault,
  account,
  menuIsOpen,
  onMenuOpen,
  expression = '',
  disableStatus,
  page,
}) => {
  const { selectedValue } = filter?.[fieldName] || {};
  const [offset, setOffset] = useState(0);
  const [searchOffset, setSearchOffset] = useState(0);
  const [searchText, setSearchText] = useState('');
  const setData = setEngagementData(fieldName, filterName);
  const dispatch = useDispatch();
  const qlikToken = localStorage.getItem('qlikToken');
  const qlikCookie = localStorage.getItem('qlikCookie');
  const isValidSearchText = (text) => text?.length >= 3 || !text;
  const dynamicStore =
    page === CONFIGURE_VIEW.myView ? 'HomeMyView' : 'CustomerView';
  const selectedChannel = useSelector(
    (state) => state?.[dynamicStore]?.marketingFilters?.Channel
  );
  const selectedDiscussionTopic = useSelector(
    (state) => state?.[dynamicStore]?.marketingFilters?.DiscussionTopic
  );
  const selectedStakeholderType = useSelector(
    (state) => state?.[dynamicStore]?.marketingFilters?.PayerStakeholderType
  );
  const selectedTemplateName = useSelector(
    (state) => state?.[dynamicStore]?.marketingFilters?.TemplateName
  );
  const selectedSurveyType = useSelector(
    (state) => state?.[dynamicStore]?.marketingFilters?.SurveyType
  );
  const selectedPresentationName = useSelector(
    (state) => state?.[dynamicStore]?.marketingFilters?.PresentationName
  );
  const selectedKeyMessage = useSelector(
    (state) => state?.[dynamicStore]?.marketingFilters?.KeyMessage
  );
  const selectedAccountParentPayer = useSelector(
    (state) =>
      state?.CustomerView?.AccountPayerMixFilters?.AccountPayerParentPayer
        ?.selectedValue
  );
  const selectedAccountBookOfBusiness = useSelector(
    (state) =>
      state?.CustomerView?.AccountPayerMixFilters?.AccountPayerBookOfBusiness
        ?.selectedValue
  );
  const selectedAccountEcosystem = useSelector(
    (state) =>
      state?.CustomerView?.AccountPayerMixFilters?.AccountPayerEcosystem
        ?.selectedValue
  );
  const selectedAccountProduct = useSelector(
    (state) =>
      state?.CustomerView?.AccountPayerMixFilters?.AccountPayerProduct
        ?.selectedValue
  );
  const selectedAccountPayer = useSelector(
    (state) =>
      state?.CustomerView?.AccountPayerMixFilters?.AccountPayerName
        ?.selectedValue
  );
  const selectedSiteUtilizationAddress = useSelector(
    (state) =>
      state?.CustomerView?.siteUtilizationFilters?.SiteUtilization_Site_Address
        ?.selectedValue
  );
  const selectedSiteName = useSelector(
    (state) =>
      state?.CustomerView?.siteUtilizationFilters?.SiteUtilization_SiteName
        ?.selectedValue
  );
  const selectedEcosystem = useSelector(
    (state) =>
      state?.CustomerView?.siteUtilizationFilters?.SiteUtilization_Ecosystem
        ?.selectedValue
  );
  const selectedCity = useSelector(
    (state) =>
      state?.CustomerView?.siteUtilizationFilters?.SiteUtilization_SiteCity
        ?.selectedValue
  );
  const selectedState = useSelector(
    (state) =>
      state?.CustomerView?.siteUtilizationFilters?.SiteUtilization_SiteState
        ?.selectedValue
  );

  const selectedCopayEcosystem = useSelector(
    (state) =>
      state?.CustomerView?.copayAnalyticsFilters?.copayEcosystem?.selectedValue
  );
  const selectedSite = useSelector(
    (state) =>
      state?.CustomerView?.copayAnalyticsFilters?.copaySquad?.selectedValue
  );
  const selectedTimePeriodCopay = useSelector(
    (state) =>
      state?.CustomerView?.copayAnalyticsFilters?.copayQuarter?.selectedValue
  );

  const selectedCopayProduct = useSelector(
    (state) =>
      state?.CustomerView?.copayAnalyticsFilters?.copayProduct?.selectedValue
  );
  const selectedCopaySquad = useSelector(
    (state) =>
      state?.CustomerView?.copayAnalyticsFilters?.copaySquad?.selectedValue
  );

  const selectedCustomer = useSelector(
    (state) => state?.CustomerView?.selectedCustomer
  );
  const firstLoading = useRef(true);
  const payerExpression = useSelector(
    (state) => state?.CustomerView?.payerExpression
  );
  const shouldLoadMore = useRef(false);

  const selectedView = useSelector(
    (state) => state?.CustomerView?.selectedView
  );
  const selectedStartDate = useSelector(
    (state) => state?.EngagementFilters?.startDate
  );
  const selectedKPI = useSelector(
    (state) => state?.[dynamicStore]?.selectedKPI
  );

  const selectedEndDate = useSelector(
    (state) => state?.EngagementFilters?.endDate
  );
  const isAccountableProduct = useSelector(
    (state) => state?.CustomerView?.sitePerformanceFilters?.IsAccountableProduct
  );
  const selectedSquad = useSelector(
    (state) =>
      state?.CustomerView?.sitePerformanceFilters?.SiteSquad?.selectedValue
  );
  const selectedProducts = useSelector(
    (state) =>
      state?.CustomerView?.sitePerformanceFilters?.Product?.selectedValue
  );
  const selectedContractSegment = useSelector(
    (state) =>
      state?.CustomerView?.sitePerformanceFilters?.contract_segment_Filter
        ?.selectedValue
  );

  const selectedParentPayer = useSelector(
    (state) => state?.CustomerView?.payerMixFilters?.ParentPayer?.selectedValue
  );
  const selectedPayer = useSelector(
    (state) => state?.CustomerView?.payerMixFilters?.Payer?.selectedValue
  );

  const selectedBookOfBusiness = useSelector(
    (state) =>
      state?.CustomerView?.payerMixFilters?.BookOfBusiness?.selectedValue
  );

  const isAccountableProductMix = useSelector(
    (state) => state?.CustomerView?.productMixFilters?.IsAccountableProduct
  );
  const selectedSquadProductMix = useSelector(
    (state) => state?.CustomerView?.productMixFilters?.SiteSquad?.selectedValue
  );
  const selectedProductsProductMix = useSelector(
    (state) => state?.CustomerView?.productMixFilters?.Product?.selectedValue
  );

  const isAccountableProductConfigure = useSelector(
    (state) =>
      state?.CustomerView?.configureInsightsFilters?.IsAccountableProduct
  );
  const selectedSquadConfigureInsights = useSelector(
    (state) =>
      state?.CustomerView?.configureInsightsFilters?.SiteSquad?.selectedValue
  );
  const selectedProductsConfigureInsights = useSelector(
    (state) =>
      state?.CustomerView?.configureInsightsFilters?.Product?.selectedValue
  );

  //Affiliation Filters
  const selectedAffiliationSiteName = useSelector(
    (state) => state?.CustomerView?.affiliationsFilters?.SiteName_Address
  );
  const selectedSiteState = useSelector(
    (state) => state?.CustomerView?.affiliationsFilters?.SiteState
  );
  const selectedSiteHIN = useSelector(
    (state) => state?.CustomerView?.affiliationsFilters?.SiteHIN
  );
  const selectedSiteAddress = useSelector(
    (state) => state?.CustomerView?.affiliationsFilters?.SiteAddress
  );
  const selectedHCPStakeholderType = useSelector(
    (state) => state?.CustomerView?.affiliationsFilters?.HCPStakeholderTypes
  );
  const selectedHCPName = useSelector(
    (state) => state?.CustomerView?.affiliationsFilters?.HCPNames
  );
  const selectedHCPAddress = useSelector(
    (state) => state?.CustomerView?.affiliationsFilters?.HCPAddress
  );
  const selectedSiteSpeciality = useSelector(
    (state) => state?.CustomerView?.affiliationsFilters?.SiteSpeciality
  );

  const selectedAll = useRef(false);

  useEffect(() => {
    if (isSelectAll && selectedStartDate) {
      const loadOptions = async () => {
        const { options } = await callLoadOptions('', true);
        if (options?.length > 1) {
          setSearchOffset(0);
          setOffset(0);
        }
        selectAll(options);

        selectedAll.current = true;
      };

      loadOptions();
    }
  }, [selectedStartDate, selectedCustomer]);

  useEffect(() => {
    setSearchOffset(0);
    setOffset(0);
  }, [
    selectedCustomer,
    selectedStartDate,
    selectedEndDate,
    isAccountableProduct,
    selectedSquad,
    selectedProducts,
    selectedContractSegment,
    selectedBookOfBusiness,
    selectedParentPayer,
    selectedPayer,
    selectedProductsProductMix,
    selectedSquadProductMix,
    isAccountableProductMix,
    isAccountableProductConfigure,
    selectedSquadConfigureInsights,
    selectedProductsConfigureInsights,
    selectedChannel,
    selectedDiscussionTopic,
    selectedStakeholderType,
    selectedTemplateName,
    selectedSurveyType,
    selectedPresentationName,
    selectedKeyMessage,
    selectedAccountPayer,
    selectedAccountProduct,
    selectedAccountEcosystem,
    selectedCopayEcosystem,
    selectedSite,
    selectedTimePeriodCopay,
    selectedCopayProduct,
    selectedCopaySquad,
    selectedAccountBookOfBusiness,
    selectedAccountParentPayer,
    selectedSiteUtilizationAddress,
    selectedSiteName,
    selectedState,
    selectedCity,
    selectedEcosystem,
    selectedAffiliationSiteName,
    selectedSiteState,
    selectedSiteHIN,
    selectedSiteAddress,
    selectedHCPStakeholderType,
    selectedHCPName,
    selectedHCPAddress,
    selectedSiteSpeciality,
  ]);

  const getAccountableProduct = () => {
    if (filterName === 'ProductMix') {
      return isAccountableProductMix ? 'Y' : undefined;
    } else if (filterName === 'configureInsights') {
      return isAccountableProductConfigure ? 'Y' : undefined;
    } else {
      return isAccountableProduct ? 'Y' : undefined;
    }
  };

  const callLoadOptions = async (inputValue, firstLoad) => {
    if (!isValidSearchText(inputValue)) {
      return {
        options: [],
      };
    }
    let searchOffsetValue = searchOffset;
    if (inputValue !== searchText) {
      setSearchOffset(0);
      searchOffsetValue = 0;
    }
    setSearchText(inputValue);
    const params = {
      appId: appid ?? CRM_APP_ID,
      fieldName,
      offset:
        firstLoad === true
          ? 0
          : firstLoading.current
          ? 0
          : !shouldLoadMore.current
          ? 0
          : offset,
      qlikToken,
      qlikCookie,
      searchText: inputValue,
      searchOffset: searchOffsetValue,
      account: account || selectedCustomer,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      isAccountableProduct: getAccountableProduct(),
      selectedProducts:
        filterName === 'ProductMix'
          ? selectedProductsProductMix
          : filterName === 'configureInsights'
          ? selectedProductsConfigureInsights
          : selectedProducts,
      selectedSquad:
        filterName === 'ProductMix'
          ? selectedSquadProductMix
          : filterName === 'configureInsights'
          ? selectedSquadConfigureInsights
          : selectedSquad,
      field: view || selectedView,
      expression:
        filterName === SITE_UTILIZATION_FILTER_NAME
          ? getFieldExpression(fieldName, account || selectedCustomer)
          : filterName === ACCOUNT_PAYER_MIX_FILTER_NAME
          ? getAccountPyerFieldExpression(
              fieldName,
              account || selectedCustomer
            )
          : filterName === HOME_MARKETING_FILTERS ||
            filterName === INSIGHT_MARKETING_FILTERS
          ? getTrendsFilterExpression(
              fieldName,
              account || selectedCustomer,
              filterName
            )
          : filterName === COPAY_ANALYTICS_FILTER_NAME
          ? getCopayExpression(fieldName, account || selectedCustomer)
          : filterName === AFFILATION_FILTER_NAME
          ? getAffilationsExpression(fieldName, account || selectedCustomer)
          : expression || payerExpression,
      payer: selectedPayer,
      parentPayer: selectedParentPayer,
      bookOfBusiness: selectedBookOfBusiness,
      isDefault: isDefault,
    };
    const { values, currentListSize, hasMoreElement } = await loadOptions(
      params,
      isMulti
    );

    if (hasMoreElement) {
      inputValue?.length >= 3
        ? setSearchOffset(searchOffset + currentListSize)
        : setOffset((prevValue) => prevValue + currentListSize);
    }

    return {
      options: values?.flatMap((item) => {
        if (item?.value !== null && item?.value !== '') {
          const formattedOption = item.label?.split('||');

          return {
            label: formattedOption[0],
            address: formattedOption[1],
            value:
              formattedOption?.length === 1
                ? formattedOption[0]
                : formattedOption[2],
          };
        } else return [];
      }),
      hasMore: hasMoreElement,
    };
  };
  const getTrendsFilterExpression = (fieldName, account, filterName) => {
    const accountExp = getCustomerExpr(account, isArray(selectedCustomer));
    let Channel =
      selectedKPI?.filterEnable?.channelNameEnable === 'Y' &&
      isArray(filter?.Channel?.selectedValue) &&
      filter?.Channel?.selectedValue.length > 0
        ? 'Channel={"' +
          filter.Channel.selectedValue.map((a) => a.label).join('","') +
          '"}'
        : '';

    let DiscussionTopic =
      selectedKPI?.filterEnable?.discussionTopicEnable === 'Y' &&
      isArray(filter?.DiscussionTopic?.selectedValue) &&
      filter?.DiscussionTopic?.selectedValue.length > 0
        ? '[Discussion Topic]={"' +
          filter.DiscussionTopic.selectedValue.map((a) => a.label).join('","') +
          '"}'
        : '';
    let StakeholderType =
      selectedKPI?.filterEnable?.stakeholderTypeEnable === 'Y' &&
      isArray(filter?.PayerStakeholderType?.selectedValue) &&
      filter?.PayerStakeholderType?.selectedValue.length > 0
        ? '[Stakeholder Type]={"' +
          filter.PayerStakeholderType.selectedValue
            .map((a) => a.label)
            .join('","') +
          '"}'
        : '';

    let TemplateName =
      selectedKPI?.filterEnable?.templateNameEnable === 'Y' &&
      isArray(filter?.TemplateName?.selectedValue) &&
      filter?.TemplateName?.selectedValue.length > 0
        ? '[Template Name]={"' +
          filter.TemplateName.selectedValue.map((a) => a.label).join('","') +
          '"}'
        : '';

    let SurveyType =
      selectedKPI?.filterEnable?.surveyTypeEnable === 'Y' &&
      isArray(filter?.SurveyType?.selectedValue) &&
      filter?.SurveyType?.selectedValue.length > 0
        ? '[Survey Type]={"' +
          filter.SurveyType.selectedValue.map((a) => a.label).join('","') +
          '"}'
        : '';

    let PresentationName =
      selectedKPI?.filterEnable?.presentationNameEnable === 'Y' &&
      isArray(filter?.PresentationName?.selectedValue) &&
      filter?.PresentationName?.selectedValue.length > 0
        ? '[Presentation Name]={"' +
          filter.PresentationName.selectedValue
            .map((a) => a.label)
            .join('","') +
          '"}'
        : '';

    let KeyMessage =
      selectedKPI?.filterEnable?.keyMessageEnable === 'Y' &&
      isArray(filter?.KeyMessage?.selectedValue) &&
      filter?.KeyMessage?.selectedValue.length > 0
        ? '[Key Message]={"' +
          filter.KeyMessage.selectedValue.map((a) => a.label).join('","') +
          '"}'
        : '';

    let fieldExpression =
      filterName === INSIGHT_MARKETING_FILTERS ? `${accountExp},` : '';
    if (Channel !== '' && fieldName !== 'Channel') {
      fieldExpression = fieldExpression.concat(`${Channel},`);
    }
    if (DiscussionTopic !== '' && fieldName !== 'DiscussionTopic') {
      fieldExpression = fieldExpression.concat(`${DiscussionTopic},`);
    }
    if (StakeholderType !== '' && fieldName !== 'PayerStakeholderType') {
      fieldExpression = fieldExpression.concat(`${StakeholderType},`);
    }
    if (TemplateName !== '' && fieldName !== 'TemplateName') {
      fieldExpression = fieldExpression.concat(`${TemplateName},`);
    }
    if (SurveyType !== '' && fieldName !== 'SurveyType') {
      fieldExpression = fieldExpression.concat(`${SurveyType},`);
    }
    if (PresentationName !== '' && fieldName !== 'PresentationName') {
      fieldExpression = fieldExpression.concat(`${PresentationName},`);
    }
    if (KeyMessage !== '' && fieldName !== 'KeyMessage') {
      fieldExpression = fieldExpression.concat(`${KeyMessage},`);
    }
    fieldExpression =
      fieldExpression.charAt(fieldExpression?.length - 1) === ','
        ? fieldExpression?.slice(0, -1)
        : fieldExpression;

    return fieldExpression;
  };

  const getCopayExpression = (fieldName, account) => {
    const accountExp = getCustomerExpr(account, isArray(selectedCustomer));
    let ecosystem =
      isArray(filter?.copayEcosystem?.selectedValue) &&
      filter?.copayEcosystem?.selectedValue.length > 0
        ? 'Ecosystem={"' +
          filter.copayEcosystem.selectedValue.map((a) => a.label).join('","') +
          '"}'
        : filter.copayEcosystem.selectedValue
        ? 'Ecosystem={"' + filter.copayEcosystem.selectedValue.label + '"}'
        : '';
    let sitename =
      isArray(filter?.copaySiteAddressName?.selectedValue) &&
      filter?.copaySiteAddressName?.selectedValue.length > 0
        ? 'Site_ID={"' +
          filter.copaySiteAddressName.selectedValue
            .map((a) => a.value)
            .join('","') +
          '"}'
        : '';
    let timeperiod =
      isArray(filter?.copayQuarter?.selectedValue) &&
      filter?.copayQuarter?.selectedValue.length > 0
        ? 'Quarter={"' +
          filter.copayQuarter.selectedValue.map((a) => a.label).join('","') +
          '"}'
        : '';
    let product =
      isArray(filter?.copayProduct?.selectedValue) &&
      filter?.copayProduct?.selectedValue.length > 0
        ? 'Product={"' +
          filter.copayProduct.selectedValue.map((a) => a.label).join('","') +
          '"}'
        : '';
    let squad =
      isArray(filter?.copaySquad?.selectedValue) &&
      filter?.copaySquad?.selectedValue.length > 0
        ? 'Squad={"' +
          filter.copaySquad.selectedValue.map((a) => a.label).join('","') +
          '"}'
        : '';
    let fieldExpression = `${accountExp}`;
    if (fieldName === 'copayEcosystem') {
      dispatch(
        setCopayAnalyticsEcosystem({
          options: [],
          selectedValue: selectedCopayEcosystem,
        })
      );
    }
    if (fieldName !== 'copayEcosystem' && ecosystem !== '') {
      fieldExpression = fieldExpression.concat(`, ${ecosystem}`);
    }
    if (sitename !== '' && fieldName !== 'copaySiteAddressName') {
      fieldExpression = fieldExpression.concat(`, ${sitename}`);
    }
    if (timeperiod !== '' && fieldName !== 'copayQuarter') {
      fieldExpression = fieldExpression.concat(`, ${timeperiod}`);
    }
    if (product !== '' && fieldName !== 'copayProduct') {
      fieldExpression = fieldExpression.concat(`, ${product}`);
    }
    if (squad !== '' && fieldName !== 'copaySquad') {
      fieldExpression = fieldExpression.concat(`, ${squad}`);
    }
    return fieldExpression;
  };

  const getAffilationsExpression = (fieldName, account) => {
    const accountExp = getCustomerExpr(account, isArray(selectedCustomer));

    let siteName =
      isArray(filter?.SiteName_Address?.selectedValue) &&
      filter?.SiteName_Address?.selectedValue?.length > 0
        ? '[Site_ID]={"' +
          filter?.SiteName_Address?.selectedValue
            ?.map((a) => a?.value)
            .join('","') +
          '"}'
        : '';
    let siteState =
      isArray(filter?.SiteState?.selectedValue) &&
      filter?.SiteState?.selectedValue?.length > 0
        ? '[Site State]={"' +
          filter?.SiteState?.selectedValue?.map((a) => a?.label).join('","') +
          '"}'
        : '';
    let siteHIN =
      isArray(filter?.SiteHIN?.selectedValue) &&
      filter?.SiteHIN?.selectedValue?.length > 0
        ? '[site_hin]={"' +
          filter?.SiteHIN?.selectedValue?.map((a) => a?.label).join('","') +
          '"}'
        : '';
    let siteAddress =
      isArray(filter?.SiteAddress?.selectedValue) &&
      filter?.SiteAddress?.selectedValue?.length > 0
        ? '[Site Address]={"' +
          filter?.SiteAddress?.selectedValue?.map((a) => a?.label).join('","') +
          '"}'
        : '';
    let HCPStakeholderType =
      isArray(filter?.HCPStakeholderTypes?.selectedValue) &&
      filter?.HCPStakeholderTypes?.selectedValue?.length > 0
        ? '[Stakeholder_Type]={"' +
          filter?.HCPStakeholderTypes?.selectedValue
            ?.map((a) => a?.label)
            .join('","') +
          '"}'
        : '';
    let HCPName =
      isArray(filter?.HCPNames?.selectedValue) &&
      filter?.HCPNames?.selectedValue?.length > 0
        ? '[HCP Name]={"' +
          filter?.HCPNames?.selectedValue?.map((a) => a?.label).join('","') +
          '"}'
        : '';
    let HCPAddress =
      isArray(filter?.HCPAddress?.selectedValue) &&
      filter?.HCPAddress?.selectedValue?.length > 0
        ? '[HCP Address]={"' +
          filter?.HCPAddress?.selectedValue?.map((a) => a?.label).join('","') +
          '"}'
        : '';

    let fieldExpression = `${accountExp}`;
    if (siteName !== '' && fieldName !== 'SiteName_Address') {
      fieldExpression = fieldExpression.concat(`, ${siteName}`);
    }
    if (siteState !== '' && fieldName !== 'SiteState') {
      fieldExpression = fieldExpression.concat(`, ${siteState}`);
    }
    if (siteAddress !== '' && fieldName !== 'SiteAddress') {
      fieldExpression = fieldExpression.concat(`, ${siteAddress}`);
    }
    if (siteHIN !== '' && fieldName !== 'SiteHIN') {
      fieldExpression = fieldExpression.concat(`, ${siteHIN}`);
    }
    if (HCPName !== '' && fieldName !== 'HCPNames') {
      fieldExpression = fieldExpression.concat(`, ${HCPName}`);
    }
    if (HCPStakeholderType !== '' && fieldName !== 'HCPStakeholderTypes') {
      fieldExpression = fieldExpression.concat(`, ${HCPStakeholderType}`);
    }
    if (HCPAddress !== '' && fieldName !== 'HCPAddress') {
      fieldExpression = fieldExpression.concat(`, ${HCPAddress}`);
    }
    return fieldExpression;
  };

  const getAccountPyerFieldExpression = (fieldName, account) => {
    const accountExp = getCustomerExpr(account, isArray(selectedCustomer));
    let accountPayer =
      isArray(filter?.AccountPayerParentPayer?.selectedValue) &&
      filter?.AccountPayerParentPayer?.selectedValue.length > 0
        ? 'Parent_Payer={"' +
          filter.AccountPayerParentPayer.selectedValue
            .map((a) => a.label)
            .join('","') +
          '"}'
        : '';
    let accountPayerName =
      isArray(filter?.AccountPayerName?.selectedValue) &&
      filter?.AccountPayerName?.selectedValue.length > 0
        ? 'Payer_Name={"' +
          filter.AccountPayerName.selectedValue
            .map((a) => a.label)
            .join('","') +
          '"}'
        : '';
    let bookOfBusiness =
      isArray(filter?.AccountPayerBookOfBusiness?.selectedValue) &&
      filter?.AccountPayerBookOfBusiness?.selectedValue.length > 0
        ? 'Book_of_Business={"' +
          filter.AccountPayerBookOfBusiness.selectedValue
            .map((a) => a.label)
            .join('","') +
          '"}'
        : '';
    let payerProduct =
      isArray(filter?.AccountPayerProduct?.selectedValue) &&
      filter?.AccountPayerProduct?.selectedValue.length > 0
        ? 'Product={"' +
          filter.AccountPayerProduct.selectedValue
            .map((a) => a.label)
            .join('","') +
          '"}'
        : '';
    let payerEcosystem =
      isArray(filter?.AccountPayerEcosystem?.selectedValue) &&
      filter?.AccountPayerEcosystem?.selectedValue.length > 0
        ? 'Ecosystem={"' +
          filter.AccountPayerEcosystem.selectedValue
            .map((a) => a.label)
            .join('","') +
          '"}'
        : '';
    let fieldExpression = `${accountExp}`;
    if (payerEcosystem !== '' && fieldName !== 'AccountPayerEcosystem') {
      fieldExpression = fieldExpression.concat(`, ${payerEcosystem}`);
    }
    if (payerProduct !== '' && fieldName !== 'AccountPayerProduct') {
      fieldExpression = fieldExpression.concat(`, ${payerProduct}`);
    }
    if (bookOfBusiness !== '' && fieldName !== 'AccountPayerBookOfBusiness') {
      fieldExpression = fieldExpression.concat(`, ${bookOfBusiness}`);
    }
    if (accountPayerName !== '' && fieldName !== 'AccountPayerName') {
      fieldExpression = fieldExpression.concat(`, ${accountPayerName}`);
    }
    if (accountPayer !== '' && fieldName !== 'AccountPayerParentPayer') {
      fieldExpression = fieldExpression.concat(`, ${accountPayer}`);
    }

    return fieldExpression;
  };

  const getFieldExpression = (fieldName, account) => {
    const accountExp = getCustomerExpr(
      selectedCustomer,
      isArray(selectedCustomer)
    );

    const ecosystemExp =
      isArray(filter.SiteUtilization_Ecosystem.selectedValue) &&
      filter.SiteUtilization_Ecosystem.selectedValue.length > 0
        ? 'Ecosystem={"' +
          filter.SiteUtilization_Ecosystem.selectedValue
            .map((a) => a.label)
            .join('","') +
          '"}'
        : '';

    const siteNameExp =
      isArray(filter.SiteUtilization_SiteName_Address.selectedValue) &&
      filter.SiteUtilization_SiteName_Address.selectedValue.length > 0
        ? '[Site_ID] ={"' +
          filter.SiteUtilization_SiteName_Address.selectedValue
            .map((a) => a.value)
            .join('","') +
          '"}'
        : '';
    const siteCityExp =
      isArray(filter.SiteUtilization_SiteCity.selectedValue) &&
      filter.SiteUtilization_SiteCity.selectedValue.length > 0
        ? '[Site City] ={"' +
          filter.SiteUtilization_SiteCity.selectedValue
            .map((a) => a.label)
            .join('","') +
          '"}'
        : '';
    const siteStateExp =
      isArray(filter.SiteUtilization_SiteState.selectedValue) &&
      filter.SiteUtilization_SiteState.selectedValue.length > 0
        ? '[Site State] ={"' +
          filter.SiteUtilization_SiteState.selectedValue
            .map((a) => a.label)
            .join('","') +
          '"}'
        : '';
    const siteAddressExp =
      isArray(filter.SiteUtilization_Site_Address.selectedValue) &&
      filter.SiteUtilization_Site_Address.selectedValue.length > 0
        ? '[Site Address] ={"' +
          filter.SiteUtilization_Site_Address.selectedValue
            .map((a) => a.label)
            .join('","') +
          '"}'
        : '';
    let siteFilterExpression = `${accountExp}`;
    if (siteNameExp !== '' && fieldName !== 'SiteUtilization_SiteName_Address')
      siteFilterExpression = siteFilterExpression.concat(`, ${siteNameExp}`);
    if (ecosystemExp !== '' && fieldName !== 'SiteUtilization_Ecosystem')
      siteFilterExpression = siteFilterExpression.concat(`, ${ecosystemExp}`);
    if (siteCityExp !== '' && fieldName !== 'SiteUtilization_SiteCity')
      siteFilterExpression = siteFilterExpression.concat(`, ${siteCityExp}`);
    if (siteStateExp !== '' && fieldName !== 'SiteUtilization_SiteState')
      siteFilterExpression = siteFilterExpression.concat(`, ${siteStateExp}`);
    if (siteAddressExp !== '' && fieldName !== 'SiteUtilization_Site_Address')
      siteFilterExpression = siteFilterExpression.concat(`, ${siteAddressExp}`);
    return siteFilterExpression;
  };

  const handleOnChange = (value) => {
    dispatch(
      setData({
        ...filter[fieldName],
        selectedValue: value,
      })
    );
  };

  const selectAll = (selections) => {
    let currentSelectedValue = [];

    if (isMulti) {
      if (selections?.length > 1) {
        currentSelectedValue = [
          ...selections.filter((item) => item.label !== 'All'),
        ];
      }
    }

    dispatch(
      setData({
        ...filter[fieldName],
        selectedValue: currentSelectedValue,
      })
    );
  };
  const shouldLoadMoreOptions = (scrollHeight, clientHeight, scrollTop) => {
    if (firstLoading.current) {
      firstLoading.current = false;
      return true;
    }
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    const loadMore = bottomBorder < scrollTop;
    shouldLoadMore.current = loadMore;
    return loadMore;
  };
  return (
    <GNEAsyncPagination
      value={selectedValue || ''}
      loadOptions={callLoadOptions}
      onChange={handleOnChange}
      // options={options}
      isMulti={isMulti}
      isTitleCase={isTitleCase}
      components={{
        ValueContainer: ValueContainer,
        Option: wrapMenuList(dropdownOptions),
      }}
      placeholder={ALL}
      closeMenuOnSelect={!isMulti}
      isClearable
      cacheUniqs={cacheUniqs}
      shouldLoadMore={shouldLoadMoreOptions}
      isDisabled={disableStatus}
      getOptionValue={(option) => option?.value}
      getOptionLabel={(option) => option?.label}
      getOptionId={(option) => option?.id}
      formatOptionLabel={(data, { context }) =>
        formatOptionLabel(data, { context })
      }
    />
  );
};
export default SelectWithPagination;
