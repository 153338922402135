import LinkButton from 'components/shared/button/LinkButton';
import GNEModal from 'components/shared/modal/Modal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Notification = () => {
  const [showModal, setShowModal] = useState(true);
  const navigation = useNavigate();

  const handleProceed = () => {
    setShowModal(false);
    navigation('/home');
  };

  const title = (
    <p
      className='title__large title__bold d-flex align-items-center'
      title='Notification'>
      Notification
    </p>
  );

  const content = (
    <div title='content'>
      <p className='d-flex align'>{process.env.REACT_APP_NOTIFICATION_MSG}</p>
    </div>
  );
  const footer = (
    <div className='d-flex justify-content-end w-100'>
      <LinkButton onClick={handleProceed}>Proceed</LinkButton>
    </div>
  );

  return (
    <GNEModal
      titlecontent={title}
      show={showModal}
      onHide={handleProceed}
      content={content}
      css='size-medium'
      footer={footer}
      backdrop='static'
    />
  );
};

export default Notification;
