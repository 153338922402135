import { useState, useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Popover } from 'antd';
import ConfigurableTable from 'components/shared/table/ConfigurableTable';

import classes from './Affiliations.module.scss';

import {
  AFFILIATIONS_COLUMNS,
  TABLE_NAME_AFFILIATION,
  FLAG
} from 'constants/constants';

import {
  exportData,
  getAffiliationsData,
} from 'store/customer/customer-view-actions';
import {
  setAffLoader,
  setSelectedCustomer,
  setSeletedView,
  setInsightType,
  setHcpFlag,
  setFilterInit,
} from 'store/customer/customerViewSlice';
import AffiliationsFilters from './AffiliationsFilter';
import styles from './../../shared/table/ConfigurableTable.module.scss';
import { getRestrictedColumn } from 'utils/utils';
import { useNavigate } from 'react-router-dom';
import { setEngagementDateInit } from 'store/customer/filter/filter-slice';

const Affiliations = ({ data }) => {
  const [filterExp, setFilterExp] = useState('');
  const [popoverVisible, setPopoverVisible] = useState(false);
  const navigation = useNavigate();
  const affiliationExpression = useSelector(
    (state) => state?.CustomerView?.affiliationsExpression
  );
  const columnRestriction = useSelector(
    (state) => state?.CustomerView?.columnsRestiction
  );

  const handleClick = (rowData, index) => {
    const payload = {
      value: rowData?.mdmId,
      label: rowData?.siteName,
      zip: rowData?.zip,
      flag: FLAG.site[0],
    };
    dispatch(setFilterInit());
    dispatch(setEngagementDateInit());
    dispatch(setSeletedView('site'));
    dispatch(setSelectedCustomer(payload));
    dispatch(setInsightType('general'));
    navigation(`/customerView/list/${rowData?.siteName}`);
  };

  const flatAffiliationsData = useSelector(
    (state) => state?.CustomerView?.affiliationsData
  );
  const isLoading = useSelector((state) => state?.CustomerView?.affLoader);
  const isExporting = useSelector(
    (state) => state?.CustomerView?.exportAffiliation
  );

  const hcpFlag = useSelector((state) => state?.CustomerView.hcpFlag);
  const group = {};

  let pivotAffiliationsData1 = flatAffiliationsData?.values && [
    ...flatAffiliationsData?.values,
  ];
  pivotAffiliationsData1?.forEach(
    ({
      siteId,
      siteName,
      siteAddress,
      siteCity,
      siteState,
      siteZip,
      ...rest
    }) => {
      group[siteId] = group[siteId] || {
        siteId,
        siteName,
        siteAddress,
        siteCity,
        siteState,
        siteZip,
        children: [],
      };
      group[siteId].children.push(rest);
    }
  );

  useEffect(() => {
    setSearchedData(flatAffiliationsData?.values);
  }, [flatAffiliationsData]);

  const [searchedData, setSearchedData] = useState(
    flatAffiliationsData?.values
  );

  const dispatch = useDispatch();
  const qlikToken = localStorage.getItem('qlikToken');
  const qlikCookie = localStorage.getItem('qlikCookie');
  const selectedCustomer = useSelector(
    (state) => state?.CustomerView?.selectedCustomer
  );
  const account = {
    id: selectedCustomer?.value,
    name: selectedCustomer?.label,
  };
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const totalRecords =
    searchedData?.length >= 0 && searchedData?.length < 100
      ? searchedData?.length
      : 100;
  const [total, setTotal] = useState(totalRecords);
  useEffect(() => {
    setTotal(totalRecords);
  }, [totalRecords]);

  const pagination = {
    total: total,
    onChange: (page, size) => {
      setPageIndex(page);
      setPageSize(size);
    },
    pageSizeOptions: [10, 20, 30],
    showSizeChanger: true,
  };
  const handleFilterApply = async (filterExp) => {
    setFilterExp(filterExp);
    if (filterExp) {
      dispatch(
        getAffiliationsData(
          selectedCustomer?.value,
          0,
          hcpFlag,
          qlikCookie,
          qlikToken,
          'siteName',
          'ASC',
          filterExp
        )
      );
    }
  };

  useEffect(() => {
    setPageIndex(pageIndex);
    const isLastPage = pageIndex === Math.ceil(total / pageSize);
    const selectedPageIndex =
      pageIndex > 0 && Math.ceil((pageIndex * pageSize) / 100);
    if (isLastPage && total < flatAffiliationsData.totalSize) {
      dispatch(
        getAffiliationsData(
          selectedCustomer?.value,
          selectedPageIndex,
          hcpFlag,
          qlikCookie,
          qlikToken,
          filterExp
        )
      );
      setTotal(total + 100);
    }
    // eslint-disable-next-line
  }, [pageIndex]);

  useEffect(() => {
    if (!selectedCustomer?.value) {
      dispatch(setAffLoader(false));
      setSearchedData([]);
      return;
    }
    if (selectedCustomer?.value && filterExp)
      dispatch(
        getAffiliationsData(
          selectedCustomer?.value,
          0,
          hcpFlag,
          qlikCookie,
          qlikToken,
          'siteName',
          'ASC',
          filterExp
        )
      );
    // eslint-disable-next-line
  }, [hcpFlag]);

  const handleSorting = (column, order) => {
    dispatch(
      getAffiliationsData(
        selectedCustomer?.value,
        0,
        hcpFlag,
        qlikCookie,
        qlikToken,
        column,
        order,
        filterExp
      )
    );
  };
  const size = { x: 1815, y: 400 };
  const exportTooltip = (
    <Tooltip bsclass='gne-tooltip tooltip--left'>Send to Excel</Tooltip>
  );

  const dowloadData = () => {
    dispatch(
      exportData(
        'Affiliation',
        selectedCustomer?.value,
        qlikCookie,
        qlikToken,
        hcpFlag,
        affiliationExpression
      )
    );
  };

  const handlePopoverVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };
  const [selectedColumns, setSelectedColumns] = useState(
    AFFILIATIONS_COLUMNS.map((column) => {
      return column.isMandatory === 'Y' && column.dataIndex;
    })
  );

  const filteredColumns = AFFILIATIONS_COLUMNS.filter((column) =>
    selectedColumns.includes(column.dataIndex)
  );

  const handleColumnSelection = (checkedValues) => {
    setSelectedColumns(checkedValues);
  };

  useEffect(() => {
    setSelectedColumns(
      AFFILIATIONS_COLUMNS.map((column) => {
        return column.isMandatory === 'Y' && column.dataIndex;
      })
    );
  }, [selectedCustomer]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (
      value === 'hcpName' ||
      value === 'hcpAddress' ||
      value === 'hcpCity' ||
      value === 'hcpState' ||
      value === 'hcpZip' ||
      value === 'hcpId' ||
      value === 'stakeholderType'
    ) {
      dispatch(setHcpFlag(checked));
    }
    if (
      value === 'hcpCity' ||
      value === 'hcpZip' ||
      value === 'hcpId' ||
      value === 'siteHin' ||
      value === 'siteDea' ||
      value === 'zip' ||
      value === 'siteState' ||
      value === 'siteCity'
    ) {
      checked ? (size.x = size.x + 150) : (size.x = size.x - 150);
    } else {
      checked ? (size.x = size.x + 250) : (size.x = size.x - 250);
    }
  };
  const columnSelectionPanel = (
    <Checkbox.Group
      defaultValue={selectedColumns}
      onChange={handleColumnSelection}
      className={styles.checkboxGroup}
      key={Math.random()}>
      {AFFILIATIONS_COLUMNS.map((column) => (
        <div
          className={styles.checkboxWrapper}
          key={column.id}>
          <Checkbox
            className={styles.checkbox}
            key={column.dataIndex}
            value={column.dataIndex}
            onChange={handleCheckboxChange}>
            {column.title}
          </Checkbox>
        </div>
      ))}
    </Checkbox.Group>
  );

  const popoverContent = (
    <div className={styles.customPopup}>{columnSelectionPanel}</div>
  );

  return (
    <div
      className={`${classes['affiliations-container']} full-width-in-container`}
      id='Affiliations'>
      <Fade>
        <Row className='mb-2'>
          <Col xs={6}>
            <p className='title title__bold title__large'> Affiliations </p>
          </Col>
          <Col
            className='d-flex justify-content-end'
            xs={6}>
            <Popover
              content={popoverContent}
              trigger='click'
              open={popoverVisible}
              onOpenChange={handlePopoverVisibleChange}>
              <i
                className={`icon icon__affiliations_standardTable_settings mt-3 me-3`}
              />
            </Popover>
            <OverlayTrigger
              placement='top'
              overlay={exportTooltip}>
              <i
                onClick={dowloadData}
                className={`icon icon__affiliations_export_excel1 mt-3`}
              />
            </OverlayTrigger>
          </Col>
        </Row>
      </Fade>
      <Row>
        <AffiliationsFilters
          site={account}
          applyFilter={(filterExp) => handleFilterApply(filterExp)}
        />
      </Row>

      <Row>
        <Col>
          <Spin spinning={isLoading || isExporting}>
            <ConfigurableTable
              rows={searchedData}
              columns={getRestrictedColumn(
                TABLE_NAME_AFFILIATION,
                filteredColumns,
                columnRestriction
              ).map((col) => {
                if (col.dataIndex === 'siteName') {
                  return {
                    ...col,
                    render: (text, record) => (
                      <span
                        className='span-link'
                        onClick={() => {
                          handleClick(record);
                        }}>
                        {text}
                      </span>
                    ),
                  };
                }
                return col;
              })}
              expandRowByClick={true}
              enableColumnSelection={false}
              enableExport={false}
              exportFileName='affiliations'
              isGlobalExport={true}
              tableName={TABLE_NAME_AFFILIATION}
              pagination={pagination}
              isGlobalSort={true}
              onSort={handleSorting}
              filterExp={filterExp}
              dimentions={size}
            />
          </Spin>
        </Col>
      </Row>
    </div>
  );
};

export default Affiliations;
