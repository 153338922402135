/* eslint-disable react-hooks/exhaustive-deps */
import { Spin } from 'antd';
import SelectWithPagination from 'components/shared/dropdown-with-pagination/SelectWithPagination';
import { useCallback, useEffect, useState } from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox, Popover } from 'antd';
import ConfigurableTable from 'components/shared/table/ConfigurableTable';
import {
  COPAY_ANALYTICS_APP_ID,
  COPAY_ANALYTICS_FILTER_NAME,
  COPAY_CONSTANT,
  TABLE_NAME_COPAY_ANALYTICS,
  FLAG
} from 'constants/constants';
import { getQlikSenseData } from 'services/qlik.service';
import {
  CopayAnalyticsData,
  exportData,
  loadOptions,
} from 'store/customer/customer-view-actions';
import {
  setCopayAnalyticsEcosystem,
  setCopayData,
  setInsightType,
  setSelectedCustomer,
  setSeletedView,
  setSiteLoader,
  setFilterInit,
} from 'store/customer/customerViewSlice';
import { getCustomerExpr, getRestrictedColumn, isArray } from 'utils/utils';
import styles from './../../shared/table/ConfigurableTable.module.scss';
import classes from './CopayAnalytics.module.scss';
import { useNavigate } from 'react-router-dom';
import { setEngagementDateInit } from 'store/customer/filter/filter-slice';

const CopayAnalytics = (props) => {
  const [menuIsOpen, setMenuIsOpen] = useState({
    copayEcosystem: false,
    copaySiteAddressName: false,
    copaySquad: false,
    copayQuarter: false,
    copayProduct: false,
  });
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [copayExpressions, setCopayExpressions] = useState();
  const mesaure = props.copayMeasure;
  const [timeSpan, setTimeSpan] = useState();
  const navigation = useNavigate();

  const copayAnalyticsFilters = useSelector(
    (state) => state?.CustomerView?.copayAnalyticsFilters
  );
  const selectedView = useSelector(
    (state) => state?.CustomerView?.selectedView
  );
  const [firstLoad, setFirstLoad] = useState(true);
  const copayData = useSelector((state) => state?.CustomerView?.copayData);

  const selectedSite = useSelector(
    (state) =>
      state?.CustomerView?.copayAnalyticsFilters?.copaySiteAddressName
        ?.selectedValue
  );

  const selectedTimePeriod = useSelector(
    (state) =>
      state?.CustomerView?.copayAnalyticsFilters?.copayQuarter?.selectedValue
  );

  const selectedProduct = useSelector(
    (state) =>
      state?.CustomerView?.copayAnalyticsFilters?.copayProduct?.selectedValue
  );
  const selectedEcosystem = useSelector(
    (state) =>
      state?.CustomerView?.copayAnalyticsFilters?.copayEcosystem?.selectedValue
  );
  const selectedSquad = useSelector(
    (state) =>
      state?.CustomerView?.copayAnalyticsFilters?.copaySquad?.selectedValue
  );

  const isLoading = useSelector((state) => state?.CustomerView?.copayLoader);

  const isExporting = useSelector(
    (state) => state?.CustomerView?.exportCopayAnalytics
  );
  const columnRestriction = useSelector(
    (state) => state?.CustomerView?.columnsRestiction
  );
  const dispatch = useDispatch();

  const [copayDimenssion, setCopayDimension] = useState(['Product']);
  const qlikToken = localStorage.getItem('qlikToken');
  const qlikCookie = localStorage.getItem('qlikCookie');
  const selectedCustomer = useSelector(
    (state) => state?.CustomerView?.selectedCustomer
  );

  const [selectedColumns, setSelectedColumns] = useState(
    props.copayColumns.map((column) => {
      return column.isMandatory === 'Y' && column.dataIndex;
    })
  );

  const filteredColumns = props.copayColumns.filter((column) =>
    selectedColumns.includes(column.dataIndex)
  );

  const timePeriodOptions = useSelector(
    (state) => state?.CustomerView?.copayAnalyticsFilters?.TimePeriod
  );
  const siteOptions = useSelector(
    (state) => state?.CustomerView?.copayAnalyticsFilters?.SiteName
  );
  const productOptions = useSelector(
    (state) => state?.CustomerView?.copayAnalyticsFilters?.Product
  );
  const squadOptions = useSelector(
    (state) => state?.CustomerView?.copayAnalyticsFilters?.Squad
  );
  let copayFilterExpression;

  const [pageIndex, setPageIndex] = useState(0);
  const [copayOffset, setCopayOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const totalRecords =
    Object.keys(copayData).length === 0
      ? 0
      : copayData?.values?.length >= 0 && copayData?.values?.length < 100
      ? copayData?.values?.length
      : 100;

  const [total, setTotal] = useState(totalRecords);
  useEffect(() => {
    setTotal(totalRecords);
  }, [totalRecords]);

  const pagination = {
    total: total,
    onChange: (page, size) => {
      setPageIndex(page);
      setPageSize(size);
    },
    pageSizeOptions: [10, 20, 30],
    showSizeChanger: true,
  };
  useEffect(() => {
    if (!selectedCustomer?.label) {
      dispatch(setSiteLoader(false));
      dispatch(setCopayData([]));
      setCopayDimension([]);
      return;
    } else {
      setTimeout(() => {
        preSelectEcosystem();
      }, 1000);
    }
  }, [selectedCustomer]);

  const preSelectEcosystem = async () => {
    const parms = {
      appId: COPAY_ANALYTICS_APP_ID,
      fieldName: 'copayEcosystem',
      offset: 0,
      searchText: '',
      account: selectedCustomer,
      qlikCookie,
      qlikToken,
      isAccountableProduct: 'Y',
      field: selectedView,
      expression: getCustomerExpr(selectedCustomer, isArray(selectedCustomer)),
    };
    const { values } = await loadOptions(parms, true);
    dispatch(
      setCopayAnalyticsEcosystem({
        options: values,
        selectedValue: values[0],
      })
    );
    getCopayData(undefined, undefined, values[0]);
  };

  useEffect(() => {
    setPageIndex(pageIndex);
    const isLastPage = pageIndex === Math.ceil(total / pageSize);
    const selectedPageIndex =
      pageIndex > 0 && Math.ceil((pageIndex * pageSize) / 100);
    if (isLastPage && total < copayData?.totalSize) {
      setCopayOffset(selectedPageIndex);
      getCopayData(undefined, undefined, undefined);
      setTotal(total + 100);
    }
  }, [pageIndex]);

  const buildExpression = (firstEcosystems = '') => {
    const accountExp = getCustomerExpr(
      selectedCustomer,
      isArray(selectedCustomer)
    );
    const isAllSite =
      isArray(siteOptions) && siteOptions?.length === selectedSite?.length;

    const isAllProduct =
      isArray(productOptions) &&
      productOptions?.length === selectedProduct?.length;

    const isAllTimePeriod =
      isArray(timePeriodOptions) &&
      timePeriodOptions?.length === selectedTimePeriod?.length;

    const isAllSquad =
      isArray(squadOptions) && squadOptions?.length === selectedSquad?.length;

    const timeperiod = isAllTimePeriod ? 'TimePeriod=' : selectedTimePeriod;
    const squad = isAllSquad ? 'Squad=' : selectedSquad;
    const site = isAllSite ? 'Site=' : selectedSite;
    const product = isAllProduct ? 'Product=' : selectedProduct;

    // build ecosystem expression
    const ecosystemExp = firstEcosystems?.label
      ? 'Ecosystem={"' + firstEcosystems?.label + '"}'
      : isArray(selectedEcosystem)
      ? 'Ecosystem={"' +
        selectedEcosystem.map((a) => a?.label).join('","') +
        '"}'
      : selectedEcosystem?.label
      ? 'Ecosystem={"' + selectedEcosystem?.label + '"}'
      : '';

    // build timeperiod expression
    const timeperiodExp = isArray(timeperiod)
      ? 'Quarter={"' + timeperiod.map((a) => a?.label).join('","') + '"}'
      : '';
    // build squad expression
    const squadExp = isArray(squad)
      ? 'Squad={"' + squad.map((a) => a?.label).join('","') + '"}'
      : '';

    const siteExp = isArray(site)
      ? 'Site_ID={"' + site.map((a) => a?.value).join('","') + '"}'
      : '';
    // build product expression
    const productExp = isArray(product)
      ? 'Product={"' + product.map((a) => a?.label).join('","') + '"}'
      : '';
    let copayFilterExpression = `${accountExp}`;
    if (ecosystemExp !== '') {
      copayFilterExpression = copayFilterExpression.concat(`, ${ecosystemExp}`);
    }
    if (timeperiodExp !== '') {
      copayFilterExpression = copayFilterExpression.concat(
        `, ${timeperiodExp}`
      );
    }
    if (squadExp !== '') {
      copayFilterExpression = copayFilterExpression.concat(`, ${squadExp}`);
    }
    if (siteExp !== '') {
      copayFilterExpression = copayFilterExpression.concat(`, ${siteExp}`);
    }
    if (productExp !== '') {
      copayFilterExpression = copayFilterExpression.concat(`, ${productExp}`);
    }
    setCopayExpressions(copayFilterExpression);
    return copayFilterExpression;
  };

  useEffect(() => {
    const getTimeSPanLabel = async () => {
      let dateSlotRes = await getQlikSenseData(
        COPAY_CONSTANT.COPAY_TIMESLOT,
        [],
        COPAY_ANALYTICS_APP_ID
      );
      setTimeSpan(dateSlotRes?.data?.data?.category[0]);
    };

    getTimeSPanLabel();
  }, []);

  useEffect(() => {
    if (!firstLoad) getCopayData();
  }, [
    selectedEcosystem,
    selectedProduct,
    selectedSquad,
    selectedSite,
    selectedTimePeriod,
  ]);

  const getCopayData = async (sortColumn, sortOrder, firstEcosystem) => {
    let expression = buildExpression(firstEcosystem);
    dispatch(
      CopayAnalyticsData(
        mesaure,
        copayDimenssion,
        expression,
        sortColumn ? sortColumn : 'Total Paid Claims',
        sortOrder ? sortOrder : 'descend',
        copayOffset
      )
    );
    setFirstLoad(false);
  };

  useEffect(() => {
    if (!firstLoad) getCopayData();
  }, [copayDimenssion]);

  const handleSorting = (column, order) => {
    getCopayData(column, order);
  };

  const onMenuOpen = useCallback((filterName, value) => {
    let menuIsOpenLocal = menuIsOpen;

    if (filterName !== '' && value) {
      menuIsOpenLocal = Object.keys(menuIsOpen).reduce((acc, key) => {
        return { ...acc, [key]: filterName === key };
      }, {});
    } else if ((filterName !== '' && !value) || filterName === '') {
      menuIsOpenLocal = Object.keys(menuIsOpen).reduce((acc, key) => {
        return { ...acc, [key]: false };
      }, {});
    }
    setMenuIsOpen({ ...menuIsOpenLocal });
  }, []);

  const downloadData = () => {
    dispatch(
      exportData(
        TABLE_NAME_COPAY_ANALYTICS,
        selectedCustomer?.value,
        qlikCookie,
        qlikToken,
        copayExpressions,
        undefined,
        copayDimenssion,
        undefined,
        undefined,
        copayDimenssion,
        mesaure,
        selectedView === 'site' ? 'SiteCopayAnalytics' : 'AccountCopayAnalytics'
      )
    );
  };
  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    if (value === 'Site') {
      const newDimension = [value, 'Site_ID', 'site_zip'];
      copayDimenssion.indexOf(value) > -1
        ? setCopayDimension(
            copayDimenssion.filter(function (item) {
              return !newDimension.includes(item);
            })
          )
        : setCopayDimension([...copayDimenssion, ...newDimension]);
    } else {
      copayDimenssion.indexOf(value) > -1
        ? setCopayDimension(
            copayDimenssion.filter(function (item) {
              return item !== value;
            })
          )
        : setCopayDimension([...copayDimenssion, value]);
    }
    setSelectedColumns([
      ...selectedColumns,
      props.copayColumns.map((column) => {
        return copayDimenssion.includes(column.title);
      }),
    ]);
  };

  const handleColumnSelection = (checkedValues) => {
    setSelectedColumns(checkedValues);
  };

  const handlePopoverVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };
  const columnSelectionPanel = (
    <Checkbox.Group
      defaultValue={selectedColumns}
      onChange={handleColumnSelection}
      className={styles.checkboxGroup}
      key={Math.random()}>
      {props.copayColumns.map((column) => (
        <div
          className={styles.checkboxWrapper}
          key={column.id}>
          <Checkbox
            className={styles.checkbox}
            key={column.key}
            value={column.dataIndex}
            disabled={
              column.key === 'NewEnrollment' ||
              column.key === 'TotalEnrollment' ||
              column.key === 'TotalPaidClaims' ||
              column.key === 'UniqueUtilizers' ||
              column.key === 'ClaimsUtilization' ||
              column.key === 'EcosystemClaimsUtilization' ||
              column.key === 'NationalClaimsUtilization'
            }
            onChange={handleCheckboxChange}>
            {column.title}
          </Checkbox>
        </div>
      ))}
    </Checkbox.Group>
  );
  const exportTooltip = (
    <Tooltip bsclass='gne-tooltip tooltip--left'>Send to Excel</Tooltip>
  );

  const CopayTooltip = (
    <Tooltip bsclass='gne-tooltip tooltip--left'>
      {props.copayView === 'HDView' && (
        <>
          Total Paid Claims are the total number of claims paid during selected
          time period. These claims include both drug and admin claims for those
          brands that have an admin program.
          <br />
          <br />
          Claims Utilization % is a directional estimate of the percent of
          commercial patients who used the Copay card. We have 3 flavors for
          Claims Utilization % as follows:
          <br />
          <br />
          Claims Utilization % (selected account/ecosystem) is the claim
          utilization % for your account and selected ecosystem only.
          <br /> <br />
          Ecosystem Claims Utilization % (account agnostic) is the claims
          utilization % for the entire selected ecosystem and is
          account-agnostic.
          <br /> <br />
          National Claims Utilization % (account agnostic) is the claims
          utilization % for the entire nation and is account-agnostic.
        </>
      )}

      {props.copayView === 'FRMView' && (
        <>
          Total Paid Claims are the total number of claims paid during selected
          time period. These claims include both drug and admin claims for those
          brands that have an admin program.
          <br />
          <br />
          New Enrollments are the new patients enrolled in a specific product's
          Copay program during selected time period.
          <br />
          <br />
          Total Enrollments is the sum of all enrollments (new + re-enrolled
          patients).
          <br />
          <br />
          Unique Utilizers (Patients) are the total number of patients who
          submitted at least one claim during the selected time period.
        </>
      )}
    </Tooltip>
  );

  const popoverContent = (
    <div className={styles.customPopup}>{columnSelectionPanel}</div>
  );

  const handleClick = async (rowData) => {
    const payload = {
      value: rowData?.Site_ID,
      label: rowData?.Site,
      zip: rowData?.zip,
      flag: FLAG.site[0],
    };
    dispatch(setSeletedView('site'));
    dispatch(setFilterInit());
    dispatch(setEngagementDateInit());
    dispatch(setSelectedCustomer(payload));
    dispatch(setInsightType('general'));
    navigation(`/customerView/list/${rowData?.Site}`);
  };
  return (
    <>
      <div
        className='pt-3'
        id={props.copayView === 'HDView' ? 'copayHDView' : 'copayFRMView'}>
        <Row>
          <Col
            className='pt-3 d-flex align-item-center'
            md={6}>
            <p className='title title__bold title__large mb-1'>
              Copay Analytics
            </p>
            <OverlayTrigger
              placement='top'
              overlay={CopayTooltip}>
              <i className={`icon icon__info__gray mt-2 m-2 `} />
            </OverlayTrigger>
          </Col>
          <Col
            className='d-flex justify-content-end'
            md={6}>
            <Popover
              content={popoverContent}
              trigger='click'
              open={popoverVisible}
              onOpenChange={handlePopoverVisibleChange}>
              <i
                className={`icon icon__affiliations_standardTable_settings mt-3 me-3`}
              />
            </Popover>
            <OverlayTrigger
              placement='top'
              overlay={exportTooltip}>
              <i
                onClick={downloadData}
                className={`icon icon__affiliations_export_excel1 mt-3`}
              />
            </OverlayTrigger>
          </Col>
        </Row>
        <Row>
          <Col>
            <p
              className={`title title__x-small title__italic title__grey ${classes['co-verbiag']}`}>
              {timeSpan} | Provides directional understanding of Genentech Copay
              Program utilization by account. Select multiple quarters to
              aggregate the data. For a comparative view of quarters, use the
              gear icon and select quarter.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <label className='title__x-small'>Ecosystem</label>
            <SelectWithPagination
              menuIsOpen={menuIsOpen}
              onMenuOpen={onMenuOpen}
              filter={copayAnalyticsFilters}
              appid={COPAY_ANALYTICS_APP_ID}
              fieldName='copayEcosystem'
              isMulti={false}
              filterName={COPAY_ANALYTICS_FILTER_NAME}
              cacheUniqs={[
                selectedCustomer,
                selectedProduct,
                selectedSquad,
                selectedSite,
                selectedTimePeriod,
              ]}
            />
          </Col>

          <Col md={3}>
            <label className='title__x-small'>Site Name</label>
            <SelectWithPagination
              menuIsOpen={menuIsOpen}
              onMenuOpen={onMenuOpen}
              filter={copayAnalyticsFilters}
              appid={COPAY_ANALYTICS_APP_ID}
              fieldName='copaySiteAddressName'
              isMulti={true}
              filterName={COPAY_ANALYTICS_FILTER_NAME}
              cacheUniqs={[
                selectedCustomer,
                selectedProduct,
                selectedSquad,
                selectedTimePeriod,
                selectedEcosystem,
              ]}
            />
          </Col>

          <Col md={2}>
            <label className='title__x-small'>Time period</label>
            <SelectWithPagination
              menuIsOpen={menuIsOpen}
              onMenuOpen={onMenuOpen}
              filter={copayAnalyticsFilters}
              appid={COPAY_ANALYTICS_APP_ID}
              fieldName='copayQuarter'
              expression={copayFilterExpression}
              isMulti={true}
              filterName={COPAY_ANALYTICS_FILTER_NAME}
              cacheUniqs={[
                selectedCustomer,
                selectedProduct,
                selectedSquad,
                selectedSite,
                selectedEcosystem,
              ]}
            />
          </Col>
          <Col md={2}>
            <label className='title__x-small'>Product</label>
            <SelectWithPagination
              menuIsOpen={menuIsOpen}
              onMenuOpen={onMenuOpen}
              filter={copayAnalyticsFilters}
              appid={COPAY_ANALYTICS_APP_ID}
              fieldName='copayProduct'
              isMulti={true}
              filterName={COPAY_ANALYTICS_FILTER_NAME}
              cacheUniqs={[
                selectedCustomer,
                selectedSquad,
                selectedSite,
                selectedTimePeriod,
                selectedEcosystem,
              ]}
            />
          </Col>
          <Col md={2}>
            <label className='title__x-small'>Squad</label>
            <SelectWithPagination
              menuIsOpen={menuIsOpen}
              onMenuOpen={onMenuOpen}
              filter={copayAnalyticsFilters}
              fieldName='copaySquad'
              appid={COPAY_ANALYTICS_APP_ID}
              isMulti={true}
              filterName={COPAY_ANALYTICS_FILTER_NAME}
              cacheUniqs={[
                selectedCustomer,
                selectedProduct,
                selectedSite,
                selectedTimePeriod,
                selectedEcosystem,
              ]}
            />
          </Col>
        </Row>
        &nbsp;
      </div>

      <Row
        id={props.copayView === 'HDView' ? 'copayHDView' : 'copayFRMView'}
        className={classes.sitePerformanceContainer}>
        <Spin spinning={isLoading || isExporting}>
          <ConfigurableTable
            rows={copayData?.values}
            columns={getRestrictedColumn(
              TABLE_NAME_COPAY_ANALYTICS,
              filteredColumns,
              columnRestriction
            ).map((col) => {
              if (col.dataIndex === 'Site') {
                return {
                  ...col,
                  render: (text, record) => (
                    <span
                      className='span-link'
                      onClick={() => {
                        handleClick(record);
                      }}>
                      {text}
                    </span>
                  ),
                };
              }
              return col;
            })}
            expandRowByClick={true}
            enableColumnSelection={false}
            enableExport={false}
            tableName={TABLE_NAME_COPAY_ANALYTICS}
            expression={copayFilterExpression}
            isGlobalExport={true}
            pagination={pagination}
            isGlobalSort={true}
            onSort={handleSorting}
            dimentions={{ x: 1100, y: 400 }}
          />
        </Spin>
      </Row>
    </>
  );
};

export default CopayAnalytics;
