import classes from './Layout.module.scss';
import NavBar from 'components/shared/nav-bar-latest/NavBar';
import { navData } from '../../lib/navData';

const IFrameLayout = (props) => {
  return (
    <div className='d-flex'>
      {/* wrapper */}
      <NavBar items={navData} />

      {/* child components listed inside of the Layout tag */}
      <main className={`flex-grow-1 ${classes.content} ${classes.iframe}`}>
        {props.children}
      </main>
    </div>
  );
};

export default IFrameLayout;
