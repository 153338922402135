import { usePromiseTracker } from 'react-promise-tracker';
import RiseLoader from 'react-spinners/RiseLoader';

export const Spinner = (props) => {
  const { promiseInProgress } = usePromiseTracker({
    area: props.area,
    delay: 0,
  });

  return (
    <>
      {promiseInProgress && (
        <div
          className={`${props.css} spinner animate__animated animate__fadeIn`}>
          <RiseLoader
            type='ThreeDots'
            color='#004677'
            size={props.size | 4}
          />
        </div>
      )}
    </>
  );
};
