import { Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import classes from './TileDetails.module.scss';
import {
  getArrayLabelValues,
  getRestrictedProducts,
  getRestrictedSquads,
} from 'utils/utils';
import {
  ACCOUNT_TYPE,
  CONFIGURE_VIEW,
  CUSTOMER_TYPE_FLAG,
} from 'constants/constants';
import { useSelector } from 'react-redux';

function TileDetails({ card, page }) {
  const { filter: filterData } = card;
  const {
    selectedEcosystem = {},
    selectedTimePeriod = {},
    selectedAccount,
    selectedProduct,
    focusAccountChecked,
    accountableProduct,
    selectedSquad = {},
  } = filterData;
  const selectedSiteName = card?.kpiFilters?.selectedSiteName;

  const userRestrictedRole = useSelector(
    (state) => state?.Auth?.userRestriction
  );
  const userRestrictedEcosystem = useSelector(
    (state) => state?.Auth?.userRestrictedEcosystemt
  );
  const restrictedProductSquad = useSelector(
    (state) => state?.Auth?.restrictedProductSquad
  );

  const isMyView = page === CONFIGURE_VIEW.myView ? true : false;

  let ecosystemName = '';
  if (
    (isMyView || page === CONFIGURE_VIEW.myDefault) &&
    userRestrictedRole?.isNational === 'N' &&
    selectedEcosystem?.length === 0 &&
    !focusAccountChecked
  ) {
    ecosystemName = getArrayLabelValues(userRestrictedEcosystem);
  } else {
    ecosystemName =
      selectedEcosystem?.length > 0
        ? getArrayLabelValues(selectedEcosystem)
        : 'All Ecosystems';
  }

  let siteName = '';
  if (page === CONFIGURE_VIEW.insights) {
    let checkLength = selectedSiteName?.length;
    let checkValue = selectedSiteName === undefined;

    if (checkLength === 0 || checkValue) {
      siteName = 'All Sites';
    } else if (checkLength > 0) {
      siteName = getArrayLabelValues(selectedSiteName);
    }
  }

  let squadNames = '';
  if (
    (isMyView || page === CONFIGURE_VIEW.myDefault) &&
    userRestrictedRole?.squadRestriction === 'Y' &&
    selectedSquad?.length === 0
  ) {
    squadNames = getRestrictedSquads(
      restrictedProductSquad,
      accountableProduct
    );
  } else {
    squadNames =
      selectedSquad?.length > 0
        ? getArrayLabelValues(selectedSquad)
        : 'All Squads';
  }

  let selectedProductNames = '';
  if (
    (isMyView || page === CONFIGURE_VIEW.myDefault) &&
    userRestrictedRole?.productRestrictions === 'Y' &&
    selectedProduct?.length === 0
  ) {
    selectedProductNames = getRestrictedProducts(
      restrictedProductSquad,
      accountableProduct
    );
  } else {
    selectedProductNames =
      selectedProduct?.length === 0 && accountableProduct
        ? 'All Accountable Products'
        : selectedProduct?.length === 0 && !accountableProduct
        ? 'All Products'
        : getArrayLabelValues(selectedProduct);
  }

  const timePeriod = selectedTimePeriod?.label;
  const selectedAccountNames =
    page === 'Insights'
      ? selectedAccount?.label
      : focusAccountChecked === true
      ? selectedAccount?.length === 0
        ? `All Priority Customers`
        : getArrayLabelValues(selectedAccount)
        ? getArrayLabelValues(selectedAccount)
        : `All Priority Customers`
      : selectedAccount?.length === 0
      ? 'All'
      : getArrayLabelValues(selectedAccount)
      ? getArrayLabelValues(selectedAccount)
      : 'All';

  const productTooltip = () => {
    return (
      <Tooltip bsclass='gne-tooltip tooltip--left'>
        <p className='text text__x-small mb-1'>
          <strong>Product</strong>: {selectedProductNames}
        </p>
      </Tooltip>
    );
  };

  const siteNameTooltip = () => {
    return (
      <Tooltip bsclass='gne-tooltip tooltip--left'>
        <p className='text text__x-small mb-1'>
          <strong>Site </strong>: {siteName}
        </p>
      </Tooltip>
    );
  };
  const ecosystemTooltip = () => {
    return (
      <Tooltip bsclass='gne-tooltip tooltip--left'>
        <p className='text text__x-small mb-1'>
          <strong>Ecosystem</strong>: {ecosystemName}
        </p>
      </Tooltip>
    );
  };

  const squadTooltip = () => {
    return (
      <Tooltip bsclass='gne-tooltip tooltip--left'>
        <p className='text text__x-small mb-1'>
          <strong>Squad</strong>: {squadNames}
        </p>
      </Tooltip>
    );
  };

  const timePeriodTooltip = () => {
    return (
      <Tooltip bsclass='gne-tooltip tooltip--left'>
        <p className='text text__x-small mb-1'>
          <strong>Time Period</strong>: {timePeriod}
        </p>
      </Tooltip>
    );
  };

  const accountTooltip = () => {
    return (
      <Tooltip bsclass='gne-tooltip tooltip--left'>
        <p className='text text__x-small mb-1'>
          <strong>Customer : </strong> {selectedAccountNames}
        </p>
      </Tooltip>
    );
  };

  return (
    <>
      <Row>
        <Col
          md={7}
          className={classes.detail}>
          <span className={classes.title}> Customer :</span>
          <OverlayTrigger
            overlay={accountTooltip()}
            placement='bottom'>
            <span className={classes.ellipsis}>
              &nbsp; {selectedAccountNames}
            </span>
          </OverlayTrigger>
        </Col>
        {page === CONFIGURE_VIEW.insights &&
          CUSTOMER_TYPE_FLAG.get(ACCOUNT_TYPE.account).includes(
            selectedAccount?.flag
          ) && (
            <Col
              md={5}
              className={classes.detail}>
              <span className={classes.title}>Site : &nbsp;</span>
              <OverlayTrigger
                overlay={siteNameTooltip()}
                placement='bottom'>
                <span className={classes.ellipsis}> {siteName}</span>
              </OverlayTrigger>
            </Col>
          )}
      </Row>

      <Row>
        <Col
          md={7}
          className={classes.detail}>
          <span className={classes.title}>Squad: &nbsp;</span>
          <OverlayTrigger
            overlay={squadTooltip()}
            placement='bottom'>
            <span className={classes.ellipsis}> {squadNames}</span>
          </OverlayTrigger>
        </Col>
        <Col
          md={5}
          className={classes.detail}>
          <span className={classes.title}>Time Period: &nbsp;</span>
          <OverlayTrigger
            overlay={timePeriodTooltip()}
            placement='bottom'>
            <span className={classes.ellipsis}>{timePeriod}</span>
          </OverlayTrigger>
        </Col>
      </Row>

      <Row>
        <Col
          md={
            page === CONFIGURE_VIEW.myView ||
            page === CONFIGURE_VIEW.myDefault ||
            page === CONFIGURE_VIEW.insights
              ? 7
              : 12
          }
          className={classes.detail}>
          <span className={classes.title}>Product: &nbsp;</span>
          <OverlayTrigger
            overlay={productTooltip()}
            placement='bottom'>
            <span className={classes.ellipsis}>{selectedProductNames}</span>
          </OverlayTrigger>
        </Col>
        {(page === CONFIGURE_VIEW.myView ||
          page === CONFIGURE_VIEW.myDefault ||
          page === CONFIGURE_VIEW.insights) && (
          <Col
            md={5}
            className={classes.detail}>
            <span className={classes.title}>Ecosystem: &nbsp;</span>
            <OverlayTrigger
              overlay={ecosystemTooltip()}
              placement='bottom'>
              <span className={classes.ellipsis}>{ecosystemName}</span>
            </OverlayTrigger>
          </Col>
        )}
      </Row>
    </>
  );
}

export default TileDetails;
