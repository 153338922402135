import uuid from 'react-native-uuid';
const ContextMenu = ({ nodeActionList, onClickMenu }) => {
  const actionList = nodeActionList.map((oneAction, index) => {
    return (
      <div
        key={uuid.v4() + index}
        className='menu-item'
        id={oneAction.value}
        onClick={onClickMenu}>
        <i className={`icon icon__${oneAction.icon}`}></i>
        {oneAction.label}
      </div>
    );
  });
  return actionList;
};

export default ContextMenu;
