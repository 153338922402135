import { createSlice } from '@reduxjs/toolkit';
import { timelineViewInitialState } from './timeline-view-initial-state';

const timelineViewSlice = createSlice({
  name: 'timelineView',
  initialState: timelineViewInitialState,
  reducers: {
    setTimeline: (state, action) => {
      state.timeline = action.payload;
    },
    setMetrics: (state, action) => {
      state.metrics = action.payload;
    },
  },
});

export const { setTimeline, setMetrics } = timelineViewSlice.actions;
export default timelineViewSlice.reducer;
