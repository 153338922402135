import classes from './Notifications.module.scss';
import { useSelector } from 'react-redux';

const NotificationsList = () => {
  const notificationsData = useSelector(
    (state) => state?.HomeMyView?.notificationsData
  );

  const getDate = (inputDate) => {
    let getMonth = inputDate?.substr(5, 2);
    let getYear = inputDate?.substr(2, 2);
    let getDay = inputDate?.substr(8, 2);
    let finalDate = `${getMonth}/${getDay}/${getYear}`;
    return finalDate;
  };

  return (
    <div className={`${classes.alertsContainer}`}>
      {notificationsData?.length ? (
        notificationsData?.map((record) => (
          <>
            <div
              className={`d-flex  ${classes.releaseContainer}`}
              key={record?.ID}>
              <div className={`me-4 ${classes.releaseIcon}`}>
                <i className={`icon icon__${record?.CategoryIcon}`}></i>
              </div>
              <div className='me-3'>
                <p className='text text__x-small mb-1'>
                  {record?.Description}
                  <br />
                  <span className={classes.notification_date}>
                    {getDate(record?.CreatedDate)}
                  </span>
                </p>
              </div>
            </div>
            <hr className={`${classes.devider}`} />
          </>
        ))
      ) : (
        <div className='d-flex flex-column align-items-center '>
          <span className='text text__x-small mt-4'>
            You have no new notifications.
          </span>
        </div>
      )}
    </div>
  );
};

export default NotificationsList;
