import { loadAccountData } from 'store/customer/customer-view-actions';
import { moveSelected } from 'utils/utils';
import isEmpty from 'lodash/isEmpty';

export const loadOptions = async (
  offset,
  options,
  selected,
  type,
  ecosystem,
  prevEcosystem,
  searchQuery,
  loadMore,
  unixId,
  isMyPriority,
  dropdownAction,
  searchOptions
) => {
  let data = [];
  let hasMore = false;
  let listSize = 0;

  /**
    1.use the existing option list if
    user made a selection, is not search,
    ecosystem has not changed and hasn't reach the dropdown height.
    2. use the existing search option list if
    user made a selection, is search,
    ecosystem has not changed and hasn't reach the dropdown height.
  **/
  if (
    selected?.length > 0 &&
    !searchQuery &&
    ecosystem?.toString() === prevEcosystem?.toString() &&
    options?.length > 0 &&
    !loadMore
  ) {
    data = [...move(selected, options)];
  } else if (
    selected?.length > 0 &&
    ecosystem?.toString() === prevEcosystem?.toString() &&
    searchOptions.length > 0 &&
    !loadMore &&
    searchQuery &&
    dropdownAction === 'set-value'
  ) {
    data = [...move(selected, searchOptions)];
  } else {
    let { values, hasMoreElement, currentListSize } = await getData(
      type,
      ecosystem,
      offset,
      searchQuery,
      selected,
      unixId,
      isMyPriority,
      loadMore
    );

    hasMore = hasMoreElement;
    data = [...values];
    listSize = currentListSize;
  }

  return {
    data: data,
    hasMore: hasMore,
    currentListSize: listSize,
  };
};

export const getData = async (
  type,
  ecosystem,
  offset,
  searchQuery,
  selected,
  unixId,
  isMyPriority,
  loadmore
) => {
  let data = [];

  const { values, currentListSize, hasMoreElement } = await loadAccountData({
    offset: offset,
    searchToken: searchQuery,
    filed: type,
    ecosystems: ecosystem,
    unixId,
    isMyPriority,
  });

  // move the selected options to the top of the list
  if ((selected?.length > 0 || !isEmpty(selected)) && !loadmore) {
    const opts = moveSelected(selected, values);
    data = [...opts];
  } else {
    data = [...values];
  }

  return { values: data, currentListSize, hasMoreElement };
};

export const shouldLoadMoreOptions = (
  scrollHeight,
  clientHeight,
  scrollTop
) => {
  const bottomBorder = (scrollHeight - clientHeight) / 2;
  return bottomBorder < scrollTop;
};

const move = (selected, options) => {
  if (selected?.length > 0 && options?.length > 0) {
    const opts = moveSelected(selected, options);
    return opts;
  } else if (options.length > 0) {
    return options;
  }

  return options;
};

export const getSelectedDimensionId = (selectedKPI, selectedDimensionName) => {
  let dimensionId = selectedKPI?.dimensions.filter(
    (dm) => dm.dimensionName === selectedDimensionName
  )?.[0]?.id;

  return dimensionId;
};

export const getSelectedDimension = (dimensions, selectedDimensionName) => {
  let defaultDimension = dimensions?.filter(
    (dm) => dm.dimensionName === selectedDimensionName
  )?.[0];
  return defaultDimension;
};

export const dimensionDetails = (selectedKPI, selectedDimensionName) => {
  const dimension = selectedKPI?.dimensions.filter(
    (dm) => dm.dimensionName === selectedDimensionName
  )?.[0];

  return dimension;
};

export const searchResult = (result = [], searchText, field = 'label') => {
  const options = result?.filter((item) =>
    item[field]?.toLowerCase()?.includes(searchText?.toLowerCase())
  );
  return options;
};
