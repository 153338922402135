import api from 'api';
import isArray from 'lodash/isArray';
import moment from 'moment';

/**
 * Make an API call to get qlik data
 * @param {*} token
 * @param {*} expression the expression used qMeasure
 * @param {*} dimension the dimension used for qDimension
 * @param {*} limit the number of rows to return
 * @returns
 */
export const getQlikSenseData = async (
  expression,
  dimension,
  appId,
  limit,
  isSortDate
) => {
  const response = await api.get('qlik', {
    params: {
      measure: expression,
      dimension,
      appId,
      limit: limit,
      isSortDate: isSortDate,
    },
    headers: {
      qlikCookie: localStorage.getItem('qlikCookie'),
      Authorization: `Bearer ${localStorage.getItem('qlikToken')}`,
    },
  });

  if (response.error) {
    throw new Error('Could not fetch qlik data!');
  }

  setQlikAuth(
    response?.headers['authorization']?.split(' ')[1],
    response?.headers['qlikcookie']
  );

  return response;
};

/**
 * makes a call to get qlik data and
 * returns the dataset based on the dataType specified
 * @param {*} expressions expressions in the form of an array or string
 * @param {*} dimension optional dimension used for hypercube as an array or string
 * @param {string} appId the qlik app to open
 * @param {number} limit the number of rows to return
 * @param {*} dataType the type of data expected in the response, the options are in QLIK_DATATYPE as an array or string
 * @returns qlik data
 */
export const getQlikSenseUnformattedData = async (
  expressions,
  dimension,
  appId,
  limit,
  dataType,
  othersAfterLimit,
  isMultiExpression = false
) => {
  if (isArray(expressions)) {
    let results = [];
    if (isMultiExpression) {
      const res = await api.get(`qlik/account`, {
        params: {
          expression: expressions,
          dimension: dimension,
          dataType: dataType,
          appId,
          limit: limit,
          othersAfterLimit: othersAfterLimit,
        },
        headers: {
          qlikCookie: localStorage.getItem('qlikCookie'),
          Authorization: `Bearer ${localStorage.getItem('qlikToken')}`,
        },
      });
      return res?.data?.data;
    } else {
      let index = 0;
      for (const expression of expressions) {
        const response = await api.get(`qlik/account`, {
          params: {
            expression,
            dimension: isArray(dimension) ? dimension[index] : dimension,
            dataType: isArray(dataType) ? dataType[index] : dataType,
            appId,
            limit: isArray(limit) ? limit[index] : limit,
            othersAfterLimit: othersAfterLimit,
          },
          headers: {
            qlikCookie: localStorage.getItem('qlikCookie'),
            Authorization: `Bearer ${localStorage.getItem('qlikToken')}`,
          },
        });

        if (response) {
          results.push(response.data.data);
          setQlikAuth(
            response?.headers['authorization']?.split(' ')[1],
            response?.headers['qlikcookie']
          );
        }

        index++;
      }
    }

    return results;
  }

  const expression = expressions;

  const response = await api.get(`qlik/account`, {
    params: {
      expression,
      dimension,
      appId,
      limit,
      dataType,
      othersAfterLimit,
    },
    headers: {
      qlikCookie: localStorage.getItem('qlikCookie'),
      Authorization: `Bearer ${localStorage.getItem('qlikToken')}`,
    },
  });

  setQlikAuth(
    response?.headers['authorization']?.split(' ')[1],
    response?.headers['qlikcookie']
  );

  return response.data.data;
};

/**
 * makes a call to get qlik data and
 * returns the dataset based on the dataType specified
 * @param {string} appId the qlik app to open
 * @param {string} expression the expression for the hypercube
 * @param {Array} dimensions dimension as an array
 * @param {number} limit the number of rows to return
 * @param {number} width the number of columns to return
 * @returns qlik data
 */
export const getQlikSenseMultiDimensionData = async (
  appId,
  expression,
  dimensions,
  limit,
  width
) => {
  const response = await api.get(`qlik/account`, {
    params: {
      expression,
      dimension: dimensions,
      appId,
      limit,
      width,
    },
    headers: {
      qlikCookie: localStorage.getItem('qlikCookie'),
      Authorization: `Bearer ${localStorage.getItem('qlikToken')}`,
    },
  });

  setQlikAuth(
    response?.headers['authorization']?.split(' ')[1],
    response?.headers['qlikcookie']
  );

  return response.data.data;
};

/**
 * store qlik token and cookie to local storage
 * @param {*} token qlik token
 * @param {*} cookie qlik cookie
 */
export const setQlikAuth = (token, cookie) => {
  if (localStorage.getItem('qlikCookie') !== cookie) {
    localStorage.setItem('__cDate', moment(new Date()));
    localStorage.setItem('__tracker', 5);
  }
  localStorage.setItem('qlikToken', token);
  localStorage.setItem('qlikCookie', cookie);
};
