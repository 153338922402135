import { createSlice } from '@reduxjs/toolkit';
import { filterInitialState } from './filter-initial-state';

const engagementFilterSlice = createSlice({
  name: 'EngagementFilter',
  initialState: filterInitialState,
  reducers: {
    setSelectedStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setSelectedEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setEngagementDateInit: (state, action) => {
      state.startDate = filterInitialState.startDate;
      state.endDate = filterInitialState.endDate;
    },
  },
});

export const {
  setSelectedStartDate,
  setSelectedEndDate,
  setEngagementDateInit,
} = engagementFilterSlice.actions;
export default engagementFilterSlice.reducer;
