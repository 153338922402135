import { Row, Col } from 'react-bootstrap';

import classes from './Banner.module.scss';

import { NULL_STRING } from 'constants/constants';

const Contacts = ({ contacts, splitIndex }) => {
  const showContacts = contacts?.length > 0;
  const noData = [1, 2, 3];
  const trimTBHPrefix = (inputString) => {
    if (inputString?.startsWith('TB_')) {
      return inputString?.substring(3);
    } else {
      return inputString;
    }
  };
  return (
    <div className='d-flex align-items-center'>
      {showContacts &&
        splitIndex &&
        contacts.slice(0, splitIndex).map((contact, index) => (
          <div
            className={classes['contact-card']}
            key={index}>
            <div className={`${classes['contact-image']}`}></div>
            <div className={`${classes['contact-data']}`}>
              <p className='title__bold title__small ms-3 mb-0'>
                {contact.name || NULL_STRING}
                {contact.primayContactFlag === 'Y' && (
                  <i className='ms-2 icon icon__primarycontact'></i>
                )}
              </p>
              <p className={classes['contact-detail']}>
                <span
                  style={{ maxWidth: '50%' }}
                  title={contact.roleCode}
                  className={classes['role-code']}>
                  {trimTBHPrefix(contact.roleCode)}
                </span>
                <span className={classes['role-code']}>
                  <span className='big-dot'></span>
                  <span title={contact.ta}>{contact.ta}</span>
                </span>
              </p>
              <p className='title__small ms-3 mb-0'>
                <a
                  className={classes['email-section']}
                  href={
                    'https://mail.google.com/mail/?view=cm&fs=1&to=' +
                    contact.userEmail
                  }
                  target='_blank'
                  rel='noreferrer'>
                  {contact.userEmail}
                </a>
              </p>
            </div>
          </div>
        ))}

      {showContacts && !splitIndex && (
        <Row className='w-100'>
          {contacts?.map((contact, index) => (
            <Col
              md={6}
              className='column'
              key={index}>
              <div
                className={`${classes['contact-card']} ${classes['two-column']} ${classes['contact-card__grey']}`}>
                <div className={`${classes['contact-image-banner']}`}></div>
                <div className={`${classes['contact-data-banner']}`}>
                  <p className='title__bold title__small ms-3 mb-0'>
                    {contact.name || NULL_STRING}
                    {contact.primayContactFlag === 'Y' && (
                      <i className='ms-2 icon icon__primarycontact'></i>
                    )}
                  </p>
                  <p className={classes['contact-detail-banner']}>
                    <span
                      title={contact.roleCode}
                      className={classes['role-code']}>
                      {contact.roleCode}
                    </span>
                    <span className={classes['role-code']}>
                      <span className='big-dot big-dot__black'></span>
                      <span title={contact.ta}>{contact.ta}</span>
                    </span>
                  </p>
                  <p className='title__small ms-3 mb-0'>
                    <a
                      style={{ textDecoration: 'underline' }}
                      href={
                        'https://mail.google.com/mail/?view=cm&fs=1&to=' +
                        contact.userEmail
                      }
                      target='_blank'
                      rel='noreferrer'>
                      {contact.userEmail}
                    </a>
                  </p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      )}

      {!showContacts &&
        noData.map((item) => (
          <div
            className={classes['contact-card']}
            key={item}>
            {NULL_STRING}
          </div>
        ))}
    </div>
  );
};

export default Contacts;
