import GNEAsyncPagination from 'components/shared/dropdown-with-pagination/GNEAsyncPagination';
import { ALL, CONFIGURE_VIEW, CUSTOMER_TYPE } from 'constants/constants';
import { isArray } from 'lodash';
import { useRef, useState } from 'react';
import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { wrapMenuList } from 'react-select-async-paginate';
import { loadOptions } from 'store/customer/customer-view-actions';
import {
  setLocalFilterDependencyData,
  setLocalFiltersData,
} from 'store/trends_v2/trendsSlice';
import {
  ConvertSingleMultiSelectValue,
  createDynamicFieldExp,
  dropdownOptions,
  formatOptionLabel,
  getCustomerExpr,
  resolveDependencies,
  ValueContainer,
} from 'utils/utils';

const LocalFilters = ({ view, page, setDisableReset }) => {
  const dispatch = useDispatch();
  const KPIFilter = useSelector((state) => state.trends.KPIFilter);
  const localFilter = useSelector((state) => state.trends.localFilters);
  const localFiltersData = useSelector(
    (state) => state.trends.localFiltersData
  );
  const depenadecyData = useSelector(
    (state) => state.trends.localFiltersDependencyData
  );
  const selectedCustomer = useSelector(
    (state) => state?.CustomerView?.selectedCustomer
  );
  const selectedStartDate = useSelector(
    (state) => state?.EngagementFilters?.startDate
  );
  const selectedEndDate = useSelector(
    (state) => state?.EngagementFilters?.endDate
  );
  const { focusAccountChecked, priorityCustomers } = useSelector(
    (state) => state?.CustomerView
  );
  const { selectedKPI } = useSelector((state) => state?.trends);
  const qlikToken = localStorage.getItem('qlikToken');
  const qlikCookie = localStorage.getItem('qlikCookie');
  const [searchOffset, setSearchOffset] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [offset, setOffset] = useState(0);
  const isValidSearchText = (text) => text?.length >= 3 || !text;
  const firstLoading = useRef(true);
  const shouldLoadMore = useRef(false);

  const getDynamicExpression = async (fieldName = '', dependencies) => {
    let dynamicLocalFilterExp = createDynamicFieldExp(
      { ...localFiltersData, ...KPIFilter, ...{ localFilter } },
      fieldName,
      dependencies,
      localFilter
    );
    if (dependencies.includes('selectedCustomer')) {
      if (focusAccountChecked && selectedCustomer.length === 0) {
        dynamicLocalFilterExp =
          dynamicLocalFilterExp !== ''
            ? dynamicLocalFilterExp.concat(
                ', ',
                getCustomerExpr(priorityCustomers, isArray(priorityCustomers))
              )
            : (dynamicLocalFilterExp = getCustomerExpr(
                priorityCustomers,
                isArray(priorityCustomers)
              ));
      } else if (
        selectedCustomer &&
        selectedCustomer.length > 0 &&
        dynamicLocalFilterExp !== ''
      ) {
        dynamicLocalFilterExp = dynamicLocalFilterExp.concat(
          ', ',
          getCustomerExpr(selectedCustomer, isArray(selectedCustomer))
        );
      } else if (
        selectedCustomer &&
        selectedCustomer.length > 0 &&
        dynamicLocalFilterExp === ''
      ) {
        dynamicLocalFilterExp = getCustomerExpr(
          selectedCustomer,
          isArray(selectedCustomer)
        );
      }
    }
    return dynamicLocalFilterExp;
  };
  const shouldLoadMoreOptions = (scrollHeight, clientHeight, scrollTop) => {
    if (firstLoading.current) {
      firstLoading.current = false;
      return true;
    }
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    const loadMore = bottomBorder < scrollTop;
    shouldLoadMore.current = loadMore;
    return loadMore;
  };
  const callLoadOptions = async (
    inputValue,
    firstLoad,
    fieldName,
    isMulti,
    dependencies
  ) => {
    if (!isValidSearchText(inputValue)) {
      return {
        options: [],
      };
    }
    let searchOffsetValue = searchOffset;
    if (inputValue !== searchText) {
      setSearchOffset(0);
      searchOffsetValue = 0;
    }
    setSearchText(inputValue);
    const params = {
      appId: selectedKPI?.AppId,
      fieldName,
      offset:
        firstLoad === true
          ? 0
          : firstLoading.current
          ? 0
          : !shouldLoadMore.current
          ? 0
          : offset,
      qlikToken,
      qlikCookie,
      searchText: inputValue,
      searchOffset: searchOffsetValue,
      account:
        focusAccountChecked && selectedCustomer.length === 0
          ? priorityCustomers
          : selectedCustomer,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      field: view,
      expression: await getDynamicExpression(fieldName, dependencies),
    };
    const { values, currentListSize, hasMoreElement } = await loadOptions(
      params,
      isMulti
    );

    if (hasMoreElement) {
      inputValue?.length >= 3
        ? setSearchOffset(searchOffset + currentListSize)
        : setOffset((prevValue) => prevValue + currentListSize);
    }

    return {
      options: values?.flatMap((item) => {
        if (item?.value !== null && item?.value !== '') {
          const formattedOption = item.label?.split('||');

          return {
            label: formattedOption[0],
            address: formattedOption[1],
            value:
              formattedOption?.length === 1
                ? formattedOption[0]
                : formattedOption[2],
          };
        } else return [];
      }),
      hasMore: hasMoreElement,
    };
  };

  const handleFilterChange = (value, filterName, depenadacyName) => {
    if (!filterName) {
      return;
    }
    dispatch(
      setLocalFiltersData({
        ...localFiltersData,
        [filterName]: {
          ...localFiltersData[filterName],
          value: ConvertSingleMultiSelectValue(value),
        },
      })
    );
    dispatch(
      setLocalFilterDependencyData({
        ...localFiltersData,
        [depenadacyName]: {
          ...localFiltersData[depenadacyName],
          value: ConvertSingleMultiSelectValue(value),
        },
      })
    );
    setDisableReset(false);
  };
  const tooltip = (content) => {
    return (
      <Tooltip
        placement='left'
        bsclass='gne-tooltip tooltip--left'>
        <p className='text text__x-small mb-1'>{content}</p>
      </Tooltip>
    );
  };

  const isDisabled = (filter) => {
    return page === CONFIGURE_VIEW.insights &&
      CUSTOMER_TYPE.get(selectedCustomer?.flag) === 'site' &&
      filter?.filterName === 'SiteName'
      ? true
      : false;
  };

  return (
    <>
      {localFilter?.map((filter, index) => {
        return (
          <Col
            key={index}
            sm={12}
            className='p-0'>
            <label
              className={`title__x-small ${
                isDisabled(filter) ? 'title__grey' : ''
              }`}>
              {filter.filterLabel}
            </label>
            {filter?.tooltip && (
              <OverlayTrigger
                overlay={tooltip(filter.tooltip)}
                placement='bottom'>
                <span
                  className={`d-inline-block justify-content-center ms-2 mt-1`}>
                  <i className='icon icon__info__gray'></i>
                </span>
              </OverlayTrigger>
            )}
            <GNEAsyncPagination
              closeMenuOnSelect={false}
              isClearable
              components={{
                ValueContainer: ValueContainer,
                Option: wrapMenuList(dropdownOptions),
              }}
              value={localFiltersData[filter?.qExprClm]?.value}
              loadOptions={(searchValue, firstLoad) => {
                return callLoadOptions(
                  searchValue,
                  firstLoad,
                  filter?.qClm,
                  filter.isMulti === 'Y',
                  filter.dependencies
                );
              }}
              menuPosition='fixed'
              // menuPlacement='top'
              shouldLoadMore={shouldLoadMoreOptions}
              cacheUniqs={resolveDependencies(filter.dependencies, {
                ...localFiltersData,
                ...KPIFilter,
                ...{ selectedCustomer },
                ...{ selectedKPI },
                ...depenadecyData,
              })}
              onChange={(value) => {
                handleFilterChange(value, filter.qExprClm, filter.filterName);
              }}
              placeholder={
                filter.isMulti === 'Y' ? `Select ${filter.filterLabel}` : ALL
              }
              isMulti={filter.isMulti === 'Y' ? true : false}
              isDisabled={isDisabled(filter)}
              getOptionValue={(option) => option?.value}
              getOptionLabel={(option) => option?.label}
              getOptionId={(option) => option?.id}
              formatOptionLabel={(data, { context }) =>
                formatOptionLabel(data, { context })
              }
            />
          </Col>
        );
      })}
    </>
  );
};

export default LocalFilters;
