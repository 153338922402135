import { ProSidebarProvider } from 'react-pro-sidebar';

import Layout from 'components/layout/Layout';
import CustomerHierarchy from 'components/customer/hierarchy/CustomerHierarchy';

const CustomerHierarchyView = () => {
  return (
    <ProSidebarProvider>
      <Layout showRighSideBar={false}>
        <div className='gne-container'>
          <CustomerHierarchy />
        </div>
      </Layout>
    </ProSidebarProvider>
  );
};

export default CustomerHierarchyView;
