import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Input } from 'antd';
import { useSelector } from 'react-redux';
import GNEModal from 'components/shared/modal/Modal';
import LinkButton from 'components/shared/button/LinkButton';
import { removeSites, addLogs } from 'services/org/org.service';
import {
  LOGTYPE,
  CHANGEACTIONTYPE,
  CHANGEVALUETYPE,
} from 'constants/constants';
import classes from './RemoveSiteLayout.module.scss';

const RemoveSiteLayout = ({
  showModal,
  setShowModal,
  currentNode,
  onCallOrgChartApi,
  account,
}) => {
  const { TextArea } = Input;
  const [removeReason, setRemoveReason] = useState('');
  const [missingReason, setMissingReason] = useState(false);

  const { unixId } = useSelector((state) => state.Auth.userClaimObject);

  const handleCloseModal = () => {
    setShowModal(false);
    setMissingReason(false);
  };

  const onChangeReason = (value) => {
    setRemoveReason(value);
  };

  const addActionLog = async () => {
    const payload = [
      {
        LogType: LOGTYPE.userlog,
        ViewChangeType: CHANGEACTIONTYPE.hierarchy,
        ImpactedMdmId: currentNode?.parentId,
        ValueType: CHANGEVALUETYPE.parent,
        OldValue: currentNode?.id,
        NewValue: '',
        Action: 'remove',
        Flag: '',
        isActive: 'Y',
        ActionDetails: `removed ${currentNode?.name} ${currentNode?.childMdmId} from ${account?.label} ${account?.value})`,
        unixId: unixId,
        customerId: account?.value,
      },
    ];
    await addLogs(payload);
  };

  const handleRemove = async () => {
    if (removeReason === '') {
      setMissingReason(true);
    } else {
      const payload = [
        {
          isActive: 'N',
          unixId: unixId,
          reason: removeReason,
          childMdmId: currentNode?.childMdmId,
          userParentMdmId: currentNode?.parentId || '',
          cpMdmId: account?.value,
        },
      ];
      await removeSites(payload, [currentNode], account?.label);
      onCallOrgChartApi();
      setShowModal(false);
      addActionLog();
    }
  };

  const content = (
    <div className={classes['content-container']}>
      <div className='d-flex align-items-center'>
        <i
          className={`icon icon__remove-black ${classes['remove-icon-wrap']}`}></i>
        <span className={classes['title-wrap']}>Remove Site</span>
      </div>
      <div className={classes['subtitle-wrap']}>
        The following site(s) will be removed from its hierarchy.
      </div>
      <div className={classes['sitename-wrap']}>
        <div className={classes['site-title']}>{currentNode?.name}</div>
        <i className={`icon icon__info__gray ${classes['info-icon']}`}></i>
      </div>
      {/* add a condition to show the warning message in case of child sites. */}
      {currentNode?._directSubordinates > 0 && (
        <div className={classes['warning-box']}>
          <div className={classes['popup-wrap']}>
            <i
              className={`icon icon__warning ${classes['warning-icon-wrap']}`}></i>
          </div>
          <div className={classes['warning-message']}>
            The{' '}
            {currentNode?._directSubordinates > 1
              ? `${currentNode?._directSubordinates} sites `
              : ` ${currentNode?._directSubordinates} site `}
            that fall under {currentNode?.name} will also be removed.
          </div>
        </div>
      )}
      <div className={classes['textbox-wrap']}>
        <div className={`d-flex ${classes['textbox-title']}`}>
          <div className={classes['mandatory-icon-style']}>*</div>Reason
        </div>
        <TextArea
          maxLength={1000}
          rows={4}
          showCount
          value={removeReason}
          onChange={(e) => onChangeReason(e.target.value)}
          className={missingReason && classes['error-message']}
        />
      </div>
    </div>
  );

  const footer = (
    <div className='d-flex justify-content-end w-100'>
      <LinkButton
        className='text text__small'
        onClick={handleCloseModal}>
        Close
      </LinkButton>
      <Button
        variant='btn btn-gne btn-primary'
        onClick={handleRemove}
        className='text__small ms-3'
        disabled={removeReason === ''}>
        Remove
      </Button>
    </div>
  );
  return (
    <GNEModal
      show={showModal}
      onHide={handleCloseModal}
      content={content}
      css='size-medium'
      footer={footer}
      backdrop='static'
    />
  );
};

export default RemoveSiteLayout;
