/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Container,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import classes from './MoveSiteLayout.module.scss';
import LinkButton from 'components/shared/button/LinkButton';
import { useEffect, useState } from 'react';
import Filters from '../../filter/Filters';
import { getAccount, moveSites, addLogs } from 'services/org/org.service';
import { useSelector } from 'react-redux';
import { getSiteGridData } from 'services/org/label.service';
import { Spin } from 'antd';
import {
  ACCOUNT_TYPE,
  ADD_SITE_SUBTEXT,
  NO_SITE_SELECTED,
  NO_SITE_SELECTED_SUBTEXT,
  optionsInitialState,
  LOGTYPE,
  CHANGEACTIONTYPE,
  CHANGEVALUETYPE,
  ORG_ACTIONS,
  ACTION_TYPE,
} from 'constants/constants';
import TextArea from 'antd/es/input/TextArea';
import SiteHeirarchyTable from '../SiteHeirarchyTable';

const MoveSiteLayout = (props) => {
  const accountId = props.account?.value;
  const accountName = props.account?.label;
  const [key, setKey] = useState('moveSite');

  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedAccount, setSeletedAccount] = useState({});

  const [selectedRowAdd, setSelectedRowAdd] = useState([]);
  const [selectedOption, setSelectedOption] = useState(optionsInitialState);
  const [addTo, setaddTo] = useState(ACCOUNT_TYPE.site);
  const [text, setText] = useState('');
  const [sitesDataTo, setSitesDataTo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [gridSize, setGridSize] = useState(0);
  const maxLength = 1000;
  const { unixId } = useSelector((state) => state.Auth.userClaimObject);

  useEffect(() => {
    setSeletedAccount(props.data[0]);
    setSelectedRow(props.nodeData);
  }, []);

  const handleRowSelectSite = (selectedRow) => {
    setSelectedRowAdd(selectedRow);
  };

  const onhandleAddTo = (value) => {
    setaddTo(value);
  };

  const onhandleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxLength) {
      setText(inputValue);
    }
  };

  const resetOptions = () => {
    setSelectedOption(optionsInitialState);
  };

  const handleFilterApply = async (filters) => {
    setIsLoading(true);
    const payload = {
      filters: {
        ...filters,
        customerId: accountId,
        unixId: unixId,
        fieldName: 'sites',
        offset: 0,
        action: ACTION_TYPE.move,
        cpMdmId: accountId,
      },
    };
    const data = await getSiteGridData(payload);
    if (key === 'moveSite') {
      setSitesDataTo(data?.result?.values);
      setGridSize(data?.result?.currentListSize);
    }
    setIsLoading(false);
  };

  const handleFilterSelect = () => {};

  useEffect(() => {
    getAccountDetails();
  }, []);

  const getAccountDetails = async () => {
    const result = await getAccount(accountId);
    setSeletedAccount(result[0]);
  };

  const addActionLog = async () => {
    const payload = [
      {
        LogType: LOGTYPE.userlog,
        ViewChangeType: CHANGEACTIONTYPE.hierarchy,
        ImpactedMdmId: accountId,
        ValueType: CHANGEVALUETYPE.parent,
        OldValue: '',
        NewValue: selectedRow?.key,
        Action: ACTION_TYPE.move,
        Flag: '',
        isActive: 'Y',
        ActionDetails: `moved ${selectedRow?.name} (${selectedRow?.childMdmId}) to ${selectedRowAdd?.name} (${selectedRowAdd?.childMdmId})`,
        unixId: unixId,
        customerId: accountId,
      },
    ];
    await addLogs(payload);
  };

  function createPayLoad(addTo, selectedRow, selectedRowAdd, text, unixId) {
    const outputArray = [];
    const { childMdmId } = selectedRow;
    const output = {
      childMdmId,
      hcosParentMdmId: selectedRow?.hcosParentMdmId || '',
      hcosChildLevel: selectedRow?.hcosChildLevel || '',
      hcosParentLevel: selectedRow?.hcosParentLevel || '',
      userParentMdmId:
        addTo === ACCOUNT_TYPE.account
          ? accountId
          : selectedRowAdd?.childMdmId || '',

      userChildLevel:
        addTo === ACCOUNT_TYPE.account
          ? selectedAccount.UserChildLevel + 1
          : selectedRowAdd.userChildLevel + 1,
      userParentLevel:
        addTo === ACCOUNT_TYPE.account
          ? selectedAccount.UserChildLevel
          : selectedRowAdd.userChildLevel,
      sharedSite: selectedRow?.shared || 'N',
      isActive: 'Y',
      unixId: unixId,
      reason: text,
      cpMdmId: props?.nodeData?.cpMdmId,
    };

    // Push the output to the outputArray
    outputArray.push(output);
    return outputArray;
  }

  const handleApply = async (selectedRow, selectedRowAdd, text) => {
    const payload = createPayLoad(
      addTo,
      selectedRow,
      selectedRowAdd,
      text,
      unixId
    );

    if (payload?.length > 0) {
      await moveSites(payload);
      props.onCallOrgChartApi();
      addActionLog();
    }
  };

  const tooltip = () => {
    return (
      <Tooltip bsclass='gne-tooltip tooltip--left'>
        {Object.keys(selectedRow)?.length > 0 ? (
          <p className='text text__x-small mb-1'>{selectedRow?.name}</p>
        ) : (
          'No Sites Selected'
        )}
      </Tooltip>
    );
  };

  const onCallNext = () => {
    setKey(ACTION_TYPE.reason);
  };

  const onCallPrevious = () => {
    setKey(ACTION_TYPE.moveSite);
  };

  return (
    <Modal
      {...props}
      size='xl'
      className='title gne-modal'>
      <Modal.Header
        closeButton
        className='ps-0'>
        <Modal.Title
          id='contained-modal-title-vcenter'
          className='w-100'>
          <Row className='justify-content-between align-items-center mb-2 mt-3'>
            <Col className='pe-0'>
              <i className={`icon icon__move ${classes['icon-alignment']}`}></i>
              <span className='title__medium'>Move Sites</span>
              <p className='title__small'>{ADD_SITE_SUBTEXT}</p>
            </Col>

            <Col className='ps-0'>
              <div
                className={`site-tag ${
                  selectedRow?.length > 0 ? 'selected' : 'no-selection-site-tag'
                }`}>
                <span className={classes['text-decorate-ellipsis']}>
                  {`Selected Sites :
                      ${
                        selectedRow?.name?.length === 0
                          ? 'No Site Selected '
                          : selectedRow?.name ?? ''
                      } `}
                </span>
                <OverlayTrigger
                  overlay={tooltip()}
                  placement='bottom'>
                  <span className={`d-inline-block ${classes['info-icon']}`}>
                    <i className='icon icon__info__gray'></i>
                  </span>
                </OverlayTrigger>
              </div>
            </Col>

            <Col className='ps-0 pe-0'>
              <div
                className={`site-tag ${
                  selectedRowAdd?.name?.length > 0
                    ? 'selected'
                    : 'no-selection-site-tag'
                }`}>
                <span className={classes['text-decorate-ellipsis']}>
                  Move To :{' '}
                  {addTo === ACCOUNT_TYPE.account
                    ? accountName
                    : selectedRowAdd.length === 0
                    ? 'No Site Selected'
                    : selectedRowAdd?.name}
                </span>
              </div>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className='show-grid p-0 m-0'>
        <Container className='p-0 gne-container'>
          <div className='button-wrapper'>
            <Button
              variant='btn btn-gne btn-primary me-2 ml-2'
              disabled={key === ACTION_TYPE.moveSite}
              onClick={onCallPrevious}>
              Previous
            </Button>

            {key === ACTION_TYPE.reason ? (
              <Button
                variant='btn btn-gne btn-primary me-2 ml-2'
                disabled={text.length < 1}
                onClick={() => handleApply(selectedRow, selectedRowAdd, text)}>
                Apply
              </Button>
            ) : (
              <Button
                variant='btn btn-gne btn-primary me-2 ml-2'
                disabled={
                  (addTo === ACCOUNT_TYPE.account &&
                    Object.keys(selectedAccount).length === 0) ||
                  (addTo === ACCOUNT_TYPE.site &&
                    Object.keys(selectedRowAdd).length === 0)
                }
                onClick={onCallNext}>
                Next
              </Button>
            )}
          </div>
          <Tabs
            id='customeHierarchyActions'
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className='mb-3'>
            <Tab
              eventKey='moveSite'
              disabled={selectedRow.length === 0}
              title={
                <span className='d-flex'>1. Move selected Site(s) to</span>
              }>
              <Row>
                {/* Gross sale left panel */}
                <Col
                  md={4}
                  xs={5}
                  lg={4}
                  className={classes['library-container']}>
                  <Filters
                    accountId={accountId}
                    onFilterSelected={(selectedFilter) =>
                      handleFilterSelect(selectedFilter)
                    }
                    onFilterApply={(filters) => handleFilterApply(filters)}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    resetOptions={resetOptions}
                    page={'actions'}
                    pageType={'addTo'}
                    addToType={'addTo'}
                    selectedType={ACTION_TYPE.add}
                    onhandleAddTo={onhandleAddTo}
                    unixId={unixId}
                    initialFilterValue={optionsInitialState}
                    action={ORG_ACTIONS.filter}
                  />
                </Col>

                {/* Col for base filter and chart */}
                <Col>
                  {addTo === ACCOUNT_TYPE.account ? (
                    <div
                      className={`m-8 ${classes['default-message-container']}`}>
                      <p>
                        {`The site can only be moved within ${
                          accountName ?? '[CP Name]'
                        }.`}
                      </p>
                    </div>
                  ) : (
                    <div>
                      {sitesDataTo.length > 0 ? (
                        <Col>
                          <Spin spinning={isLoading}>
                            <Col>
                              <SiteHeirarchyTable
                                gridData={sitesDataTo}
                                type={'radio'}
                                handleRowSelect={handleRowSelectSite}
                                selectedRow={selectedRowAdd}
                                page='add'
                                total={gridSize}>
                                {' '}
                              </SiteHeirarchyTable>
                            </Col>
                          </Spin>
                        </Col>
                      ) : (
                        <Col>
                          <Spin spinning={isLoading}>
                            <div
                              className={`m-8 ${classes['message-align-wrap']}`}>
                              <div className={classes['default-message']}>
                                {NO_SITE_SELECTED}
                              </div>
                              <p>{NO_SITE_SELECTED_SUBTEXT}</p>
                            </div>
                          </Spin>
                        </Col>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            </Tab>
            <Tab
              eventKey='reason'
              disabled={
                (addTo === ACCOUNT_TYPE.account &&
                  Object.keys(selectedAccount).length === 0) ||
                (addTo === ACCOUNT_TYPE.site &&
                  Object.keys(selectedRowAdd).length === 0)
              }
              title={<span className='d-flex'>2. Reason</span>}>
              <div className={`${classes['reasonPanelHeight']} col-md-12`}>
                <p>*Reason</p>
                <TextArea
                  rows={3}
                  maxLength={1000}
                  value={text}
                  className={classes['label-editinput']}
                  showCount
                  onChange={onhandleChange}
                />
              </div>
            </Tab>
          </Tabs>
        </Container>
      </Modal.Body>

      <Modal.Footer className={classes['modal-footer']}>
        <LinkButton
          onClick={props.onHide}
          className='title title__small'>
          Close
        </LinkButton>
      </Modal.Footer>
    </Modal>
  );
};

export default MoveSiteLayout;
