import classes from './UnderMaintenance.module.scss';

const UnderMaintenance = () => {
  return (
    <div className={`d-flex flex-column align-items-center bg-white`}>
      <div className={`border-bottom border-lightgrey border-2`}>
        <i className='icon icon__logo animate__animated animate__rubberBand'></i>
      </div>
      <div className={`${classes.messageContainer} mt-4`}>
        <p
          className={`${classes.sessionMessage} mt-2 mb-2 text__grey title__bold title__medium`}>
          Dear C360 User,
        </p>
        <p
          className={`${classes.sessionMessage} mt-2 mb-2 text__grey title__bold title__medium`}>
          {process.env.REACT_APP_MAINTENANCE_MSG}
        </p>
        <p
          className={`${classes.sessionMessage} mt-2 mb-2 text__grey title__bold title__medium`}>
          We apologize for any inconvenience this may cause and appreciate your
          understanding as we strive to enhance our services.
        </p>
        <p
          className={`${classes.sessionMessage} mt-2 mb-2 text__grey title__bold title__medium`}>
          For any inquiries or further assistance, please do not hesitate to
          reach out to our support team at C360-USIX-d@gene.com.
        </p>
        <p
          className={`${classes.sessionMessage} mt-2 mb-2 text__grey title__bold title__medium`}>
          Thank you for your cooperation.
        </p>
      </div>
    </div>
  );
};

export default UnderMaintenance;
