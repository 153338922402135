import Favorites from './favorites/Favorites';
import Layout from 'components/layout/Layout';
import { ProSidebarProvider } from 'react-pro-sidebar';

const Home = () => {
  return (
    <ProSidebarProvider>
      <Layout showRighSideBar={true}>
        <div className='gne-container'>
          <Favorites />
        </div>
      </Layout>
    </ProSidebarProvider>
  );
};

export default Home;
