import Chart from 'components/charts/Echart';
import LinkButton from 'components/shared/button/LinkButton';
import { Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

const DataModal = ({ component, data, ...props }) => {
  const chartModal = component === 'RelationshipChart' ? true : false;

  const modalTitle = chartModal ? 'Relationship NPS' : 'Darwin Reports';
  return (
    <Modal
      {...props}
      size='lg'>
      {/* the modal header*/}
      <Modal.Header
        closeButton
        className={`modal-header border-0 m-3`}>
        <Modal.Title>
          <p className=' title title__bold mb-0'>
            {!chartModal && (
              <i className='icon icon__darwinReportsTitle me-1'></i>
            )}
            {modalTitle}
          </p>
          {chartModal && <p className='title__small'>Oct 21 - Sep 22</p>}
        </Modal.Title>
      </Modal.Header>

      {/* the modal body */}
      <Modal.Body className='m-3 mt-0'>
        {chartModal && (
          <div>
            <Chart
              chartData={data}
              chartType='line'
            />
            <span className='title__bold'># of Survey:</span>
          </div>
        )}
        {!chartModal && (
          <Table hover>
            <thead>
              <tr className='title__bold'>
                <td className='title__blue'>Name</td>
                <td>Published Date</td>
              </tr>
            </thead>
            <tbody>
              {data !== undefined &&
                data?.map((item, index) => (
                  <tr
                    key={index}
                    className='title__normal'>
                    <td>
                      <a
                        className='title__blue'
                        href={item.fileLink}
                        target='_blank'
                        rel='noreferrer'>
                        {item.fileName}
                      </a>
                    </td>

                    <td>{item.timePeriod}</td>
                    {/* <td>
                      <i className='icon icon__download m-0'></i>
                    </td> */}
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
      </Modal.Body>
      {chartModal && (
        <Modal.Footer className='ms-5 me-5'>
          <LinkButton
            onClick={props.onHide}
            className='title title__small'>
            Close
          </LinkButton>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default DataModal;
