export const navData = [
  {
    categoryID: 0,
    categoryName: 'Home',
    link: '/home',
    categoryIcon: 'home',
  },
  {
    categoryID: 1,
    categoryName: 'Customer View',
    link: '/customerView/list',
    categoryIcon: 'customer-view',
  },
  {
    categoryID: 2,
    categoryName: 'Customer Hierarchy',
    link: '/customer-hierarchy',
    categoryIcon: 'customer-hierarchy',
  },
  {
    categoryID: 3,
    categoryName: 'Trends',
    link: '/trends',
    categoryIcon: 'trends',
  },
];
